<template>
  <div class="shareAppointmentTab d-flex flex-column text-center">
    <p>
      Copy link to share a live view of this appointment. No personal account
      information will be shared. Please be mindful how you use it.
    </p>
    <div v-if="loadingMagicUrlState === 'loading'">
      <i>Generating URL...</i>
    </div>
    <div v-else-if="loadingMagicUrlState === 'error'">
      <div class="error--text">
        <i class="fa fa-exclamation-circle mr-1" /><span
          >Unable to generate the URL at this time. Please refresh and try
          again.</span
        >
      </div>
    </div>
    <div v-else>
      <div v-if="canCopyToClipboard">
        <v-btn
          :color="copyLinkState === 'error' ? 'error' : 'primary'"
          rounded
          @click="handleCopyLink"
        >
          {{ copyLinkText }}
        </v-btn>
      </div>
      <div v-else>
        <Input
          ref="appointmentUrlInput"
          v-model="copyLinkUrl"
          readonly
          label="Appointment URL:"
          @click="handleClickOnAppointmentUrlInput"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Appointment } from "serviceshift-ui/shared/src/typings/appointment";
import { Job } from "serviceshift-ui/shared/src/typings/job";
import { computed, onMounted, ref, watch } from "vue";
import { canCopyToClipboard } from "@/lib/clipboard";

import Input from "@/components/Input.vue";
import { useAppointmentHookData } from "@/hooks/useAppointmentHookData";

const props = defineProps({
  isCustomer: {
    type: Boolean,
    default: () => false
  },
  appointment: {
    type: Object as () => Appointment,
    default: () => null
  },
  job: {
    type: Object as () => Job,
    default: () => null
  }
});

const { generateMagicLink, updateAppointmentHookData } =
  useAppointmentHookData();
const copyLinkState = ref<"done">();
const copyLinkUrl = ref("");
const loadingMagicUrlState = ref<"loading" | "success" | "error">("loading");
const appointmentUrlInput = ref<any>(null);

const copyLinkText = computed(() => {
  switch (copyLinkState.value) {
    case "done":
      return "Link Copied!";
    default:
      return "Copy Appointment Link";
  }
});

watch(props, updateAppointmentHookData, { immediate: true });

onMounted(async () => {
  loadingMagicUrlState.value = "loading";
  try {
    await generateMagicLinkURL();
    loadingMagicUrlState.value = "success";
  } catch (e) {
    loadingMagicUrlState.value = "error";
  }
});

function handleClickOnAppointmentUrlInput() {
  const input = appointmentUrlInput.value!.$el.querySelector("input");
  input.focus();
  input.select();
}

async function generateMagicLinkURL() {
  let url = location.href;
  if (props.isCustomer) {
    url = await generateMagicLink();
  }
  copyLinkUrl.value = url;
}

async function handleCopyLink() {
  if (
    copyLinkState.value === "done" ||
    copyLinkState.value === "loadingMagicUrlState"
  )
    return;
  await navigator.clipboard.writeText(copyLinkUrl.value);
  copyLinkState.value = "done";
  setTimeout(() => (copyLinkState.value = undefined), 1000);
}
</script>
