import axios from "axios";

import ENV from "../env";

export default {
  addItem: (jobId, quoteId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/quotes/${quoteId}/items`, {
      method: "POST",
      data
    });
  },
  approveQuote: (jobId, quoteId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/quotes/${quoteId}/accept`, {
      method: "POST",
      data: {
        quote: data
      }
    });
  },
  emailQuote: (jobId, quoteId) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/quotes/${quoteId}/email`, {
      method: "POST"
    });
  },
  getAll: (jobId) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/quotes`, {
      method: "GET"
    });
  },
  getDiscounts: () => {
    return axios(`${ENV.v2Path}/discounts`, {
      method: "GET"
    });
  },
  removeItem: (jobId, quoteId, itemId) => {
    return axios(
      `${ENV.v2Path}/jobs/${jobId}/quotes/${quoteId}/items/${itemId}`,
      {
        method: "DELETE"
      }
    );
  },
  swapMembershipProgram: (jobId, quoteId, actualId, comparisonId) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/quotes/${quoteId}/pricing`, {
      method: "PUT",
      data: {
        actual_membership_program_id: actualId,
        comparison_membership_program_id: comparisonId
      }
    });
  },
  update: (jobId, quoteId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/quotes/${quoteId}`, {
      method: "PUT",
      data
    });
  },
  updateItem: (jobId, quoteId, item) => {
    return axios(
      `${ENV.v2Path}/jobs/${jobId}/quotes/${quoteId}/items/${item.id}`,
      {
        method: "PUT",
        data: {
          item
        }
      }
    );
  },
  updateMembershipProgram: (jobId, quoteId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/quotes/${quoteId}/pricing`, {
      method: "PUT",
      data
    });
  }
};
