<template>
  <v-footer app inset absolute>
    <div class="d-flex align-center full-width">
      <div class="d-flex flex-column full-width">
        <div class="footer-divider"></div>
        <div class="d-flex width-75 px-4">
          <div>
            <span>
              Copyright
              <v-icon>mdi-copyright</v-icon>
              2019 - {{ new Date().getFullYear() }}
              <a href="https://sera.tech">Sera Systems, Inc.</a>
              <br />All rights reserved.
              <a href="https://sera.tech/privacy-policy/" target="_blank"
                >Privacy Policy</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style lang="scss" scoped>
.v-footer {
  background-color: transparent !important;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0;
  padding-bottom: 15px;
  i {
    font-size: 0.8rem;
  }
}
img {
  height: 32px;
  transform: rotate(-20deg);
}
.footer-divider {
  margin-bottom: 15px;
  height: 2px;
  width: 100%;
}
.width-75 {
  width: 75%;
}
</style>
