import API from "serviceshift-ui/api-client";

export default {
  async cancelAppointment(
    { dispatch },
    params: {
      id: any;
      data: {
        reason: string;
      };
    }
  ) {
    const { id, data } = params;
    await API.makeRequest(`/v2/appointments/${id}/cancel`, {
      method: "PUT",
      data
    });
    dispatch("fetchAppointment", id);
  },
  async updateAppointment(
    { dispatch },
    params: {
      id: any;
      data: any;
    }
  ) {
    const { id, data } = params;
    await API.makeRequest(`/v2/appointments/${id}`, {
      method: "PUT",
      data
    });
    dispatch("fetchAppointment", id);
  }
};
