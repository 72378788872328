<template>
  <div>
    <Loader v-if="loading" />
    <Page v-else-if="invoice" :title="title">
      <div class="d-flex full-width">
        <router-link :to="{ name: 'invoices' }">
          <i class="mr-3 fa fa-arrow-left"></i>
          <span>Return to invoices</span>
        </router-link>
        <div
          v-if="invoice.financials.balance_cents === 0 && !invoice.voided"
          class="d-flex ml-auto justify-space-between mb-4 caption"
        >
          <v-btn color="primary" small text rounded @click="handleDownload">
            <v-icon class="subtitle-1 mr-1">mdi-download</v-icon>
            Download Invoice
          </v-btn>
        </div>
      </div>
      <CustomerInvoiceBalance
        v-if="invoice.financials.balance_cents > 0"
        :invoice="invoice"
        class="mb-6 full-width"
      >
        <template #actions>
          <div class="d-flex w-full">
            <div class="flex-1 d-flex justify-center">
              <button class="link" @click="showPaymentModal = true">
                Make a Payment
              </button>
            </div>
            <div class="flex-1 d-flex justify-center">
              <button class="link" @click="handleDownload">
                Download Invoice
              </button>
            </div>
          </div>
        </template>
      </CustomerInvoiceBalance>
      <div v-if="invoice" class="full-width">
        <v-alert v-if="$vuetify.breakpoint.xsOnly" type="warning" dense>
          Invoice best viewed in Landscape mode. Rotate your device for the best
          experience.
        </v-alert>
        <span class="overline text-left full-width">Invoice</span>
        <v-card
          class="full-width bordered-card mb-4 pa-3"
          style="font-size: 0.75rem !important; overflow-y: scroll"
        >
          <Invoice
            :invoice="invoice"
            :customizations="customizations"
            tech-mode
          />
        </v-card>
      </div>
    </Page>
    <InvoicePaymentModal
      v-model="showPaymentModal"
      :customer="user"
      :invoice="invoice"
      @payment-success="loadInvoice()"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { trackViewInvoice } from "@/lib/gaEvents";

import InvoicePaymentModal from "@/components/InvoicePaymentModal.vue";
import Loader from "@/components/Loader.vue";
import Page from "@/components/Page.vue";
import CustomerInvoiceBalance from "@/modules/paymentMethods/components/CustomerInvoiceBalance.vue";
import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";

export default {
  components: {
    Loader,
    CustomerInvoiceBalance,
    InvoicePaymentModal,
    Page
  },
  setup() {
    const { definePaymentCallbacks } = usePaymentMethods();
    return { definePaymentCallbacks };
  },
  data() {
    return {
      loading: true,
      showPaymentModal: false,
      details: [],
      dialog: false
    };
  },
  computed: {
    ...mapState({
      invoice: "currentInvoice",
      user: "user",
      customizations: ({ customizations, tenant }) => {
        return { ...customizations, Tenant: tenant };
      }
    }),
    title() {
      const title = `Invoice #${this.invoice.invoice_id}`;
      if (this.invoice.voided) return `${title} (Voided)`;

      return title;
    }
  },
  watch: {
    "$route.params.id": function () {
      this.loadInvoice();
    }
  },
  mounted() {
    this.loadInvoice();
    this.definePaymentCallbacks({
      onPaymentSuccess: this.loadInvoice
    });
  },
  methods: {
    ...mapActions(["fetchAppointment", "fetchInvoice"]),
    handleDownload() {
      window.open(this.invoice.pdf_url, "downloadInvoice");
    },
    async loadInvoice() {
      this.loading = true;
      try {
        trackViewInvoice({
          invoice_id: this.$route.params.id,
          customer_id: this.user.id
        });
        await this.fetchInvoice(this.$route.params.id);
      } catch (e) {
        // do nothing
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  margin: 0 !important;
}
.payment-method {
  text-transform: capitalize;
}
</style>
