export const generateUID = () => {
  return Math.floor(Math.random() * 1000) + Date.now();
};

// Capitalizes first letter in a word
export const capitalize = (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
};
