<template>
  <v-card class="mb-4 mt-1 pa-3 bordered-card">
    <v-select
      v-for="field in serviceCategory.details.fields"
      :key="field.name"
      outlined
      :label="field.label"
      :items="field.options"
      :value="formData[field.name]"
      @input="(value) => handleSelect(field, value)"
    ></v-select>
    <v-textarea
      v-model="notes"
      outlined
      label="Any additional information?"
    ></v-textarea>
    <v-text-field
      v-model="promoCode"
      outlined
      label="Promo Code"
    ></v-text-field>
    <v-file-input
      v-if="!noPhotoUpload && !$vuetify.breakpoint.smAndDown"
      v-model="files"
      color="primary"
      counter
      label="Add Photos"
      multiple
      prepend-icon
      prepend-inner-icon="mdi-file-image"
      outlined
      :show-size="1000"
      accept=".png, .jpg, .jpeg"
    >
      <template #selection="{ index, text }">
        <v-chip v-if="index < 2" color="secondary" dark label small>{{
          text
        }}</v-chip>
        <span
          v-else-if="index === 2"
          class="overline grey--text text--darken-3 mx-2"
          >+{{ files.length - 2 }} File(s)</span
        >
      </template>
    </v-file-input>
    <label
      v-if="!noPhotoUpload && $vuetify.breakpoint.smAndDown"
      class="mobile-photo-upload"
      :class="{ 'has-files': files.length > 0 }"
    >
      <span>
        <v-icon class="mr-1">mdi-file-image</v-icon>
        <span v-if="files.length === 0">Add Photos</span>
        <span v-else
          >{{ files.length }}
          {{ files.length === 1 ? "Photo" : "Photos" }} Added</span
        >
      </span>
      <input
        ref="fileUpload"
        class="file-input"
        type="file"
        accept=".png, .jpg, .jpeg"
        name="photos"
        multiple
        @change="handleMobileUpload"
      />
    </label>
    <span
      v-if="!noPhotoUpload && $vuetify.breakpoint.smAndDown"
      class="photo-hint-label"
      >Take a photo or select multiple from your photo library</span
    >
  </v-card>
</template>

<script>
export default {
  name: "AdditionalDetailsForm",
  props: {
    serviceCategory: {
      type: Object,
      default: () => ({})
    },
    updateDetails: {
      type: Function,
      default: () => {}
    },
    updatePromo: {
      type: Function,
      default: () => {}
    },
    noPhotoUpload: Boolean,
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      files: [],
      notes: this.formData.notes,
      promoCode: this.formData.promoCode
    };
  },
  watch: {
    value: function () {
      this.files = this.value;
    },
    files: function () {
      let photos = [];
      for (let i = 0; i < this.files.length; i++) {
        const file = this.files[i];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          photos.push({
            file,
            fileName: file.name,
            thumbnail: reader.result
          });
        };
      }
      this.$emit("input", photos);
    },
    notes(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updateDetails({ notes: newValue });
      }
    },
    promoCode(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.updatePromo(newValue);
      }
    }
  },
  methods: {
    handleSelect(field, value) {
      this.updateDetails({ [field.name]: value });
    },
    handleMobileUpload(e) {
      this.files = e.target.files;
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-photo-upload {
  padding: 12px 8px;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: inherit;
  width: 100%;
  display: block;
  &.has-files,
  &:focus {
    border-color: var(--v-primary-base);
    color: var(--v-secondary-base);
    i {
      color: var(--v-secondary-base);
    }
  }
}
.photo-hint-label {
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.7em;
}
.file-input {
  opacity: 0;
  height: 0;
  width: 0;
  outline: none;
}
</style>
