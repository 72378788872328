<template>
  <div class="d-inline-block">
    <Checkbox v-model="modelValue" label="Save this payment method" />
    <SavePaymentMethodModal
      v-model="showSavedPaymentNotice"
      :payment-type="paymentType"
      @cancel="modelValue = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";

import SavePaymentMethodModal from "@/modules/paymentMethods/components/modals/SavePaymentMethodModal.vue";

const props = defineProps({
  paymentType: {
    type: String,
    default: () => "Payment Method"
  },
  value: {
    type: Boolean,
    default: () => false
  }
});

const emit = defineEmits(["input"]);
const showSavedPaymentNotice = ref(false);
const originalSavedValue = ref(props.value);

const modelValue = computed({
  get() {
    return props.value;
  },
  set(val) {
    emit("input", val);
  }
});

watchEffect(() => {
  if (modelValue.value === false) {
    originalSavedValue.value = false;
  }
  if (modelValue.value === true && originalSavedValue.value !== true) {
    showSavedPaymentNotice.value = true;
  }
});
</script>
