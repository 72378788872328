export enum AnalyticsEvents {
  viewMagicLink = "magicLink.appointment:view",
  viewInvoice = "invoice:view",
  startedPayment = "payment:start",
  finishedPayment = "payment:finished",
  failedPayment = "payment:failed"
}

export const trackMagicLink = (params: { appointment_id: string | number }) => {
  window.gtag("event", AnalyticsEvents.viewMagicLink, params);
};

export const trackViewInvoice = (params: {
  invoice_id: string | number;
  customer_id: string | number;
}) => {
  window.gtag("event", AnalyticsEvents.viewInvoice, params);
};

export const trackStartedPayment = (params: {
  invoice_id: string | number;
  customer_id: string | number;
}) => {
  window.gtag("event", AnalyticsEvents.startedPayment, params);
};

export const trackPaymentFailure = (params: {
  invoice_id: string | number;
  customer_id: string | number;
}) => {
  window.gtag("event", AnalyticsEvents.failedPayment, params);
};

export const trackFinishedPayment = (params: {
  invoice_id: string | number;
  customer_id: string | number;
}) => {
  window.gtag("event", AnalyticsEvents.finishedPayment, params);
};
