import { Channel } from "@anycable/web";

export default class AppointmentsChannel extends Channel {
  static identifier = "AppointmentsChannel";

  receive(message) {
    if (message.position) {
      return this.emit("technician-location", message);
    }

    super.receive(message);
  }
}
