<template>
  <div>
    <div class="!tw-mb-4">
      <div class="!tw-mb-4"><b>Are you replacing?</b></div>
      <div class="tw-flex tw-items-center">
        <RadioButton
          v-model="replacing"
          label="Yes"
          :radio-value="true"
          name="replacing"
          class="tw-mr-8"
        />
        <RadioButton
          v-model="replacing"
          label="No"
          :radio-value="false"
          name="replacing"
        />
      </div>
    </div>
    <ReplaceFlow
      v-if="replacing"
      :equipment="filteredEquipment"
      :generic-equipment-type-id="genericEquipmentTypeId"
      @update:replacesEquipmentId="$emit('update:replacesEquipmentId', $event)"
      @update:newEquipmentId="$emit('update:newEquipmentId', $event)"
      @update:replacingType="$emit('update:replacingType', $event)"
      @update:replacingComponentIds="
        $emit('update:replacingComponentIds', $event)
      "
    >
      <template #equipmentForm="{ equipmentTypeId }">
        <slot name="equipmentForm" :equipment-type-id="equipmentTypeId" />
      </template>
      <template #componentForm="{ component }"
        ><slot name="componentForm" :component="component"
      /></template>
    </ReplaceFlow>
    <slot v-if="replacing === false" name="equipmentForm" />
  </div>
</template>

<script>
import RadioButton from "serviceshift-ui/components/Inputs/RadioButton.vue";
import * as EquipmentTypes from "serviceshift-ui/shared/src/classes/equipment";

import ReplaceFlow from "./ReplaceFlow.vue";
export default {
  name: "EquipmentWizard",
  components: {
    RadioButton,
    ReplaceFlow
  },
  props: {
    /** @type {EquipmentTypes.Equipment[]} */
    equipment: {
      type: Array,
      default: () => []
    },
    showArchivedEquipment: {
      type: Boolean,
      default: false
    },
    // The id for the tenants generic equipment type
    // used in replacing equipment flow
    genericEquipmentTypeId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      replacing: null
    };
  },
  computed: {
    filteredEquipment() {
      return this.equipment.filter((equipment) => {
        if (!this.showArchivedEquipment) return !equipment.archived;
        return true;
      });
    }
  },
  mounted() {
    // If no equipment has been passed in, the default for replacing should be "no". The user can still change this response.
    if (!this.equipment.length) this.replacing = false;
  }
};
</script>

<style lang="scss" scoped></style>
