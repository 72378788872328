import axios from "axios";

import ENV from "../env";

export default {
  create: (data) => {
    return axios(`${ENV.v2Path}/departments`, {
      method: "POST",
      data
    });
  },
  createPriceSheet: (id, data) => {
    return axios(`${ENV.v2Path}/departments/${id}/price_sheets`, {
      method: "POST",
      data: {
        price_sheet: data
      }
    });
  },
  deletePriceSheet: (id, priceSheetId) => {
    return axios(
      `${ENV.v2Path}/departments/${id}/price_sheets/${priceSheetId}`,
      {
        method: "DELETE"
      }
    );
  },
  get: (id) => {
    return axios(`${ENV.v2Path}/departments/${id}`, {
      method: "GET"
    });
  },
  getAll: () => {
    return axios(`${ENV.basePath}/departments`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json"
      },
      data: {}
    });
  },
  getPriceSheets: (id) => {
    return axios(`${ENV.v2Path}/departments/${id}/price_sheets`, {
      method: "GET"
    });
  },
  update: (data) => {
    return axios(`${ENV.v2Path}/departments/${data.id}`, {
      method: "PUT",
      data
    });
  },
  updatePriceSheet: (id, data) => {
    return axios(`${ENV.v2Path}/departments/${id}/price_sheets/${data.id}`, {
      method: "PUT",
      data: {
        price_sheet: data
      }
    });
  },
  uploadPriceSheet: (id, priceSheetId, data) => {
    return axios(
      `${ENV.v2Path}/departments/${id}/price_sheets/${priceSheetId}/upload`,
      {
        method: "PUT",
        data: data.body
      }
    );
  }
};
