<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {
  name: "BaseCard"
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.5rem;
  box-shadow: 0 3px 6px 0 $secondary-color;
  ::v-deep .card-header {
    border-radius: 25px 25px 0 0;
    padding: 0.75rem 0 !important;
    margin: 0 1.25rem;
  }
  ::v-deep .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
    padding-top: 0;
  }
}
</style>
