<template>
  <v-snackbar v-model="open" color="red" top class="error-snackbar">
    <div class="header text-center">
      <div class="close-icon" @click="toggleOpen">
        <v-icon small>mdi-close-circle</v-icon>
      </div>
    </div>
    <div class="d-flex flex-column full-width text-center">
      <slot name="body"></slot>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      open: true
    };
  },
  watch: {
    open(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.$emit("hide-modal");
      }
    }
  },
  methods: {
    toggleOpen() {
      this.open = !this.open;
    }
  }
};
</script>

<style lang="scss">
.error-snackbar {
  font-family: "Roboto";
  .v-snack__wrapper {
    min-width: auto !important;
    width: 300px !important;
  }
  .v-snack__content {
    padding: 10px 10px 10px 10px !important;
    margin: 0 auto;
    background: #e53935;
  }
  .header {
    position: relative;
    width: 100%;
  }
  .close-icon {
    position: absolute;
    top: -20px;
    right: -15px;
  }
}
</style>
