import Vue from "vue";
import Vuetify, { VTextField } from "vuetify/lib";

export const vuetifyOptions = {
  icons: {
    iconfont: "mdi"
  },
  theme: {
    options: {
      customProperties: true
    }
  },
  components: {
    VTextField
  }
};

Vue.use(Vuetify, vuetifyOptions);

export default new Vuetify(vuetifyOptions);
