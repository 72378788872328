<template>
  <div>
    <div class="gpi-form px-4" :class="{ hidden: !ready }">
      <PaymentMethodInput
        required
        label="Name on Card"
        class="w-full border-bottom mb-1"
        :error="errorMessages['nameOnCard']"
      >
        <Input v-model="name" solo />
      </PaymentMethodInput>
      <div class="border-bottom mb-3 flex items-center d-flex">
        <PaymentMethodInput
          iframe-input
          required
          :error="errorMessages['cardNumber']"
          label="Card Number"
          class="items-center flex-1"
        >
          <div id="card-number" class="gpi-field" />
        </PaymentMethodInput>
        <PaymentMethodInput
          iframe-input
          required
          tooltip="The CVV is a 3-4 digit number on the back of VISA, MasterCard, Discover, or from the front of American Express cards."
          :error="errorMessages['cvv']"
          label="CVV"
          class="items-center pl-4 cvv-field"
        >
          <div id="card-cvv" class="gpi-field" />
        </PaymentMethodInput>
      </div>
      <div class="d-flex border-bottom mb-1">
        <PaymentMethodInput
          iframe-input
          required
          :error="errorMessages['exp']"
          label="Expiration Date"
          class="flex items-center border-right exp-field"
        >
          <div id="card-expiration" class="gpi-field" />
        </PaymentMethodInput>
      </div>
      <BillingAddressForm
        ref="billingAddressForm"
        :address="billingAddress"
        class="mb-1"
      />
      <div id="submit" class="submit-container" />
      <div
        v-if="showSavePayment && config && config.card_savable && !autoSaveCard"
        class="flex-col mb-3 mt-4"
      >
        <SavePaymentMethodCheckbox
          v-model="saveCard"
          payment-type="Credit Card"
        />
      </div>
    </div>
    <div v-if="!ready" class="text-center p-6 my-4">
      Loading payment form...
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";

import Input from "@/components/Input.vue";
import BillingAddressForm from "@/modules/paymentMethods/components/BillingAddressForm.vue";
import PaymentMethodInput from "@/modules/paymentMethods/components/PaymentMethodInput.vue";
import usePaymentMethods from "../usePaymentMethods";
import SavePaymentMethodCheckbox from "./SavePaymentMethodCheckbox.vue";

const { paymentMethodOptions, customer, billingAddress } = usePaymentMethods();

const props = defineProps({
  showSavePayment: {
    type: Boolean,
    default: true
  },
  autoSaveCard: {
    type: Boolean,
    default: false
  }
});

const errorMessages = ref({
  nameOnCard: "",
  cardNumber: "",
  cvv: "",
  exp: ""
});
const form = ref(null as any);
const name = ref("");
const resolver = ref(null as any);
const rejector = ref(null as any);
const saveCard = ref(false);
const ready = ref(false);
const billingAddressForm = ref<any>(null);

defineExpose({
  getData,
  resetForm
});

const config = computed(() => {
  return paymentMethodOptions.value.find((method) => method.key === "gpi_card");
});

onMounted(() => {
  initForm();
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getData() {
  clearErrors();

  // Quick front end validation
  let errorMessage = "";
  let billingAddressData = {} as any;
  if (!name.value) {
    errorMessage = "Name is required.";
    errorMessages.value.nameOnCard = errorMessage;
  }
  try {
    billingAddressData = await billingAddressForm.value!.getData();
  } catch (e: any) {
    errorMessage = e;
  }
  if (errorMessage) {
    throw new Error(
      "Unable to submit GPI CC Payment form with error: " + errorMessage
    );
  }

  const formData = await new Promise<any>((resolve, reject) => {
    resolver.value = resolve;
    rejector.value = reject;
    form.value.frames.submit.events.click[0]();
  });
  return {
    ...formData,
    ...billingAddressData
  };
}

function initForm() {
  if (!config.value) {
    return;
  }
  try {
    // @ts-ignore
    window.GlobalPayments.configure({
      "X-GP-Api-Key": config.value.tokenization_key,
      "X-GP-Environment": config.value.environment
    });

    // @ts-ignore
    form.value = window.GlobalPayments.ui.form({
      fields: {
        "card-number": {
          target: "#card-number", //renders field to <div id="card-number">
          placeholder: "•••• •••• •••• ••••" //Optional
        },
        "card-expiration": {
          target: "#card-expiration",
          placeholder: "MM / YYYY"
        },
        "card-cvv": {
          target: "#card-cvv",
          placeholder: "•••"
        },
        submit: {
          text: "Validate Card", //Set text for Submit button
          target: "#submit"
        }
      },
      styles: {
        "#secure-payment-field-body": {
          background: "#D6FFD9"
        },
        "button, input": {
          outline: "none",
          "border-width": "0",
          "letter-spacing": "2px"
        },
        "input, select": {
          display: "block",
          width: "100%",
          height: "41px",
          "line-height": "1.5",
          color: "#495057",
          "background-color": "#ECF2FD",
          "background-clip": "padding-box",
          border: "none",
          "font-size": "14px",
          padding: "0 10px"
        },
        button: {
          display: "none"
        },
        "input.invalid": {
          border: "none",
          "background-color": "#f7e5e5"
        },
        ".card-number.card-type-visa": {
          background:
            "rgba(0,0,0,0) url(https://hps.github.io/token/gp-1.0.0/images/logo-visa@2x.png) no-repeat right",
          "background-position-y": "8px",
          "background-size": "60px 64px"
        },
        ".card-number.card-type-mastercard": {
          background:
            "rgba(0,0,0,0) url(https://hps.github.io/token/gp-1.0.0/images/logo-mastercard@2x.png) no-repeat right",
          "background-position-y": "3px",
          "background-size": "72px 76px"
        },
        ".card-number.card-type-discover": {
          background:
            "rgba(0,0,0,0) url(https://hps.github.io/token/gp-1.0.0/images/logo-discover@2x.png) no-repeat right",
          "background-position-y": "8px",
          "background-size": "60px 64px"
        },
        ".card-number.card-type-jcb": {
          background:
            "rgba(0,0,0,0) url(https://hps.github.io/token/gp-1.0.0/images/logo-jcb@2x.png) no-repeat right",
          "background-position-y": "8px",
          "background-size": "60px 64px"
        },
        ".card-number.card-type-amex": {
          background:
            "rgba(0,0,0,0) url(https://hps.github.io/token/gp-1.0.0/images/logo-amex@2x.png) no-repeat right",
          "background-position-y": "8px",
          "background-size": "70px 74px"
        }
      }
    });

    form.value.ready((frames) => {
      try {
        Object.keys(frames).forEach((frameId) => {
          const frame = frames[frameId];
          const iframeCount = frame.container.childElementCount;
          if (iframeCount > 1) {
            frame.container.querySelectorAll("iframe").forEach((iframe) => {
              // If iframe id doesn't match registered frame
              // remove from DOM
              if (!iframe.id.includes(frame.id)) {
                iframe.remove();
              }
            });
          }
        });
        ready.value = true;
      } catch (e) {
        console.error(e);
        ready.value = true;
      }
    });

    // Token success handler
    form.value.on("card-number", "token-success", (resp) => {
      resolver.value({
        name: name.value,
        card_token: resp.temporary_token,
        card_save_payment_method: saveCard.value || props.autoSaveCard
      });
    });

    // Token error handler
    form.value.on("card-number", "token-error", handleFormError);
  } catch (e) {
    console.log(e);
    // Sentry.captureException(
    //   new Error("GPI form initialization error: ", e)
    // );
  }

  if (customer.value) {
    name.value =
      customer.value.full_name ||
      `${customer.value.first_name} ${customer.value.last_name}`;
  }
}

function handleFormError(resp) {
  let errorText = resp.error.message;
  const details = (resp.error.detail || [])[0];
  const invalidData = resp.error.message.includes("Invalid input data");
  if (invalidData && details) {
    const errorDetail = resp.error.detail[0].data_path
      .replace(/[/_]/gi, " ")
      .trim();
    errorText = `${errorText} (${errorDetail})`;
  }
  rejector.value(errorText);
  highlightFieldErrors(errorText);
}

function clearErrors() {
  Object.keys(errorMessages.value).forEach((key) => {
    errorMessages.value[key] = "";
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function resetForm() {
  // Manually reset payfield iframes
  try {
    form.value.frames["card-number"].events.dispose[0]();
    form.value.frames["card-cvv"].events.dispose[0]();
    form.value.frames["card-expiration"].events.dispose[0]();
  } catch (e) {
    console.log(e);
  }
  initForm();
}

function highlightFieldErrors(errorText: string) {
  const errorMessage = errorText.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, " ");

  // Note: GPI returns 'invalid input' and 'invalid_input'.

  // Card Number
  if (errorMessage.includes("card number")) {
    if (
      errorMessage.includes("invalid card") ||
      errorMessage.includes("invalid input")
    ) {
      errorMessages.value.cardNumber = "Invalid card number";
    } else {
      errorMessages.value.cardNumber = errorText;
    }
  }

  // Card Expiration
  if (
    errorMessage.includes("card expiration") ||
    errorMessage.includes("card expiry")
  ) {
    if (errorMessage.includes("invalid input")) {
      errorMessages.value.exp = "Invalid expiration date";
    } else {
      errorMessages.value.exp = errorText;
    }
  }

  // CVV
  if (errorMessage.includes("security code")) {
    if (errorMessage.includes("invalid input")) {
      errorMessages.value.cvv = "Invalid Code";
    } else {
      errorMessages.value.cvv = errorText;
    }
  }
}
</script>

<style lang="scss">
.gpi-form {
  iframe,
  .v-input__control {
    width: 100%;
    min-height: 45px !important;
  }
  .label {
    margin-bottom: 3px;
    font-weight: bold;
  }
  .submit-container {
    display: none;
  }
  .gpi-field {
    height: 44px;
    overflow: hidden;
    background: #d6ffd9;
  }
  #card-cvv,
  .exp-field {
    width: 100px;
    overflow: hidden;
  }
}
</style>
