<template>
  <div class="d-flex align-center justify-center my-6">
    <v-progress-circular
      indeterminate
      color="primary"
      class="mr-2"
    ></v-progress-circular
    >Loading account details...
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  watch: {
    "$store.state.recentAppointments": function () {
      this.redirectUser();
    }
  },
  mounted() {
    if (this.$store.state.recentAppointmentsLoaded) {
      this.redirectUser();
    }
  },
  methods: {
    redirectUser() {
      // React to user appointments loaded from NavMenu
      const appointments = this.$store.state.recentAppointments;
      if (appointments.length) {
        const activeStatuses = [
          "Pending",
          "Assigned",
          "Accepted",
          "Running",
          "Installation Pending",
          "Late"
        ];
        // Check if any are active or future
        const activeAppt = appointments.find((appt) =>
          activeStatuses.includes(appt.status)
        );
        // if so then redirect to them
        if (activeAppt) {
          return this.$router.push({
            name: "appointment",
            params: { id: activeAppt.id }
          });
        }
      }
      // If the user has no appointments, redirect to schedule
      return this.$router.push({ name: "scheduleAppointment" });
    }
  }
};
</script>

<style lang="scss" scoped></style>
