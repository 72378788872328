<template>
  <div>
    <div class="tw-flex tw-items-center tw-justify-between !tw-mb-4">
      <b>Selected Equipment</b>
      <SeraButton
        text="Clear Selection"
        @click="$emit('clear-selection', equipment)"
      />
    </div>
    <slot name="equipmentCard">
      <EquipmentCard
        class="tw-mt-6"
        v-bind="equipment"
        :address="addressLabel"
        default-collapsed
      />
    </slot>
  </div>
</template>

<script>
import EquipmentCard from "serviceshift-ui/components/Equipment/EquipmentCard.vue";
import SeraButton from "serviceshift-ui/components/Inputs/Button.vue";
import * as EquipmentTypes from "serviceshift-ui/shared/src/classes/equipment";
import { fullAddress } from "serviceshift-ui/shared/src/mixins";
export default {
  name: "EquipmentRequestPresentation",
  components: {
    SeraButton,
    EquipmentCard
  },
  props: {
    /** @type {{ new (): EquipmentTypes.Equipment }} */
    equipment: {
      type: Object,
      default: null,
      required: true
    }
  },
  computed: {
    addressLabel() {
      if (!this.equipment || !this.equipment?.address) return "--";
      return fullAddress(this.equipment.address);
    }
  }
};
</script>

<style lang="scss" scoped>
.form-cont {
  border-radius: 0 0 4px 4px;
}
</style>
