<template>
  <div :class="['simple-table', { 'simple-table-card': props.cardWrap }]">
    <table>
      <slot />
    </table>
  </div>
</template>

<script lang="ts">
export default {
  name: "SimpleTable"
};
</script>

<script setup lang="ts">
import { withDefaults } from "vue";

const props = withDefaults(
  defineProps<{
    cardWrap?: boolean;
  }>(),
  { cardWrap: false }
);
</script>

<style lang="scss">
.simple-table-card {
  padding: 0.75rem;
  border: 1px solid $medium-color;
  border-radius: 0.25rem;
}

.simple-table {
  width: 100%;

  table {
    width: 100%;

    thead tr th {
      border-bottom: 1px solid $medium-color;
    }

    td,
    th {
      text-align: left;
      line-height: 1.5;
      vertical-align: top !important;
    }

    td {
      padding: 0.25rem 0.5rem 0 0;
    }
  }
}
</style>
