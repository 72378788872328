// Converts cents to dollars as a string
export const dollars = (cents) => {
  if (!cents && cents !== 0) {
    return "--";
  }

  const fixed = (cents / 100).toFixed(2);
  try {
    return Number(parseFloat(fixed)).toLocaleString("en-us", {
      style: "currency",
      currency: "USD"
    });
  } catch (e) {
    console.log(e);
    return "$0.00";
  }
};
