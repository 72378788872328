<template>
  <div>
    <div class="membership-name mb-1 full-width">
      {{ membership.membership_program_name }}
      <img class="membership-icon" :src="membership.icon_url" />
    </div>
    <div
      class="membership-content"
      :class="{ mobile: $vuetify.breakpoint.smAndDown }"
    >
      <div class="flex-1">
        <div><b>Starts On:</b> {{ formatDate(membership.starts_at) }}</div>
        <div v-if="membership.renews_at">
          <b>Next Billing Date:</b> {{ formatDate(membership.renews_at) }}
        </div>
        <div v-if="membership.ends_at">
          <b>{{ endsTag }}</b> {{ formatDate(membership.ends_at) }}
        </div>
        <div>
          <b>Covered System(s):</b>
          {{ membership.covered_systems || "--" }}
        </div>
        <div><b>Billing Price:</b> {{ displayPrice }}</div>
        <div><b>Duration:</b> {{ displayDuration }}</div>
      </div>
      <v-divider
        v-if="$vuetify.breakpoint.smAndDown"
        class="mt-3 mb-3"
      ></v-divider>
      <v-divider v-else vertical class="divider mr-3 ml-3"></v-divider>
      <div class="flex-1">
        <div><b>Description </b></div>
        <span class="description-text">{{
          membership.membership_program_description || "--"
        }}</span>
      </div>
      <v-divider
        v-if="$vuetify.breakpoint.smAndDown"
        class="mt-3 mb-3"
      ></v-divider>
      <v-divider v-else vertical class="mr-3 ml-3"></v-divider>
      <div class="flex-1">
        <b>Included Services </b>
        <div v-if="!membership.items.length">--</div>
        <div v-for="item in membership.items" :key="item.item_id">
          {{ displayItemInfo(item) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    membership: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    displayPrice() {
      const price =
        this.membership.price_cents > 0
          ? (this.membership.price_cents / 100).toFixed(2)
          : 0;
      return `$${price}`;
    },
    endTag() {
      return this.membership.auto_renews ? "Renewal Date:" : "Expiration Date:";
    },
    displayDuration() {
      if (this.membership && this.membership.duration) {
        const action = this.membership.auto_renews
          ? "Renews every"
          : "Expires after";
        const plural = this.membership.duration.count > 1 ? "s" : "";
        return `${action} ${this.membership.duration.count} ${this.membership.duration.period}${plural}`;
      }
      return "";
    },
    endsTag() {
      return this.membership.auto_renews ? "Cancellation Date:" : "Ends On:";
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US");
    },
    displayItemInfo(item) {
      if (!item.frequency) {
        return `${item.unit_name}`;
      }
      const frequency = `${
        item.frequency.count > 1 ? `${item.frequency.count} ` : ""
      }${item.frequency.period}${item.frequency.count > 1 ? "s" : ""}`;
      return `${item.unit_name} every ${frequency}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.membership-icon {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-bottom: 4px;
}

.membership-content {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: black;
}

.mobile {
  flex-direction: column;
}

.membership-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
  text-align: left;
}

.description-text {
  white-space: pre-line;
}

.expired-text {
  color: rgba(0, 0, 0, 0.6);
  font-style: italic;
  font-size: 16px;
  font-weight: normal;
}
</style>
