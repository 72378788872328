<template>
  <Card headless>
    <div class="tw-flex tw-flex-col tw-pt-6">
      <div class="tw-flex tw-mb-4">
        <div class="tw-flex-1">
          <Select
            v-model="formData.generic_equipment_id"
            label="Generic Equipment"
            :options="equipmentOptions"
            search
            placeholder="None"
            :field-error="errorMessage"
          />
        </div>
        <div class="convert-divider tw-mx-6">
          <div class="divider-line" />
          <div class="divider-text">Convert to</div>
        </div>
        <div class="tw-flex-1 tw-text-center non-generic-name">
          <div class="tw-text-sm">Non-Generic Component</div>
          <div class="tw-text-base tw-font-bold tw-mt-2">
            {{ component.name }}
          </div>
        </div>
      </div>
      <div class="tw-flex tw-justify-between">
        <ComponentDetailsCard
          :header-color="genericComponent ? 'success' : 'default'"
          :component="genericComponent"
          class="tw-flex-1 tw-mr-6"
          :generic-equipment-label="selectedGenericEquipment?.name"
          :keep-equipment-label="keepEquipmentLabel"
        >
          <template #title>
            Details To Keep
            <i v-if="genericComponent" class="fa-solid fa-check tw-ml-1" />
          </template>
          <template #body>
            <div v-if="!genericComponent" class="generic-message">
              Select the generic equipment to see the details to be kept.
              <br />
              <br />
              Conversion will only occur by saving your selection at the bottom.
            </div>
          </template>
        </ComponentDetailsCard>
        <ComponentDetailsCard
          :header-color="genericComponent ? 'danger' : 'default'"
          :component="component"
          class="tw-flex-1"
        >
          <template #title>
            Details to be Replaced
            <i
              v-if="genericComponent"
              class="fa-solid fa-align-slash tw-ml-1"
            />
          </template>
          <template #body>
            <WarningMessage
              v-if="componentHasData && genericComponent"
              class="warning-message"
            >
              After the conversion, these details will be completely removed.
            </WarningMessage>
          </template>
        </ComponentDetailsCard>
      </div>
    </div>
  </Card>
</template>

<script>
import Card from "serviceshift-ui/components/Card/Card.vue";
import WarningMessage from "serviceshift-ui/components/General/WarningMessage.vue";
import Select from "serviceshift-ui/components/Inputs/Select.vue";

import ComponentDetailsCard from "./ComponentDetailsCard.vue";

export default {
  components: {
    ComponentDetailsCard,
    Card,
    Select,
    WarningMessage
  },
  props: {
    /** @type {EquipmentTypes.Equipment[]} */
    genericEquipment: {
      type: Array,
      default: () => []
    },
    /** @type { {component_type_id: number; generic_equipment_id: number;} } */
    formData: {
      type: Object,
      default: () => ({})
    },
    /** @type { EquipmentComponent } */
    component: {
      type: Object,
      default: null
    },
    keepEquipmentLabel: {
      type: String,
      default: "existing equipment"
    },
    /** @type { Number[] } */
    idsInUse: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: ""
    }
  },
  computed: {
    equipmentOptions() {
      return this.genericEquipment
        .filter(
          (equipment) =>
            !this.idsInUse.includes(equipment.id) ||
            equipment.id === this.formData.generic_equipment_id
        )
        .map((equipment) => ({
          label: `${equipment.name} (${equipment.equipment_type_name})`,
          value: equipment.id,
          archived: equipment.archived
        }));
    },
    selectedGenericEquipment() {
      return this.genericEquipment.find(
        (equipment) => equipment.id === this.formData.generic_equipment_id
      );
    },
    genericComponent() {
      return this.selectedGenericEquipment
        ? {
            ...this.selectedGenericEquipment.components[0],
            service_history: this.selectedGenericEquipment.service_history
          }
        : null;
    },
    componentHasData() {
      const dataKeys = [
        "manufacturer",
        "model_number",
        "serial_number",
        "warranty",
        "install_location",
        "installed_on",
        "manufactured_on"
      ];
      return dataKeys.some((key) => Boolean(this.component[key]));
    }
  }
};
</script>

<style lang="scss" scoped>
.convert-divider {
  height: 52px;
  width: 52px;
  position: relative;
  .divider-line,
  .divider-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .divider-line {
    z-index: 1;
    height: 100%;
    border-right: 1px solid $light-gray-border;
  }
  .divider-text {
    z-index: 2;
    background: $white;
    padding: 5px;
    white-space: nowrap;
    font-size: 0.9em;
  }
}
.generic-message {
  margin: 20% auto;
  width: 230px;
  text-align: center;
}
// Aligns the non-generic equipment text
// with the ComponentDetails card header text
.non-generic-name {
  position: relative;
  left: -20px;
}
.warning-message {
  margin-bottom: 0 !important;
}
</style>
