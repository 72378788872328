<template>
  <div>
    <div v-if="loading">
      <DefaultLayout>
        <div class="d-flex mt-10 full-width flex-column align-content-center">
          <div class="text-center">
            <v-progress-circular indeterminate color="primary" />
            <div class="text-small mt-1">Loading...</div>
          </div>
        </div>
      </DefaultLayout>
    </div>
    <div v-else>
      <CustomerLayout v-if="store.getters.isCustomer">
        <router-view />
      </CustomerLayout>
      <DefaultLayout v-else show-login-and-call-us>
        <div class="pt-4"><router-view /></div>
      </DefaultLayout>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Customer } from "serviceshift-ui/shared/src/typings/customer";
import { watch } from "vue";
import { useRoute } from "vue-router/composables";
import { useStore } from "@/lib/vuex-composition";

import CustomerLayout from "@/components/Layouts/CustomerLayout.vue";
import DefaultLayout from "@/components/Layouts/DefaultLayout.vue";
import useTokenHook from "@/hooks/useTokenHook";

const store = useStore();
const { authenticate, loading } = useTokenHook<Customer>();
const route = useRoute();

watch(
  () => route.params.token,
  async (oldVal, newVal) => {
    if (oldVal === newVal) return;
    await authenticate(route.params.token);
  },
  { immediate: true }
);
</script>
