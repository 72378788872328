<template>
  <div class="basic-info info-container full-width">
    <div class="user-name">
      {{ user.first_name }}
      {{ user.last_name }}
    </div>
    <img
      v-if="user.membership"
      class="membership-icon"
      :src="getMembershipIcon"
    />
    <ContactList
      :emails="emails"
      :phones="phones"
      card-wrap
      class="full-width"
      :class="{
        'mobile-list': $vuetify.breakpoint.smAndDown,
        'desktop-list': !$vuetify.breakpoint.smAndDown
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    phones() {
      return this.user.phones;
    },
    emails() {
      return this.user.emails;
    },
    getMembershipIcon() {
      return this.$store.state.user.membership.icon_url;
    }
  }
};
</script>

<style lang="scss" scoped>
.membership-icon {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-bottom: 4px;
}

.basic-info {
  text-align: left;
}

.info-container {
  white-space: nowrap;
  color: black;
}

.profile-text {
  font-size: 12px;
  line-height: 24px;
  color: black;
}

.user-name {
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 10px;
}

p {
  margin: 0 !important;
}

.mobile-list {
  flex-direction: column;
}

::v-deep(.simple-table) {
  table {
    table-layout: fixed;
  }
}
</style>
