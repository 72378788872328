export const formatFullAddress = (address) => {
  const { street, unit, city, state, zip_code } = address;
  return unit
    ? `${street}, ${unit}, ${city}, ${state} ${zip_code}`
    : `${street}, ${city}, ${state} ${zip_code}`;
};

export const addressLabel = (address) => {
  let label = addressShortLabel(address);
  if (address.name !== label) {
    label = `${address.name} - ${label}`;
  }
  return label;
};

export const addressShortLabel = (address) => {
  const { street, zip_code, unit } = address;
  return unit ? `${street}, ${unit}, ${zip_code}` : `${street}, ${zip_code}`;
};

export const getAddressName = (address) => {
  let label = `${address.street}, ${address.zip_code}`;
  if (address.name !== label) {
    return address.name;
  }
  return "";
};

// Converts google autocomplete place to address api payload
export const getAddressComponents = (place) => {
  const { street_number, route, unit, name, type, primary, street } = place;
  const streetName = street || `${street_number} ${route}`;
  return {
    street: streetName,
    name: name || "Home",
    city: place.locality,
    state: place.administrative_area_level_1,
    zip_code: place.postal_code,
    latitude: place.latitude,
    longitude: place.longitude,
    property_type: type,
    primary: primary,
    unit
  };
};

export const getSuite = (address) => {
  return address.street.substr(
    address.street.lastIndexOf(" ") + 1,
    address.street.length
  );
};
