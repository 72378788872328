<template>
  <div class="relative">
    <v-container v-if="photos.length" fluid>
      <v-row>
        <v-col
          v-for="(photo, i) in photos"
          :key="photo.id"
          class="d-flex child-flex"
          cols="4"
        >
          <v-card flat tile class="d-flex">
            <div class="photo-gallery-img" @click="showPreview(i)">
              <v-img
                :src="getSrc(photo.file)"
                :lazy-src="getSrc(photo.file)"
                aspect-ratio="1"
                class="elevation-2 image-thumb"
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="secondary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span class="caption mt-2 grey--text text--darken-2">{{
                getPhotoUploadDate(photo.created_at)
              }}</span>
              <v-btn
                v-if="!readonly"
                link
                icon
                class="delete-icon red--text"
                @click="removePhoto(photo.id, $event)"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div v-else class="d-flex align-center justify-center caption">
      <v-icon small>mdi-image-off</v-icon>
      <p class="ml-2 mb-0">No photos available</p>
    </div>
    <div v-if="loading || deleting" class="loading-photos-overlay">
      <v-progress-circular
        indeterminate
        color="secondary"
        class="mr-2"
      ></v-progress-circular
      >{{ loading ? "Uploading photos..." : "Deleting photo..." }}
    </div>
    <v-dialog
      v-model="preview"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : '60%'"
    >
      <v-card v-if="previewIndex !== null">
        <v-img :src="getPreviewSrc()" aspect-ratio="1" :contain="true">
          <template #placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="secondary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import API from "serviceshift-ui/api-client";
import { mapActions } from "vuex";

export default {
  name: "PhotoGallery",
  props: {
    jobId: {
      type: Number,
      default: -1
    },
    photos: {
      type: Array,
      default: () => []
    },
    loading: Boolean,
    readonly: Boolean
  },
  data() {
    return {
      preview: false,
      previewIndex: null,
      deleting: false
    };
  },
  methods: {
    ...mapActions(["fetchJob"]),
    getPhotoUploadDate(date) {
      return new Date(date).toLocaleDateString();
    },
    getPreviewSrc() {
      return this.getSrc(this.photos[this.previewIndex].file, true);
    },
    getSrc(file, fullPreview = false) {
      const thumb = file.thumb || {};
      if (fullPreview || !thumb.url) {
        return file.url;
      }
      return thumb.url;
    },
    removePhoto(id, e) {
      this.deleting = true;
      e.stopImmediatePropagation();
      API.job.deletePhoto(this.jobId, id).then(async () => {
        await this.fetchJob(this.jobId);
        this.deleting = false;
      });
    },
    showPreview(i) {
      this.preview = true;
      this.previewIndex = i;
    }
  }
};
</script>

<style lang="scss">
.loading-photos-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.photo-gallery-img {
  height: 100%;
  text-align: center;
  position: relative;
  width: 100%;
}
.delete-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  background: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.image-thumb {
  max-height: 200px;
}
</style>
