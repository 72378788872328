import axios from "axios";

import ENV from "../env";

export default {
  create: (data) => {
    return axios(`${ENV.v2Path}/membership_programs`, {
      method: "POST",
      data
    });
  },
  get: (id) => {
    return axios(`${ENV.v2Path}/membership_programs/${id}`, {
      method: "GET"
    });
  },
  getAll: (activeOnly) => {
    return axios(`${ENV.v2Path}/membership_programs`, {
      method: "GET",
      params: { active_only: activeOnly }
    });
  },
  update: (data) => {
    return axios(`${ENV.v2Path}/membership_programs/${data.membership.id}`, {
      method: "PUT",
      data
    });
  }
};
