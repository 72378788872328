<template>
  <div class="section-spacing-20">
    <div>
      <div class="tw-mb-2">
        1. Select the non-generic equipment you wish to use for the conversion
      </div>
      <Select
        v-model="keepEquipmentId"
        :options="keepEquipmentOptions"
        full-width
        search
        :field-error="selectError"
      />
    </div>

    <Transition>
      <div v-if="keepEquipment">
        <div class="tw-mb-2">
          2. Select the generic equipment you wish to keep and convert to a
          non-generic component
        </div>
        <div class="section-spacing-20">
          <ConvertComponentCard
            v-for="componentData in formData.components"
            :key="componentData.component_type_id"
            :form-data="componentData"
            :component="componentData.component"
            :generic-equipment="genericEquipmentOptions"
            :keep-equipment-label="keepEquipmentLabel"
            :ids-in-use="idsInUse"
            :error-message="genericEquipmentError"
          />
        </div>
      </div>
    </Transition>

    <Transition>
      <div v-if="keepEquipment && warrantyOptions.length">
        <div class="tw-mb-2">
          3. Select the extended warranty program you wish to keep per
          conversion
        </div>
        <WarrantySelector :form-data="formData" :warranties="warrantyOptions" />
      </div>
    </Transition>

    <Transition>
      <ConvertChangeSummary
        v-if="keepEquipment && idsInUse.length"
        :equipment="equipment"
        :ids-in-use="idsInUse"
        :form-data="formData"
        :selected-warranty="selectedWarranty"
        :keep-equipment-label="keepEquipmentLabel"
      />
    </Transition>
  </div>
</template>

<script>
import Transition from "serviceshift-ui/components/General/Transition.vue";
import Select from "serviceshift-ui/components/Inputs/Select.vue";
import * as EquipmentTypes from "serviceshift-ui/shared/src/classes/equipment";

import ConvertChangeSummary from "./ConvertChangeSummary.vue";
import ConvertComponentCard from "./ConvertComponentCard.vue";
import WarrantySelector from "./WarrantySelector.vue";

const SELECT_ERROR_MESSAGE = "Non-Generic Equipment is a required field";
const GENERIC_EQUIPMENT_ERROR_MESSAGE =
  "Must select at least one generic equipment";

export default {
  name: "ConvertGenericEquipmentFlow",
  components: {
    Select,
    ConvertComponentCard,
    ConvertChangeSummary,
    Transition,
    WarrantySelector
  },
  props: {
    /** @type {EquipmentTypes.Equipment[]} */
    equipment: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      keepEquipmentId: null,
      formData: {
        components: [],
        equipment_warranty_id: null
      },
      selectError: null,
      genericEquipmentError: null
    };
  },
  computed: {
    keepEquipmentOptions() {
      return this.equipment
        .filter(
          (equipment) =>
            !equipment.equipment_type_is_default && !equipment.archived
        )
        .map((equipment) => ({
          label: `${equipment.name} (${equipment.equipment_type_name})`,
          value: equipment.id,
          archived: equipment.archived
        }));
    },
    genericEquipmentOptions() {
      // Returns generic equipment at the same address as the keep equipment
      return this.equipment
        .filter(
          (equipment) =>
            equipment.equipment_type_is_default && !equipment.archived
        )
        .filter(
          (equipment) => equipment.address.id === this.keepEquipment.address.id
        );
    },
    keepEquipment() {
      return this.equipment.find(
        (equipment) => equipment.id === this.keepEquipmentId
      );
    },
    keepEquipmentLabel() {
      if (!this.keepEquipment) return "";
      return `${this.keepEquipment.name} (${this.keepEquipment.equipment_type_name})`;
    },
    idsInUse() {
      // Returns a list of generic equipment ids selected for conversion
      // used to filter out already used equipment
      return this.formData.components.map(
        (component) => component.generic_equipment_id
      );
    },
    warrantyOptions() {
      if (!this.keepEquipment) return [];
      const defaultWarranty = this.keepEquipment.warranty
        ? [
            {
              ...this.keepEquipment.warranty,
              warranty_program_name: `${this.keepEquipment.warranty.warranty_program_name} (Existing)`,
              label: this.keepEquipmentLabel
            }
          ]
        : [];
      const genericWarranties = this.formData.components.reduce(
        (warranties, component) => {
          const equipment = this.equipment.find(
            (eq) => eq.id === component.generic_equipment_id
          );
          if (equipment && equipment.warranty) {
            warranties.push({ ...equipment.warranty, label: equipment.name });
          }
          return warranties;
        },
        []
      );
      return defaultWarranty.concat(genericWarranties);
    },
    selectedWarranty() {
      if (!this.formData.equipment_warranty_id) return null;
      return this.warrantyOptions.find(
        (warranty) => warranty.id === this.formData.equipment_warranty_id
      );
    }
  },
  watch: {
    keepEquipmentId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectError = newValue ? null : SELECT_ERROR_MESSAGE;
        this.genericEquipmentError = null;
        this.setFormData();
      }
    },
    "formData.components": {
      deep: true,
      immediate: true,
      handler(newValue) {
        // Turn off generic equipment error when making selection
        if (
          this.genericEquipmentError &&
          newValue.some((comp) => comp.generic_equipment_id)
        ) {
          this.genericEquipmentError = null;
        }
      }
    },
    warrantyOptions: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue.length && !oldValue.length) {
          this.formData.equipment_warranty_id = newValue[0].id;
          return;
        }
        if (!newValue.length) {
          this.formData.equipment_warranty_id = null;
        }
      }
    }
  },
  methods: {
    getData() {
      return new Promise((resolve, reject) => {
        if (!this.keepEquipmentId) {
          this.selectError = SELECT_ERROR_MESSAGE;
          return reject(SELECT_ERROR_MESSAGE);
        }
        // Only send components that have been updated and remove UI data from api payload
        const components = this.formData.components
          .filter((comp) => comp.generic_equipment_id)
          .map((comp) => {
            const { component, ...apiData } = comp;
            return apiData;
          });
        // Show error message if no generic equipment is selected
        if (!components.length) {
          this.genericEquipmentError = GENERIC_EQUIPMENT_ERROR_MESSAGE;
          return reject(GENERIC_EQUIPMENT_ERROR_MESSAGE);
        }
        resolve({
          ...this.formData,
          apiLink: this.keepEquipment.links.convert_generic,
          components
        });
      });
    },
    setFormData() {
      if (!this.keepEquipment) {
        this.formData = { components: [] };
        return;
      }
      this.formData = {
        components: this.keepEquipment.components
          .filter((component) => !component.component_type_is_default)
          .map((component) => ({
            generic_equipment_id: null,
            component_type_id: component.component_type_id,
            component
          })),
        equipment_warranty_id: this.keepEquipment.warranty
          ? this.keepEquipment.warranty.id
          : null
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
