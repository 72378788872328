<template>
  <div>
    <CustomerNavMenu v-model="open" />
    <v-app-bar app dense :clipped-left="!$vuetify.breakpoint.smAndDown">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        color="white"
        @click="toggleOpen"
      ></v-app-bar-nav-icon>
      <router-link to="/" class="d-flex align-center">
        <img
          :src="$store.state.tenant.customizations.Assets.logo_url"
          alt="logo"
          class="nav-logo"
        />
      </router-link>
      <div class="flex-grow-1"></div>
      <v-toolbar-items>
        <div class="d-flex align-center">
          <v-btn
            v-if="!scheduling"
            class="schedule-nav-btn"
            color="primary"
            rounded
            :small="$vuetify.breakpoint.smAndDown"
            to="/schedule"
            >Schedule Appointment</v-btn
          >
        </div>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import CustomerNavMenu from "./CustomerNavMenu.vue";

export default {
  components: {
    CustomerNavMenu
  },
  data() {
    return {
      open: false,
      scheduling: false
    };
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.name !== oldValue.name) {
        this.setScheduling();
      }
    }
  },
  mounted() {
    this.setScheduling();
  },
  methods: {
    setScheduling() {
      this.scheduling = this.$route.name === "scheduleAppointment";
    },
    toggleOpen() {
      this.open = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-app-bar {
  background-color: var(--v-secondary-base) !important;
}
.v-toolbar__title {
  color: #fff;
  width: 35%;
  padding-left: 0 !important;
}
.v-list {
  padding: 0;
}
.nav-logo {
  max-height: 35px;
  max-width: 100px;
}
.schedule-nav-btn {
  text-transform: none;
}
.hq-phone {
  line-height: 1.5;
}
</style>
