<template>
  <div class="provide-address-container">
    <p class="provide-text">{{ message }}</p>
    <v-btn
      class="provide-button"
      color="primary"
      rounded
      contained
      small
      @click="$emit('provide-address')"
      >Provide Your Address</v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    schedule: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    message() {
      const scheduleText = this.schedule ? "\n to schedule an appointment" : "";
      return `Please provide us with your current address${scheduleText}.`;
    }
  }
};
</script>

<style lang="scss" scoped>
.provide-address-container {
  font-size: 0.75rem;
  display: block;
  text-align: center;
}

.provide-button {
  margin-bottom: 16px;
}

.provide-text {
  margin-top: 16px;
  white-space: pre;
}
</style>
