<template>
  <div :class="classNames">
    <Table class="lineItemTable">
      <thead>
        <th v-if="editing" width="88" class="drag-col-header" />
        <th width="60" />
        <th align="left" />
        <th v-if="!twoColumn" width="100" align="right" />
        <th v-if="!twoColumn" width="100" align="right" />
        <th
          v-if="twoColumn"
          width="100"
          colspan="2"
          align="center"
          style="text-align: center"
        >
          <em>{{ data.comparison_membership_program_name }}</em>
        </th>
        <th
          v-if="twoColumn"
          width="100"
          colspan="2"
          align="center"
          style="text-align: center"
        >
          {{ data.actual_membership_program_name }}
        </th>
        <th v-if="editing" width="40" />
        <th v-if="editing && showTaxColumn" width="40" />
      </thead>
      <tbody>
        <tr class="table-header">
          <td v-if="editing" width="88" class="drag-col-header" />
          <td class="col-qty">Qty</td>
          <td class="col-lineItemRowDetails">Item</td>
          <td v-if="twoColumn" class="col-per-unit comparison-value">
            Per Unit
          </td>
          <td v-if="twoColumn" class="col-total comparison-value">Total</td>
          <td v-if="editing && showTaxColumn" class="col-tax">Tax</td>
          <td class="col-per-unit">Per Unit</td>
          <td class="col-total">Total</td>
          <td v-if="editing" width="40" />
        </tr>
      </tbody>
      <draggable
        v-model="items"
        tag="tbody"
        draggable=".lineItemGroup"
        handle=".drag-handle"
        class="rootList"
        :chosen-class="'line-item-chosen'"
        :ghost-class="'line-item-ghost'"
        :animation="150"
        @end="handleSort"
        @start="handleStart"
      >
        <LineItemGroup
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
          v-model="items[itemIndex]"
          :grouping-enabled="groupingEnabled && items.length > 1"
          :edit-taxes-enabled="showTaxColumn"
          :class="getRowClass(item, itemIndex)"
          :editing="editing"
          :line-item-row-details-open.sync="lineItemRowDetailsOpen"
          :line-item-type="type"
          :tech-mode="techMode"
          :event-id="eventId"
          :two-column="twoColumn"
          :equipment-types-enabled="equipmentTypesEnabled"
          :group-items="groupItems"
          @edit-quantity="handleEditQuantity"
          @price-change="handlePriceChange"
          @taxable-change="handleTaxableChange"
          @remove-item="handleRemoveItem"
          @action="handleAction"
          @sort-items="handleSort"
          @ungroup-item="handleUngroupItem"
          @group-item-selected="handleGroupItemSelected"
        >
          <template #actionsIcon>
            <slot name="actionsIcon" />
          </template>
          <template #deleteIcon>
            <slot name="deleteIcon" />
          </template>
        </LineItemGroup>
      </draggable>
      <tbody>
        <tr v-if="editing">
          <td />
          <td />
          <td>
            <a
              href="javascript:;"
              class="line-item-add-link"
              @click="handleAddItem"
              >ADD LINE ITEM</a
            >
          </td>
          <td />
          <td />
          <td v-if="twoColumn" />
          <td v-if="twoColumn" />
          <td v-if="editing" />
          <td v-if="editing && showTaxColumn" />
        </tr>
        <tr class="totals-row">
          <td v-if="editing" />
          <td v-if="editing && showTaxColumn" />
          <td
            v-if="twoColumn"
            colspan="3"
            class="comparison-value"
            align="right"
          />
          <td v-if="twoColumn" class="comparison-value" align="right" />
          <td v-if="twoColumn" align="right">Subtotal</td>
          <td v-if="!twoColumn" align="right" colspan="3">Subtotal</td>
          <td align="right">
            {{ (data.financials.subtotal_cents / 100) | currency }}
          </td>
          <td v-if="editing" />
        </tr>
      </tbody>
    </Table>
  </div>
</template>

<script>
import Table from "serviceshift-ui/components/General/Table.vue";
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface.js";
import LineItemGroup from "serviceshift-ui/components/QuoteInvoice/LineItemGroup.vue";
import currency from "serviceshift-ui/shared/src/filters/currency";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";
import { generateUID } from "serviceshift-ui/shared/src/lib/helpers";
import debounce from "serviceshift-ui/shared/src/mixins/debounce";
import draggable from "vuedraggable";

export default {
  filters: {
    currency
  },
  components: {
    LineItemGroup,
    draggable,
    Table
  },
  props: {
    groupingEnabled: {
      type: Boolean,
      default: true
    },
    /** @type { QuoteInvoiceTypes.Quote } */
    data: {
      type: Object,
      default: () => ({})
    },
    editing: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "quote"
    },
    techMode: {
      type: Boolean,
      default: false
    },
    equipmentTypesEnabled: {
      type: Boolean,
      default: false
    },
    editTaxesEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDragging: false,
      canMoveItemToGroup: false,
      shouldCheckTimer: true,
      /** @type { Record<string, boolean> } */
      lineItemRowDetailsOpen: {},
      /** @type {Array<QuoteInvoiceTypes.Quote['items'][0] & { group?: Group; }>} */
      items: [],
      moneyConfig: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        suffix: "",
        precision: 2,
        masked: false
      },
      groupItems: []
    };
  },
  computed: {
    classNames() {
      const classNames = [];
      if (this.type === "invoice") classNames.push("invoice");
      if (this.isDragging) classNames.push("dragging");
      if (this.groupingEnabled) classNames.push("grouping-enabled");
      return classNames.join(" ");
    },
    eventId() {
      return this.data.invoice_id || this.data.id;
    },
    twoColumn() {
      return (
        !this.editing &&
        this.data.quote_type &&
        this.data.quote_type === "two_column"
      );
    },
    showGroupSubmit() {
      return this.groupItems.length > 1;
    },
    showTaxColumn() {
      return this.editTaxesEnabled && this.data?.tax_setting?.taxable;
    }
  },
  watch: {
    "data.items_attributes": {
      deep: true,
      immediate: true,
      /** @param quoteItems {Quote['items_attributes']} */
      handler(quoteItems) {
        // Remap the items with items_in_group property to make it an observable
        this.items = quoteItems.slice(0).map((item, index) => {
          return {
            ...item,
            _ssui_order_id: index,
            items_in_group: item.items_in_group ? item.items_in_group : []
          };
        });
      }
    },
    editing(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.groupItems = [];
      }
    },
    items(newValue, oldValue) {
      if (newValue !== oldValue) {
        // update groupItems when items change
        // Removes any groupedItems that no longer exist on the quote
        // Flattens all items to check for existence
        const newItems = newValue.reduce((items, item) => {
          if (item.type === "group") {
            items = items.concat(item.items_in_group);
          } else {
            items.push(item);
          }
          return items;
        }, []);
        this.groupItems = this.groupItems.filter((groupItem) =>
          newItems.find((_item) => groupItem.id === _item.id)
        );
      }
    }
  },
  methods: {
    handleGroupItems() {
      const existingGroupIds = [];
      // Iterates over all items and updates grouping based on whats in the group Items array
      const groupItems = this.groupItems.reduce((items, item) => {
        // Flatten all groups and lineItems to a single list
        if (item.group_id) {
          const group = this.items.find(
            (_item) => _item.group_id === item.group_id
          );
          items = items.concat(group.items_in_group);
          existingGroupIds.push(group.group_id);
        } else {
          items.push(item);
        }
        return items;
      }, []);

      const mainGroupItem = groupItems[0];

      const selectedIds = groupItems.map((item) => item.id || item.group_id);
      const groupId = mainGroupItem.group_id || generateUID(4);
      const group = {
        type: "group",
        group_id: groupId,
        items_in_group: groupItems.map((item) => {
          // Set the new group id on all items
          item.group_id = groupId;
          return item;
        })
      };
      this.items = this.items.reduce((items, item) => {
        // Only push the new group and ignore the others
        if (
          selectedIds.includes(item.id) ||
          item.group_id === group.group_id ||
          existingGroupIds.includes(item.group_id)
        ) {
          // Only push group once!
          if (!items.some((i) => i.group_id === group.group_id))
            items.push(group);
        } else {
          items.push(item);
        }
        return items;
      }, []);

      EventBus.$emit("line-items-sort", this.items, this.eventId);
      EventBus.$emit("clear-group-selected", this.eventId);
      this.groupItems = [];
    },
    handleGroupItemSelected(lineItem, isSelected) {
      //add/remove from this.groupItems
      if (isSelected) {
        // Only add if it doesn't already exist
        // fixes issue when sorting line items after selecting items to group
        const existing = this.groupItems.find(
          (item) => item.id === lineItem.id
        );
        if (!existing) {
          this.groupItems = this.groupItems.concat([lineItem]);
        }
      } else {
        // Remove from group items
        this.groupItems = this.groupItems.filter(
          (item) => item.id !== lineItem.id
        );
      }
    },
    handleStart() {
      this.isDragging = true;
    },
    /**
     * Rows can either be groups or individual line items
     * @param item {Quote['items'][0]}
     */
    getRowClass(item, itemIndex) {
      const rowClasses = [];
      if (item) {
        if (item.group) {
          rowClasses.push("group");
        } else {
          rowClasses.push("lineItemRow");
        }
      }
      if (itemIndex % 2 === 0) {
        rowClasses.push("even");
      }
      return rowClasses.join(" ");
    },
    // https://github.com/SortableJS/Sortable
    // evt.to;    // target list
    // evt.from;  // previous list
    // evt.oldIndex;  // element's old index within old parent
    // evt.newIndex;  // element's new index within new parent
    // evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
    // evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
    // evt.clone // the clone element
    // evt.pullMode;  // when it
    handleSort() {
      this.isDragging = false;
      EventBus.$emit("line-items-sort", this.items, this.eventId);
    },

    // Emits an item to be ungrouped
    // Item was dragged out of group
    // Get item id and group id from data attributes
    handleUngroupItem(item) {
      const { id, group_id } = item;
      const group = this.items
        .filter((item) => item.type === "group")
        .find((item) => item.group_id.toString() === group_id.toString());
      if (group) {
        const item = group.items_in_group.find(
          (item) => item.id.toString() === id.toString()
        );
        if (item) {
          EventBus.$emit("ungroup-item", item, this.eventId);
        }
      }
    },
    handlePriceChange: debounce(function (value, item) {
      if (value !== item.unit_price_cents) {
        EventBus.$emit(
          "edit-unit-price",
          {
            target: {
              value
            }
          },
          item,
          this.eventId
        );
      }
    }, 750),
    handleTaxableChange(value, item) {
      EventBus.$emit(
        "edit-taxable",
        {
          target: { value }
        },
        item,
        this.eventId
      );
    },
    handleEditQuantity(value, item) {
      let inputValue = value;
      try {
        inputValue = parseInt(value, 10);
      } catch (e) {
        console.log("error parsing qty input value");
      }
      EventBus.$emit(
        "edit-quantity",
        {
          target: {
            value: inputValue
          }
        },
        item,
        this.eventId
      );
    },
    handleAction(actionAndItem) {
      if (actionAndItem.eventName === "group-selected-items") {
        this.handleGroupItems();
      } else {
        EventBus.$emit("action", actionAndItem, this.eventId);
      }
    },
    handleRemoveItem(item) {
      EventBus.$emit("remove-item", item, this.eventId);
    },
    handleAddItem() {
      EventBus.$emit("add-item", this.eventId);
    }
  }
};
</script>

<style lang="scss" scoped>
/** Table styling for nested tables should be the same **/
::v-deep {
  // Default dragging styles should have the elements spaced and a line display to indicate where it will be moved
  table {
    position: relative;
  }
  .sortable-ghost {
    td {
      padding: 4px !important; // Fixes issue where we had a fisher price border on the dragged item
    }
  }
  &.dragging {
    .sortable-drag {
      opacity: 0.6;
      padding: 0px;
    }
    .lineItemGroup__table {
      margin-bottom: 8px;
      margin-top: 8px;
      border: 0;
    }
    .lineItemGroup {
      &.even {
        background: #fff;
      }
    }
  }
  &.dragging-item {
    .sortable-ghost.sortable-chosen {
      box-shadow: 0 0 0 4px $cobalt-blue;
    }
  }

  // Fixes display issue with reordering items in a group
  &.dragging-item-in-group {
    .sortable-ghost.sortable-chosen {
      box-shadow: 0 0 0 4px $cobalt-blue inset !important;
    }
  }

  // For dragging an item with grouping enabled, we want to make sure groups have a blue dashed border
  &.grouping-enabled {
    &.dragging-group {
      .lineItemGroup.sortable-ghost.sortable-chosen {
        box-shadow: 0 0px 0 4px $cobalt-blue;
      }
    }
  }
  // End of sort styles

  .lineItemGroup.group + .lineItemGroup.group {
    .lineItemGroup__table {
      border-top: 0;
    }
  }

  td {
    vertical-align: baseline;
    width: auto;
    padding: 10px;
    white-space: normal !important;
  }

  td.col-drag {
    width: 60px;
  }

  td.col-qty {
    text-align: center;
    width: 60px;
  }
  td.col-per-unit {
    width: 120px;
    text-align: right;
  }
  td.col-total {
    width: 100px;
    text-align: right;
  }

  // For invoice, items align better when they are at the top
  .invoice & {
    td {
      vertical-align: top !important;
    }
  }

  td.col-tax {
    width: 45px !important;

    label {
      position: relative;
      top: 6px;
    }
  }

  .table-header td.col-tax {
    text-align: right;
  }

  td.col-actions {
    width: 15px !important;

    .icon {
      font-size: 1.3em;
      text-align: right;
      margin-left: auto;
      color: $primary-color;
      cursor: pointer;
    }
  }

  .quantity-input,
  .unit-price-input {
    text-align: center;
    border: 1px solid $primary-accent-color;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    min-height: 30px;
    font-family: inherit;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      opacity: 0;
      pointer-events: none;
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .quantity-input {
    width: 35px;
  }

  .unit-price-input {
    width: 100px;
  }

  .unit-name-input {
    width: 100%;
    text-align: left;
  }

  .drag-handle {
    text-align: center;
    cursor: grab;

    &:active,
    &:focus {
      cursor: grabbing;
    }

    i {
      transform: translateY(3px);
      font-size: 22px;
      width: 30px;
      height: 21px;
      border-radius: 7px;
    }
  }

  .lineItemGroup {
    &.even {
      background: $angel-blue;
    }
  }
}

table {
  width: 100%;
  background-color: $light-color;
  thead {
    th {
      background-color: $tertiary-color;
      color: $light-color;
      font-weight: bold;
      height: 25px;
      font-size: 0.9em;
    }
  }
  tbody {
    tr {
      background-color: #fff;
      &.table-header {
        td {
          padding: 10px;
          color: $text-color-ss;
          border-bottom: 2px solid $secondary-color;
          font-weight: bold;
        }
      }
      &.totals-row {
        td {
          border-top: 1px solid $tertiary-color;
          background-color: $light-color;
          border-left: none;
          border-right: none;
        }
      }
      td.group {
        padding: 0;
      }
    }
  }
}
.comparison-value {
  font-style: italic;
  color: $text-color-ss;
}
</style>
