<template>
  <tr class="lineItemGroup" :class="tableClasses">
    <td colspan="100" class="!tw-p-0 box-shadow drag-cont">
      <table class="lineItemGroup__table" :class="tableClasses">
        <Draggable
          v-model="items"
          tag="tbody"
          class="sublist"
          draggable=".lineItemRow"
          handle=".drag-handle"
          :chosen-class="'line-item-chosen'"
          :ghost-class="'line-item-ghost'"
          :class="tableClasses"
          :animation="150"
          :move="handleMove"
          @end="handleEndDrag"
        >
          <LineItemRow
            v-for="(item, itemIndex) in items"
            :key="`${item.id}-${itemIndex}`"
            ref="itemRow"
            v-bind="$props"
            :item="item"
            :item-index="itemIndex"
            :items="items"
            v-on="$listeners"
          >
            <template #actionsIcon>
              <slot name="actionsIcon" />
            </template>
            <template #deleteIcon>
              <slot name="deleteIcon" />
            </template>
          </LineItemRow>
        </Draggable>
        <tr v-if="isGroup && editing" class="groupTotal">
          <td colspan="4"><div class="groupTotal__border" /></td>
          <td class="groupTotal">
            <div class="groupTotal__border" />
            <strong class="groupTotal__value">{{
              getCostDisplay(value.total_cents)
            }}</strong>
          </td>
          <td><div class="groupTotal__border" /></td>
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
import * as QuiteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import LineItemRow from "serviceshift-ui/components/QuoteInvoice/LineItemRow.vue";
import currency from "serviceshift-ui/shared/src/filters/currency";
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
    LineItemRow
  },
  filters: {
    currency
  },
  props: {
    equipmentTypesEnabled: {
      type: Boolean,
      default: false
    },
    groupingEnabled: {
      type: Boolean,
      default: true
    },
    editTaxesEnabled: {
      type: Boolean,
      default: false
    },
    lineItemRowDetailsOpen: {
      type: Object,
      default: () => ({})
    },
    /** @type { QuiteInvoiceTypes.Group } */
    value: {
      type: Object,
      default: null,
      required: true
    },
    eventId: {
      type: Number,
      required: true
    },
    lineItemType: {
      type: String,
      default: "quote"
    },
    editing: {
      type: Boolean,
      default: false
    },
    techMode: {
      type: Boolean,
      default: false
    },
    twoColumn: {
      type: Boolean,
      default: false
    },
    groupItems: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isGroup() {
      return this.items.length > 1;
    },
    tableClasses() {
      const classes = [];
      if (this.isGroup) classes.push("group");
      if (this.editing) classes.push("editing");
      return classes.join(" ");
    },
    items: {
      get() {
        if (!this.value.items_in_group.length) {
          return [this.value];
        }
        return this.value.items_in_group;
      },
      set(newItems) {
        // Create a truly unique group ID if we are creating a new group
        const groupId =
          this.value.group_id || Math.floor(new Date().getTime() / 1000);
        const isGroup = newItems.length > 1;
        this.$emit("input", {
          ...this.value,
          items_in_group: newItems.map((item) => {
            item.group_id = isGroup ? groupId : undefined;
            return item;
          }),
          type: isGroup ? "group" : "lineItem",
          group_id: groupId
        });
      }
    }
  },
  methods: {
    handleMove(draggableEvent) {
      this.$emit("move", draggableEvent);
    },
    /** @type { string | undefined } */
    getCostDisplay(priceInCents, item) {
      if (item && !this.editing && this.items.indexOf(item) > 0)
        return undefined;
      return currency(priceInCents / 100);
    },
    handleEndDrag(e) {
      this.$emit("sort-items", e);
    }
  }
};
</script>

<style scoped lang="scss">
.lineItemGroup {
  .groupTotal {
    text-align: right;
    &__perUnit {
      padding-right: 5px;
    }
    td {
      position: relative;
      &:first-child {
        .groupTotal__border {
          left: 30px;
        }
      }
      &:last-child {
        .groupTotal__border {
          right: 30px;
        }
      }
    }
    &__border {
      border-top: 1px solid $secondary-color;
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  tr:not(:first-child) {
    .col-lineItemRowDetails {
      padding-left: 20px;
    }
  }
  &__table {
    border: 2px dashed transparent;
    width: 100%;
    position: relative;
  }
  &.group {
    &.editing {
      // Fixes safari display issue
      .drag-cont {
        box-shadow: 0 0 0 1px $secondary-color inset;
      }
    }
    & + .group {
      border-top: 0;
    }
  }
}

.line-item-chosen {
  border: 1px solid $medium-color;
  box-shadow: $box-shadow;
  background: #fff;
}
.line-item-ghost {
  pointer-events: none;
  // background: #90b5ff !important;
  background: $tertiary-color !important;
  color: #fff !important;
  ::v-deep {
    input,
    textarea {
      color: $text-color-ss !important;
    }
  }
}
.drag-cont {
  position: relative;
}
.drag-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: $primary-color;
  opacity: 0.3;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
.first-cell {
  width: 60px;
}
</style>
