export function scrollToNearestErrorMessage() {
  setTimeout(() => {
    const errorElement = document.querySelector(".errorMessage");
    if (!errorElement) return;
    errorElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }, 50);
}
