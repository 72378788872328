<template>
  <div class="category-card" @click="handleClick">
    <a>{{ category.name }}</a>
  </div>
</template>

<script>
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  props: {
    category: {
      type: Object,
      default: null
    }
  },
  methods: {
    handleClick() {
      EventBus.$emit("category-click", this.category);
    }
  }
};
</script>

<style lang="scss" scoped>
.category-card {
  line-height: 1.1;
  background-color: $primary-color;
  border-radius: 24px;
  box-shadow: $box-shadow;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  width: 18%;
  height: 50px;
  font-size: 0.8em;
  margin: 1%;
  &:hover {
    background-color: darken($primary-color, 5%);
  }
  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}
</style>
