<template>
  <div v-if="job && jobAppointment" class="appointmentDetails">
    <ArrivalDetails :appointment="appointment" :job="job" class="mb-4">
      <template #tabs>
        <AppointmentTabs
          :is-customer="isCustomer"
          :appointment="appointment"
          :job="job"
        />
      </template>
    </ArrivalDetails>
    <AppointmentMap
      :job-appointment="jobAppointment"
      :address="appointment.address"
      :tech-last-position="appointment.technician?.last_position"
      class="mb-3"
    />
    <ServiceSummary v-if="isCustomer" :appointment="appointment" :job="job" />
  </div>
</template>

<script lang="ts" setup>
import { Appointment } from "serviceshift-ui/shared/src/typings/appointment";
import { Job } from "serviceshift-ui/shared/src/typings/job";
import { watch } from "vue";

import ArrivalDetails from "@/components/Appointment/ArrivalDetails.vue";
import AppointmentTabs from "@/components/Appointment/tabs/AppointmentTabs.vue";
import ServiceSummary from "@/components/ServiceSummary.vue";
import { useAppointmentHookData } from "@/hooks/useAppointmentHookData";
import AppointmentMap from "./AppointmentMap.vue";

const { jobAppointment, updateAppointmentHookData } = useAppointmentHookData();

const props = defineProps({
  isCustomer: {
    type: Boolean,
    default: () => false
  },
  appointment: {
    type: Object as () => Appointment,
    default: () => null
  },
  job: {
    type: Object as () => Job,
    default: () => null
  }
});

watch(props, updateAppointmentHookData, { immediate: true });
</script>
