<template>
  <label class="d-flex align-items-center" :data-cy="label || 'checkbox'">
    <div
      class="checkbox-container"
      :class="{ 'tw-mr-3': !!label, active: checked, disabled }"
    >
      <Transition>
        <i v-if="checked" class="fas fa-check check-icon" />
      </Transition>
      <input
        v-model="checked"
        type="checkbox"
        class="custom-checkbox"
        :disabled="disabled"
      />
    </div>
    {{ label }}
  </label>
</template>

<script>
import Transition from "../General/Transition.vue";

export default {
  name: "Checkbox",
  components: {
    Transition
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  computed: {
    checked: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  height: 1.8em;
  width: 1.8em;
  border: 1px solid #ced4da;
  border-radius: 3px;
  background: white;
  color: white;
  cursor: pointer;
  transition: background 200ms ease-out;
  padding: 0.2em;
  position: relative;
  &.active {
    background: $primary-color;
    border-color: $primary-color;
  }
  &.disabled {
    background: $light-color;
    border-color: $light-color;
    color: $medium-color;
    cursor: default;
    opacity: 0.7;
  }
}

.custom-checkbox {
  display: none;
}
.check-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
