import axios from "axios";

import packageInfo from "../../../package.json";
import accounting from "./api/accounting";
import appointment from "./api/appointment";
import availability from "./api/availability";
import comment from "./api/comment";
import department from "./api/department";
import equipment from "./api/equipment";
import invoice from "./api/invoice";
import job from "./api/job";
import membershipProgram from "./api/membershipProgram";
import parts from "./api/parts";
import pricing from "./api/pricing";
import quote from "./api/quote";
import serviceCategories from "./api/service_categories";
import tasks from "./api/tasks";
import technician from "./api/technician";
import tenant from "./api/tenant";
import user from "./api/user";
import vendors from "./api/vendors";
import warrantyProgram from "./api/warrantyProgram";
import ENV from "./env";
import {
  attachRequestInterceptor,
  attachResponseInterceptor,
  ejectRequestInterceptor,
  ejectResponseInterceptor
} from "./interceptors";
import { makeRequest } from "./request";

const init = ENV.init;

export default {
  accounting,
  appointment,
  attachRequestInterceptor,
  attachResponseInterceptor,
  availability,
  axios,
  comment,
  department,
  ejectRequestInterceptor,
  ejectResponseInterceptor,
  ENV,
  equipment,
  init,
  invoice,
  job,
  makeRequest,
  membershipProgram,
  parts,
  pricing,
  quote,
  serviceCategories,
  tasks,
  technician,
  tenant,
  user,
  vendors,
  version: packageInfo.version,
  warrantyProgram
};
