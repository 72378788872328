<template>
  <v-dialog
    v-model="show"
    :transition="
      $vuetify.breakpoint.smAndDown
        ? 'dialog-bottom-transition'
        : 'dialog-transition'
    "
    max-width="450"
  >
    <template v-if="showDeleteIconTrigger" #activator="{ on: dialog }">
      <v-tooltip left>
        <template #activator="{ on: tooltip }">
          <v-btn
            class="delete-icon"
            icon
            color="secondary"
            v-on="{ ...dialog, ...tooltip }"
          >
            <v-icon medium color="primary">mdi-trash-can-outline</v-icon>
          </v-btn>
        </template>
        <span>Delete Payment Method</span>
      </v-tooltip>
    </template>

    <v-tooltip left>
      <span>Delete Payment Method</span>
    </v-tooltip>
    <v-card>
      <div class="p-6 text-center">
        <div class="mb-4">
          <strong>Are you sure you want to delete this payment method?</strong>
        </div>
        <div class="subtle-text mb-10">
          Note: This action cannot be undone, but you can register the payment
          method info again.
        </div>
        <div class="text-center">
          <v-btn
            color="warning"
            class="mr-14"
            outlined
            rounded
            @click="hideDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            rounded
            class="ml-4"
            type="submit"
            :loading="loading"
            @click="handleDelete"
            >Delete</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { PaymentMethod } from "serviceshift-ui/shared/src/typings/paymentMethods";
import { computed, ref } from "vue";

import usePaymentMethods from "../../usePaymentMethods";

const { deletePaymentMethod } = usePaymentMethods();

const emit = defineEmits(["input", "payment-method-deleted"]);

const props = defineProps({
  paymentMethod: {
    type: Object as () => PaymentMethod,
    default: () => null
  },
  showDeleteIconTrigger: {
    type: Boolean,
    default: () => false
  },
  value: {
    type: Boolean,
    default: () => false
  }
});

const loading = ref(false);
const show = computed({
  get() {
    return props.value;
  },
  set(val) {
    emit("input", val);
  }
});

function hideDialog() {
  show.value = false;
}

async function handleDelete(this: any) {
  loading.value = true;
  await deletePaymentMethod();
  loading.value = false;
  show.value = false;
  emit("payment-method-deleted");
}
</script>

<style scoped>
.delete-icon {
  position: absolute;
  top: 20px;
  right: 2px;
}
.subtle-text {
  font-size: medium;
  color: #666;
}
</style>
