import axios from "axios";

import ENV from "../env";

export default {
  getAllPriceSheets: () => {
    return axios(`${ENV.v2Path}/price_sheets`, {
      method: "GET"
    });
  }
};
