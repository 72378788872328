<template>
  <div :class="['truncated-text', { expand }]">
    <slot>{{ text }}</slot>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ""
    },
    expand: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.truncated-text {
  max-width: 100%;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.expand {
    &:hover {
      white-space: normal;
    }
  }
}
</style>
