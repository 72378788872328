<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="gpi-form px-4">
    <div class="mb-6 introText">
      ACH allows you to make an automatic payment from your bank account.
    </div>
    <div class="d-flex mb-1 border-bottom">
      <PaymentMethodInput
        required
        :error="errorMessages.firstName"
        label="First Name"
        class="w-full mr-4"
      >
        <Input v-model="form.ach_first_name" solo name="First Name" />
      </PaymentMethodInput>
      <PaymentMethodInput
        required
        :error="errorMessages.lastName"
        label="Last Name"
        class="w-full"
      >
        <Input v-model="form.ach_last_name" solo name="Last Name" />
      </PaymentMethodInput>
    </div>
    <div class="border-bottom mb-3">
      <PaymentMethodInput
        required
        data-test-id="accountTypeInput"
        :error="errorMessages.accountType"
        label="Account Type"
        class="w-full mb-3"
      >
        <Select
          v-model="form.ach_account_type"
          :options="accountTypes"
          placeholder="Select Account Type"
        />
      </PaymentMethodInput>
    </div>
    <div class="d-flex mb-3 border-bottom">
      <PaymentMethodInput
        required
        :error="errorMessages.routingNumber"
        class="w-full mr-4"
        tooltip="Nine digit number unique to your bank."
        label="Routing Number"
      >
        <Input v-model="form.ach_routing_number" solo name="Routing Number" />
      </PaymentMethodInput>
      <PaymentMethodInput
        required
        :error="errorMessages.accountNumber"
        class="w-full"
        label="Account Number"
      >
        <Input v-model="form.ach_account_number" solo name="Account Number" />
      </PaymentMethodInput>
    </div>
    <div
      v-if="showSavePayment && config && config.card_savable && !autoSaveCard"
      class="flex-column mb-3 mt-4 save-payment"
    >
      <SavePaymentMethodCheckbox
        v-model="form.ach_save_payment_method"
        payment-type="ACH"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import Input from "@/components/Input.vue";
import usePaymentMethods from "../usePaymentMethods";
import PaymentMethodInput from "./PaymentMethodInput.vue";
import SavePaymentMethodCheckbox from "./SavePaymentMethodCheckbox.vue";

type ACHForm = {
  ach_first_name: string;
  ach_last_name: string;
  ach_account_type: string;
  ach_routing_number: string;
  ach_account_number: string;
  ach_save_payment_method: string;
};

const { paymentMethodOptions } = usePaymentMethods();

const props = defineProps({
  showSavePayment: {
    type: Boolean,
    default: true
  },
  form: {
    type: Object as () => ACHForm,
    default: () => null
  },
  autoSaveCard: {
    type: Boolean,
    default: () => false
  }
});

defineExpose({
  getData
});

const accountTypes = ref([
  { label: "Checking", value: "checking" },
  { label: "Savings", value: "savings" }
]);
const errorMessages = ref({
  accountType: "",
  firstName: "",
  lastName: "",
  routingNumber: "",
  accountNumber: ""
});

const config = computed(() => {
  return paymentMethodOptions.value.find((method) => method.key === "gpi_ach");
});

function clearErrors() {
  Object.keys(errorMessages.value).forEach((key) => {
    errorMessages.value[key] = "";
  });
}

async function getData() {
  clearErrors();
  let errorMessage = "";
  if (!props.form.ach_account_type) {
    errorMessage = "Account type is required.";
    errorMessages.value.accountType = errorMessage;
  } else if (!props.form.ach_first_name) {
    errorMessage = "First name is required.";
    errorMessages.value.firstName = errorMessage;
  } else if (!props.form.ach_last_name) {
    errorMessage = "Last name is required.";
    errorMessages.value.lastName = errorMessage;
  } else if (!props.form.ach_routing_number) {
    errorMessage = "Routing number is required.";
    errorMessages.value.routingNumber = errorMessage;
  } else if (!props.form.ach_account_number) {
    errorMessage = "Account number is required.";
    errorMessages.value.accountNumber = errorMessage;
  }
  if (errorMessage) {
    throw new Error(
      "Unable to submit ACH Payment form with error: " + errorMessage
    );
  }
  return props.form;
}
</script>

<style lang="scss" scoped>
.introText {
  font-size: 14px;
  line-height: 16px;
}
:deep(.select-box) {
  box-shadow: none;
  margin-bottom: -3px;
}
</style>
