const state = {
  API_BASE_URL: "",
  initialized: false
};

const ENV = {
  state,
  get basePath() {
    return `${state.API_BASE_URL}`;
  },
  get v1Path() {
    return `${state.API_BASE_URL}/v1`;
  },
  get v2Path() {
    return `${state.API_BASE_URL}/v2`;
  },
  get v3Path() {
    return `${state.API_BASE_URL}/v3`;
  },
  init(envVariables: Partial<typeof state>) {
    if (envVariables) {
      Object.keys(envVariables).forEach((key) => {
        state[key] = envVariables[key];
      });
      state.initialized = true;
    }
    return this;
  }
};

export default ENV;
