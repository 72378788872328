<template>
  <Money v-model="price" v-bind="moneyConfig" text-right class="price-input" />
</template>

<script>
import { Money } from "v-money";

export default {
  components: {
    Money
  },
  props: {
    value: {
      type: [Number, String, null],
      default: null
    }
  },
  data() {
    return {
      moneyConfig: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        suffix: "",
        precision: 2,
        masked: false
      }
    };
  },
  computed: {
    price: {
      get() {
        return this.value / 100;
      },
      set(val) {
        const cents = parseFloat((val * 100).toFixed(0));
        this.$emit("price-change", cents);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
