<template>
  <div v-if="jobNotes" class="d-flex justify-center align-center">
    <v-simple-table
      v-if="jobNotes.length > 0"
      class="comments-table full-width"
    >
      <thead>
        <tr>
          <th class="small-column">Date</th>
          <th class="small-column">User</th>
          <th>Note</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="note in jobNotes" :key="note.id">
          <td>{{ formatDate(note.updated_at) }}</td>
          <td>{{ note.user.full_name }}</td>
          <td>
            <v-icon v-if="note.pinned" color="error" class="pinned-icon mr-1"
              >mdi-alert-circle-outline</v-icon
            >
            <div class="note-container">{{ note.comment }}</div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <span v-else class="no-notes-text"
      >There are no notes for this appointment</span
    >
  </div>
</template>

<script>
import { mapActions } from "vuex";

import { prettyFormat } from "../lib/date";

export default {
  props: {
    jobId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      jobNotes: null
    };
  },
  mounted() {
    this.fetchJobComments();
  },
  methods: {
    ...mapActions(["fetchComments"]),
    formatDate(date) {
      return prettyFormat(new Date(date));
    },
    fetchJobComments() {
      this.fetchComments({
        id: this.jobId,
        type: "Job"
      }).then((result) => {
        this.jobNotes = Object.keys(result.data)
          .map((property) => result.data[property]) //Object.values pre-ie11
          .reduce((acc, value) => [...acc, ...value], [])
          .filter((note) => note.customer_visible);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.no-notes-text {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-family: "Roboto", sans-serif;
}

.small-column {
  width: 15%;
}

.note-container {
  white-space: pre-line;
  display: inline;
}

.pinned-icon {
  display: inline;
}

.comments-table table tr td {
  vertical-align: top;
  font-size: 0.75rem !important;
}
</style>
