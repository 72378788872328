import axios from "axios";

import ENV from "../env";

export default {
  get: (deptId, id) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/tasks/${id}`, {
      method: "GET"
    });
  },
  update: (deptId, id, data) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/tasks/${id}`, {
      method: "PUT",
      data
    });
  },
  delete: (deptId, id) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/tasks/${id}`, {
      method: "DELETE"
    });
  },
  create: (deptId, data) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/tasks`, {
      method: "POST",
      data
    });
  },
  fetchCategories(deptId) {
    return axios(`${ENV.v2Path}/departments/${deptId}/task_categories`, {
      method: "GET"
    });
  },
  fetchCategoryTree(deptId) {
    return axios(`${ENV.v2Path}/departments/${deptId}/tasks/organized`, {
      method: "GET"
    });
  },
  fetchCategory(deptId, id) {
    return axios(`${ENV.v2Path}/departments/${deptId}/task_categories/${id}`, {
      method: "GET"
    });
  },
  createCategory(deptId, data) {
    return axios(`${ENV.v2Path}/departments/${deptId}/task_categories`, {
      method: "POST",
      data: {
        task_category: data
      }
    });
  },
  createPart(deptId, id, data) {
    return axios(`${ENV.v2Path}/departments/${deptId}/tasks/${id}/task_parts`, {
      method: "POST",
      data
    });
  },
  updatePart(deptId, id, data) {
    return axios(
      `${ENV.v2Path}/departments/${deptId}/tasks/${id}/task_parts/${data.id}`,
      {
        method: "PUT",
        data
      }
    );
  },
  deletePart(deptId, id, partId) {
    return axios(
      `${ENV.v2Path}/departments/${deptId}/tasks/${id}/task_parts/${partId}`,
      {
        method: "DELETE"
      }
    );
  },
  updateCategory(deptId, data) {
    return axios(
      `${ENV.v2Path}/departments/${deptId}/task_categories/${data.id}`,
      {
        method: "PUT",
        data: {
          task_category: data
        }
      }
    );
  },
  deleteCategory(deptId, id) {
    return axios(`${ENV.v2Path}/departments/${deptId}/task_categories/${id}`, {
      method: "DELETE"
    });
  },
  fetchTaskTree() {
    return axios(`${ENV.v2Path}/tasks`, {
      method: "GET"
    });
  },
  updateRecommendations({ deptId, id, type, recommendations }) {
    return axios(`${ENV.v2Path}/departments/${deptId}/tasks/${id}/${type}`, {
      method: "POST",
      data: { items: recommendations }
    });
  }
};
