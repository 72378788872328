import axios from "axios";

import ENV from "../env";

export default {
  getPreview: () => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/technician_preview`
    });
  },
  getAll: () => {
    return axios(`${ENV.v2Path}/technicians`, {
      method: "GET"
    });
  },
  getAllForJob: (jobId) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/technicians`, {
      method: "GET"
    });
  }
};
