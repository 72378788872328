<template>
  <Page title="Thank You!" no-banner>
    <div class="mb-6">
      <div class="mx-6 my-3 text-center caption">
        <p>Your account has been created successfully.</p>
        <p>
          We have {{ $store.state.tenant.name }} technicians ready to help you,
          whenever you need us!
        </p>
        <p class="italic">
          To change this info,
          <router-link :to="{ name: 'profile' }"
            >edit your profile.</router-link
          >
        </p>
      </div>
      <span class="overline ml-4">Account Info</span>
      <v-card class="ma-4 mt-1 pa-3 text-center bordered-card">
        <v-card-text>
          <p class="title">
            {{ $store.state.user.first_name }} {{ $store.state.user.last_name }}
          </p>
          <p>{{ $store.state.user.phone }}</p>
          <p>{{ $store.state.user.email }}</p>
        </v-card-text>
      </v-card>
      <span class="overline ml-4">Preferences</span>
      <v-card class="ma-4 mt-1 pa-3 bordered-card">
        <v-card-text>
          <v-row class="row caption">
            <v-col cols="5" class="pa-0 px-1">
              <p class="grey--text text--darken-2">Payment Method</p>
            </v-col>
            <v-col cols="7" class="pa-0">
              <p>None specified</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <span class="overline ml-4">Your Addresses</span>
      <v-card class="ma-4 mt-1 pa-3 bordered-card">
        <v-card-text>
          <v-row class="row caption">
            <v-col cols="5" class="pa-0 px-1">
              <div class="d-flex">
                <v-icon small color="primary" class="mr-2">mdi-star</v-icon>
                <p class="grey--text text--darken-2">Primary</p>
              </div>
            </v-col>
            <v-col cols="7" class="pa-0">
              <p>{{ $store.state.user.addresses[0].street }}</p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="d-flex flex-column align-center">
        <span class="overline primary--text"
          >Our technicians are ready to go.</span
        >
        <v-btn rounded color="primary" to="/schedule"
          >Schedule Your First Appointment</v-btn
        >
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "@/components/Page.vue";
import { formatFullAddress } from "../lib/address";

export default {
  components: {
    Page
  },
  mounted() {
    this.$store.commit("replaceState", {
      state: "userAppointmentsLoaded",
      data: true
    });
  },
  methods: {
    formatAddress() {
      return formatFullAddress(this.$store.state.user.addresses[0]);
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  margin: 0 !important;
}
.v-card {
  color: rgba(0, 0, 0, 0.54);
}
</style>
