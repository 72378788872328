<template>
  <div>
    <div class="equipment-request-header tw-px-3 tw-py-1">
      Optional Equipment Information
    </div>
    <div class="divide-y bg-light tw-px-3">
      <div class="tw-py-4">
        <Select
          v-model="equipmentId"
          full-width
          label="Use Existing Equipment (Optional)"
          :options="equipmentOptions"
          :placeholder="
            equipmentOptions.length ? 'Select...' : 'No available equipment'
          "
        >
          <template #item-row="{ option }">
            <div class="option-row">
              <span :class="{ 'tw-line-through': option.archived }">{{
                option.label
              }}</span>
              <span v-if="option.archived" class="tw-italic tw-ml-1"
                >(Archived)</span
              >
            </div>
          </template>
        </Select>
      </div>
      <div class="tw-flex tw-items-center tw-justify-between tw-py-4">
        <b>Add new equipment?</b>
        <SeraButton text="Add/Replace" @click="$emit('add-replace-click')" />
      </div>
    </div>
  </div>
</template>

<script>
import SeraButton from "serviceshift-ui/components/Inputs/Button.vue";
import Select from "serviceshift-ui/components/Inputs/Select.vue";
import * as EquipmentTypes from "serviceshift-ui/shared/src/classes/equipment";

export default {
  name: "AdditionalEquipmentRequestForm",
  components: {
    Select,
    SeraButton
  },
  props: {
    /** @type {EquipmentTypes.Equipment[]} */
    equipment: {
      type: Array,
      default: () => []
    },
    showArchivedEquipment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      equipmentId: null
    };
  },
  computed: {
    equipmentOptions() {
      const filteredEquipment = this.equipment.filter((equipment) => {
        if (!this.showArchivedEquipment) return !equipment.archived;
        return true;
      });
      return filteredEquipment.map((eq) => ({
        label: eq.name,
        value: eq.id,
        archived: eq.archived
      }));
    },
    selectedEquipment() {
      if (!this.equipmentId) return null;
      return this.equipment.find((eq) => eq.id === this.equipmentId);
    }
  },
  watch: {
    selectedEquipment(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.$emit("create-equipment-link", {
          equipment_id: newValue.id,
          equipment_type_id: newValue.equipment_type_id
        });
      }
    }
  },
  methods: {
    resetForm() {
      this.equipmentId = null;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .field-label {
  font-weight: bold;
}
.equipment-request-header {
  background: $tertiary-color;
  color: #fff;
  border-radius: 4px 4px 0 0;
}
.form-cont {
  border-radius: 0 0 4px 4px;
}
</style>
