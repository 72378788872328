<template>
  <label class="radio-button-label" @click="handleClick">
    <input
      :id="radioValue"
      :checked="checked"
      :value="radioValue"
      type="radio"
    />
    <div :class="['radio-target', { checked }]" />
    <slot name="label">
      <span v-if="label" class="radio-label">{{ label }}</span>
    </slot>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      default: false
    },
    radioValue: {
      type: [String, Number, Boolean],
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  computed: {
    checked() {
      return this.value === this.radioValue;
    }
  },
  methods: {
    handleClick() {
      this.$emit("input", this.radioValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.radio-button-label {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  input {
    opacity: 0;
    height: 0;
    width: 0;
  }
  .radio-target {
    background: #fff;
    display: block;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: 0.1em solid $secondary-color;
    margin-right: 5px;
    transition: background 150ms ease-out;
    &.checked {
      background: radial-gradient($primary-color 50%, rgba(255, 0, 0, 0) 51%);
    }
  }
}
</style>
