<template>
  <div class="display-content">
    <Tooltip v-if="!techMode && calculation_details">
      <a
        v-if="detail_report_url"
        slot="trigger"
        :href="detail_report_url"
        class="table-cell-value"
      >
        {{ valueDisplay }}
      </a>
      <span v-else slot="trigger" class="table-cell-value">{{
        valueDisplay
      }}</span>
      <div slot="tooltip">
        <slot name="tooltip">
          {{ calculation_details }}
        </slot>
      </div>
    </Tooltip>
    <div v-else>
      <a
        v-if="!techMode && detail_report_url"
        :href="detail_report_url"
        class="table-cell-value"
        >{{ valueDisplay }}</a
      >
      <span v-else class="table-cell-value">{{ valueDisplay }}</span>
    </div>
    <span class="table-cell-value" :class="[deltaColor]">
      {{
        Number.isFinite(comparisonDelta) ? formatPercent(comparisonDelta) : "--"
      }}
      <i
        v-if="deltaIcon"
        class="table-cell-icon"
        :class="`fas fa-arrow-${deltaIcon}`"
      />
    </span>
  </div>
</template>

<script>
import Tooltip from "serviceshift-ui/components/General/Tooltip.vue";
import { dollars } from "serviceshift-ui/shared/src/mixins";
import { awsAsset } from "serviceshift-ui/shared/src/mixins/aws";

const MILLION_DOLLAR_LENGTH = 9;

export default {
  components: {
    Tooltip
  },
  props: {
    base: {
      type: Object,
      default: null
    },
    comparison: {
      type: Object,
      default: null
    },
    techMode: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    }
  },
  computed: {
    valueDisplay() {
      const value = this.base ? this.base.value : 0;
      if (this.type === "currency") {
        const rawCents = Math.floor(value);
        const options =
          rawCents.toString().length >= MILLION_DOLLAR_LENGTH
            ? {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              }
            : {};
        return dollars(value, options);
      }
      if (this.type === "percentage") {
        return this.formatPercent(value);
      }
      return value.toLocaleString("en-us");
    },
    comparisonDelta() {
      if (this.base && this.comparison && this.comparison.value > 0) {
        const difference = this.base.value - this.comparison.value;
        return this.type === "percentage"
          ? difference
          : difference / this.comparison.value;
      }
      return Infinity;
    },
    deltaIcon() {
      if (
        this.comparisonDelta === 0 ||
        !Number.isFinite(this.comparisonDelta)
      ) {
        return "";
      }
      return this.comparisonDelta > 0 ? "up green" : "down red";
    },
    deltaColor() {
      if (
        this.comparisonDelta === 0 ||
        !Number.isFinite(this.comparisonDelta)
      ) {
        return "";
      }

      return this.comparisonDelta > 0 ? "green" : "red";
    },
    calculation_details() {
      return this.base ? this.base.calculation_details : "";
    },
    detail_report_url() {
      return this.base ? this.base.detail_report_url : "";
    }
  },
  methods: {
    formatPercent(percent) {
      return `${parseFloat((percent * 100).toFixed(2))}%`;
    },
    getSvg(svgName) {
      return awsAsset(svgName, "leaderboard", "svg");
    }
  }
};
</script>

<style lang="scss" scoped>
.display-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table-cell-value {
  font-size: 14px;
}

.table-cell-delta {
  color: #676666;
  font-size: 12px;
}

.table-cell-icon {
  font-size: 0.7em;
  color: #b1b1b1;
  margin-left: 5px;
}

.green {
  color: $success-color;
}

.red {
  color: $danger-color;
}
</style>
