<template>
  <div
    v-if="quote"
    :class="[
      'quote-card',
      {
        'presentation-mode': presentationMode,
        'approved-quote': quote.status === 'approved' || quote.selected
      }
    ]"
    @click="handleQuoteClick"
  >
    <div class="d-flex align-items-center">
      <!-- Card view for a single quote. Two modes: normal/presentation -->
      <div v-if="!presentationMode" class="quote-info tw-mr-3">
        <div class="quote-id font-bold">Quote #{{ quote.id }}</div>
        <div class="quote-date text-gray">
          {{ formatDate(quote.created_at) }}
        </div>
      </div>
      <div class="flex-grow-2 quote-details">
        <div v-if="presentationMode" class="quote-id text-link-color font-bold">
          Quote #{{ quote.id }}
        </div>
        <div class="quote-name">
          {{ quote.name || "Untitled Quote" }}
        </div>
        <div class="quote-summary text-gray">
          {{ quote.summary || "--" }}
        </div>
      </div>
      <div
        v-if="!presentationMode"
        class="tw-mr-1 tw-ml-1 text-darkblue font-bold quote-price"
      >
        {{ formatCents(quote.financials.total_cents) }}
      </div>
      <div
        v-if="!presentationMode"
        class="action-menu-cont"
        @click="preventQuoteClick"
      >
        <QuoteActionMenu
          :quote="quote"
          :tech-mode="techMode"
          quote-group-mode
        />
      </div>
      <div v-if="presentationMode" class="d-flex presentation-details">
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <div>
            <span class="quote-price">{{
              formatCents(quote.financials.total_cents)
            }}</span>
          </div>
          <div>
            <span class="membership-savings-label">{{ savingsLabel }}</span>
            <div
              :class="{
                'text-marketing-lime-green': quote.financials.savings_cents >= 0
              }"
            >
              {{ formatCents(Math.abs(quote.financials.savings_cents)) }}
            </div>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-center approve-icon-cont tw-ml-1"
          @click="handleApproveClick"
        >
          <i
            v-if="quote.status === 'approved'"
            class="fas fa-check approve-icon text-marketing-lime-green"
          />
          <i
            v-else-if="quote.selected"
            class="fas fa-thumbs-up approve-icon text-marketing-lime-green"
          />
          <i v-else class="fas fa-thumbs-up approve-icon white-icon" />
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import isNil from "lodash/isNil";
import QuoteActionMenu from "serviceshift-ui/components/MultipleQuotes/QuoteActionMenu.vue";
import * as QuiteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import { Quote } from "serviceshift-ui/components/QuoteInvoice/interface";
import { dollars, formatDate } from "serviceshift-ui/shared/src/mixins";

export default {
  name: "QuoteCard",
  components: {
    QuoteActionMenu
  },
  props: {
    /** @type { QuiteInvoiceTypes.Quote } */
    quote: {
      type: Object,
      default: null
    },
    presentationMode: {
      type: Boolean,
      default: false
    },
    techMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isNil
    };
  },
  computed: {
    savingsLabel() {
      return this.quote && this.quote.financials.savings_cents >= 0
        ? "Membership Savings"
        : "You could have saved";
    }
  },
  methods: {
    formatCents(cents) {
      return dollars(cents);
    },
    formatDate(date) {
      return formatDate(new Date(date));
    },
    handleApproveClick(e) {
      e.stopPropagation();
      this.$emit("quote-approve-click", this.quote.id);
    },
    handleQuoteClick() {
      this.$emit("quote-card-click", this.quote.id);
    },
    preventQuoteClick(e) {
      e.stopPropagation();
    }
  }
};
</script>

<style lang="scss" scoped>
.quote-card {
  padding: 15px;
  background-color: #fff;
  cursor: pointer;

  .quote-id {
    font-size: 0.9em;
    white-space: nowrap;
  }
  .quote-date {
    font-size: 0.9em;
  }
  .quote-name {
    font-weight: bold;
    font-size: 1.2em;
  }
  .quote-summary {
    white-space: pre-line;
    font-size: 0.9em;
  }
  .quote-price {
    font-size: 1.2em;
  }
  .action-menu-cont {
    width: 0.5em;
  }
  &.presentation-mode {
    box-shadow: $box-shadow;
    padding: 0;
    align-items: stretch !important;
    border-radius: 5px;
    overflow: hidden;
    &.approved-quote > .quote-details {
      border: 2px solid $tertiary-color;
    }
    .quote-details {
      padding: 15px;
      cursor: pointer;
    }
    .presentation-details {
      background-color: $tertiary-color;
      color: #fff;
      font-weight: bold;
      flex-shrink: 0;
      padding: 10px 15px;
      align-self: stretch;
      text-align: center;
    }
    .membership-savings-label {
      color: $medium-color;
      font-size: 0.7em;
      font-weight: normal;
      white-space: nowrap;
      margin-top: 5px;
    }
    .approve-icon-cont {
      border-left: 1px solid #fff;
      padding-left: 10px;
      cursor: pointer;
      .approve-icon {
        font-size: 1.4em;
      }
    }
  }
  .white-icon {
    color: white;
  }
}
</style>
