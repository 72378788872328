import { inject, provide } from "vue";

let uid = 0;

// Creates a hook that injects the definition into the child components
export default function provideHook<T extends any>(definition: T) {
  const id = `$_provideHook_${++uid}`;
  const hook = (...args) => {
    const instance = inject(id, null);
    if (instance) return instance;
    const hook = (definition as any)(...args);
    provide(id, hook);
    return hook;
  };
  return hook as T;
}
