<template>
  <div>
    <UpcomingAppointmentBanner v-if="!noBanner"></UpcomingAppointmentBanner>
    <div
      class="mb-6 mx-auto d-flex flex-column align-center justify-center"
      :style="`max-width: ${maxWidth}px`"
    >
      <div class="d-flex align-center justify-center full-width">
        <h1
          v-if="title"
          class="text-center ma-0 font-weight-regular"
          :class="{
            title: $vuetify.breakpoint.smAndDown,
            headline: !$vuetify.breakpoint.smAndDown
          }"
        >
          {{ title }}
        </h1>
        <slot name="headerActions" />
      </div>
      <v-divider
        v-if="title && !noDivider"
        class="full-width mb-4 mt-2"
      ></v-divider>
      <slot />
    </div>
  </div>
</template>

<script>
import UpcomingAppointmentBanner from "@/components/UpcomingAppointmentBanner.vue";

export default {
  name: "Page",
  components: {
    UpcomingAppointmentBanner
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    maxWidth: {
      type: String,
      default: "768"
    },
    noBanner: Boolean,
    noDivider: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped></style>
