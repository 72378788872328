export enum AuthRole {
  customer = "customer",
  appointment = "appointment"
}

export enum AuthStrategy {
  token = "token",
  password = "password"
}

// TODO: Do not export enums alone for now...
// SEE: https://github.com/vitejs/vite/issues/11783
export default { AuthRole, AuthStrategy };
