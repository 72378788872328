<template>
  <div
    class="tabs d-flex flex-column align-items-center"
    :class="{ 'has-active-tab': value !== null }"
  >
    <div class="d-flex full-width">
      <button
        v-for="section in sections"
        :key="section"
        :class="{ active: section === value }"
        class="tab text--primary full-width d-flex flex-column align-items-center"
        @click="toggle(section)"
      >
        <div>
          <span class="mr-2">{{ section }}</span>
          <i class="fa fa-chevron-down" />
        </div>
      </button>
    </div>
    <div v-if="$slots.default" class="tab-content pa-4">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  sections: {
    type: Array as () => string[],
    default: () => []
  },
  value: {
    type: String as () => string | null,
    default: () => null
  }
});

const emit = defineEmits(["input"]);

function toggle(section: string) {
  if (props.value === section) {
    return emit("input", null);
  }
  emit("input", section);
}
</script>

<style lang="scss" scoped>
.tabs {
  font-size: 14px;
  background: #fff;
  .tab-content {
    background: #eeeeee;
    width: 100%;
  }
  .tab {
    transition: all 0.5s;
    background: #eeeeee;
    padding: 8px;
    flex: 1;
    &:not(:first-child) {
      margin-left: 1px;
    }
    &.active {
      font-weight: 700;
      .fa-chevron-down {
        transform: rotate(180deg);
      }
    }
  }
  &.has-active-tab {
    .tab:not(.active) {
      background: #d1d1d1;
    }
  }
}
</style>
