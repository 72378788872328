<template>
  <Card>
    <div class="section-spacing-20">
      <div
        class="card-header d-flex justify-content-between align-items-center"
        :class="{ 'tw-pb-0': isCollapsible && !collapsed }"
      >
        <div class="header-content">
          <slot name="header" />
          <Button
            v-if="isCollapsible"
            :icon="`fa fa-solid fa-angle-${collapsed ? 'down' : 'up'}`"
            icon-position="right"
            class="collapse-button"
            @click="handleToggleCollapse"
          />
        </div>
      </div>
      <div v-if="isCollapsible && !collapsed" class="card-body">
        <slot name="body" />
      </div>
    </div>
  </Card>
</template>

<script>
import Card from "../../components/Card/BaseCard.vue";
import Button from "../../components/Inputs/Button.vue";

export default {
  name: "CollapsibleCard",
  components: {
    Card,
    Button
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isCollapsible() {
      return !!this.$slots.body;
    },
    collapsed: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    handleToggleCollapse() {
      this.collapsed = !this.collapsed;
    }
  }
};
</script>

<style lang="scss" scoped>
.collapse-button {
  font-size: 1.6em;
  color: $primary-color;
}
.header-content {
  color: $text-color-ss;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.card-body {
  color: $text-color-ss;
}
</style>
