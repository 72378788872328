<template>
  <ActionsMenu
    :actions="actions"
    :options="actionMenuOptions"
    :tech-mode="techMode"
    @approve-quote="emitEvent('quote-approve-click', 'quote_accept')"
    @force-approve-quote="
      emitEvent('quote-force-approve-click', 'quote_force_accept')
    "
    @schedule-work="emitEvent('schedule-work-click', 'quote_schedule')"
    @decline-quote="emitEvent('decline-quote-click', 'quote_decline')"
    @draft-quote="emitEvent('draft-quote-click', 'quote_draft')"
    @pending-quote="emitEvent('pending-quote-click', 'quote_pending')"
    @edit-quote="emitEvent('edit-quote-click')"
    @email-quote="emitEvent('email-quote-click', 'quote_email')"
    @pdf-quote="emitEvent('pdf-quote-click', 'quote_pdf')"
    @archive-quote="emitEvent('archive-quote-click', 'quote_archive')"
    @unarchive-quote="emitEvent('unarchive-quote-click', 'quote_unarchive')"
    @clone-quote="emitEvent('clone-quote-click', 'quote_clone')"
    @reactivate-quote="emitEvent('reactivate-quote-click', 'quote_reactivate')"
    @unapprove-quote="emitEvent('unapprove-quote-click', 'quote_break')"
  >
    <slot slot="trigger" name="trigger">
      <i class="fas fa-ellipsis-v action-trigger text-link-color" />
    </slot>
  </ActionsMenu>
</template>

<script>
import ActionsMenu from "serviceshift-ui/components/General/ActionsMenu.vue";
import * as QuiteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  name: "QuoteActionMenu",
  components: {
    ActionsMenu
  },
  props: {
    /** @type { QuiteInvoiceTypes.Quote } */
    quote: {
      type: Object,
      default: () => ({})
    },
    techMode: {
      type: Boolean,
      default: false
    },
    tippyOptions: {
      type: Object,
      default: () => ({})
    },
    quoteGroupMode: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    actionMenuOptions() {
      // tippy should be appended to the parent
      // instead of body when used as webcomponent in tech app
      // to handle styling the tooltip within the shadow dom
      return {
        ...this.tippyOptions,
        theme: "light",
        appendTo: this.techMode ? "parent" : document.body
      };
    },
    actions() {
      // Dynamic actions for quotes based on status
      return [
        {
          label: "Create an Invoice",
          icon: "fas fa-file-invoice-dollar",
          eventName: "approve-quote",
          className: "menu-item",
          visible: !this.quote.job_id && !this.quoteGroupMode && !this.techMode,
          disabled: !this.quote.links.quote_accept
        },
        {
          label: "Manually Accept Quote",
          icon: "fas fa-thumbs-up",
          eventName: "approve-quote",
          className: "menu-item",
          visible:
            (this.quote.job_id || this.quoteGroupMode) &&
            !this.techMode &&
            !this.quote.links.quote_force_accept,
          disabled: !this.quote.links.quote_accept
        },
        {
          label: "Force Accept",
          icon: "fas fa-thumbs-up",
          eventName: "force-approve-quote",
          className: "menu-item",
          visible: this.quote.links.quote_force_accept
        },
        {
          label: "Schedule Work",
          icon: "fas fa-calendar-alt",
          eventName: "schedule-work",
          className: "menu-item",
          visible: !this.quote.job_id && !this.quoteGroupMode && !this.techMode,
          disabled: !this.quote.links.quote_schedule
        },
        {
          label: "Clone",
          icon: "fas fa-copy",
          eventName: "clone-quote",
          className: "menu-item",
          disabled: !this.quote.links.quote_clone
        },
        {
          label: "Draft",
          icon: "fas fa-file-edit",
          eventName: "draft-quote",
          className: "menu-item",
          disabled: !this.quote.links.quote_draft,
          visible: !["draft", "denied"].includes(this.quote.status)
        },
        {
          label: "Reactivate",
          icon: "fas fa-check-circle",
          eventName: "reactivate-quote",
          className: "menu-item",
          disabled: !this.quote.links.quote_reactivate,
          visible: this.quote.status === "denied"
        },
        {
          label: "Propose",
          icon: "fas fa-check-circle",
          eventName: "pending-quote",
          className: "menu-item",
          disabled: !this.quote.links.quote_pending,
          visible: this.quote.status === "draft"
        },
        {
          label: "Decline Quote",
          icon: "fas fa-thumbs-down",
          eventName: "decline-quote",
          className: "menu-item",
          disabled: !this.quote.links.quote_decline,
          visible: !["draft", "denied"].includes(this.quote.status)
        },
        {
          label: "Edit",
          icon: "fas fa-edit",
          eventName: "edit-quote",
          className: "menu-item",
          disabled: !this.quote.links.quote_update
        },
        {
          label: "Email",
          icon: "fas fa-envelope",
          eventName: "email-quote",
          className: "menu-item",
          disabled: !this.quote.links.quote_email,
          visible: this.quote.status !== "draft"
        },
        {
          label: "Download PDF",
          icon: "fas fa-file-pdf",
          eventName: "pdf-quote",
          className: "menu-item",
          disabled: !this.quote.links.quote_pdf,
          visible: !this.techMode
        },
        {
          label: "Archive",
          icon: "fas fa-archive",
          eventName: "archive-quote",
          className: "menu-item",
          visible: !this.quote.archived,
          disabled: !this.quote.links.quote_archive
        },
        {
          label: "Unarchive",
          icon: "fas fa-box-open",
          eventName: "unarchive-quote",
          className: "menu-item",
          visible: this.quote.archived,
          disabled: !this.quote.links.quote_unarchive
        },
        {
          label: "Unapprove",
          icon: "fas fa-times-hexagon",
          eventName: "unapprove-quote",
          className: "menu-item",
          visible: this.quote.links.quote_break
        }
      ];
    }
  },
  methods: {
    emitEvent(eventName, linkName) {
      if (!this.quote) return false;
      const payload = {
        id: this.quote.id,
        link: this.quote.links[linkName]
      };
      if (this.quoteGroupMode) {
        EventBus.$emit(eventName, payload);
      } else {
        this.$emit(eventName, payload);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.action-trigger {
  font-size: 1em;
  box-sizing: content-box;
  padding: 7px;
}
</style>
