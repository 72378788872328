import appointment from "./modules/appointment/actions";
import comments from "./modules/comments";
import equipment from "./modules/equipment";
import financing from "./modules/financing/actions";

export default {
  ...appointment,
  ...financing,
  ...comments,
  ...equipment
};
