import API from "serviceshift-ui/api-client";
import { AuthRole } from "serviceshift-ui/shared/src/typings/auth";
import { setRequestHeaders } from "@/lib/api";
import { setAuth } from "@/lib/auth";

export default {
  methods: {
    async login(email, password) {
      // Update user session info and base info.
      // TODO: Login response should be the equivalent of findByEmail. We can avoid multiple calls.
      // TODO: Login response needs to come back with a role
      const loginResponse = await API.user.login({
        session: {
          email,
          password
        }
      });
      const loginUser = {
        ...loginResponse.data,
        role: AuthRole.customer
      };
      setAuth(loginUser);
      setRequestHeaders({ ...loginUser, id: undefined });

      // Get the rest of the user info and update again
      const userResponse = await API.user.findByEmail(email);
      await this.$store.dispatch("setUser", {
        ...userResponse.data,
        role: AuthRole.customer
      });
      return userResponse.data;
    },
    logout() {
      this.$store.dispatch("setUser", null);
      this.$store.commit("replaceState", {
        state: "userAppointments",
        data: []
      });
      this.$store.commit("replaceState", {
        state: "userAppointmentsLoaded",
        data: false
      });
      this.$store.commit("replaceState", {
        state: "job",
        data: null
      });
      this.$store.commit("replaceState", {
        state: "fetchedMonths",
        data: {}
      });
      this.$router.push({ path: "/login" });
    }
  }
};
