import sortBy from "lodash/sortBy";
import API from "serviceshift-ui/api-client";

export function sortCustomerEquipment(equipment = []) {
  return sortBy(equipment, (item) => item.id).reverse();
}

export default {
  async fetchCustomerEquipment({ commit, state }) {
    const { links } = state.user;
    try {
      const res = await API.makeRequest(links.fetch_equipment);
      const equipment = sortCustomerEquipment(res.data);
      commit("replaceState", {
        state: "equipment",
        data: equipment
      });
    } catch (e) {
      // do nothing
    }
  }
};
