import API from "serviceshift-ui/api-client";
import { Appointment } from "serviceshift-ui/shared/src/typings/appointment";
import { Job } from "serviceshift-ui/shared/src/typings/job";
import { computed, ref } from "vue";
import { formatFullAddress } from "@/lib/address";
import { getAppointmentWindow, prettyFormat } from "@/lib/date";

export function useAppointmentHookData() {
  const appointment = ref<Appointment>();
  const job = ref<Job>();

  // TODO: The appointment within the job contains different information than the appointmentById response
  const jobAppointment = computed(() => {
    return job.value?.appointments.find(
      (jobAppointment) => jobAppointment.id === appointment.value?.id
    );
  });

  // TODO: In the future, we should make sure the appointment/:id endpoint has the
  // same data as seen in the jobs/:id endpoint. The status of the appointment is different
  // in the jobs/:id vs appointment/:id.
  const status = computed(() => {
    const appointmentToUse = jobAppointment.value || appointment.value;
    if (!appointmentToUse) return "";
    return appointmentToUse.status;
  });

  const isTechnicianOnRoute = computed(() => {
    if (!status.value) return false;
    return ["accepted", "running"].includes(status.value);
  });

  const addressDisplay = computed(() => {
    if (!appointment.value?.address) return "";
    return formatFullAddress(appointment.value.address);
  });

  const invalidAppointment = computed(() => {
    return appointment.value === undefined;
  });

  const isActiveAppointment = computed(() => {
    return (
      !!appointment.value?.technician && !!appointment.value?.iso_dispatch_time
    );
  });

  const hasArrived = computed(() => {
    return status.value === "running";
  });

  const appointmentDate = computed(() => {
    if (!jobAppointment.value?.scheduled_time) return "--";
    return prettyFormat(new Date(jobAppointment.value.scheduled_time));
  });

  const appointmentWindow = computed(() => {
    const jobTimeZone = job.value?.address.time_zone;
    if (!jobAppointment.value?.scheduled_time) return "--";
    if (!jobTimeZone) return "--";
    return getAppointmentWindow(jobAppointment.value, jobTimeZone);
  });

  const canRescheduleCancel = computed(() => {
    const allowedStatuses = ["pending"];
    return allowedStatuses.includes(status.value);
  });

  async function generateMagicLink() {
    const res = await API.makeRequest<{
      expires_at: string;
      token: string;
    }>(`/v1/appointments/${appointment.value!.id}/share`, {});
    return location.origin + `/appointment-details/${res.data.token}`;
  }

  function updateAppointmentHookData(
    params: {
      appointment: Appointment;
      job: Job;
    } | null
  ) {
    appointment.value = params?.appointment;
    job.value = params?.job;
  }

  return {
    generateMagicLink,
    isTechnicianOnRoute,
    jobAppointment,
    addressDisplay,
    appointment,
    invalidAppointment,
    isActiveAppointment,
    hasArrived,
    appointmentDate,
    appointmentWindow,
    canRescheduleCancel,
    status,
    updateAppointmentHookData
  };
}
