<template>
  <div>
    <Page title="All Invoices" max-width="850">
      <UnpaidInvoices @pay-balance="handlePayBalance" />
      <Datatable
        ref="datatable"
        :headers="headers"
        :report-path="'/reports/consumer/invoices.json'"
        :sort-by="'id'"
        :sort-desc="true"
      >
        <template #item.view="{ item }">
          <router-link :to="`/invoice/${item.id}`">View Invoice</router-link>
        </template>
        <template v-if="paymentMethodsAvailable" #item.pay="{ item }">
          <v-btn
            v-if="item.balance !== '$0.00'"
            color="primary"
            @click="handlePayBalance(item.id)"
          >
            Pay Balance Due
          </v-btn>
        </template>
      </Datatable>
    </Page>
    <InvoicePaymentModal
      v-model="showPaymentModal"
      :customer="user"
      :invoice-id="selectedInvoiceId"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Datatable from "@/components/Datatable.vue";
import InvoicePaymentModal from "@/components/InvoicePaymentModal.vue";
import Page from "@/components/Page.vue";
import UnpaidInvoices from "@/components/UnpaidInvoices.vue";
import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";

export default {
  components: {
    UnpaidInvoices,
    Page,
    InvoicePaymentModal,
    Datatable
  },
  setup() {
    const { paymentMethodsAvailable, definePaymentCallbacks } =
      usePaymentMethods();
    return {
      definePaymentCallbacks,
      paymentMethodsAvailable
    };
  },
  data() {
    return {
      headers: [
        { text: "Date", value: "date" },
        { text: "Invoice #", value: "id" },
        { text: "Job #", value: "job_id" },
        { text: "Total", value: "total", align: "end" },
        { text: "Balance Due", value: "balance", align: "end" },
        { text: "View", value: "view", sortable: false },
        { text: "", value: "pay", sortable: false }
      ],
      selectedInvoiceId: null,
      showPaymentModal: false
    };
  },
  computed: {
    ...mapState({ user: "user" })
  },
  mounted() {
    this.definePaymentCallbacks({
      onPaymentSuccess: this.refreshData
    });
  },
  methods: {
    refreshData() {
      this.$refs.datatable.fetchData();
    },
    handlePayBalance(invoiceId) {
      this.selectedInvoiceId = invoiceId;
      this.showPaymentModal = true;
    }
  }
};
</script>

<style lang="scss"></style>
