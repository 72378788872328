<template>
  <div class="radio-group" :class="{ condensed, horizontal }">
    <div
      v-for="option in options"
      :key="option.value"
      class="group d-flex align-items-center"
      :class="{ active: option.value === selectedValue }"
    >
      <label class="d-flex align-items-center full-width mb-0 cursor-pointer">
        <input
          class="group-radio"
          type="radio"
          :checked="option.value === selectedValue"
          @change="handleChange(option)"
        />
        <div
          class="d-flex justify-content-between align-items-center group-content full-width"
        >
          <div
            v-if="option.label || option.subtitle"
            class="d-flex flex-column justify-content-center"
          >
            <span v-if="option.label" class="group-title">{{
              option.label
            }}</span>
            <span v-if="option.subtitle" class="group-subtitle">{{
              option.subtitle
            }}</span>
          </div>
          <slot :name="`option-${option.value}-content`" />
        </div>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "RadioGroup",
  props: {
    condensed: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array as () => {
        value: number | string;
        label: string;
        subtitle?: string;
      }[],
      default: () => []
    },
    value: {
      type: [Number, String],
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    validationErrors: {
      type: Object,
      default() {
        return {
          first: () => {
            // Do nothing
          }
        };
      }
    }
  },
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    handleChange(option) {
      this.selectedValue = option.value;
    }
  }
};
</script>

<style lang="scss">
.radio-group {
  &.horizontal {
    gap: 15px;
    display: flex;
  }
  &.condensed {
    .group {
      margin-bottom: 0.8em !important;
      box-shadow: none;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #c9cdd0;
    }
    .group .group-radio {
      margin-right: 10px;
    }
    .group-title {
      color: $gray !important;
      font-size: 1em !important;
    }
    .group-subtitle {
      display: none;
    }
  }
  .group {
    box-shadow: $box-shadow;
    border-radius: 6px;
    background-color: $white;
    margin-bottom: 0.5em;
    padding: 1em 1.2em;
    transition: background-color 250ms ease;
    &.active {
      background-color: #e2eeff;
    }
    .group-radio {
      margin-right: 1.2em;
    }
    .group-title {
      color: $primary-color;
      font-size: 1.2em;
    }
    .group-subtitle {
      color: $gray;
      font-size: 1em;
    }
  }
}
</style>
