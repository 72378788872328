<template>
  <div>
    <v-dialog
      v-model="modelValue"
      :transition="
        $vuetify.breakpoint.smAndDown
          ? 'dialog-bottom-transition'
          : 'dialog-transition'
      "
      max-width="350"
    >
      <v-card>
        <div class="modal-content text-center py-5 px-5">
          <div class="mb-5">
            <strong>Saving {{ paymentType }} for Future Payments</strong>
          </div>
          <div class="text-small mb-5">
            The payment method you are saving will be your default payment
            method and allows you to make payments to unpaid balances and
            recurrent payments for memberships.
            <br />
            <br />
            <strong
              >This will overwrite any previously saved payment method.</strong
            >
          </div>
          <v-btn
            color="warning"
            rounded
            outlined
            class="mr-14"
            @click="handleCancel"
            >Cancel</v-btn
          >
          <v-btn rounded color="primary" @click="modelValue = false"
            >Confirm</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  paymentType: {
    type: String,
    default: () => "Payment Method"
  },
  value: {
    type: Boolean,
    default: () => false
  }
});

const emit = defineEmits(["cancel", "input"]);

const modelValue = computed({
  get() {
    return props.value;
  },
  set(val) {
    emit("input", val);
  }
});

function handleCancel() {
  modelValue.value = false;
  emit("cancel");
}
</script>
