import { createLocalStorage } from "serviceshift-ui/shared/src/lib/localStorage";
import { AuthStrategy } from "serviceshift-ui/shared/src/typings/auth";
import { AuthRole } from "serviceshift-ui/shared/src/typings/auth";
import { Customer } from "serviceshift-ui/shared/src/typings/customer";

export const AuthLocalStorage = createLocalStorage<{
  token: string;
  email: string;
  role: AuthRole;
  auth_strategy: AuthStrategy;
}>("auth", ["token", "email", "role", "auth_strategy"]);

export const isLoggedInWithRole = (role: AuthRole) => {
  if (!AuthLocalStorage.token) return false;
  // If you are logged in as a customer, you can see any page
  if (AuthLocalStorage.role === "customer") return true;
  return AuthLocalStorage.role === role;
};

export const setAuth = (user: Customer) => {
  if (!user.auth_strategy) user.auth_strategy = AuthStrategy.password;
  if (!user.role) user.role = AuthRole.customer;
  AuthLocalStorage.setItems({
    ...user,
    token: user.authentication_token
  });
  return AuthLocalStorage;
};
