<template>
  <div class="archived-label-cont">
    <div :class="{ 'archived-label': !disabled }">
      <slot />
    </div>
    <div v-if="!disabled" class="tw-ml-1 archived-label-archived">
      (Archived)
    </div>
  </div>
</template>

<script>
export default {
  name: "ArchivedLabel",
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.archived-label-cont {
  display: flex;
  align-items: center;
  .archived-label {
    text-decoration: line-through;
    font-style: italic;
  }
  .archived-label-archived {
    color: var(--rufous);
    font-style: italic;
    font-weight: bold;
  }
}
</style>
