<template>
  <div
    v-if="invoice"
    :class="['table-cont', className, { 'tech-app': techMode }]"
  >
    <InvoiceHeader v-bind="$props" />
    <div class="invoice-body">
      <LineItemTable
        :data="invoice"
        type="invoice"
        :equipment-types-enabled="equipmentTypesEnabled"
      />
      <Totals :data="invoice" type="invoice" :tech-mode="techMode" />
      <hr />
      <Signatures
        v-if="invoice.signature || (invoice.job_id && !invoice.voided)"
        :data="invoice.signature"
        :event-id="invoice.invoice_id"
        placeholder="Work Completed"
        approval-text="Work Completed"
        type="invoice"
        :tech-mode="techMode"
      />
      <div v-else-if="invoice.finalized" class="tw-text-right tw-mt-6">
        <strong>Invoice Date:</strong>
        {{ formatDate(invoice.invoice_date, { timezone: tenantTimezone }) }}
      </div>
      <TransactionDetails
        :transactions="invoice.payments"
        :tech-mode="techMode"
      />
      <InvoiceNotes :notes="invoice.notes" />
      <InvoiceFooter :customizations="customizations" />
    </div>
  </div>
</template>

<script>
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import InvoiceFooter from "serviceshift-ui/components/QuoteInvoice/InvoiceFooter.vue";
import InvoiceHeader from "serviceshift-ui/components/QuoteInvoice/InvoiceHeader.vue";
import InvoiceNotes from "serviceshift-ui/components/QuoteInvoice/InvoiceNotes.vue";
import LineItemTable from "serviceshift-ui/components/QuoteInvoice/LineItemTable.vue";
import Signatures from "serviceshift-ui/components/QuoteInvoice/Signatures.vue";
import Totals from "serviceshift-ui/components/QuoteInvoice/Totals.vue";
import TransactionDetails from "serviceshift-ui/components/QuoteInvoice/TransactionDetails.vue";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";
import { formatDate } from "serviceshift-ui/shared/src/mixins";

export default {
  name: "Invoice",
  components: {
    LineItemTable,
    Totals,
    Signatures,
    InvoiceHeader,
    TransactionDetails,
    InvoiceNotes,
    InvoiceFooter
  },
  props: {
    className: {
      type: String,
      default: ""
    },
    /** @type { QuoteInvoiceTypes.Invoice } */
    invoice: {
      type: Object,
      default: null
    },
    /** @type { QuoteInvoiceTypes.Customizations } */
    customizations: {
      type: Object,
      default: null
    },
    techMode: {
      type: Boolean,
      default: false
    },
    equipmentTypesEnabled: {
      type: Boolean,
      default: false
    },
    tenantTimezone: {
      type: String,
      default: ""
    }
  },
  mounted() {
    EventBus.$on("signature-click", this.signatureClick);
    EventBus.$on("discount-click", this.discountClick);
    EventBus.$on("payments-click", this.paymentsClick);
    EventBus.$on("savings-click", this.savingsClick);
    EventBus.$on("edit-transaction-note-click", this.editNotesClick);
    EventBus.$on("equipment-request-click", this.equipmentRequestClick);
  },
  beforeDestroy() {
    EventBus.$off("signature-click", this.signatureClick);
    EventBus.$off("discount-click", this.discountClick);
    EventBus.$off("payments-click", this.paymentsClick);
    EventBus.$off("savings-click", this.savingsClick);
    EventBus.$off("edit-transaction-note-click", this.editNotesClick);
    EventBus.$off("equipment-request-click", this.equipmentRequestClick);
  },
  methods: {
    formatDate,
    signatureClick(eventId) {
      if (eventId === this.invoice.invoice_id) {
        this.$emit("signature-click");
      }
    },
    discountClick(eventId) {
      if (eventId === this.invoice.invoice_id) {
        this.$emit("discount-click");
      }
    },
    paymentsClick(eventId) {
      if (eventId === this.invoice.invoice_id) {
        this.$emit("payments-click");
      }
    },
    savingsClick(eventId) {
      if (eventId === this.invoice.invoice_id) {
        this.$emit("savings-click");
      }
    },
    editNotesClick(transaction, eventId) {
      if (eventId === this.invoice.invoice_id) {
        this.$emit("edit-transaction-note-click", transaction);
      }
    },
    equipmentRequestClick(item, eventId) {
      if (eventId === this.invoice.invoice_id) {
        this.$emit("equipment-request-click", item, eventId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
hr {
  border-color: $primary-light-accent-color !important;
  margin: 0 !important;
  border: none !important;
  height: 1px !important;
  background-color: $primary-light-accent-color !important;
}
</style>
