import axios from "axios";

import ENV from "../env";

export default {
  get: (deptId, id) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/vendors/${id}`, {
      method: "GET"
    });
  },
  update: (deptId, id, data) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/vendors/${id}`, {
      method: "PUT",
      data
    });
  },
  delete: (deptId, id) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/vendors/${id}`, {
      method: "DELETE"
    });
  },
  getAll: (deptId) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/vendors`, {
      method: "GET"
    });
  },
  create: (deptId, data) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/vendors`, {
      method: "POST",
      data
    });
  },
  uploadPrices: (deptId, id, data) => {
    return axios(
      `${ENV.v2Path}/departments/${deptId}/vendors/${id}/prices_upload`,
      {
        method: "POST",
        data: data.body
      }
    );
  },
  downloadPrices: (deptId, id) => {
    return axios(
      `${ENV.v2Path}/departments/${deptId}/vendors/${id}/prices_download`,
      {
        method: "POST"
      }
    );
  }
};
