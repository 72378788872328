/* eslint-disable no-underscore-dangle */
import {
  addDays,
  getNextMonth,
  isDateGreaterThan,
  monthsDifference,
  parseDate,
  prettyFormat
} from "../lib/date";

const today = new Date();
export default {
  data() {
    return {
      maxMonthAttempts: 3 // How many months in advance to search
    };
  },
  methods: {
    autoSuggest(date, category, zipCode) {
      // Get first day of next month for next attempt
      let nextDate = getNextMonth(date);

      return new Promise(async (resolve) => {
        // Fetch next available dates
        const availableDate = await this.fetchAvailableDates(
          date,
          category,
          zipCode
        ).then(() =>
          this.getFirstAvailableDate(this.$store.state.availableDates, date)
        );
        if (availableDate) {
          // Get first time slot for availableDate
          // const availableTimeSlot = this.getFirstAvailableTimeSlot(availableDate);
          const availableTimeSlot = await this.fetchTimeSlots(
            availableDate,
            category,
            zipCode
          ).then(() =>
            this.getFirstAvailableTimeSlot(availableDate, this.allTimeSlots)
          );
          if (availableTimeSlot) {
            resolve(availableTimeSlot);
            return;
          }
          // If no time slot for date, try next date
          // Get next available day from state or next month start date
          // nextDate = this.getNextAvailableDate(date) || addDays(date, 1);
          nextDate = addDays(date, 1);
        }
        // Call autoSuggest recursively until a match is found
        // Only look ahead two months
        if (monthsDifference(today, nextDate) < this.maxMonthAttempts) {
          resolve(this.autoSuggest(nextDate, category, zipCode));
          return;
        }
        // Return null if past max attempts
        // no available time slots
        resolve(null);
      });
    },
    getFirstAvailableDate(availableDates, lastCheckedDate) {
      let nextAvailableDate = null;
      // Check each day in the month
      Object.keys(availableDates).some((date) => {
        // If date is not available or in the past, skip to next date
        const parsedDate = parseDate(date);
        const validDate = isDateGreaterThan(parsedDate, lastCheckedDate);
        if (!availableDates[date] || !validDate) {
          return false;
        }
        nextAvailableDate = parsedDate;
        return true;
      });
      return nextAvailableDate;
    },
    getFirstAvailableTimeSlot(date, slots) {
      // Find the first non-disabled slot
      const firstAvailableSlot = slots.find((slot) => !slot.disabled);
      // Found an available slot
      if (firstAvailableSlot) {
        // Parse slot day name
        const dayName = prettyFormat(date);
        return {
          ...firstAvailableSlot,
          day: dayName,
          date
        };
      }
      return null;
    },
    fetchAvailableDates(date, category, zipCode) {
      return this.$store.dispatch("fetchAvailableDates", {
        date,
        category,
        zipCode
      });
    },
    fetchTimeSlots(date, category, zipCode) {
      return this.$store.dispatch("fetchTimeSlots", {
        date,
        category,
        zipCode
      });
    }
  },
  computed: {
    allTimeSlots() {
      return this.$store.state.timeSlots;
    }
  }
};
