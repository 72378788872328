<template>
  <div v-if="equipmentLink">
    <div class="equipment-request-header tw-px-3 tw-py-1">
      {{ equipmentLink.optional ? "Optional" : "Requested" }} Equipment
      Information for
      <b>{{ equipmentLink.equipment_type.name }}</b>
    </div>
    <div
      v-if="equipmentLink.status !== 'complete' || equipmentLink.not_applicable"
      class="divide-y bg-light tw-px-3 form-cont"
    >
      <div class="tw-flex tw-items-center tw-justify-between tw-py-4">
        <b>Does the requested equipment type apply to the sale?</b>
        <Checkbox v-model="not_applicable" label="Not Applicable" />
      </div>
      <div v-if="!not_applicable" class="tw-py-4">
        <Select
          v-model="equipment_id"
          full-width
          label="Use Existing Equipment"
          :options="equipmentOptions"
          :placeholder="
            equipmentOptions.length ? 'Select...' : 'No available equipment'
          "
        >
          <template #item-row="{ option }">
            <div class="option-row">
              <span :class="{ 'tw-line-through': option.archived }">{{
                option.label
              }}</span>
              <span v-if="option.archived" class="tw-italic tw-ml-1"
                >(Archived)</span
              >
            </div>
          </template>
        </Select>
      </div>
      <div
        v-if="!not_applicable"
        class="tw-flex tw-items-center tw-justify-between tw-py-4"
      >
        <b>Are you Replacing Equipment or Components?</b>
        <SeraButton text="Add/Replace" @click="$emit('add-replace-click')" />
      </div>
    </div>
    <div v-else-if="equipmentLink.equipment" class="bg-light tw-p-3">
      <EquipmentRequestPresentation
        :equipment="equipmentLink.equipment"
        @clear-selection="$emit('clear-selection', equipmentLink)"
      >
        <template #equipmentCard>
          <slot name="equipmentCard" />
        </template>
      </EquipmentRequestPresentation>
    </div>
  </div>
</template>

<script>
import SeraButton from "serviceshift-ui/components/Inputs/Button.vue";
import Checkbox from "serviceshift-ui/components/Inputs/Checkbox.vue";
import Select from "serviceshift-ui/components/Inputs/Select.vue";
import * as EquipmentTypes from "serviceshift-ui/shared/src/classes/equipment";
import * as QuoteInvoiceTypes from "serviceshift-ui/shared/src/classes/quoteInvoice";

import EquipmentRequestPresentation from "./EquipmentRequestPresentation.vue";

export default {
  name: "EquipmentRequestForm",
  components: {
    Select,
    Checkbox,
    SeraButton,
    EquipmentRequestPresentation
  },
  props: {
    /** @type {EquipmentTypes.Equipment[]} */
    equipment: {
      type: Array,
      default: () => []
    },
    /** @type {QuoteInvoiceTypes.EquipmentLink} */
    equipmentLink: {
      type: Object,
      default: null,
      required: true
    },
    showArchivedEquipment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      not_applicable: this.equipmentLink.not_applicable,
      equipment_id: this.equipmentLink.equipment?.id
    };
  },
  computed: {
    equipmentOptions() {
      const filteredEquipment = this.equipment.filter((equipment) => {
        if (!this.showArchivedEquipment) return !equipment.archived;
        return true;
      });
      return filteredEquipment.map((eq) => ({
        label: eq.name,
        value: eq.id,
        archived: eq.archived
      }));
    }
  },
  watch: {
    equipmentLink(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setFormData();
      }
    },
    not_applicable(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.emitUpdate({
          not_applicable: newValue,
          equipment_id: null
        });
      }
    },
    equipment_id(newValue, oldValue) {
      if (
        newValue !== oldValue &&
        newValue &&
        newValue !== this.equipmentLink.equipment?.id
      ) {
        this.emitUpdate({
          not_applicable: false,
          equipment_id: newValue
        });
      }
    }
  },
  methods: {
    emitUpdate(data) {
      this.$emit("update", {
        ...this.equipmentLink,
        ...data
      });
    },
    setFormData() {
      this.not_applicable = this.equipmentLink.not_applicable;
      this.equipment_id = this.equipmentLink.equipment?.id;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .field-label {
  font-weight: bold;
}
.equipment-request-header {
  background: $tertiary-color;
  color: #fff;
  border-radius: 4px 4px 0 0;
}
.form-cont {
  border-radius: 0 0 4px 4px;
}
</style>
