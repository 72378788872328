<template>
  <div
    v-if="quote"
    class="quote-body"
    :class="[className, { 'tech-app': techMode }]"
  >
    <QuoteHeader
      v-if="!editing"
      :quote="quote"
      :customizations="customizations"
    />
    <QuoteFields v-else :quote="quote" :customer-addresses="customerAddresses">
      <template
        v-if="$scopedSlots.serviceAddressField"
        #serviceAddressField="addressFieldProps"
      >
        <slot name="serviceAddressField" v-bind="addressFieldProps" />
      </template>
    </QuoteFields>
    <MembershipSwitcher v-if="showMembershipSwitcher" :quote="quote">
      <template #swapIcon>
        <slot name="swapIcon" />
      </template>
      <template #spinner>
        <slot name="spinner" />
      </template>
    </MembershipSwitcher>
    <LineItemTable
      :data="quote"
      :grouping-enabled="groupingEnabled"
      :edit-taxes-enabled="editTaxesEnabled"
      :editing="editing"
      :tech-mode="techMode"
    >
      <template #deleteIcon>
        <slot name="deleteIcon" />
      </template>
      <template #actionsIcon>
        <slot name="actionsIcon" />
      </template>
    </LineItemTable>
    <Totals
      :data="quote"
      :editing="editing"
      :late-fees-enabled="lateFeesEnabled"
      :edit-taxes-enabled="editTaxesEnabled"
      :tech-mode="techMode"
      type="quote"
    >
      <template #deleteIcon>
        <slot name="deleteIcon" />
      </template>
    </Totals>
    <slot v-if="!editing" name="financingOptions" />
    <hr v-if="!editing" />
    <Signatures
      v-if="showSignature"
      class="tw-mt-2"
      :data="quote.signature"
      :event-id="quote.id"
      :tech-mode="techMode"
      placeholder="Sign Quote Here"
      approval-text="Quote Approved"
      type="quote"
    />
  </div>
</template>

<script>
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import LineItemTable from "serviceshift-ui/components/QuoteInvoice/LineItemTable.vue";
import MembershipSwitcher from "serviceshift-ui/components/QuoteInvoice/MembershipSwitcher.vue";
import QuoteFields from "serviceshift-ui/components/QuoteInvoice/QuoteFields.vue";
import QuoteHeader from "serviceshift-ui/components/QuoteInvoice/QuoteHeader.vue";
import Signatures from "serviceshift-ui/components/QuoteInvoice/Signatures.vue";
import Totals from "serviceshift-ui/components/QuoteInvoice/Totals.vue";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  name: "Quote",
  components: {
    LineItemTable,
    Totals,
    Signatures,
    QuoteFields,
    MembershipSwitcher,
    QuoteHeader
  },
  props: {
    groupingEnabled: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: ""
    },
    /** @type { QuiteInvoiceTypes.Quote } */
    quote: {
      type: Object,
      default: null
    },
    editing: {
      type: Boolean,
      default: false
    },
    lateFeesEnabled: {
      type: Boolean,
      default: false
    },
    techMode: {
      type: Boolean,
      default: false
    },
    customerAddresses: {
      type: Array,
      default: () => []
    },
    customizations: {
      type: Object,
      default: null
    },
    editTaxesEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showMembershipSwitcher() {
      return this.quote.quote_type === "two_column" && this.editing;
    },
    showSignature() {
      return this.quote.status !== "denied" && !this.editing;
    },
    quoteId() {
      return this.quote ? this.quote.id : null;
    }
  },
  mounted() {
    EventBus.$on("membership-click", this.membershipClick);
    EventBus.$on("swap-click", this.swapClick);
    EventBus.$on("add-item", this.addItem);
    EventBus.$on("remove-item", this.removeItem);
    EventBus.$on("edit-taxable", this.editTaxable);
    EventBus.$on("edit-unit-price", this.editUnitPrice);
    EventBus.$on("edit-quantity", this.editQuantity);
    EventBus.$on("edit-warranty", this.editWarranty);
    EventBus.$on("edit-description", this.editDescription);
    EventBus.$on("edit-unit-name", this.editUnitName);
    EventBus.$on("edit-covered-systems", this.editCoveredSystems);
    EventBus.$on("discount-click", this.discountClick);
    EventBus.$on("remove-discount", this.removeDiscount);
    EventBus.$on("taxes-click", this.taxesClick);
    EventBus.$on("payments-click", this.paymentsClick);
    EventBus.$on("savings-click", this.savingsClick);
    EventBus.$on("remove-late-fee", this.removeLateFee);
    EventBus.$on("edit-late-fee", this.editLateFee);
    EventBus.$on("signature-click", this.signatureClick);
    EventBus.$on("edit-quote-name", this.editName);
    EventBus.$on("edit-quote-summary", this.editSummary);
    EventBus.$on("line-items-sort", this.sortLineItems);
    EventBus.$on("add-address-click", this.addAddressClick);
    EventBus.$on("edit-quote-address", this.editAddress);
    EventBus.$on("replace-line-item", this.replaceLineItem);
    EventBus.$on("action", this.handleAction);
    EventBus.$on("ungroup-item", this.handleUngroupItem);
  },
  beforeDestroy() {
    EventBus.$off("membership-click", this.membershipClick);
    EventBus.$off("swap-click", this.swapClick);
    EventBus.$off("add-item", this.addItem);
    EventBus.$off("remove-item", this.removeItem);
    EventBus.$off("edit-taxable", this.editTaxable);
    EventBus.$off("edit-unit-price", this.editUnitPrice);
    EventBus.$off("edit-quantity", this.editQuantity);
    EventBus.$off("edit-warranty", this.editWarranty);
    EventBus.$off("edit-description", this.editDescription);
    EventBus.$off("edit-unit-name", this.editUnitName);
    EventBus.$off("edit-covered-systems", this.editCoveredSystems);
    EventBus.$off("discount-click", this.discountClick);
    EventBus.$off("remove-discount", this.removeDiscount);
    EventBus.$off("taxes-click", this.taxesClick);
    EventBus.$off("payments-click", this.paymentsClick);
    EventBus.$off("savings-click", this.savingsClick);
    EventBus.$off("remove-late-fee", this.removeLateFee);
    EventBus.$off("edit-late-fee", this.editLateFee);
    EventBus.$off("signature-click", this.signatureClick);
    EventBus.$off("edit-quote-name", this.editName);
    EventBus.$off("edit-quote-summary", this.editSummary);
    EventBus.$off("line-items-sort", this.sortLineItems);
    EventBus.$off("add-address-click", this.addAddressClick);
    EventBus.$off("edit-quote-address", this.editAddress);
    EventBus.$off("replace-line-item", this.replaceLineItem);
    EventBus.$off("action", this.handleAction);
    EventBus.$off("ungroup-item", this.handleUngroupItem);
  },
  methods: {
    handleUngroupItem(item, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("ungroup-item", item);
      }
    },
    handleAction(actionAndItem, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("action", actionAndItem);
      }
    },
    addAddressClick(eventId) {
      if (eventId === this.quoteId) {
        this.$emit("add-address-click");
      }
    },
    addItem(eventId) {
      if (eventId === this.quoteId) {
        this.$emit("add-item");
      }
    },
    removeItem(e, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("remove-item", e, eventId);
      }
    },
    editAddress(e, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-quote-address", e, eventId);
      }
    },
    editTaxable(e, item, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-taxable", e, item);
      }
    },
    editUnitPrice(e, item, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-unit-price", e, item);
      }
    },
    editQuantity(e, item, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-quantity", e, item);
      }
    },
    editWarranty(e, item, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-warranty", e, item);
      }
    },
    editDescription(e, item, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-description", e, item);
      }
    },
    editName(e, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-quote-name", e, this.quoteId);
      }
    },
    editSummary(e, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-quote-summary", e, this.quoteId);
      }
    },
    editUnitName(e, item, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-unit-name", e, item);
      }
    },
    editCoveredSystems(e, item, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-covered-systems", e, item);
      }
    },
    discountClick(eventId) {
      if (eventId === this.quoteId) {
        this.$emit("discount-click");
      }
    },
    removeDiscount(e, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("remove-discount", e);
      }
    },
    taxesClick(eventId) {
      if (eventId === this.quoteId) {
        this.$emit("taxes-click");
      }
    },
    paymentsClick(eventId) {
      if (eventId === this.quoteId) {
        this.$emit("payments-click");
      }
    },
    savingsClick(eventId) {
      if (eventId === this.quoteId) {
        this.$emit("savings-click");
      }
    },
    removeLateFee(eventId) {
      if (eventId === this.quoteId) {
        this.$emit("remove-late-fee");
      }
    },
    editLateFee(e, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("edit-late-fee", e);
      }
    },
    membershipClick(id, type, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("membership-click", id, type);
      }
    },
    swapClick(e, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("swap-click", e);
      }
    },
    signatureClick(id) {
      if (id === this.quoteId) {
        this.$emit("signature-click");
      }
    },
    sortLineItems(items, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("line-items-sort", items, this.quoteId);
      }
    },
    replaceLineItem(item, eventId) {
      if (eventId === this.quoteId) {
        this.$emit("replace-line-item", item, this.quoteId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
hr {
  border-color: $primary-light-accent-color !important;
  margin: 0 !important;
  border: none !important;
  height: 1px !important;
  background-color: $primary-light-accent-color !important;
}
:deep(.price-input) {
  text-align: center;
  border: 1px solid $primary-accent-color;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
  min-height: 30px;
  font-family: inherit;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    opacity: 0;
    pointer-events: none;
    -webkit-appearance: none;
    margin: 0;
  }
}
:deep(.remove-icon) {
  color: $primary-color;
  font-size: 1.3em !important;
  cursor: pointer;
}
</style>
