<template>
  <div v-if="nonTasks.length" class="category-cards-cont">
    <div :class="['category-cards', { 'full-view': showMore }]">
      <CategoryCard
        v-for="category in nonTasks"
        :key="category.id"
        :category="category"
      />
    </div>
    <div class="view-more-cont">
      <a
        v-if="hasOverflow"
        :class="['view-more-categories-link', { 'tech-link': techApp }]"
        @click="toggleMore"
      >
        {{ `View ${showMore ? "Less" : "More"} Categories...` }}
      </a>
    </div>
  </div>
</template>

<script>
import CategoryCard from "serviceshift-ui/components/TaskPicker/CategoryCard.vue";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";
import { nextTick } from "vue";

export default {
  components: {
    CategoryCard
  },
  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showMore: false,
      techApp: false
    };
  },
  computed: {
    nonTasks() {
      return this.categories.filter((node) => node.kind !== "task");
    },
    hasOverflow() {
      return this.nonTasks.length > 10;
    }
  },
  mounted() {
    EventBus.$on("get-tech-app-response", this.setTechApp);
    EventBus.$emit("get-tech-app");
  },
  beforeDestroy() {
    EventBus.$off("get-tech-app-response", this.setTechApp);
  },
  methods: {
    setTechApp(isTechApp) {
      this.techApp = isTechApp;
    },
    toggleMore() {
      this.showMore = !this.showMore;
      nextTick(() => {
        EventBus.$emit("header-toggle");
        setTimeout(() => EventBus.$emit("header-toggle"), 300);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.category-cards-cont {
  padding: 10px;
  background-color: #fff;
}
.category-cards {
  display: flex;
  flex-wrap: wrap;
  max-height: 125px;
  overflow: hidden;
  transition: max-height 250ms ease-out;
  &.full-view {
    max-height: 100vh;
  }
}
a {
  margin-top: 10px;
  display: inline-block;
  font-size: 0.8em;
}
.view-more-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
