<template>
  <div class="task-details" @click="stopBubble">
    <!-- warranty_program -->
    <div v-if="task.kind === 'warranty_program'">
      <div v-if="task.description" class="description">
        <div class="tw-mr-3 flex-1">{{ task.description }}</div>
        <div class="flex-1">
          {{ task.warranty }}
        </div>
      </div>
      <div class="member-submit-cont">
        <div class="add-task-button" @click="handleClick">
          {{ adding ? "Adding Program..." : "Add Warranty Program" }}
        </div>
      </div>
    </div>

    <!-- membership_program -->
    <div v-else-if="task.kind === 'membership_program'">
      <div v-if="task.description" class="description">
        <div class="tw-mr-3 flex-1">{{ task.description }}</div>
        <div class="flex-1">
          {{ coveredSystemsDescription }}
        </div>
      </div>
      <div class="member-submit-cont">
        <div class="add-task-button" @click="handleClick">
          {{ adding ? "Adding Program..." : "Add Membership Program" }}
        </div>
      </div>
    </div>

    <!-- Everything else -->
    <div v-else>
      <div class="warranty">
        <span class="font-bold">Warranty:&nbsp;</span>
        <span>{{ task.warranty || "Not provided" }}</span>
      </div>
      <div v-if="task.description" class="description">
        <div>{{ task.description }}</div>
      </div>
      <div class="upgrades-addons-cont">
        <Addons
          v-if="showAddons"
          title="Add-ons"
          :tasks="task.addons"
          :selected="addons"
          @update-addons="updateAddons"
        />
        <div class="add-task-button" @click="handleClick">
          {{ submitText }}
        </div>
      </div>
      <div class="links">
        <LinkCollection
          v-if="showUpgrades"
          title="Upgrades"
          :links="upgradeLinks"
          empty-text="No recommendations available"
          class="collection"
        />
        <RecentlyViewed
          class="recently-viewed collection"
          :current-task="task"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Addons from "serviceshift-ui/components/TaskPicker/Addons.vue";
import LinkCollection from "serviceshift-ui/components/TaskPicker/LinkCollection.vue";
import RecentlyViewed from "serviceshift-ui/components/TaskPicker/RecentlyViewed.vue";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  components: {
    Addons,
    LinkCollection,
    RecentlyViewed
  },
  props: {
    showAddons: {
      type: Boolean,
      default: true
    },
    showUpgrades: {
      type: Boolean,
      default: true
    },
    task: {
      type: Object,
      default: null
    },
    replaceMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addons: [],
      adding: false
    };
  },
  computed: {
    submitText() {
      if (this.adding) {
        return this.replaceMode ? "Replacing..." : "Adding...";
      }
      const action = this.replaceMode ? "Replace" : "Add";
      let text = `${action} Item`;
      if (this.addons.length) {
        const plural = this.addons.length > 1;
        text += ` + ${this.addons.length} Add-on${plural ? "s" : ""}`;
      }
      return text;
    },
    upgradeLinks() {
      if (!this.task) {
        return [];
      }
      return this.task.upgrades.map((upgrade) => ({
        label: upgrade.name,
        eventName: "upgrade-click",
        data: upgrade
      }));
    },
    coveredSystemsDescription() {
      const membershipPrices = this.task.prices.find(
        (membership) => membership.membership_program_id === this.task.id
      );
      if (membershipPrices) {
        const price = `$${(
          membershipPrices.additional_unit_price_cents / 100
        ).toFixed(2)}`;
        return `Includes 1 system\n + ${price} for each additional system`;
      }
      return "";
    }
  },
  methods: {
    handleClick() {
      if (!this.adding) {
        const allItems = [this.task].concat(this.addons);
        this.adding = true;
        const eventName = this.replaceMode ? "replace-task" : "add-task";
        EventBus.$emit(eventName, allItems, this.task, this.addons, () => {
          this.adding = false;
        });
      }
    },
    stopBubble(e) {
      e.stopPropagation();
    },
    updateAddons(e, addon) {
      if (e.target.checked) {
        this.addons.push(addon);
      } else {
        this.addons = this.addons.filter((a) => a.id !== addon.id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.task-details {
  padding: 20px;
  background-color: #fcfcfc;
  border-bottom: $border;
}
.warranty {
  margin-bottom: 0.75em;
}
.description {
  white-space: pre-line;
  display: flex;
  justify-content: space-between;
}
.warranty,
.description,
.links {
  font-size: 0.9em;
}
.upgrades-addons-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em auto;
  width: 90%;
  padding: 10px 0;
}
.upgrade-card {
  margin-right: 2em;
}
.add-task-button {
  margin-top: 1em;
  background-color: $primary-color;
  box-shadow: $box-shadow !important;
  color: #fff;
  text-align: center;
  padding: 0.25em 12px;
  border-radius: 24px;
  font-size: 0.9em;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($primary-color, 5%);
  }
}

.member-submit-cont {
  display: block;
  width: 40%;
  margin: 1.5em auto 0 auto;
}
.links {
  display: flex;
  align-items: stretch;
  border-top: 1px solid $primary-light-accent-color;
  padding-top: 20px;
  .collection {
    width: 50%;
  }
}
</style>
