<template>
  <div class="content-wrapper">
    <Table
      v-if="techLeaderboard.length"
      light-theme
      class="tech-leaderboard-table"
      :class="{ techMode }"
    >
      <thead>
        <tr>
          <th />
          <th>Technician</th>
          <th
            v-for="(metric, i) in Object.keys(metrics)"
            :key="i"
            class="pointer"
            style="padding: 5px 0 !important"
            @click="selectSortingMetric(metric)"
          >
            <div
              :class="[
                'column-header',
                { 'selected-column': isSelected(metric) }
              ]"
            >
              <span class="column-title">{{ metrics[metric] }}</span>
              <i :class="getSortingIcon(metric)" class="sorting-icon" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, i) in sortedLeaderBoard"
          :key="row.technician.id"
          :class="{ first: isNumberOne(i) }"
        >
          <td>
            <div class="ranking-text">
              <span>{{ sortingRanking(i) }}</span>
            </div>
          </td>
          <td>
            <TechnicianTableCell
              :technician="row.technician"
              :tech-mode="techMode"
              :is-first="isNumberOne(i)"
            />
          </td>
          <td>
            <ComparisonTableCell
              :base="row.base.jobs_revenue"
              :comparison="row.comparison.jobs_revenue"
              :tech-mode="techMode"
              type="currency"
            />
          </td>
          <td>
            <ComparisonTableCell
              :base="row.base.jobs_sold"
              :comparison="row.comparison.jobs_sold"
              :tech-mode="techMode"
            />
          </td>
          <td>
            <ComparisonTableCell
              :base="row.base.jobs_win_rate"
              :comparison="row.comparison.jobs_win_rate"
              :tech-mode="techMode"
              type="percentage"
            />
          </td>
          <td>
            <ComparisonTableCell
              :base="row.base.jobs_revenue_average"
              :comparison="row.comparison.jobs_revenue_average"
              :tech-mode="techMode"
              type="currency"
            />
          </td>
          <td>
            <ComparisonTableCell
              :base="row.base.memberships_sold"
              :comparison="row.comparison.memberships_sold"
              :tech-mode="techMode"
            />
          </td>
          <td>
            <ComparisonTableCell
              :base="row.base.memberships_win_rate"
              :comparison="row.comparison.memberships_win_rate"
              :tech-mode="techMode"
              type="percentage"
            />
          </td>
        </tr>
      </tbody>
    </Table>
    <img
      v-else-if="loading"
      class="skeleton"
      :src="getPng('leaderboard_skeleton')"
    />
    <div v-else class="no-results">No Data Available</div>
  </div>
</template>

<script>
import Table from "serviceshift-ui/components/General/Table.vue";
import ComparisonTableCell from "serviceshift-ui/components/TechLeaderboard/ComparisonTableCell.vue";
import TechnicianTableCell from "serviceshift-ui/components/TechLeaderboard/TechnicianTableCell.vue";
import { awsAsset } from "serviceshift-ui/shared/src/mixins/aws";

export default {
  name: "TechLeaderboard",
  components: {
    Table,
    TechnicianTableCell,
    ComparisonTableCell
  },
  props: {
    techLeaderboard: {
      type: Array,
      default: () => []
    },
    techMode: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedSort: { metric: "jobs_revenue", asc: false },
      sortedLeaderBoard: []
    };
  },
  computed: {
    metrics() {
      return this.techMode
        ? {
            jobs_revenue: "Sales",
            jobs_sold: "JS",
            jobs_win_rate: "JC %",
            jobs_revenue_average: "Avg. Ticket",
            memberships_sold: "MS",
            memberships_win_rate: "MC %"
          }
        : {
            jobs_revenue: "Revenue",
            jobs_sold: "Jobs Sold",
            jobs_win_rate: "Close Rate",
            jobs_revenue_average: "Avg. Ticket",
            memberships_sold: "Memberships",
            memberships_win_rate: "MWR"
          };
    }
  },
  watch: {
    techLeaderboard(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.sortLeaderboard();
      }
    },
    selectedSort(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.sortLeaderboard();
      }
    }
  },
  mounted() {
    this.sortLeaderboard();
  },
  methods: {
    sortingRanking(index) {
      return this.selectedSort.asc
        ? this.sortedLeaderBoard.length - index
        : index + 1;
    },
    isNumberOne(i) {
      return this.sortingRanking(i) === 1;
    },
    getSortingIcon(metric) {
      const selectedIcon = this.selectedSort.asc
        ? "fad fa-sort-alt fa-swap-opacity"
        : "fad fa-sort-alt";
      return this.isSelected(metric)
        ? selectedIcon
        : "fas fa-sort-alt standby-icon";
    },
    isSelected(metric) {
      return this.selectedSort.metric === metric;
    },
    selectSortingMetric(metric) {
      const asc = this.isSelected(metric) ? !this.selectedSort.asc : false;
      this.selectedSort = {
        metric,
        asc
      };
    },
    sortByMetric(first, second) {
      const { metric } = this.selectedSort;
      if (first.base[metric].value === second.base[metric].value) {
        return 0;
      }
      if (this.selectedSort.asc) {
        return first.base[metric].value < second.base[metric].value ? -1 : 1;
      }
      return first.base[metric].value > second.base[metric].value ? -1 : 1;
    },
    getPng(pngName) {
      return awsAsset(pngName, "leaderboard", "png");
    },
    sortLeaderboard() {
      this.sortedLeaderBoard = [...this.techLeaderboard].sort(
        this.sortByMetric
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.tech-leaderboard-table th {
  text-align: center;
  white-space: nowrap;
}

.ranking-text {
  font-size: 1.2em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  overflow-x: scroll;
  height: 100%;
  top: 39px;
  left: 0;
  width: 100%;
}

table thead tr th div.selected-column {
  color: white;
  background-color: $primary-color;
  border-radius: 2rem;
}

.column-header {
  padding: 2px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.column-title {
  padding: 0 5px;
}

.skeleton {
  margin: 0 auto;
  display: block;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.sorting-icon {
  font-weight: 1em;
}

tbody tr.first td {
  vertical-align: bottom;
}

tbody tr.first td:first-child {
  padding-bottom: 1em;
}

.standby-icon {
  color: $secondary-color;
}

.no-results {
  border: $border;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9em;
  background-color: #fff;
}
</style>
