<template>
  <footer
    v-if="customizations"
    style="background-color: #f4f5f5; padding: 24px; margin-top: 24px"
  >
    <div
      style="
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
      "
    >
      <span style="white-space: nowrap">For any questions:</span>
      <div
        style="display: flex; align-items: center; gap: 16px; margin-top: 8px"
      >
        <span style="white-space: nowrap; font-weight: bold">{{
          tenantPhone
        }}</span>
        <div
          style="
            border-right: 1px solid #c7cfd3;
            height: 15px;
            display: inline-block;
          "
        ></div>
        <a
          :href="`mailto:${customizations.Tenant.support_email}`"
          target="_blank"
          >Email Us</a
        >
      </div>
    </div>
    <hr
      style="border: none; border-bottom: 1px solid #9ca4aa; margin: 10px 0"
    />
    <p style="font-size: 12px; font-weight: 400; line-height: 20px">
      <span
        style="white-space: pre-line; word-break: break-word"
        v-html="emailLicenceInfo"
      ></span>
    </p>
    <hr
      style="border: none; border-bottom: 1px solid #9ca4aa; margin: 10px 0"
    />
    <p
      v-if="emailTermsText"
      style="font-size: 12px; font-weight: 400; line-height: 20px"
    >
      <span
        style="white-space: pre-line; word-break: break-word"
        v-html="emailTermsText"
      ></span>
    </p>
  </footer>
</template>

<script>
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";

export default {
  props: {
    /** @type { QuiteInvoiceTypes.Customizations } */
    customizations: {
      type: Object,
      default: null
    }
  },
  computed: {
    emailTermsText() {
      const termsText = this.customizations.General.email_terms_of_service;
      if (!termsText) return "";
      return termsText.value || termsText.default;
    },
    emailLicenceInfo() {
      const licenceInfo = this.customizations.General.email_license_information;
      if (!licenceInfo) return "";
      return licenceInfo.value || licenceInfo.default;
    },
    tenantPhone() {
      const { phone } = this.customizations.General;
      return phone.value || phone.default;
    }
  }
};
</script>

<style lang="scss" scoped>
.divider {
  height: 1px;
  max-height: 1px;
  width: 100%;
  background-color: $separator-gray;
  margin: 0.5rem 0;
}

.footer-info-container {
  display: flex;
  flex-direction: row;
  font-size: 13px;
  padding: 1rem;
}

.tenant-info {
  width: 25%;
  white-space: pre-line;
  border-right: 1px solid $separator-gray;
  margin-right: 1rem;
}

.email-terms {
  width: 75%;
}

.tenant-name {
  font-weight: bold;
}

.support-info {
  text-align: center;
  font-size: 13px;
}

.footer {
  margin-bottom: 3rem;
}
</style>
