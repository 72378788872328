<template>
  <Card class="equipmentCard">
    <template #header>
      <div class="tw-w-full divide-y section-spacing-10">
        <div class="section-spacing-5 tw-mb-2">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <h4 class="tw-mb-0">
                <ArchivedLabel :disabled="!archived">
                  {{ name }}
                </ArchivedLabel>
              </h4>
              <Button
                v-if="$listeners.edit"
                icon="fas fa-edit"
                class="edit-icon tw-ml-2"
                @click="handleEdit"
              />
            </div>
          </div>
          <div class="section-spacing-10">
            <div>
              <div v-if="equipmentTypeName" class="tw-flex">
                <div>
                  <strong>{{ equipmentTypeName || emptyDisplay }}</strong>
                </div>
                <div class="tw-mx-1">|</div>
                <div>
                  <strong>Oldest Component:&nbsp;</strong>
                  <span>{{ oldestComponentInstalledDisplay }}</span>
                </div>
                <div class="tw-mx-1">|</div>
                <div>
                  <strong>Last Serviced:&nbsp;</strong>
                  <span>{{ lastServicedDate }}</span>
                </div>
              </div>
              <p v-if="address">
                {{ address }}
              </p>
            </div>
            <div v-if="warranty" class="warranty">
              <div>
                <div class="warranty__title">
                  {{ warranty.warranty_program_name }}:
                </div>
                <div>
                  Provider: {{ warranty.warranty_program_provider_name }}
                </div>
                <div v-if="showWarrantyLaborRate">
                  Labor Rate: {{ currency(warranty.labor_rate_cents / 100) }}
                </div>
                <div>Term (Years): {{ warranty.term_years }}</div>
              </div>
              <div v-if="showWarrantyPurchaseDate" class="warranty__date">
                <div class="warranty__title">Purchase Date</div>
                <div>{{ formatDate(warranty.purchase_date) }}</div>
              </div>
              <div>
                <div class="warranty__title">Effective Date</div>
                <div>{{ formatDate(warranty.effective_date) }}</div>
              </div>
              <div>
                <div class="warranty__title">Expiration Date</div>
                <div>{{ formatDate(warranty.expiration_date) }}</div>
              </div>
            </div>
            <div>
              <strong>Notes:&nbsp;</strong>
              <span class="tw-whitespace-pre-line">{{
                notes || emptyDisplay
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div class="tw-w-full divide-y section-spacing-10">
        <div class="tw-flex tw-justify-between tw-items-center tw-pt-2">
          <h5 class="!tw-mb-0">Components ({{ components.length }})</h5>
          <Button
            :icon="`fa fa-solid fa-angle-${
              actualCollapsedComponents ? 'down' : 'up'
            }`"
            icon-position="right"
            class="collapse-button"
            @click="handleToggleCollapseComponents"
          />
        </div>
        <div v-if="!actualCollapsedComponents" class="tw-pt-5">
          <slot name="componentsTable">
            <ComponentTable :components="components" />
          </slot>
        </div>
        <div
          v-if="showServiceHistory"
          class="tw-flex tw-justify-between tw-items-center tw-pt-2"
        >
          <h5 class="!tw-mb-0">
            Service History ({{ serviceHistory.length }})
          </h5>
          <Button
            :icon="`fa fa-solid fa-angle-${
              actualCollapsedServiceHistory ? 'down' : 'up'
            }`"
            icon-position="right"
            class="collapse-button"
            @click="handleToggleCollapseServiceHistory"
          />
        </div>
        <div
          v-if="showServiceHistory && !actualCollapsedServiceHistory"
          class="tw-pt-5"
        >
          <slot name="serviceHistoryTable">
            <ServiceHistoryTable :service-history="serviceHistory" />
          </slot>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import sortBy from "lodash/sortBy";
import Card from "serviceshift-ui/components/Card/Card.vue";
import ComponentTable from "serviceshift-ui/components/Equipment/ComponentTable.vue";
import ServiceHistoryTable from "serviceshift-ui/components/Equipment/ServiceHistoryTable.vue";
import ArchivedLabel from "serviceshift-ui/components/General/ArchivedLabel.vue";
import Button from "serviceshift-ui/components/Inputs/Button.vue";
import ComponentType from "serviceshift-ui/shared/src/classes/componentType.js";
import { ServiceHistoryRecord } from "serviceshift-ui/shared/src/classes/serviceHistory";
import currency from "serviceshift-ui/shared/src/filters/currency.js";
import {
  emptyDisplay,
  formatDate,
  fromNow
} from "serviceshift-ui/shared/src/mixins";

export default {
  name: "EquipmentCard",
  components: {
    Button,
    Card,
    ArchivedLabel,
    ComponentTable,
    ServiceHistoryTable
  },
  props: {
    equipmentTypeName: {
      type: String,
      default: () => ""
    },
    /** @type { ServiceHistoryRecord[] } */
    serviceHistory: {
      type: Array,
      default: () => []
    },
    warranty: {
      type: Object,
      default: () => undefined
    },
    showWarrantyPurchaseDate: {
      type: Boolean,
      default: () => true
    },
    showWarrantyLaborRate: {
      type: Boolean,
      default: () => true
    },
    showServiceHistory: {
      type: Boolean,
      default: () => true
    },
    name: {
      type: String,
      default: () => ""
    },
    address: {
      type: String,
      default: () => ""
    },
    notes: {
      type: String,
      default: () => ""
    },
    archived: {
      type: Boolean,
      default: () => false
    },
    /** @type { ComponentType[] } */
    components: {
      type: Array,
      default: () => []
    },
    collapsedComponents: {
      type: Boolean,
      default: () => undefined
    },
    collapsedServiceHistory: {
      type: Boolean,
      default: () => undefined
    },
    defaultCollapsed: {
      type: Boolean,
      default: () => undefined
    }
  },
  emits: [
    "onEdit",
    "update:collapsed-components",
    "update:collapsed-service-history"
  ],
  data: function () {
    return {
      emptyDisplay,
      internalComponentsCollapsed:
        this.defaultCollapsed !== undefined ? this.defaultCollapsed : false,
      internalServiceHistoryCollapsed:
        this.defaultCollapsed !== undefined ? this.defaultCollapsed : false
    };
  },
  computed: {
    oldestComponentInstalledDisplay() {
      /** @type { ComponentType[] } */
      const components = this.components.slice(0);
      if (!this.components.length) return emptyDisplay;
      const installedComponents = components
        .map((component) => ({
          ...component,
          calculatedInstallDate:
            component.installed_on || component.manufactured_on
        }))
        .filter((component) => !!component.calculatedInstallDate);
      if (!installedComponents.length) return emptyDisplay;
      const oldestComponent = sortBy(installedComponents, (component) =>
        new Date(component.calculatedInstallDate).getTime()
      )[0];
      return fromNow(new Date(oldestComponent.calculatedInstallDate));
    },
    lastServicedDate() {
      /** @type { ServiceHistoryRecord[] } */
      const serviceHistoryRecords = this.serviceHistory.slice(0);
      if (!serviceHistoryRecords.length) return emptyDisplay;
      const latestServiceRecord = sortBy(serviceHistoryRecords, (record) =>
        new Date(record.invoice_finalized_at).getTime()
      ).reverse()[0];
      return formatDate(new Date(latestServiceRecord.invoice_finalized_at));
    },
    actualCollapsedComponents: {
      get() {
        if (this.collapsedComponents !== undefined)
          return this.collapsedComponents;
        return this.internalComponentsCollapsed;
      },
      set(val) {
        this.$emit("update:collapsed-components", val);
        this.internalComponentsCollapsed = val;
      }
    },
    actualCollapsedServiceHistory: {
      get() {
        if (this.collapsedServiceHistory !== undefined)
          return this.collapsedServiceHistory;
        return this.internalServiceHistoryCollapsed;
      },
      set(val) {
        this.$emit("update:collapsed-service-history", val);
        this.internalServiceHistoryCollapsed = val;
      }
    }
  },
  methods: {
    currency,
    formatDate,
    handleEdit() {
      this.$emit("edit");
    },
    handleToggleCollapseComponents() {
      this.actualCollapsedComponents = !this.actualCollapsedComponents;
    },
    handleToggleCollapseServiceHistory() {
      this.actualCollapsedServiceHistory = !this.actualCollapsedServiceHistory;
    }
  }
};
</script>

<style lang="scss" scoped>
.equipmentCard {
  .subtitle {
    border-bottom: 1px solid #d3d7d9;
  }
  .warranty {
    display: flex;
    justify-content: left;
    &__title {
      font-weight: bold;
      max-width: 300px;
      word-break: break-word;
    }
    > * {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
    @media only screen and (max-width: 600px) {
      display: block;
      > * {
        margin-bottom: 7.5px;
      }
    }
  }
  .edit-icon {
    font-size: 20px;
  }
  :deep(.card-header) {
    margin-bottom: 0 !important;
  }
}
.collapse-button {
  font-size: 1.5em;
}
</style>
