<template>
  <v-slide-y-transition>
    <div
      v-if="lateSeconds > 0"
      class="d-flex justify-space-between full-width px-3 py-2 timer elevation-2 mb-2 relative"
    >
      <div class="help">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon color="white" class="subtitle-1 mx-1" v-on="on"
              >mdi-help-circle</v-icon
            >
          </template>
          <span>
            If we arrive after
            {{ formatEndTime(appointment.arrival_window_end) }}, our overdue
            clock starts and discounts your bill for every minute we're late!
          </span>
        </v-tooltip>
      </div>
      <div class="d-flex full-width">
        <div class="d-flex flex-column align-center flex-1">
          <p class="title d-flex align-flex-start">
            <v-icon color="white" class="subtitle-1 mr-1" style="height: 19px"
              >mdi-clock-outline</v-icon
            >
            {{ lateTime }}
          </p>
          <p class="subtitle-1">Late</p>
        </div>
        <v-divider
          vertical
          dark
          style="height: 100%; border-color: rgba(255, 255, 255, 0.3)"
          class="mx-3"
        ></v-divider>
        <div class="d-flex flex-column align-center flex-1">
          <p class="title">{{ discountLabel }}</p>
          <p class="subtitle-1">Late Discount</p>
        </div>
      </div>
      <div></div>
    </div>
  </v-slide-y-transition>
</template>

<script>
import { formatToTimeZone } from "date-fns-timezone";
import { mapState } from "vuex";
import { getStartOfDay, startPad } from "@/lib/date";

export default {
  props: {
    appointment: {
      type: Object,
      default: () => ({})
    },
    timeZone: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      lateSeconds: 0,
      interval: null
    };
  },
  computed: {
    ...mapState({
      dollarsPerMin() {
        return this.appointment?.settings.late_fee_per_minute_cents > 0
          ? this.appointment.settings.late_fee_per_minute_cents / 100
          : 0;
      },
      maxLateFee() {
        return this.appointment?.settings.max_late_fee_cents > 0
          ? this.appointment.settings.max_late_fee_cents / 100
          : 0;
      },
      showDollars() {
        return this.dollarsPerMin > 0;
      },
      minutesLate() {
        return (this.lateSeconds / 60).toFixed(0);
      },
      lateTime() {
        const date = getStartOfDay(new Date());
        date.setSeconds(date.getSeconds() + this.lateSeconds);
        return `${startPad(date.getHours())}:${startPad(
          date.getMinutes()
        )}:${startPad(date.getSeconds())}`;
      },
      discountLabel() {
        const minsLate = this.lateSeconds / 60;
        const dollars = Math.floor(minsLate * this.dollarsPerMin);
        return `$${Math.min(this.maxLateFee, dollars).toFixed(2)}`;
      }
    })
  },
  mounted() {
    if (this.appointment) {
      this.setLateSeconds();
      this.startTimer();
    }
  },
  beforeUnmount() {
    if (this.interval) {
      // @ts-ignore
      clearInterval(this.interval);
    }
  },
  methods: {
    setLateSeconds() {
      const date =
        this.appointment?.arrival_time || this.appointment?.iso_arrival_time;
      this.lateSeconds =
        ((date ? new Date(date) : new Date()) -
          new Date(this.appointment?.arrival_window_end)) /
        1000;
    },
    formatEndTime(date) {
      return formatToTimeZone(date, "h:mm a", {
        timeZone: this.timeZone
      });
    },
    startTimer() {
      // @ts-ignore
      clearInterval(this.interval);
      // @ts-ignore
      this.interval = setInterval(() => {
        this.setLateSeconds();
      }, 1_000);
    }
  }
};
</script>

<style lang="scss" scoped>
.timer {
  background-color: var(--v-primary-base);
  color: #fff;
  border-radius: 5px;
}
.help {
  position: absolute;
  top: 0px;
  right: 0px;
}
p {
  margin: 0 !important;
  line-height: 21px !important;
}
</style>
