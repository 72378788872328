<template>
  <div class="header-content">
    <div class="d-flex full-width justify-content-between align-items-center">
      <img :src="invoiceLogo" alt="logo" class="logo-img" :style="logoStyle" />
      <div class="table-container">
        <Table>
          <thead>
            <tr>
              <th colspan="2" class="table-header-text">
                <b>Invoice</b>
                #{{ invoice.invoice_id }}
                <span v-if="invoice.job_id">
                  for
                  <b>Job</b>
                  #{{ invoice.job_id }}
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="table-left-cell">Invoice Date</td>
              <td class="table-info-cell">{{ invoiceDate }}</td>
            </tr>
            <tr>
              <td class="table-left-cell">Bill to</td>
              <td class="table-info-cell">
                <div class="customer-text">
                  {{ customerName }}
                </div>
                <div>{{ billingAddress }}</div>
              </td>
            </tr>
            <tr>
              <td class="table-left-cell">Service Address</td>
              <td class="table-info-cell">
                <div class="location-name-text">
                  {{ serviceAddressLocationName }}
                </div>
                {{ serviceAddress }}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
    <div class="invoice-details">
      <p v-if="includeDetails && invoice.summary" class="invoice-summary">
        {{ invoice.summary }}
      </p>
    </div>
    <div class="summary-text">ORDER SUMMARY</div>
  </div>
</template>

<script>
import Table from "serviceshift-ui/components/General/Table.vue";
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import { formatDate } from "serviceshift-ui/shared/src/mixins";

export default {
  components: {
    Table
  },
  props: {
    /** @type { QuoteInvoiceTypes.Invoice } */
    invoice: {
      type: Object,
      default: null
    },
    /** @type { QuoteInvoiceTypes.Customizations } */
    customizations: {
      type: Object,
      default: null
    },
    tenantTimezone: {
      type: String,
      default: ""
    }
  },
  computed: {
    invoiceLogo() {
      if (this.customizations) {
        const darkLogo = this.customizations.Assets.dark_logo_url;
        return darkLogo.value || darkLogo.default;
      }
      return "";
    },
    customerName() {
      return this.invoice && this.invoice.customer
        ? this.invoice.customer.full_name
        : "";
    },
    invoiceDate() {
      return this.invoice && this.invoice.invoice_date
        ? formatDate(this.invoice.invoice_date, {
            timezone: this.tenantTimezone
          })
        : "--";
    },
    billingAddress() {
      return this.invoice && this.invoice.billing_address
        ? this.formatAddressLines(this.invoice.billing_address)
        : "";
    },
    serviceAddress() {
      return this.invoice && this.invoice.service_address
        ? this.formatAddressLines(this.invoice.service_address)
        : "";
    },
    serviceAddressLocationName() {
      return this.invoice && this.invoice.service_address
        ? this.invoice.service_address.name
        : "";
    },
    includeDetails() {
      return this.invoice?.format === "detailed";
    },
    logoStyle() {
      if (this.customizations) {
        const logo_size_percentage =
          this.customizations.General.logo_size_percentage;
        const value =
          logo_size_percentage.value || logo_size_percentage.default;
        return `max-width:${value};`;
      }
      return "";
    }
  },
  methods: {
    formatAddressLines(address) {
      const { street, city, state } = address;
      return `${street}\n${city}, ${state} ${address.zip_code}`;
    }
  }
};
</script>

<style lang="scss" scoped>
tbody {
  border: 1px solid $primary-accent-color !important;
}

.header-content {
  display: flex;
  flex-direction: column;
}

.logo-img {
  max-height: 185px;
  flex-shrink: 0;
}

.table-left-cell {
  padding: 10px !important;
  background-color: $angel-blue;
  width: 110px;
  font-size: 14px;
  font-weight: bolder;
  border: 1px solid $medium-color !important;
  text-align: left;
  vertical-align: top;
}

.table-info-cell {
  padding: 10px !important;
  background-color: white;
  font-size: 12px;
  white-space: pre-line;
  text-align: left;
  min-width: 156px;
}

.summary-text {
  font-size: 14px;
  font-weight: bolder;
  line-height: 16px;
  margin-bottom: 15px;
}

.table-container {
  display: flex;
  align-items: flex-end;
  min-width: 400px;
  max-width: 50%;
}

.customer-text,
.location-name-text {
  font-weight: bold;
}

.table-header-text {
  text-align: left;
  font-weight: normal;
}

.invoice-details {
  margin: 15px 0px;
}
.invoice-summary {
  margin-top: 8px;
  white-space: pre-line;
}
</style>
