<template>
  <div
    class="d-flex align-center justify-center banner pa-2 caption"
    :style="`background-color: ${color}`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "AppBanner",
  props: {
    color: {
      type: String,
      default: "#e0e0e0"
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  top: -11px;
  margin-left: -15px;
  margin-right: -15px;
  border-bottom: 1px solid #e0e0e0;
}
</style>
