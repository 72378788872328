import API from "serviceshift-ui/api-client";
import pollResponse from "serviceshift-ui/shared/src/lib/pollResponse";

import { VendorLoanOffers } from "./interface";

export default {
  async fetchLoanOffersForQuote(
    _,
    params: {
      customerId: number | string;
      quoteId: number | string;
      downPaymentAmountCents?: number;
    }
  ) {
    const downPaymentCents = params.downPaymentAmountCents || 0;
    return pollResponse(async () => {
      return (
        await API.makeRequest<{
          loading: boolean;
          data: VendorLoanOffers;
        }>(
          `/v2/customers/${params.customerId}/quotes/${params.quoteId}/financing_options?down_payment_cents=${downPaymentCents}`
        )
      ).data;
    });
  },
};
