<template>
  <div class="section-spacing-20">
    <div>
      <div class="!tw-mb-4"><b>What are you replacing?</b></div>
      <div class="tw-flex tw-items-center">
        <RadioButton
          v-model="replacingType"
          label="Equipment"
          :radio-value="'equipment'"
          name="replacingType"
          class="tw-mr-8"
        />
        <RadioButton
          v-model="replacingType"
          label="Component"
          :radio-value="'components'"
          name="replacingType"
        />
      </div>
    </div>
    <Select
      v-if="replacingType"
      v-model="replacesEquipmentId"
      :label="selectLabel"
      :options="equipmentOptions"
    >
      <template #item-row="{ option }">
        <div class="option-row">
          <span :class="{ 'tw-line-through': option.archived }">{{
            option.label
          }}</span>
          <span v-if="option.archived" class="tw-italic tw-ml-1"
            >(Archived)</span
          >
        </div>
      </template>
    </Select>
    <div v-if="replacesEquipment">
      <slot name="equipmentCard">
        <EquipmentCard
          v-bind="replacesEquipment"
          :address="addressLabel"
          default-collapsed
        />
      </slot>
    </div>
    <div v-if="replacingType === 'equipment' && replacesEquipmentId">
      <Select
        v-model="newEquipmentId"
        label="Select the new equipment"
        :options="newEquipmentOptions"
        class="tw-mt-6"
      >
        <template #item-row="{ option }">
          <div class="option-row">
            <span :class="{ 'tw-line-through': option.archived }">{{
              option.label
            }}</span>
            <span v-if="option.archived" class="tw-italic tw-ml-1"
              >(Archived)</span
            >
          </div>
        </template>
      </Select>
      <div v-if="newEquipment" class="tw-mt-6">
        <slot name="equipmentCard">
          <EquipmentCard
            v-bind="newEquipment"
            :address="addressLabel"
            default-collapsed
          />
        </slot>
      </div>
      <div v-if="newEquipmentId === -1" class="tw-mt-6">
        <slot
          name="equipmentForm"
          :equipment-type-id="replacesEquipment.equipment_type_id"
        />
      </div>
    </div>
    <div v-if="replacingType === 'components' && replacesEquipment">
      <div class="tw-font-bold !tw-mb-2">
        Select the component(s) to be replaced
      </div>
      <div class="divide-y tw-bg-white">
        <div
          v-for="component in replaceableComponents"
          :key="component.id"
          class="tw-p-3"
        >
          <Checkbox
            v-model="component.replace"
            :label="component.name"
            @input="trackComponentId(component.id)"
          />
          <div v-if="component.replace" class="tw-mt-6">
            <div class="tw-font-bold !tw-mb-2">
              Replace {{ component.name }}
            </div>
            <slot name="componentForm" :component="component" />
          </div>
        </div>
      </div>
      <div v-if="!replaceableComponents.length">No Components Available</div>
    </div>
  </div>
</template>

<script>
import EquipmentCard from "serviceshift-ui/components/Equipment/EquipmentCard.vue";
import Checkbox from "serviceshift-ui/components/Inputs/Checkbox.vue";
import RadioButton from "serviceshift-ui/components/Inputs/RadioButton.vue";
import Select from "serviceshift-ui/components/Inputs/Select.vue";
import { fullAddress } from "serviceshift-ui/shared/src/mixins";
export default {
  components: {
    RadioButton,
    Select,
    EquipmentCard,
    Checkbox
  },
  props: {
    /** @type {EquipmentTypes.Equipment[]} */
    equipment: {
      type: Array,
      default: () => []
    },
    // The id for the tenants generic equipment type
    genericEquipmentTypeId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      replacingType: null,
      replacesEquipmentId: null,
      newEquipmentId: null,
      replacingIds: []
    };
  },
  computed: {
    selectLabel() {
      return this.replacingType === "components"
        ? "Which existing equipment does the component belong to?"
        : "Which existing equipment do you need to replace?";
    },
    equipmentOptions() {
      return this.equipment.map((eq) => ({
        label: eq.name,
        value: eq.id,
        archived: eq.archived,
        equipment_type_id: eq.equipment_type_id
      }));
    },
    newEquipmentOptions() {
      // Only allow replacing equipment with matching equipment type or TO generic
      // Do NOT allow replacing FROM generic to a different equipment type
      return this.equipmentOptions
        .filter(
          (option) =>
            option.value !== this.replacesEquipmentId &&
            (option.equipment_type_id === this.genericEquipmentTypeId ||
              option.equipment_type_id ===
                this.replacesEquipment.equipment_type_id)
        )
        .concat([
          {
            value: -1,
            label: "Equipment Not Found - Add New Equipment"
          }
        ]);
    },
    replacesEquipment() {
      if (!this.replacesEquipmentId) return null;
      return this.equipment.find((eq) => eq.id === this.replacesEquipmentId);
    },
    replaceableComponents() {
      if (!this.replacesEquipment) return [];
      return this.replacesEquipment.components.filter((comp) => !comp.archived);
    },
    newEquipment() {
      if (!this.newEquipmentId) return null;
      return this.equipment.find((eq) => eq.id === this.newEquipmentId);
    },
    addressLabel() {
      if (!this.replacesEquipment || !this.replacesEquipment?.address)
        return "--";
      return fullAddress(this.replacesEquipment.address);
    }
  },
  watch: {
    replacingType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.replacesEquipmentId = null;
        this.newEquipmentId = null;
        this.$emit("update:replacingType", newValue);
      }
    },
    replacesEquipmentId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("update:replacesEquipmentId", newValue);
      }
    },
    newEquipmentId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("update:newEquipmentId", newValue);
      }
    },
    replacingIds: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit("update:replacingComponentIds", newValue);
        }
      },
      deep: true
    }
  },
  methods: {
    trackComponentId(id) {
      if (this.replacingIds.includes(id)) {
        this.replacingIds = this.replacingIds.filter((compId) => compId !== id);
        return;
      }
      this.replacingIds = this.replacingIds.concat([id]);
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.field-label) {
  font-weight: bold;
}
</style>
