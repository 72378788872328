<template>
  <div class="task-header">
    <div class="header">
      <Breadcrumbs
        hide-root-text
        light
        class="task-picker-breadcrumbs"
        :crumbs="crumbs"
        @click="handleCategoryClick"
      />
      <div class="toggle-icon" @click="toggleCards">
        <ChevronIcon light :direction="showCards ? 'up' : 'down'" />
      </div>
    </div>
    <Transition>
      <CategoryCards v-if="showCards" :categories="categories" />
    </Transition>
  </div>
</template>

<script>
import Breadcrumbs from "serviceshift-ui/components/General/Breadcrumbs.vue";
import ChevronIcon from "serviceshift-ui/components/General/ChevronIcon.vue";
import Transition from "serviceshift-ui/components/General/Transition.vue";
import CategoryCards from "serviceshift-ui/components/TaskPicker/CategoryCards.vue";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";
import { nextTick } from "vue";

export default {
  components: {
    Breadcrumbs,
    CategoryCards,
    ChevronIcon,
    Transition
  },
  props: {
    crumbs: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showCards: true
    };
  },
  methods: {
    handleCategoryClick(category) {
      EventBus.$emit("category-click", category);
    },
    toggleCards() {
      this.showCards = !this.showCards;
      nextTick(() => {
        EventBus.$emit("header-toggle");
        setTimeout(() => EventBus.$emit("header-toggle"), 300);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-header {
  border: $border;
  border-radius: 5px;
  margin-bottom: 20px;
}
.header {
  display: flex;
  border-radius: 5px 5px 0 0;
  align-items: center;
  color: white;
  justify-content: space-between;
  background-color: $tertiary-color;
  padding: 2px 10px;
}
.toggle-icon {
  cursor: pointer;
}
</style>
