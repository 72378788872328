<template>
  <div>
    <v-text-field
      :maxlength="maxLength"
      v-bind="idAttrs"
      :solo="solo"
      :label="solo ? '' : label"
      :hint="hintLabel"
      :type="type"
      :value="value"
      :name="name"
      :placeholder="placeholder"
      class="text-input"
      :readonly="readonly"
      v-on="$listeners"
      @input="handleInput"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  inheritAttrs: false,
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    hintLabel: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    maxLength: {
      type: Number,
      default: () => undefined
    },
    placeholder: {
      type: String,
      default: ""
    }
  },
  computed: {
    idAttrs(): object {
      return { "data-ss-id": this.$attrs["data-ss-id"] };
    }
  },
  methods: {
    handleInput(value: string) {
      this.$emit("input", value);
    }
  }
});
</script>

<style lang="scss" scoped>
.label {
  font-size: 1.1em;
  text-align: center;
  display: block;
}
</style>
