import axios from "axios";

const requestInterceptors: number[] = [];
const responseInterceptors: number[] = [];

export const attachRequestInterceptor = (reqInterceptor) => {
  // Add auth token to request header
  const interceptor = axios.interceptors.request.use(
    (config) => reqInterceptor(config),
    (error) => Promise.reject(error)
  );
  requestInterceptors.push(interceptor);
  return interceptor;
};

export const attachResponseInterceptor = (
  errInterceptor,
  successInterceptor
) => {
  // Handle response interceptors

  // Success interceptor is optional
  let onSuccess = successInterceptor;
  if (!onSuccess) {
    onSuccess = (response) => response;
  }

  const interceptor = axios.interceptors.response.use(
    (response) => onSuccess(response),
    (error) => errInterceptor(error)
  );
  responseInterceptors.push(interceptor);
  return interceptor;
};

export const ejectRequestInterceptor = (interceptor?: any) => {
  if (interceptor) {
    return axios.interceptors.request.eject(interceptor);
  }
  requestInterceptors.forEach((interceptor) =>
    axios.interceptors.request.eject(interceptor)
  );
};

export const ejectResponseInterceptor = (interceptor?: any) => {
  if (interceptor) {
    return axios.interceptors.response.eject(interceptor);
  }
  responseInterceptors.forEach((interceptor) =>
    axios.interceptors.response.eject(interceptor)
  );
};
