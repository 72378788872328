<template>
  <div class="row-content">
    <EquipmentRequestIcon
      v-if="equipmentTypesEnabled"
      :status="item.equipment_request_status"
      class="tw-mr-2 tw-inline-flex"
      @click="handleEqRequestClick"
    />
    <div class="name-cont">
      <div v-if="techMode">
        <a v-if="editing" @click="emitReplaceItem">
          <strong>{{ itemName }}</strong>
        </a>
        <strong v-else class="name-text">{{ itemName }}</strong>
      </div>
      <div v-else class="name-container">
        <strong v-if="!editing" class="name-text">{{ itemName }}</strong>
        <input
          v-else-if="editing && open"
          class="unit-name-input"
          type="text"
          :value="item.unit_name"
          @change="handleEdit"
        />
        <a v-else @click="emitReplaceItem">
          <strong>{{ itemName }}</strong>
        </a>
      </div>
    </div>
    <ChevronIcon
      v-if="editing"
      class="arrow-button"
      :direction="open ? 'up' : 'down'"
      @click="$emit('toggle-open-details')"
    />
  </div>
</template>

<script>
import EquipmentRequestIcon from "serviceshift-ui/components/Equipment/EquipmentRequestIcon.vue";
import ChevronIcon from "serviceshift-ui/components/General/ChevronIcon.vue";
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  components: {
    ChevronIcon,
    EquipmentRequestIcon
  },
  props: {
    /** @type { QuoteInvoiceTypes.LineItem } */
    item: {
      type: Object,
      default: () => ({})
    },
    open: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    },
    techMode: {
      type: Boolean,
      default: false
    },
    eventId: {
      type: Number,
      default: null
    },
    equipmentTypesEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    arrowStyle() {
      return this.open ? "arrow up" : "arrow down";
    },
    itemName() {
      const coveredSystems =
        this.item.item_type === "membership_program" &&
        this.item.covered_systems
          ? ` - Covered Systems (${this.item.covered_systems})`
          : "";
      return `${this.item.unit_name}${coveredSystems}`;
    }
  },
  methods: {
    handleEdit(e) {
      EventBus.$emit("edit-unit-name", e, this.item, this.eventId);
    },
    emitReplaceItem() {
      EventBus.$emit("replace-line-item", this.item, this.eventId);
    },
    handleEqRequestClick() {
      EventBus.$emit("equipment-request-click", this.item, this.eventId);
    }
  }
};
</script>

<style lang="scss" scoped>
.unit-name-input {
  width: 100%;
  border: 1px solid $primary-accent-color;
  border-radius: 5px;
  padding: 2px 10px;
  margin: 5px 5px;
  min-height: 30px;
  font-family: inherit;
}

.name-container {
  width: 98%;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  background-origin: #999;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow-button {
  margin-left: 10px;
  cursor: pointer;
}

.name-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.row-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cover-systems-container {
  margin-left: 10px;
  white-space: nowrap;
}
.cover-systems-text {
  margin-right: 15px;
}

.name-text {
  font-weight: 500;
}
.link {
  color: #027db4 !important;
  cursor: pointer;
  &:hover {
    color: darken(#027db4, 10%) !important;
    text-decoration: underline;
  }
}
</style>
