<template>
  <div v-if="transactions.length" class="transaction-container">
    <div class="sub-header-text">TRANSACTION DETAILS</div>
    <Table>
      <thead>
        <tr>
          <th>Date</th>
          <th class="text-left">Method</th>
          <th>Type</th>
          <th class="text-right">Amount</th>
          <th class="note-header" :class="{ techModeNotes: techMode }">
            <span class="note-header-wrapper">Notes</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="transaction in transactions"
          :key="transaction.id"
          :class="{ void: isVoid(transaction) }"
        >
          <td class="date-column">
            {{ formatTransactionDate(transaction.transaction_time) }}
          </td>
          <td class="method-container">
            <img
              v-if="isCard(transaction) && cardImageSrc(transaction)"
              class="card-img"
              :src="cardImageSrc(transaction)"
            />
            {{ displayTransactionMethod(transaction) }}
          </td>
          <td class="text-center">
            {{ displayTransactionType(transaction) }}
          </td>
          <td class="text-right">
            {{ formatCents(transaction.amount_cents) }}
          </td>
          <td class="transaction-notes">
            <div v-if="isFailed(transaction)" class="text-danger notes-content">
              <i
                class="fas fa-exclamation-circle warning-icon"
                title="Failed"
              />
              <span>{{ transaction.notes || emptyDisplay }}</span>
            </div>
            <div v-else class="notes-content">
              {{ transaction.notes || emptyDisplay }}
              <i
                v-if="!techMode"
                class="fas fa-edit edit-icon text-link-color"
                @click="handleClick(transaction)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  </div>
</template>

<script>
import Table from "serviceshift-ui/components/General/Table.vue";
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import amexSrc from "serviceshift-ui/shared/src/assets/amex.svg";
import discoverSrc from "serviceshift-ui/shared/src/assets/discover.svg";
import masterCardSrc from "serviceshift-ui/shared/src/assets/mastercard.svg";
import visaSrc from "serviceshift-ui/shared/src/assets/visa.svg";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";
import {
  capitalize,
  dollars,
  emptyDisplay
} from "serviceshift-ui/shared/src/mixins";

export default {
  components: {
    Table
  },
  props: {
    /** @type { QuoteInvoiceTypes.Transaction[] } */
    transactions: {
      type: Array,
      default: () => []
    },
    techMode: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      emptyDisplay
    };
  },
  methods: {
    formatTransactionDate(date) {
      return new Date(date)
        .toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit"
        })
        .replace(",", "");
    },
    /** @param transaction {QuoteInvoiceTypes.Transaction} */
    isCard(transaction) {
      return transaction.transaction_type === "card";
    },

    /** @param transaction {QuoteInvoiceTypes.Transaction} */
    cardImageSrc(transaction) {
      const cardBrand = transaction.card_brand || "";
      switch (cardBrand.toLowerCase()) {
        case "discover":
          return discoverSrc;
        case "visa":
          return visaSrc;
        case "mastercard":
          return masterCardSrc;
        case "american express":
        case "amex":
          return amexSrc;
        default:
          return "";
      }
    },
    formatCents(cents) {
      return dollars(cents);
    },

    /** @param transaction {QuoteInvoiceTypes.Transaction} */
    displayTransactionMethod(transaction) {
      const cardNumber = transaction.card_last4
        ? `**** ${transaction.card_last4}`
        : "****";

      switch (transaction.transaction_type) {
        case "check":
          return transaction.amount_cents > 0
            ? `Check (#${transaction.check_number})`
            : "Check/Cash";
        case "card":
          return `${cardNumber}${
            transaction.entry_method ? ` (${transaction.entry_method})` : ""
          }`;
        case "ach":
          return `${capitalize(transaction.ach_account_type)} ACH ...${
            transaction.ach_last4
          }`;
        case "external":
          return `External Payment: ${transaction.processor_name}`;
        case "external_financing":
          return `External Financing: ${transaction.processor_name} (Loan #${transaction.reference_id})`;
        default:
          return "Paid in Cash";
      }
    },

    /** @param transaction {QuoteInvoiceTypes.Transaction} */
    displayTransactionType(transaction) {
      return transaction.amount_cents >= 0 ? "Payment" : "Refund";
    },

    /** @param transaction {QuoteInvoiceTypes.Transaction} */
    handleClick(transaction) {
      EventBus.$emit(
        "edit-transaction-note-click",
        transaction,
        transaction.invoice_id
      );
    },

    /** @param transaction {QuoteInvoiceTypes.Transaction} */
    isVoid(transaction) {
      return transaction.status === "voided";
    },

    /** @param transaction {QuoteInvoiceTypes.Transaction} */
    isFailed(transaction) {
      return (
        transaction.status === "failed" || transaction.status === "attempted"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.transaction-container {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

tbody tr td {
  vertical-align: top;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
}

thead tr th {
  text-align: center;
}

.sub-header-text {
  font-size: 14px;
  font-weight: bolder;
  line-height: 16px;
  margin-bottom: 14px;
}

.note-header {
  text-align: left;
  width: 50%;
}

.note-header-wrapper {
  margin-left: 0.8em;
}

.transaction-notes {
  text-align: left;
  img {
    width: 15px;
    height: 15px;
    vertical-align: text-top;
  }
}

.edit-icon {
  font-size: 1em;
  margin-left: 5px;
  cursor: pointer;
}

.warning-icon {
  font-size: 1em;
  margin-right: 5px;
  margin-left: 0px;
  cursor: none;
}

.method-container {
  text-align: left;
  white-space: nowrap;
  img {
    vertical-align: middle;
  }
}

.card-img {
  display: inline-block;
  margin-right: 5px;
  width: 35px;
  height: 25px;
}

.techModeNotes {
  width: 35%;
}

.void {
  text-decoration: line-through;
  font-style: italic;
}

.text-danger {
  color: $danger-color;
}

.notes-content {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal !important;
  margin-left: 1em;
}

.date-column {
  white-space: nowrap;
}
</style>
