<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <div class="stax-form px-4">
    <div class="mb-6 introText">
      ACH allows you to make an automatic payment from your bank account.
    </div>
    <div class="d-flex mb-1">
      <PaymentMethodInput
        required
        :error="errorMessages.firstname"
        label="First Name"
        class="w-full mr-4"
      >
        <Input v-model="form.ach_first_name" solo />
      </PaymentMethodInput>
      <PaymentMethodInput
        required
        :error="errorMessages.lastname"
        label="Last Name"
        class="w-full"
      >
        <Input v-model="form.ach_last_name" solo />
      </PaymentMethodInput>
    </div>
    <div class="d-flex border-bottom">
      <PaymentMethodInput
        required
        :error="errorMessages.bank_type"
        label="Account Type"
        class="w-full mb-3 mr-4"
      >
        <Select
          v-model="form.ach_account_type"
          required
          :options="accountTypes"
          placeholder="Select Account"
        />
      </PaymentMethodInput>
      <PaymentMethodInput
        required
        :error="errorMessages.bank_holder_type"
        label="Account Usage"
        class="w-full mb-3"
      >
        <Select
          v-model="form.ach_holder_type"
          :options="accountHolderTypes"
          placeholder="Select Account Type"
        />
      </PaymentMethodInput>
    </div>
    <div class="d-flex mb-4 border-bottom">
      <PaymentMethodInput
        required
        :error="errorMessages.bank_routing"
        label="Routing Number"
        tooltip="Nine digit number unique to your bank."
        class="w-full mr-4"
      >
        <Input v-model="form.ach_routing_number" solo />
      </PaymentMethodInput>
      <PaymentMethodInput
        required
        :error="errorMessages.bank_account"
        label="Account Number"
        class="w-full"
      >
        <Input v-model="form.ach_account_number" solo />
      </PaymentMethodInput>
    </div>
    <div
      v-if="showSavePayment && config && config.card_savable && !autoSaveCard"
      class="flex-column mt-4 save-payment"
    >
      <SavePaymentMethodCheckbox
        v-model="form.ach_save_payment_method"
        payment-type="ACH"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
// import * as Sentry from "sentry-cordova";
import { computed, onMounted, ref } from "vue";

import Input from "@/components/Input.vue";
import SavePaymentMethodCheckbox from "@/modules/paymentMethods/components/SavePaymentMethodCheckbox.vue";
import usePaymentMethods from "../usePaymentMethods";
import PaymentMethodInput from "./PaymentMethodInput.vue";

const { paymentMethodOptions } = usePaymentMethods();

const props = defineProps({
  showSavePayment: {
    type: Boolean,
    default: true
  },
  form: { type: Object, default: null },
  autoSaveCard: {
    type: Boolean,
    default: false
  }
});

const staxJs = ref(null as any);
const rejector = ref(null as any);
const resolver = ref(null as any);
const accountTypes = ref([
  { label: "Checking", value: "checking" },
  { label: "Savings", value: "savings" }
]);
const accountHolderTypes = ref([
  { label: "Personal", value: "personal" },
  { label: "Business", value: "business" }
]);
const errorMessages = ref({
  firstname: "",
  lastname: "",
  bank_type: "",
  bank_holder_type: "",
  bank_account: "",
  bank_routing: "",
  person_name: ""
});

defineExpose({
  getData
});

const config = computed(() => {
  return paymentMethodOptions.value.find((method) => method.key === "stax_ach");
});

onMounted(() => {
  initForm();
});

function clearErrors() {
  Object.keys(errorMessages.value).forEach((key) => {
    errorMessages.value[key] = "";
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getData() {
  clearErrors();

  // Quick front end validation
  let errorMessage = "";
  if (!props.form.ach_first_name) {
    errorMessage = "First name is required.";
    errorMessages.value.firstname = errorMessage;
  } else if (!props.form.ach_last_name) {
    errorMessage = "Last name is required.";
    errorMessages.value.lastname = errorMessage;
  } else if (!props.form.ach_account_type) {
    errorMessage = "Account is required.";
    errorMessages.value.bank_type = errorMessage;
  } else if (!props.form.ach_holder_type) {
    errorMessage = "Account type is required.";
    errorMessages.value.bank_holder_type = errorMessage;
  } else if (!props.form.ach_routing_number) {
    errorMessage = "Routing number is required.";
    errorMessages.value.bank_routing = errorMessage;
  } else if (!props.form.ach_account_number) {
    errorMessage = "Account number is required.";
    errorMessages.value.bank_account = errorMessage;
  }
  if (errorMessage) {
    throw new Error(
      "Unable to submit ACH Payment form with error: " + errorMessage
    );
  }

  return new Promise((resolve, reject) => {
    resolver.value = resolve;
    rejector.value = reject;

    const details = {
      method: "bank",
      firstname: props.form.ach_first_name,
      lastname: props.form.ach_last_name,
      bank_type: props.form.ach_account_type, // checking or savings
      bank_holder_type: props.form.ach_holder_type, // personal or business
      bank_account: props.form.ach_account_number,
      bank_routing: props.form.ach_routing_number,
      person_name: `${props.form.ach_first_name} ${props.form.ach_last_name}`
    };
    return staxJs.value
      .tokenize(details)
      .then((response) => {
        resolve({
          ach_token: response.id,
          ach_save_payment_method:
            props.form.ach_save_payment_method || props.autoSaveCard
        });
      })
      .catch((err) => {
        highlightFieldErrors(err);
        throw rejector.value("Unable to submit Stax ACH with error: ", err);
      });
  });
}

function initForm() {
  if (!config.value) {
    return;
  }
  staxJs.value = new StaxJs(config.value.web_id, {});
}

function highlightFieldErrors(err: any) {
  if (!err) return;

  if (err.errors) {
    const errorMessage = (err.errors[0] || "").toLowerCase();
    if (errorMessage.includes("routing number")) {
      errorMessages.value.bank_routing = "Invalid routing number";
      return;
    }
    const prefix = "could not tokenize payment method: ";
    if (errorMessage.startsWith("could not tokenize payment method:")) {
      const message = errorMessage.replace(prefix, "");
      if (message.includes("account")) {
        errorMessages.value.bank_account = message;
      }
    }
  } else if (err.field) {
    errorMessages.value[err.field] = err.message;
  } else if (Object.keys(err).length) {
    // If the err is an object, map existing keys
    Object.keys(errorMessages.value).forEach((key) => {
      if (err[key]) errorMessages.value[key] = err[key][0];
    });
  }
}
</script>

<style lang="scss" scoped>
.introText {
  font-size: 14px;
  line-height: 16px;
}
.paymentProcessingError {
  color: #f00;
  font-weight: bold;
}
:deep(.select-box) {
  box-shadow: none;
  margin-bottom: -3px;
}
.save-payment {
  height: 65px;
  margin-top: 16px;
}
</style>
