<template>
  <Card headless class="tw-pt-4">
    <div>
      <div class="tw-mb-4">
        We’ve detected the following extended warranty programs:
      </div>
      <div v-for="warranty in warranties" :key="warranty.id" class="tw-mb-4">
        <RadioButton
          v-model="formData.equipment_warranty_id"
          :radio-value="warranty.id"
          name="warranty"
        >
          <template #label>
            <div>
              <div>
                <strong>{{ warranty.warranty_program_name }}</strong>
              </div>
              <div class="tw-ml-2">{{ warranty.label }}</div>
              <div class="tw-flex tw-flex-wrap tw-whitespace-nowrap tw-ml-2">
                <div class="tw-mr-1">
                  Purchase Date:
                  {{ display(formatDate(warranty.purchase_date)) }} &bull;
                </div>
                <div class="tw-mr-1">
                  Effective Date:
                  {{ display(formatDate(warranty.effective_date)) }} &bull;
                </div>
                <div class="tw-mr-1">
                  Expiration Date:
                  {{ display(formatDate(warranty.expiration_date)) }}
                </div>
              </div>
              <div class="tw-flex tw-flex-wrap tw-whitespace-nowrap tw-ml-2">
                <div class="tw-mr-1">
                  SKU: {{ display(warranty.sku_number) }} &bull;
                </div>
                <div class="tw-mr-1">
                  Provider:
                  {{ display(warranty.warranty_program_provider_name) }} &bull;
                </div>
                <div class="tw-mr-1">
                  Labor Rate:
                  {{ display(currency(warranty.labor_rate_cents / 100)) }}
                  &bull;
                </div>
                <div>Term (Years): {{ display(warranty.term_years) }}</div>
              </div>
            </div>
          </template>
        </RadioButton>
      </div>
      <!-- <div>
        <RadioButton v-model="formData.equipment_warranty_id" :radio-value="null" name="warranty" label="None" />
      </div> -->
    </div>
  </Card>
</template>

<script>
import Card from "serviceshift-ui/components/Card/Card.vue";
import RadioButton from "serviceshift-ui/components/Inputs/RadioButton.vue";
import * as EquipmentTypes from "serviceshift-ui/shared/src/classes/equipment";
import currency from "serviceshift-ui/shared/src/filters/currency";
import { emptyDisplay, formatDate } from "serviceshift-ui/shared/src/mixins";
export default {
  components: {
    Card,
    RadioButton
  },
  props: {
    /** @type {EquipmentTypes.Warranty[]} */
    warranties: {
      type: Array,
      default: () => []
    },
    /** @type { { components: Array<any>; equipment_warranty_id: number;} } */
    formData: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    formatDate,
    currency,
    display(val) {
      return val || emptyDisplay;
    }
  }
};
</script>

<style lang="scss" scoped></style>
