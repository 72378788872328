<template>
  <v-dialog
    v-model="isOpen"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="
      $vuetify.breakpoint.smAndDown
        ? 'dialog-bottom-transition'
        : 'dialog-transition'
    "
    max-width="768"
  >
    <v-card>
      <v-toolbar dark color="secondary">
        <v-btn icon @click="isOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Proceed with Service</v-toolbar-title>
      </v-toolbar>
      <div class="pa-6">
        <v-form ref="form" lazy-validation @submit.prevent="handleSubmit">
          <p class="ma-0">
            <b>{{ quoteName }} ({{ quoteTotal }}):</b>
            {{ quoteSummary }}
          </p>
          <p class="ma-0 mt-4">
            By clicking on submit you’re notifying {{ tenant.name }} to contact
            you and schedule a service call for this quote.
          </p>
          <div class="mt-4">
            <v-textarea
              v-model="notes"
              outlined
              label="Add a note to this request for service"
            ></v-textarea>
          </div>
          <div class="text-right">
            <v-btn type="submit" color="primary" :loading="loading"
              >Submit</v-btn
            >
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { dollars } from "@/mixins/index.js";

export default {
  props: {
    quote: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      notes: "",
      isOpen: true
    };
  },
  computed: {
    ...mapState({
      tenant: "tenant"
    }),
    quoteName() {
      return this.quote.name || "Untitled Quote";
    },
    quoteSummary() {
      return this.quote.summary || "--";
    },
    quoteTotal() {
      return this.quote.total || dollars(this.quote.financials.total_cents);
    },
    quoteProceedUrl() {
      return this.quote.proceed_url || this.quote.links.quote_proceed;
    }
  },
  watch: {
    isOpen(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        setTimeout(() => {
          this.$emit("hide-modal");
        }, 500);
      }
    }
  },
  methods: {
    ...mapActions(["quoteProceed"]),
    handleSubmit() {
      this.loading = true;
      this.quoteProceed({
        link: this.quoteProceedUrl,
        data: {
          notes: this.notes
        }
      })
        .then(() => {
          this.isOpen = false;
          this.$emit("save-success");
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style lang="scss" scoped></style>
