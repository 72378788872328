<template>
  <div>
    <v-dialog
      v-model="modelValue"
      persistent
      :transition="
        $vuetify.breakpoint.smAndDown
          ? 'dialog-bottom-transition'
          : 'dialog-transition'
      "
      max-width="350"
    >
      <v-card>
        <div class="modal-content text-center py-5 px-5 paymentSuccessModal">
          <i class="fa fa-circle-check mb-5 ssui-green" />
          <div class="mb-1">
            <strong>Thank you for your payment!</strong>
          </div>
          <div class="text-small mb-5">
            Your {{ paymentTypeFormatted }} payment was successful.
          </div>
          <div class="totalAmountPaid">Total Amount Paid</div>
          <div class="amountPaid mb-3" data-cy="total-amount-paid">
            {{ dollars(amountInCents) }}
          </div>
          <div class="paymentDetails mb-5">
            <div class="paymentDetails__row">
              <div class="paymentDetails__title">Date:</div>
              <div class="paymentDetails__value" data-cy="payment-date">
                {{ formattedDate }}
              </div>
            </div>
            <div class="paymentDetails__row">
              <div class="paymentDetails__title">Confirmation #:</div>
              <div class="paymentDetails__value" data-cy="confirmation-number">
                {{ confirmationNumber || "--" }}
              </div>
            </div>
            <div class="paymentDetails__row">
              <div class="paymentDetails__title">Invoice #:</div>
              <div class="paymentDetails__value" data-cy="invoice-number">
                {{ invoiceNumber || "--" }}
              </div>
            </div>
          </div>
          <v-btn color="primary" rounded type="submit" @click="handleConfirm"
            >Got it</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import formatDate from "date-fns/format";
import { PaymentMethodType } from "serviceshift-ui/shared/src/typings/paymentMethods";
import { computed } from "vue";

const props = defineProps({
  value: {
    type: Boolean,
    default: () => false
  },
  paymentType: {
    type: String as () => PaymentMethodType,
    default: () => ""
  },
  amountInCents: {
    type: Number,
    default: () => 0
  },
  date: {
    type: String,
    default: () => null
  },
  confirmationNumber: {
    type: String,
    default: () => ""
  },
  invoiceNumber: {
    type: String,
    default: () => ""
  }
});

const emit = defineEmits(["input", "confirm"]);

const modelValue = computed({
  get() {
    return props.value;
  },
  set(val) {
    emit("input", val);
  }
});

const paymentTypeFormatted = computed(() => {
  switch (props.paymentType) {
    case "stax_ach":
    case "ach":
    case "gpi_ach":
      return "ACH";
    case "card":
    case "stax_card":
    case "gpi_card":
      return "credit card";
    default:
      return "";
  }
});

const formattedDate = computed(() => {
  if (!props.date) return "";
  return formatDate(new Date(props.date), "MM/dd/yyyy");
});

function handleConfirm() {
  emit("input", false);
  emit("confirm");
}
</script>

<style lang="scss">
.paymentSuccessModal {
  .fa-circle-check {
    font-size: 30px;
  }
  .amountPaid {
    font-weight: 400;
    font-size: 22px;
  }
  .paymentDetails {
    padding-top: 15px;
    border-top: 1px solid #d3d7d9;
  }
  .paymentDetails__row {
    display: flex;
    font-size: 14px;
  }
  .paymentDetails__title {
    font-weight: 500;
  }
  .paymentDetails__value {
    margin-left: auto;
    word-break: break-word;
    text-align: right;
    max-width: 180px;
  }
  .totalAmountPaid {
    font-size: 13px;
  }
}
</style>
