import axios from "axios";

import ENV from "../env";

export default {
  getAll: (customerId) => {
    return axios(`${ENV.v2Path}/customers/${customerId}/equipment`, {
      method: "GET"
    });
  },
  create: (customerId, data) => {
    return axios(`${ENV.v2Path}/customers/${customerId}/equipment`, {
      method: "POST",
      data
    });
  },
  get: (customerId, equipmentId) => {
    return axios(
      `${ENV.v2Path}/customers/${customerId}/equipment/${equipmentId}`,
      {
        method: "GET"
      }
    );
  },
  update: (customerId, data) => {
    return axios(
      `${ENV.v2Path}/customers/${customerId}/equipment/${data.equipment.id}`,
      {
        method: "PUT",
        data
      }
    );
  },
  delete: (customerId, equipmentId) => {
    return axios(
      `${ENV.v2Path}/customers/${customerId}/equipment/${equipmentId}`,
      {
        method: "DELETE"
      }
    );
  }
};
