<template>
  <div class="component-details-card tw-flex tw-flex-col tw-w-full">
    <div
      class="component-details-card-header tw-flex tw-items-center tw-justify-center tw-w-full tw-font-bold"
      :class="{
        'success-bg': headerColor === 'success',
        'danger-bg': headerColor === 'danger'
      }"
    >
      <slot name="title" />
    </div>
    <div class="component-details-body">
      <ul v-if="component" class="tw-m-4 default-style">
        <li v-for="fieldName in sortedFields" :key="fieldName">
          <strong>{{ getDisplayForField(fieldName) }}:</strong>
          {{ getValueDisplay(component, fieldName) }}
        </li>
        <li
          v-if="component.service_history && component.service_history.length"
        >
          <strong
            >Service History ({{ component.service_history.length }})*</strong
          >
          <br />
          *The service history details for {{ genericEquipmentLabel }} will be
          transferred to
          {{ keepEquipmentLabel }}
        </li>
      </ul>
      <slot name="body" />
    </div>
  </div>
</template>

<script>
import {
  formatDate,
  fromNow,
  tableFieldDisplay,
  tableValueDisplay
} from "serviceshift-ui/shared/src/mixins";
export default {
  props: {
    /** @type { EquipmentComponent } */
    component: {
      type: Object,
      default: null
    },
    genericEquipmentLabel: {
      type: String,
      default: "generic equipment"
    },
    keepEquipmentLabel: {
      type: String,
      default: "existing equipment"
    },
    fieldLabelLookup: {
      type: Object,
      default: () => {
        return {
          age: "Age",
          install_location: "Equipment Location",
          warranty: "Included Warranty",
          manufactured_on: "Manufacturer Date",
          installed_on: "Installation Date",
          model_number: "Model Number",
          serial_number: "Serial Number"
        };
      }
    },
    headerColor: {
      type: String,
      default: "default" // default|success|danger
    }
  },
  data() {
    return {
      sortedFields: [
        "manufacturer",
        "install_location",
        "warranty",
        "model_number",
        "serial_number",
        "manufactured_on",
        "installed_on",
        "age"
      ]
    };
  },
  methods: {
    formatDate,
    getValueDisplay(component, fieldName) {
      if (fieldName === "age") {
        return fromNow(component.installed_on || component.manufactured_on);
      }
      return tableValueDisplay(component[fieldName]);
    },
    getDisplayForField(fieldName) {
      if (this.fieldLabelLookup[fieldName])
        return this.fieldLabelLookup[fieldName];
      return tableFieldDisplay(fieldName);
    }
  }
};
</script>

<style lang="scss" scoped>
.component-details-card {
  border-radius: 4px;
  background: $light-color;
  position: relative;
}
.component-details-card-header {
  background: $tertiary-color;
  color: $white;
  padding: 7px;
  border-radius: 4px 4px 0 0;
  &.success-bg {
    background: $success-color;
  }
  &.danger-bg {
    background: $danger-dark-color;
  }
}
.component-details-body {
  background: $light-color;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
</style>
