<template>
  <Page title="Create Account">
    <v-form
      ref="form"
      v-model="formIsValid"
      lazy-validation
      class="full-width"
      @submit.prevent="handleSubmit"
    >
      <span class="overline ml-4">Personal Info</span>
      <v-card class="ma-4 mt-1 pa-3 bordered-card">
        <v-text-field
          v-model="firstName"
          outlined
          label="First Name"
          :rules="requiredRules"
          required
        />
        <v-text-field
          v-model="lastName"
          outlined
          label="Last Name"
          :rules="requiredRules"
        />
        <v-text-field
          v-model="phone"
          v-mask="'(###) ###-####'"
          outlined
          label="Mobile Phone"
          hint="ex: (555) 555-5555"
          :rules="requiredRules"
        />
      </v-card>

      <span class="overline ml-4">Account Info</span>
      <v-card class="ma-4 mt-1 pa-3 bordered-card">
        <v-text-field
          v-model="email"
          outlined
          type="email"
          label="Email"
          hint="ex: name@domain.com"
          :rules="emailRules"
          :error-messages="emailValidationErrorMessage"
        />
        <v-text-field
          v-model="password"
          outlined
          label="Password"
          :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          :rules="passwordRules"
          @click:append="toggleVisible"
        />
      </v-card>

      <span class="overline ml-4">Address</span>
      <v-card class="ma-4 mt-1 pa-3 bordered-card">
        <AddressField ref="address" />
      </v-card>

      <div class="text-center mt-6">
        <Recaptcha ref="recaptcha"></Recaptcha>
        <v-btn type="submit" rounded color="primary" :loading="loading"
          >Create Account</v-btn
        >
      </div>
    </v-form>
  </Page>
</template>

<script>
import API from "serviceshift-ui/api-client";
import { getAddressComponents } from "@/lib/address";

import AddressField from "@/components/AddressField.vue";
import Page from "@/components/Page.vue";
import Recaptcha from "@/components/Recaptcha.vue";
import ValidationRules from "../mixins/validationRules";

export default {
  name: "CreateAccount",
  components: {
    AddressField,
    Page,
    Recaptcha
  },
  mixins: [ValidationRules],
  data() {
    return {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      password: "",
      address: {},
      visible: false,
      loading: false
    };
  },
  watch: {
    email(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.validateEmail(newValue);
      }
    }
  },
  methods: {
    async handleSubmit() {
      if (
        this.$refs.form.validate() &&
        (await this.$refs.recaptcha.validate()) &&
        !this.$refs.address.addressValidationErrorMessage &&
        !this.emailValidationErrorMessage
      ) {
        this.address = this.$refs.address.getAddress();
        const addressComponents = getAddressComponents({
          ...this.address,
          name: "Home",
          primary: true,
          type: "home"
        });
        this.loading = true;
        API.user
          .create({
            user: {
              password: this.password,
              password_confirmation: this.password,
              email: this.email,
              first_name: this.firstName,
              last_name: this.lastName,
              phone: this.phone,
              ...addressComponents,
              payment: {},
              addresses_attributes: [addressComponents]
            }
          })
          .then((res) => {
            this.$store.dispatch("setUser", res.data);
            this.$router.push({
              name: "profile",
              query: { created: "success" }
            });
            window.gtag("event", "sign_up", { method: "email" });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    toggleVisible() {
      this.visible = !this.visible;
    }
  }
};
</script>

<style lang="scss" scoped>
.overline {
  text-align: left;
}
.v-card {
  min-width: 350px;
}
</style>
