import Vue from "vue";
import Router from "vue-router";

import CustomerLayout from "@/components/Layouts/CustomerLayout.vue";
import TokenLayout from "@/components/Layouts/TokenLayout.vue";
import Appointment from "@/views/Appointment.vue";
import AppointmentSummary from "@/views/AppointmentSummary.vue";
import CreateAccount from "@/views/CreateAccount.vue";
import CreateAccountSuccess from "@/views/CreateAccountSuccess.vue";
import Dashboard from "@/views/Dashboard.vue";
import Invoice from "@/views/Invoice.vue";
import InvoicesPage from "@/views/InvoicesPage.vue";
import Login from "@/views/Login.vue";
import MagicLinkAppointment from "@/views/magicLinks/MagicLinkAppointment.vue";
import NewPassword from "@/views/NewPassword.vue";
import PasswordReset from "@/views/PasswordReset.vue";
import Profile from "@/views/Profile.vue";
import QuotePreview from "@/views/QuotePreview.vue";
import QuotesPage from "@/views/QuotesPage.vue";
import ScheduleAppointment from "@/views/ScheduleAppointment.vue";
import ScheduleAppointmentWidget from "@/views/ScheduleAppointmentWidget.vue";
import ServiceHistory from "@/views/ServiceHistory.vue";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes: [
    {
      path: "/appointment-details/:token",
      name: "magicLinkAppointment",
      component: MagicLinkAppointment,
      meta: {
        layout: TokenLayout
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "Login"
      }
    },
    {
      path: "/password_reset",
      name: "passwordReset",
      component: PasswordReset,
      meta: {
        title: "Password Reset"
      }
    },
    {
      path: "/new_password",
      name: "newPassword",
      component: NewPassword,
      meta: {
        title: "New Password"
      }
    },
    {
      path: "/create_account",
      name: "createAccount",
      component: CreateAccount,
      meta: {
        title: "Create Account"
      }
    },
    {
      path: "/create_account_success",
      name: "createAccountSuccess",
      component: CreateAccountSuccess,
      meta: {
        title: "Create Account Success"
      }
    },
    {
      path: "/schedule-appointment",
      name: "scheduleAppointmentWidget",
      component: ScheduleAppointmentWidget,
      meta: {
        title: "Schedule Appointment"
      }
    },
    {
      path: "/",
      name: "dashboard",
      component: Dashboard,
      meta: {
        layout: CustomerLayout,
        title: "Home"
      }
    },
    {
      path: "/schedule",
      name: "scheduleAppointment",
      component: ScheduleAppointment,
      meta: {
        layout: CustomerLayout,
        title: "Schedule Appointment"
      }
    },
    {
      path: "/appointment/:id",
      name: "appointment",
      component: Appointment,
      meta: {
        layout: CustomerLayout,
        title: "Appointment"
      }
    },
    {
      path: "/appointment_summary/:id",
      name: "appointmentSummary",
      component: AppointmentSummary,
      meta: {
        layout: CustomerLayout,
        title: "Appointment Summary"
      }
    },
    {
      path: "/invoice/:id",
      name: "invoice",
      component: Invoice,
      meta: {
        layout: CustomerLayout,
        title: "Invoice"
      }
    },
    {
      path: "/service_history",
      name: "serviceHistory",
      component: ServiceHistory,
      meta: {
        layout: CustomerLayout,
        title: "Service History"
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        layout: CustomerLayout,
        title: "Profile"
      }
    },
    {
      path: "/invoices",
      name: "invoices",
      component: InvoicesPage,
      meta: {
        layout: CustomerLayout,
        title: "Invoices"
      }
    },
    {
      path: "/quotes",
      name: "quotes",
      component: QuotesPage,
      meta: {
        layout: CustomerLayout,
        title: "Quotes"
      }
    },
    {
      path: "/quote_preview/:id",
      name: "quotePreview",
      component: QuotePreview,
      meta: {
        layout: CustomerLayout,
        title: "Quote Preview"
      }
    },
    {
      path: "*",
      component: Dashboard,
      meta: {
        layout: CustomerLayout
      }
    }
  ]
});

router.afterEach((to) => {
  // Update page title based on route
  if (store.state.tenant) {
    document.title = `${store.state.tenant.name} Portal - ${to.meta.title}`;
  } else {
    document.title = `Portal - ${to.meta.title}`;
  }
});

export default router;
