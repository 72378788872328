import axios from "axios";
import { Id } from "serviceshift-ui/shared/src/typings/globals";

import ENV from "../env";

export default {
  create: (id: Id, type, comment) => {
    return axios(`${ENV.v1Path}/comments`, {
      method: "POST",
      data: {
        comment: {
          commentable_id: id,
          commentable_type: type,
          comment
        }
      }
    });
  },
  delete: (id: Id) => {
    return axios(`${ENV.v1Path}/comments/${id}`, {
      method: "DELETE"
    });
  },
  getAll: (id: Id, type) => {
    return axios(`${ENV.v1Path}/comments/by_date`, {
      method: "GET",
      params: {
        "comment[commentable_id]": id,
        "comment[commentable_type]": type
      }
    });
  },
  update: (id: Id, comment) => {
    return axios(`${ENV.v1Path}/comments/${id}`, {
      method: "PUT",
      data: {
        comment: {
          comment
        }
      }
    });
  },
  updateVisibility: (id: Id, visible) => {
    return axios(`${ENV.v1Path}/comments/${id}`, {
      method: "PUT",
      data: {
        comment: {
          customer_visible: visible
        }
      }
    });
  },
  updatePinned: (id: Id, pinned) => {
    return axios(`${ENV.v1Path}/comments/${id}`, {
      method: "PUT",
      data: {
        comment: {
          pinned
        }
      }
    });
  }
};
