<template>
  <div v-if="customer" class="customerPaymentMethods">
    <div
      v-for="method in storedPaymentMethods"
      :key="method.id"
      class="d-flex justify-content-between align-items-center payment-method"
      :class="{ active: method.id === selectedId }"
    >
      <div v-if="method.payment_type === 'card'" class="cardDetails full-width">
        <div class="d-flex align-items-center mb-1">
          <div
            class="d-flex align-items-center cardInfo"
            :class="{ expired: method.expired }"
          >
            <img :src="cardImages[method.brand]" class="mr-2" />
            <span class="mr-2">***{{ method.last4 }}</span>
            <span>{{ method.exp_month }}/{{ method.exp_year }}</span>
            <span v-if="method.expired" class="ml-3">
              <i class="fas fa-exclamation-circle mr-1" />
              Expired
            </span>
          </div>
          <button
            class="fas ml-auto fa-trash-alt menu-icon"
            @click="handleDeletePaymentMethod"
          />
        </div>
        <div>
          <!-- Support existing payment methods without billing address info -->
          <div v-if="savedPaymentBillingAddress?.cityStateZip">
            <div><b>Billing Address:</b></div>
            <div>{{ savedPaymentBillingAddress.name }}</div>
            <div>{{ savedPaymentBillingAddress.street }}</div>
            <div>{{ savedPaymentBillingAddress.cityStateZip }}</div>
          </div>
          <div v-else>
            <div>{{ fullName }}</div>
          </div>
        </div>
      </div>
      <div
        v-if="method.payment_type === 'ach'"
        class="d-flex align-items-center full-width"
      >
        <div>
          <i class="fas fa-university mr-2" />
          <span>
            {{ capitalize(method.ach_account_type) }} account ending in
            <b>{{ method.last4 }}</b>
          </span>
        </div>
        <button
          class="fas ml-auto fa-trash-alt menu-icon"
          @click="handleDeletePaymentMethod"
        />
      </div>
    </div>
    <div v-if="!storedPaymentMethods.length" class="text-center">
      No payment method on file
    </div>
    <DeletePaymentMethodConfirmation
      v-model="showRemovePaymentConfirmation"
      @payment-method-deleted="emit('payment-method-deleted')"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { capitalize } from "@/lib/helpers";

import AmericanExpressCard from "../assets/amex.svg";
import DiscoverCard from "../assets/discover.svg";
import MasterCard from "../assets/mastercard.svg";
import VisaCard from "../assets/visa.svg";
import usePaymentMethods from "../usePaymentMethods";
import DeletePaymentMethodConfirmation from "./modals/DeletePaymentMethodConfirmation.vue";

const { customer, storedPaymentMethods, fullName, savedPaymentBillingAddress } =
  usePaymentMethods();

// TODO: Currently this component does not have a way to select a stored method. We
// need to update this UI to add a button in the future.
defineProps({
  selectedId: {
    type: Number,
    default: null
  }
});

const emit = defineEmits(["payment-method-deleted"]);

const showRemovePaymentConfirmation = ref(false);
const cardImages = ref({
  Discover: DiscoverCard,
  Visa: VisaCard,
  MasterCard,
  "American Express": AmericanExpressCard
});

function handleDeletePaymentMethod() {
  showRemovePaymentConfirmation.value = true;
}
</script>

<style lang="scss">
.customerPaymentMethods {
  .fa-trash-alt {
    color: #de0000;
  }
  .cardDetails {
    font-size: 14px;
  }
  .cardInfo.expired {
    color: #de0000;
  }
}
</style>
