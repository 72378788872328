<template>
  <v-app v-if="layoutReady" dark>
    <CustomerNavbar />
    <v-main>
      <v-container fluid>
        <slot />
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>

<script lang="ts" setup>
import { AuthRole } from "serviceshift-ui/shared/src/typings/auth";
import { onBeforeMount, ref } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { isLoggedInWithRole } from "@/lib/auth";

import Footer from "@/components/Footer.vue";
import CustomerNavbar from "./customer/CustomerNavbar.vue";

const router = useRouter();
const route = useRoute();
const layoutReady = ref(false);

onBeforeMount(() => {
  if (!isLoggedInWithRole(AuthRole.customer)) {
    router.push({ name: "login", query: { redirect: route.fullPath } });
    return;
  }
  layoutReady.value = true;
});
</script>
