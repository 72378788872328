import API from "serviceshift-ui/api-client";

import { createCable } from "@anycable/web";

export default ({ token }) => {
  const cableURL = import.meta.env.VITE_CABLE_SERVER;
  return createCable(`${cableURL}?token=${token}`, {
    tokenRefresher: async (transport) => {
      const {
        data: { cable_token }
      } = await API.makeRequest("/users/cable_auth", {
        method: "GET"
      });

      transport.setURL(`${cableURL}?token=${cable_token}`);
    }
  });
};
