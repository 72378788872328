import axios from "axios";

import ENV from "../env";

export default {
  getAll: () => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/service_categories`
    });
  },
  getTasks: (params) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/tasks`,
      params
    });
  }
};
