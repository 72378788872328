export const storablePaymentMethods: PaymentMethodType[] = [
  "payment_method",
  "stax_ach",
  "stax_card",
  "gpi_card",
  "gpi_ach",
  "card",
  "ach"
];

export type PaymentMethodType =
  | "stax_ach"
  | "stax_card"
  | "gpi_card"
  | "cash"
  | "check"
  | "gpi_ach"
  | "payment_method"
  | "external"
  | "card"
  | "ach";

export class PaymentMethod {
  key: PaymentMethodType = "card";
  label = "";
  web_id = "";
  transaction_type?: PaymentMethodType = "card";
  card_savable = false;
  id? = -1;
  payment_method_delete_url = "";
  ach_account_type? = "";
  expired = false;
  brand? = "";
  last4? = "";
  exp_month? = "";
  exp_year? = "";
  payment_type?: PaymentMethodType = "card";
  check_payable_to?: string = undefined;
  environment?: string = undefined;
  tokenization_key?: string = undefined;

  constructor(params: Partial<PaymentMethod> = {}) {
    Object.assign(this, params);
  }
}
