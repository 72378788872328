<template>
  <div class="appointmentTabs">
    <Tabs v-model="activeTab" :sections="tabSections">
      <MakeChangesTab
        v-if="activeTab === TabNames.makeChanges"
        v-bind="props"
      />
      <ShareAppointmentTab v-if="activeTab === TabNames.share" v-bind="props" />
    </Tabs>
  </div>
</template>

<script setup lang="ts">
import { Appointment } from "serviceshift-ui/shared/src/typings/appointment";
import { Job } from "serviceshift-ui/shared/src/typings/job";
import { computed, ref } from "vue";

import MakeChangesTab from "@/components/Appointment/tabs/MakeChangesTab.vue";
import ShareAppointmentTab from "@/components/Appointment/tabs/ShareAppointmentTab.vue";
import Tabs from "@/components/Tabs.vue";

const props = defineProps({
  isCustomer: {
    type: Boolean,
    default: () => false
  },
  appointment: {
    type: Object as () => Appointment,
    default: () => null
  },
  job: {
    type: Object as () => Job,
    default: () => null
  }
});

enum TabNames {
  makeChanges = "Make Changes",
  share = "Share Appointment"
}

const activeTab = ref<TabNames | null>(null);

const tabSections = computed(() => {
  if (props.isCustomer) return [TabNames.makeChanges, TabNames.share];
  return [TabNames.share];
});
</script>

<style lang="scss" scoped>
.tabs {
  font-size: 14px;
  background: #fff;
  .tab {
    background: #eeeeee;
    padding: 8px;
    flex: 1;
    &:not(:first-child) {
      margin-left: 1px;
    }
  }
}
</style>
