<template>
  <div class="toggle d-flex">
    <div
      v-for="option in options"
      :key="option.value"
      class="toggle-item"
      :class="{ active: toggleValue === option.value }"
      @click="handleSelect(option.value)"
    >
      {{ option.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonToggle",
  props: {
    options: { type: Array, default: () => [] },
    value: { type: [String, Number], default: "" }
  },
  computed: {
    toggleValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    handleSelect(value) {
      this.toggleValue = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-item {
  flex: 1;
  border-radius: 0;
  border-color: #cdd4da;
  background-color: #fff;
  cursor: pointer;
  padding: 0 20px;
  height: 40px;
  display: flex;
  white-space: nowrap;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  font-weight: bold;
  border: 1px solid #cdd4da;
  border-right: none;
  box-shadow: $box-shadow;
  &:hover,
  &:active {
    background-color: #fff;
    border-color: #cdd4da;
  }
  &.active {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: white;
  }
  &:first-of-type {
    border-radius: 24px 0 0 24px;
  }
  &:last-of-type {
    border-radius: 0 24px 24px 0;
    border-right: 1px solid #cdd4da;
  }
}
</style>
