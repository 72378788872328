import ServiceFinanceLogo from "@/assets/service_finance.png";

// Datatable response is everything in strings with some extra display fields
type Status = "active" | "inactive" | "unavailable";

export type VendorLoanOffers = {
  lender: string;
  loan_offers: LoanOffer[];
}[];

export type FinancingSummaryOffers = {
  lender: string | "other";
  paymentsAsLowAsCents: number;
}[];

export type LoanCalculatorResults = {
  totalFinanceAmountCents: number;
  paymentsAsLowAsCents: number;
};

// TODO: Eventually this will come from the API
export const Lenders = {
  service_finance: {
    key: "service_finance",
    logo: ServiceFinanceLogo,
    name: "Service Finance",
  },
} as Record<string, { key: string; logo: string; name: string }>;
export const LendersList = Object.keys(Lenders).map((lenderName) => ({
  ...Lenders[lenderName],
  id: lenderName,
}));

export class LoanProgramDTResponse {
  id = "";
  lender = "";
  external_id = "";
  name = "";
  description = "";
  min_amount = "";
  max_amount = "";
  max_interest_rate = "";
  dealer_fee_percentage = "";
  preferred_min_amount = "";
  preferred_min_amount_cents = "";
  status = "Inactive" as "Inactive" | "Active";

  constructor(params: Partial<LoanProgramDTResponse> = {}) {
    Object.assign(this, params);
  }
}

export class LoanProgram {
  id = -1;
  lender = "";
  external_id = "";
  name = "";
  description = "";
  min_amount_cents = 0;
  max_amount_cents = 0;
  max_interest_rate = 0;
  dealer_fee_percentage = 0;
  preferred_min_amount_cents = null as null | number;
  status = "active" as Status;

  constructor(params: Partial<LoanProgram> = {}) {
    Object.assign(this, params);
  }
}

export class LoanOffer {
  description = "";
  down_payment_amount_cents = 0;
  id = -1;
  interest_rate = 0; // Comes back as the number we should display like 2.9
  loan_amount_cents = 0;
  loan_program_id = -1;
  min_amount_cents = 0;
  payment_amount_cents = 0;
  promotional_interest_rate: null | number = null;
  promotional_payment_amount_cents: null | number = null;
  promotional_term_months: null | number = null;
  term_months = 0;
  payment_summary = "";
  promotional_payment_summary = "";

  constructor(params: Partial<LoanOffer> = {}) {
    Object.assign(this, params);
  }
}
