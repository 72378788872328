<template>
  <div :class="{ 'tech-app': techMode }">
    <div v-for="status in sortedStatuses" :key="status">
      <QuoteGroup
        v-if="groups[status]"
        :status="status"
        :quotes="groups[status]"
        class="quote-group"
        :tech-mode="techMode"
        @quote-card-click="quoteCardClick"
      >
        <template #content="{ quote }">
          <slot name="group-card-content" :quote="quote" />
        </template>
      </QuoteGroup>
    </div>
  </div>
</template>

<script>
import QuoteGroup from "serviceshift-ui/components/MultipleQuotes/QuoteGroup.vue";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  name: "QuoteGroupsList",
  components: {
    QuoteGroup
  },
  props: {
    quotes: {
      type: Array,
      default: () => []
    },
    techMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sortedStatuses: [
        "approved",
        "pending",
        "open",
        "draft",
        "denied",
        "voided",
        "archived"
      ]
    };
  },
  computed: {
    groups() {
      // Splits quotes by status
      return this.quotes.reduce((groups, quote) => {
        if (groups[quote.status]) {
          groups[quote.status].push(quote);
        } else {
          // eslint-disable-next-line no-param-reassign
          groups[quote.status] = [quote];
        }
        return groups;
      }, {});
    }
  },
  mounted() {
    EventBus.$on("quote-approve-click", this.approveClick);
    EventBus.$on("quote-force-approve-click", this.forceApproveClick);
    EventBus.$on("schedule-work-click", this.scheduleClick);
    EventBus.$on("decline-quote-click", this.declineClick);
    EventBus.$on("edit-quote-click", this.editClick);
    EventBus.$on("draft-quote-click", this.draftQuote);
    EventBus.$on("pending-quote-click", this.pendingQuote);
    EventBus.$on("email-quote-click", this.emailClick);
    EventBus.$on("archive-quote-click", this.archiveClick);
    EventBus.$on("unarchive-quote-click", this.unarchiveClick);
    EventBus.$on("clone-quote-click", this.cloneClick);
    EventBus.$on("reactivate-quote-click", this.reactivateClick);
    EventBus.$on("pdf-quote-click", this.downloadPdfClick);
    EventBus.$on("unapprove-quote-click", this.unapproveQuoteClick);
  },
  beforeDestroy() {
    EventBus.$off("quote-approve-click", this.approveClick);
    EventBus.$off("quote-force-approve-click", this.forceApproveClick);
    EventBus.$off("schedule-work-click", this.scheduleClick);
    EventBus.$off("decline-quote-click", this.declineClick);
    EventBus.$off("edit-quote-click", this.editClick);
    EventBus.$off("draft-quote-click", this.draftQuote);
    EventBus.$off("pending-quote-click", this.pendingQuote);
    EventBus.$off("email-quote-click", this.emailClick);
    EventBus.$off("archive-quote-click", this.archiveClick);
    EventBus.$off("unarchive-quote-click", this.unarchiveClick);
    EventBus.$off("clone-quote-click", this.cloneClick);
    EventBus.$off("reactivate-quote-click", this.reactivateClick);
    EventBus.$off("pdf-quote-click", this.downloadPdfClick);
    EventBus.$off("unapprove-quote-click", this.unapproveQuoteClick);
  },
  methods: {
    approveClick(payload) {
      this.$emit("quote-approve-click", payload);
    },
    forceApproveClick(payload) {
      this.$emit("quote-force-approve-click", payload);
    },
    scheduleClick(payload) {
      this.$emit("schedule-work-click", payload);
    },
    cloneClick(payload) {
      this.$emit("clone-quote-click", payload);
    },
    declineClick(payload) {
      this.$emit("decline-quote-click", payload);
    },
    draftQuote(payload) {
      this.$emit("draft-quote-click", payload);
    },
    pendingQuote(payload) {
      this.$emit("pending-quote-click", payload);
    },
    editClick(payload) {
      this.$emit("edit-quote-click", payload);
    },
    emailClick(payload) {
      this.$emit("email-quote-click", payload);
    },
    archiveClick(payload) {
      this.$emit("archive-quote-click", payload);
    },
    unarchiveClick(payload) {
      this.$emit("unarchive-quote-click", payload);
    },
    quoteCardClick(quoteId) {
      this.$emit("quote-card-click", quoteId);
    },
    reactivateClick(payload) {
      this.$emit("reactivate-quote-click", payload);
    },
    downloadPdfClick(payload) {
      this.$emit("pdf-quote-click", payload);
    },
    unapproveQuoteClick(payload) {
      this.$emit("unapprove-quote-click", payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.quote-group {
  margin-bottom: 25px;
}
</style>
