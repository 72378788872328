export default (value) => {
  if (!value) {
    return "";
  }
  const date = new Date(value);
  const now = new Date();
  const isToday = date.getDate().toString() === now.getDate().toString();
  const formatOptions = {
    weekday: "long",
    month: "numeric",
    day: "numeric",
    year: "numeric"
  };
  if (isToday) {
    delete formatOptions.weekday;
  }
  const formattedDate = date.toLocaleDateString("en-us", formatOptions);
  return isToday ? `Today, ${formattedDate}` : formattedDate;
};
