<template>
  <div>
    <Loader v-if="loading" />
    <div v-if="appointment && validated">
      <Page
        :title="`Appointment #${appointment.job_id}`"
        max-width="768"
        data-test-id="appointmentPage"
        no-banner
      >
        <AppointmentDetails
          is-customer
          class="full-width"
          :appointment="appointment"
          :job="job"
        />
      </Page>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useStore } from "@/lib/vuex-composition";

import AppointmentDetails from "@/components/Appointment/AppointmentDetails.vue";
import Loader from "@/components/Loader.vue";
import Page from "@/components/Page.vue";

const store = useStore();
const route = useRoute();
const router = useRouter();
const redirectStatuses = ["cancelled", "canceled", "quote_denied", "completed"];
const validated = ref(false);
const loading = ref(true);

const job = computed(() => store.state.job || undefined);
const appointment = computed(() => store.state.appointment);

onMounted(() => refreshData());

async function refreshData() {
  loading.value = true;
  try {
    const res = await store.dispatch("fetchAppointment", route.params.id);
    validated.value = true;
    // If appointment is not in an active state, redirect to summary
    if (redirectStatuses.includes(res.data.status)) {
      return router.replace({
        name: "appointmentSummary",
        params: { id: res.data.id }
      });
    }
  } catch (e) {
    // do nothing
  }
  loading.value = false;
}
</script>
