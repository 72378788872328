<template>
  <div v-if="changes.length" class="change-summary-cont tw-p-4">
    <div class="tw-mb-2"><strong>Saving these changes will:</strong></div>
    <ul class="default-style !tw-mb-0">
      <li v-for="change in changes" :key="change">{{ change }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    /** @type {EquipmentTypes.Equipment[]} */
    equipment: {
      type: Array,
      default: () => []
    },
    /** @type { { components: Array<{ generic_equipment_id: number; component_type_id: number; }>; equipment_warranty_id: number;} } */
    formData: {
      type: Object,
      default: () => ({})
    },
    /** @type {EquipmentTypes.Warranty} */
    selectedWarranty: {
      type: Object,
      default: null
    },
    keepEquipmentLabel: {
      type: String,
      default: "existing equipment"
    }
  },
  computed: {
    changes() {
      const changes = [];
      this.formData.components.map((component) => {
        const genericEquipment = this.equipment.find(
          (eq) => eq.id === component.generic_equipment_id
        );
        if (genericEquipment) {
          changes.push(
            `Convert generic component details from ${genericEquipment.name} to ${component.component.name}`
          );
          if (genericEquipment.service_history.length) {
            changes.push(
              `Transfer service history from ${genericEquipment.name} to ${this.keepEquipmentLabel}`
            );
          }
        }
      });
      if (this.selectedWarranty) {
        changes.push(
          `Keep the ${this.selectedWarranty.warranty_program_name} warranty for ${this.keepEquipmentLabel}`
        );
      }
      return changes;
    }
  }
};
</script>

<style lang="scss" scoped>
.change-summary-cont {
  background: $light-gray;
  border: 1px solid $dark-gray-border;
  border-radius: 4px;
}
ul li {
  margin-bottom: 0;
}
</style>
