import API from "serviceshift-ui/api-client";
import { AuthRole } from "serviceshift-ui/shared/src/typings/auth";

let requestInterceptor: any = null;
let tenantInterceptor: any = null;
let responseInterceptor: any = null;
export const apiConfig = {
  ignoreToastNotificationOnError: false
};

export const setRequestHeaders = (
  requestConfig: {
    authentication_token?: string;
    role?: AuthRole;
    email?: string;
    id?: number | string;
  } | null
) => {
  // Set user auth token in all request headers
  if (requestInterceptor !== null) {
    API.ejectRequestInterceptor(requestInterceptor);
  }
  requestInterceptor = API.attachRequestInterceptor((config) => {
    config.headers = config.headers || {};
    config.headers.Authorization = undefined;
    if (requestConfig) {
      const AuthorizationParameters = Object.entries({
        token: requestConfig.authentication_token,
        role: requestConfig.role,
        email: requestConfig.email,
        id: requestConfig.id
      }).reduce((acc, [key, val], index) => {
        if (val === undefined) return acc;
        if (index > 0) acc += "; ";
        acc += `${key}=${val}`;
        return acc;
      }, "");
      config.headers.Authorization = `Token ${AuthorizationParameters}`;
    }
    return config;
  });
};

export const setTenantInterceptor = (tenant) => {
  if (tenantInterceptor !== null) {
    API.ejectRequestInterceptor(tenantInterceptor);
  }
  tenantInterceptor = API.attachRequestInterceptor((config) => {
    if (!includesTenant(config.url, tenant)) {
      config.url = config.url.replace("//", `//${tenant}.`);
    }
    return config;
  });
};

export const setErrorInterceptor = (func) => {
  if (responseInterceptor !== null) {
    API.ejectResponseInterceptor(responseInterceptor);
  }
  responseInterceptor = API.attachResponseInterceptor((error) => {
    func(error);
    return Promise.reject(error);
  }, undefined);
};

const includesTenant = (url, tenant) => {
  return url.indexOf(`//${tenant}`) > -1;
};
