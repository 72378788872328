<template>
  <component :is="componentType" class="card" @click="emit('click')">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  isButton: {
    type: Boolean,
    default: () => false
  }
});

const emit = defineEmits(["click"]);

const componentType = computed(() => {
  if (props.isButton) return "button";
  return "div";
});
</script>

<style lang="scss">
.card {
  align-items: center;
  padding: 10px;
  display: block;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
</style>
