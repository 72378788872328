<template>
  <div class="d-flex align-items-center">
    <i class="far fa-circle-notch spinner" />
    <span v-if="message" class="tw-ml-3">{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    message: { type: String, default: "" }
  }
};
</script>
