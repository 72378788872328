// This is a reference file to help understand the data used in the QuoteInvoice components
// TODO: Make these types once we use Typescript

export class Group {
  type = "group";
  group_id = -1;
  comparison_price_cents = 0;
  comparison_total_cents = 0;
  total_cents = 0;
  unit_price_cents = 0;

  /** @type {LineItemInGroup[]} */
  items_in_group = [];
}

export class LineItem {
  type = "lineItem";
  id = -1;
  item_type = "";
  description = "";
  quantity = 0;
  comparison_price_cents = 0;
  comparison_total_cents = 0;
  unit_price_cents = 0;
  total_cents = 0;
  warranty = "";
  links = {
    line_item_update: "",
    line_item_replace: "",
    line_item_delete: ""
  };
}

export class LineItemInGroup extends LineItem {
  group_id = -1;

  constructor() {
    super();
  }
}

export class Address {
  street = "";
  city = "";
  state = "";
  zip_code = "";
}

export class Financial {
  discount_amount_cents = 0;
  balance_cents = 0;
  late_fee_cents = 0;
  savings_cents = 0;
  sales_tax_cents = 0;
  subtotal_cents = 0;
  total_transactions_cents = 0;
  total_cents = 0;
}

export class Signature {
  kind = "";
  signature_time = "";
  image_url = "";
}

// Quote + Invoice are the same object
export class Quote {
  id = -1;
  editing = false;
  invoice_id = -1;
  discount_attributes = {
    id: -1,
    kind: "",
    percent: 0,
    code: ""
  };
  /** @type {Financial|undefined} */
  financials = {};
  name = "";
  summary = "";
  /** @type {Array.<LineItem|Group>} */
  items_attributes = [];
  job_id = -1;
  quote_type = ""; // two_column
  status = "";
  // optional
  settings = {
    late_fee_per_minute_cents: 0
  };
  // optional
  signature = {
    image_url: ""
  };
  // optional
  links = {
    quote_accept: "",
    quote_decline: "",
    quote_force_accept: "",
    line_item_create: "",
    line_item_reorder: "",
    quote_update: ""
  };
  /** @type {Array.<Note>} */
  notes = [];
  payments = [];
  /** @type {Address} */
  billing_address = {};
  /** @type {Address} */
  service_address = {};
  // optional
  customer = {
    full_name: ""
  };
  actual_membership_program_id = -1;
  actual_membership_program_name = "";
  comparison_membership_program_id = -1;
  comparison_membership_program_name = "";
}

export class Note {
  pinned = false;
  created_at = ""; // date
  updated_at = ""; // date
  comment = "";
}

class CustomizationValue {
  default = "";
  /** @type {string | undefined} */
  value = "";
}

export class Customizations {
  Assets = {
    /** @type {CustomizationValue} */
    dark_logo_url: {}
  };
  General = {
    /** @type {CustomizationValue} */
    phone: {},
    /** @type {CustomizationValue} */
    email_terms_of_service: {},
    /** @type {CustomizationValue} */
    email_license_information: {}
  };
  Tenant = {
    name: "",
    support_email: ""
  };
}

// We are treating Invoices and Quotes the same for now
export const Invoice = Quote;

export class Transaction {
  status = "";
  invoice_id = -1;
  transaction_time = ""; // date
  amount_cents = 0;
  notes = "";
  transaction_type = "";
  /** @type {string|null} */
  card_brand = "";
  check_number = "";
  card_last4 = "";
  ach_account_type = "";
  ach_last4 = "";
  processor_name = "";
  entry_method = "";
}
