<template>
  <div
    ref="row"
    :class="['task-row', { 'category-row': isCategory, 'task-open': open }]"
    data-cy="taskrow"
    @click="toggleOpen"
  >
    <div class="task-name-row">
      <ChevronIcon
        v-if="!isCategory"
        :direction="chevronDirection"
        style="margin-right: 10px"
      />
      <span>
        {{ task.name }}
      </span>
      <span v-if="!isCategory && showPrices" class="price">
        {{ currency(price) }}
      </span>
    </div>
    <TaskDetails
      v-if="open"
      :task="task"
      :show-addons="showAddons"
      :show-upgrades="showUpgrades"
      :replace-mode="!!replaceTaskId"
    />
  </div>
</template>

<script>
import ChevronIcon from "serviceshift-ui/components/General/ChevronIcon.vue";
import TaskDetails from "serviceshift-ui/components/TaskPicker/TaskDetails.vue";
import currency from "serviceshift-ui/shared/src/filters/currency";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  components: {
    TaskDetails,
    ChevronIcon
  },
  props: {
    showAddons: {
      type: Boolean,
      default: true
    },
    showUpgrades: {
      type: Boolean,
      default: true
    },
    showPrices: {
      type: Boolean,
      default: true
    },
    task: {
      type: Object,
      default: () => ({})
    },
    membershipProgramId: {
      type: Number,
      default: null
    },
    replaceTaskId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    isCategory() {
      return this.task.kind === "category";
    },
    chevronDirection() {
      return this.open ? "up" : "down";
    },
    price() {
      const { prices } = this.task;
      if (!prices) return 0;
      // eslint-disable-next-line max-len
      const membership = prices.find(
        (price) => price.membership_program_id === this.membershipProgramId
      );
      return membership ? membership.price_cents / 100 : 0;
    }
  },
  watch: {
    open(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        EventBus.$emit("task-opened", this.task, this.$refs.row);
      }
    }
  },
  mounted() {
    EventBus.$on("upgrade-click", this.shouldOpenTask);
    EventBus.$on("task-opened", this.closeDetails);
    if (this.task.id === this.replaceTaskId) {
      this.open = true;
    }
  },
  beforeDestroy() {
    EventBus.$off("upgrade-click", this.shouldOpenTask);
    EventBus.$off("task-opened", this.closeDetails);
  },
  methods: {
    currency,
    shouldOpenTask(upgrade) {
      if (upgrade.id === this.task.id) {
        this.open = true;
      }
    },
    closeDetails(e) {
      if (this.open && e.id !== this.task.id) {
        this.toggleOpen();
      }
    },
    toggleOpen() {
      if (!this.isCategory) {
        this.open = !this.open;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$taskborder: 1px solid $border-light-color;
$categoryborder: 1px solid $primary-light-accent-color;
.task-row {
  cursor: pointer;
  i {
    font-size: 0.8em;
  }
  &.task-open {
    border: 3px solid $primary-light-accent-color;
    .task-name-row {
      background-color: $pale-blue;
      font-size: 1.1em;
    }
  }
}
.category-row {
  background-color: $tertiary-color !important;
  border-right: $categoryborder;
  border-left: $categoryborder;
  font-weight: bold;
  color: #fff;
  .task-name-row {
    padding: 2px 10px;
  }
}
.task-name-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px 5px 10px;
  span {
    &:first-of-type {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 10px;
    }
    &.price {
      text-align: right;
    }
  }
}
</style>
