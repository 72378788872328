<template>
  <div class="widget-cont">
    <component
      :is="'script'"
      src="https://cdnjs.cloudflare.com/ajax/libs/webcomponentsjs/2.2.10/webcomponents-bundle.js"
      @load="handleLoad"
    ></component>
    <component
      :is="'script'"
      src="https://cdn.sera.tech/widgets/v1/schedule/schedule-appointment.min.js"
      @load="handleLoad"
    ></component>
    <schedule-appointment
      v-if="scriptsLoaded"
      :tenant="subdomain"
      :api-url="apiUrl"
    >
      Loading Schedule Form ...
    </schedule-appointment>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "@/lib/vuex-composition";

// The widget fails to load if it detects an active instance of
// window.google && window.google.maps.places on the page.
// Destroy the active google instance only on
// the widget schedule page
onBeforeMount(() => {
  window.google = null;
});
// Force browser refresh when leaving page to reset google instance
onBeforeUnmount(() => {
  window.location.reload();
});

const store = useStore();
const scriptsLoadedCount = ref(0);
const apiUrl = ref(import.meta.env.VITE_API_BASE_URL);

const subdomain = computed(() => store.state.tenant.subdomain);
const scriptsLoaded = computed(() => scriptsLoadedCount.value === 2);

const handleLoad = () => {
  scriptsLoadedCount.value = scriptsLoadedCount.value + 1;
};
</script>

<style lang="scss" scoped>
.widget-cont {
  margin-bottom: 50px;
}
</style>
