import axios, { AxiosRequestConfig } from "axios";

import ENV from "./env";

const isFullURL = (url) => {
  const urlRegex = new RegExp(/https?:\/\//g);
  return urlRegex.test(url);
};

export const makeRequest = <RESPONSE = any>(
  url,
  config: AxiosRequestConfig = {}
) => {
  // Note: Consumer Portal Compatiblity Fix
  // Makes sure headers work the same as before. If no headers were defined, then
  // accept json, plain, and *. This fixes some requests such as the "Download Errors"
  // on the  Importers flow where the request fails if it accepts only json.
  if (!config.headers) {
    config.headers = {
      Accept: "application/json, text/plain, */*"
    };
  }
  let requestURL = url;
  // Add base domain if url is a path
  if (!isFullURL(url)) {
    requestURL = `${ENV.basePath}${url}`;
  }
  return axios<RESPONSE>(requestURL, config);
};
