import "./styles/tailwind.scss";
import "./styles/main.scss";
import * as components from "./components";

const ServiceShiftUI = {
  install(Vue) {
    // Installs components into Vue instance
    Object.keys(components).forEach((componentName) => {
      const component = components[componentName];
      Vue.component(component.name || componentName, component);
    });
  }
};

export default ServiceShiftUI;

// Automatically install components in existing Vue instance
if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(ServiceShiftUI);
}
