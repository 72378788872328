import API from "serviceshift-ui/api-client";
import {
  AuthRole,
  AuthStrategy
} from "serviceshift-ui/shared/src/typings/auth";
import { Customer } from "serviceshift-ui/shared/src/typings/customer";
import { Ref, ref } from "vue";
import { apiConfig, setRequestHeaders } from "@/lib/api";
import { AuthLocalStorage } from "@/lib/auth";
import { useStore } from "@/lib/vuex-composition";

const storage = {
  loading: ref(true),
  invalidToken: ref(false),
  tokenResponse: ref<any>(null),
  tokenId: ref<string | null>(null)
};

export default function useTokenHook<TokenResponse extends any>() {
  const store = useStore();
  async function authenticate(tokenId: string) {
    apiConfig.ignoreToastNotificationOnError = true;
    storage.tokenId.value = tokenId;
    storage.loading.value = true;
    try {
      const res = await API.makeRequest<{
        id: number;
        authentication_token: string;
        role: AuthRole;
      }>(`/v1/tokens/${tokenId}/auth`);
      storage.tokenResponse.value = res.data;

      // If the user is a customer, we should not override the session
      if (AuthLocalStorage.role !== AuthRole.customer) {
        setRequestHeaders(res.data);
        await store.dispatch(
          "setUser",
          new Customer({
            ...res.data,
            authentication_token: "", // TODO: Leaving this blank till we find a better way
            auth_strategy: AuthStrategy.token
          })
        );
      }
    } catch (e) {
      storage.invalidToken.value = true;
      storage.tokenResponse.value = null;
    }
    apiConfig.ignoreToastNotificationOnError = false;
    storage.loading.value = false;
  }

  return {
    ...storage,
    authenticate,
    tokenResponse: storage.tokenResponse as Ref<TokenResponse | null>
  };
}
