<template>
  <v-navigation-drawer
    v-model="isOpen"
    :temporary="$vuetify.breakpoint.smAndDown"
    :permanent="!$vuetify.breakpoint.smAndDown"
    clipped
    app
  >
    <v-subheader class="secondary white--text overline"
      >Service History</v-subheader
    >
    <v-list nav dense class="pa-0">
      <v-list-item
        v-if="recentAppointments.length === 0"
        link
        two-line
        to="/schedule"
      >
        <v-list-item-content>
          <v-list-item-subtitle
            >We're ready to get started...</v-list-item-subtitle
          >
          <v-list-item-title class="white-space-normal">
            Schedule your first
            {{ tenant.name }} appointment
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <div v-if="recentAppointments.length">
        <div v-for="appointment in recentAppointments" :key="appointment.id">
          <v-list-item
            link
            :to="{ name: 'appointmentSummary', params: { id: appointment.id } }"
          >
            <v-list-item-content>
              <v-list-item-subtitle class="d-flex justify-content-between">
                <div>{{ appointment.date }}</div>
                <div>{{ appointment.status }}</div>
              </v-list-item-subtitle>
              <v-list-item-title class="d-flex justify-content-between">
                <div class="address-label">
                  {{ appointment.address_short }}
                </div>
                <div
                  v-if="appointment.showPayInvoice"
                  class="font-weight-bold"
                  :class="{ 'red--text': hasBalance(appointment) }"
                >
                  {{ balanceDueOrTotal(appointment) }}
                </div>
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="paymentMethodsAvailable && hasBalance(appointment)"
                class="d-flex justify-content-between"
              >
                <router-link
                  :to="{
                    name: 'invoice',
                    params: { id: appointment.invoice_id }
                  }"
                  >View Invoice</router-link
                >
                <button
                  v-if="appointment.showPayInvoice"
                  class="link"
                  @click.prevent="handlePayBalance(appointment.invoice_id)"
                >
                  Pay Balance Due
                </button>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </div>
        <InvoicePaymentModal
          v-model="showPaymentModal"
          :customer="user"
          :invoice-id="selectedInvoiceId"
        />
      </div>

      <v-list-item link :to="{ name: 'invoices' }">
        <v-list-item-content>
          <v-list-item-title>All Invoices</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />

      <v-list-item link :to="{ name: 'quotes' }">
        <v-list-item-content>
          <v-list-item-title>All Quotes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />

      <v-list-item
        v-if="recentAppointments.length"
        link
        :to="{ name: 'serviceHistory' }"
      >
        <v-list-item-content>
          <v-list-item-title>All Appointments</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-subheader class="secondary white--text overline">Account</v-subheader>

    <v-list nav dense class="pa-0">
      <v-list-item link to="/profile">
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />

      <v-list-item link two-line @click="logout">
        <v-list-item-content>
          <v-list-item-title>
            Welcome back,
            {{ user && user.first_name }}!
            <span v-if="user && user.membership"
              ><img class="membership-icon" :src="getMembershipIcon"
            /></span>
          </v-list-item-title>
          <v-list-item-title class="primary--text">Log out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-subheader class="secondary white--text overline">
      {{ domainNameDisplay }}
    </v-subheader>

    <v-list nav dense class="grey lighten-3 pa-0">
      <v-list-item
        link
        :href="tenant.customizations.General.homepage_url"
        target="_blank"
      >
        <v-list-item-content>
          <v-list-item-title>{{ tenant.name }} Home</v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-arrow-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-divider />
      <v-list-item
        two-line
        link
        :href="
          tenant.customizations.General.contact_url ||
          tenant.customizations.General.homepage_url
        "
        target="_blank"
      >
        <v-list-item-content>
          <v-list-item-title>Contact us</v-list-item-title>
          <v-list-item-subtitle>
            {{ tenant.customizations.General.phone }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon>mdi-arrow-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { AppointmentReport } from "serviceshift-ui/shared/src/typings/appointmentReport";
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue";
import { useStore } from "@/lib/vuex-composition";

import InvoicePaymentModal from "@/components/InvoicePaymentModal.vue";
import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";
import AuthMixin from "../../../mixins/auth";

const store = useStore();

const props = defineProps({
  value: {
    type: Boolean
  }
});

const { paymentMethodsAvailable, definePaymentCallbacks } = usePaymentMethods();

const logout = AuthMixin.methods.logout.bind(getCurrentInstance()?.proxy); // Mixins require the scope to be a vue instance
const selectedInvoiceId = ref<string | null>(null);
const isOpen = ref(props.value);
const showPaymentModal = ref(false);

const emit = defineEmits(["input"]);

const user = computed(() => store.state.user);

// Filter out duplicate invoice ids. The appointments array is modified
// to include a "showPayInvoice" boolean.
const recentAppointments = computed(() => {
  const previousInvoiceIds: string[] = [];
  return store.state.recentAppointments.slice(0, 3).map((appointment) => {
    const remappedAppointment = {
      ...appointment,
      showPayInvoice: true
    };
    if (!remappedAppointment.invoice_id) return remappedAppointment;
    const invoiceId = remappedAppointment.invoice_id;
    if (previousInvoiceIds.includes(invoiceId)) {
      remappedAppointment.showPayInvoice = false;
    }
    previousInvoiceIds.push(remappedAppointment.invoice_id);
    return remappedAppointment;
  });
});

const getMembershipIcon = computed(() => {
  return store.state.user?.membership?.icon_url;
});

const domainNameDisplay = computed(() => {
  return store.state.tenant?.customizations.General.homepage_url.replace(
    /((http|https):\/\/)/,
    ""
  );
});

const tenant = computed(() => {
  return store.state.tenant!;
});

onMounted(() => {
  fetchData();
});

watch(
  () => props.value,
  () => {
    isOpen.value = props.value;
  }
);

watch(isOpen, () => {
  emit("input", isOpen.value);
});

definePaymentCallbacks({
  onPaymentSuccess: fetchData
});

function handlePayBalance(invoiceId) {
  selectedInvoiceId.value = invoiceId;
  showPaymentModal.value = true;
}

function fetchData() {
  store.dispatch("fetchRecentAppointments");
}

function hasBalance(appointment: AppointmentReport) {
  return (
    appointment.invoice_id !== "--" && appointment.invoice_balance !== "$0.00"
  );
}

function balanceDueOrTotal(appointment: AppointmentReport) {
  if (hasBalance(appointment)) {
    return appointment.invoice_balance;
  } else if (appointment.invoice_total !== "--") {
    return appointment.invoice_total;
  } else {
    return "";
  }
}
</script>

<style lang="scss" scoped>
.white-space-normal {
  white-space: normal;
}
.v-list-item {
  margin: 0 !important;
}
.address-label {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 165px;
  display: inline-block;
}
.membership-icon {
  width: 15px;
  height: 15px;
}
.v-application a {
  text-decoration: none;
  color: #276fff;
  font-weight: 400;
}
</style>
