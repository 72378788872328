<template>
  <transition :mode="mode" :name="name">
    <slot />
  </transition>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "fade"
    },
    mode: {
      type: String,
      default: "out-in"
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-down-enter-active {
  animation: growDown 250ms ease-in-out forwards;
  transform-origin: top center;
}
.slide-down-leave-active,
.slide-down-leave {
  animation: growUp 150ms ease-in-out reverse;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}
@keyframes growUp {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}
</style>
