<template>
  <div>
    <div v-for="quote in quotes" :key="quote.id">
      <QuoteCard
        :quote="quote"
        presentation-mode
        class="quote-card"
        @quote-approve-click="$emit('quote-approve-click', $event)"
        @quote-card-click="$emit('quote-card-click', $event)"
      >
        <slot name="content" :quote="quote" />
      </QuoteCard>
    </div>
  </div>
</template>

<script>
import QuoteCard from "serviceshift-ui/components/MultipleQuotes/QuoteCard.vue";

export default {
  name: "QuotePresentationList",
  components: {
    QuoteCard
  },
  props: {
    quotes: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.quote-card {
  margin-bottom: 25px;
}
</style>
