<template>
  <div class="d-flex justify-content-end quotes-approval-cont">
    <Signatures
      v-if="selectedQuotes.length"
      class="signature-pad"
      :data="signature"
      event-id="allQuotes"
      tech-mode
      placeholder="Sign Quotes Here"
      approval-text="Quote Approved"
      type="quote"
      style="justify-content: flex-start !important"
    />
    <div class="approval-details">
      <div class="details-label">Total Cost ({{ allQuotes.length }})</div>
      <div class="quotes-total font-bold">
        {{ formatDollars(quotesTotal) }}
      </div>
      <div class="details-label">
        {{ savingsLabel }}
      </div>
      <div
        class="quotes-savings font-bold"
        :class="{ 'negative-savings': quotesSavings < 0 }"
      >
        {{ formatDollars(Math.abs(quotesSavings)) }}
        <img
          v-if="Math.abs(quotesSavings) > 0"
          class="savings-circle"
          :src="HandDrawnCircle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Signature } from "serviceshift-ui/components/QuoteInvoice/interface";
import Signatures from "serviceshift-ui/components/QuoteInvoice/Signatures.vue";
import HandDrawnCircle from "serviceshift-ui/shared/src/assets/hand_drawn_circle.svg";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";
import { dollars } from "serviceshift-ui/shared/src/mixins";

export default {
  name: "QuotesApproval",
  components: { Signatures },
  props: {
    quotes: {
      type: Array,
      default: () => []
    },
    signature: {
      type: Signature,
      default: null
    }
  },
  data() {
    return {
      HandDrawnCircle
    };
  },
  computed: {
    allQuotes() {
      return this.quotes.filter(
        (quote) => quote.selected || quote.status === "approved"
      );
    },
    selectedQuotes() {
      return this.quotes.filter((quote) => quote.selected);
    },
    quotesSavings() {
      return this.allQuotes.reduce((total, quote) => {
        return total + quote.financials.savings_cents;
      }, 0);
    },
    quotesTotal() {
      return this.allQuotes.reduce((total, quote) => {
        return total + quote.financials.total_cents;
      }, 0);
    },
    savingsLabel() {
      return this.quotesSavings >= 0
        ? "Total Membership Savings"
        : "You could have saved";
    }
  },
  mounted() {
    EventBus.$on("signature-click", this.signatureClick);
  },
  beforeDestroy() {
    EventBus.$off("signature-click", this.signatureClick);
  },
  methods: {
    formatDollars(cents) {
      return dollars(cents);
    },
    signatureClick(eventId) {
      if (eventId === "allQuotes") {
        const selectedIds = this.selectedQuotes.map((quote) => quote.id);
        this.$emit("signature-click", selectedIds);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.signature-pad {
  flex-grow: 1;
  justify-content: flex-start !important;
}
.quotes-approval-cont {
  background-color: #fff;
  box-shadow: $box-shadow;
  padding: 10px 15px;
}
.approval-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 1em;
}
.quotes-total {
  font-size: 1.2em;
  margin-bottom: 5px;
}
.quotes-savings {
  color: $success-color;
  position: relative;
  margin: 10px 0;
  .savings-circle {
    position: absolute;
    width: 100px;
    top: -7px;
    right: -14px;
    -webkit-animation: puff-in-center 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
      1s both;
    animation: puff-in-center 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }
}
.details-label {
  font-size: 0.7em;
  font-weight: normal;
  white-space: nowrap;
}
</style>
