<template>
  <div :class="['accordion-item', { expanded: isOpen }]">
    <div class="acc-item-header" @click="toggleOpen">
      <slot name="header" :item="item" />
      <i class="toggle-icon fas fa-chevron-down" />
    </div>
    <div ref="content" class="acc-item-content">
      <div class="acc-item-inner-content">
        <slot name="content" :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    item: {
      type: Object,
      default: null
    },
    selectedItem: {
      type: Object,
      default: null
    }
  },
  computed: {
    isOpen() {
      if (!this.selectedItem) {
        return false;
      }
      return JSON.stringify(this.item) === JSON.stringify(this.selectedItem);
    }
  },
  watch: {
    isOpen(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.expandSection();
        } else {
          this.collapseSection();
        }
      }
    }
  },
  mounted() {
    if (this.isOpen) {
      this.expandSection();
    }
  },
  methods: {
    toggleOpen(e) {
      if (e.target.hasAttribute("data-no-toggle")) return;

      this.$emit("expand-item", this.isOpen ? null : this.item);
    },
    collapseSection() {
      // get the height of the element's inner content, regardless of its actual size
      const sectionHeight = this.$refs.content.scrollHeight;

      // temporarily disable all css transitions
      const elementTransition = this.$refs.content.style.transition;
      this.$refs.content.style.transition = "";

      // on the next frame (as soon as the previous style change has taken effect),
      // explicitly set the element's height to its current pixel height, so we
      // aren't transitioning out of 'auto'
      requestAnimationFrame(() => {
        this.$refs.content.style.height = `${sectionHeight}px`;
        this.$refs.content.style.transition = elementTransition;

        // on the next frame (as soon as the previous style change has taken effect),
        // have the element transition to height: 0
        requestAnimationFrame(() => {
          this.$refs.content.style.height = `${0}px`;
        });
      });
    },
    expandSection() {
      // get the height of the element's inner content, regardless of its actual size
      // have the element transition to the height of its inner content
      this.$refs.content.style.height = `${this.$refs.content.scrollHeight}px`;
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-item {
  border-bottom: 1px solid #d3d7d9;
  &:last-child {
    border-bottom: none;
  }
  .toggle-icon {
    transition: transform 200ms ease;
    transform-origin: center;
  }
  &.expanded {
    .toggle-icon {
      transform: rotate(180deg);
    }
  }
  .acc-item-header {
    padding: 15px 0;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .toggle-icon {
      color: #276fff;
    }
  }
  .acc-item-content {
    transition: height 200ms ease-in-out;
    height: 0;
    overflow: hidden;
    .acc-item-inner-content {
      padding-bottom: 15px;
    }
  }
}
</style>
