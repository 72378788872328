<template>
  <button
    :class="[
      'btn btn-sm',
      className,
      { disabled },
      { 'text-primary': isIconButton }
    ]"
    :disabled="disabled"
    v-on="$listeners"
  >
    <div v-if="!saving">
      <i v-if="icon && iconPosition !== 'right'" :class="[`${icon} tw-mr-1`]" />
      <slot>{{ text }}</slot>
      <i v-if="icon && iconPosition === 'right'" :class="[`${icon} tw-ml-1`]" />
    </div>
    <Spinner v-else class="saving-spinner" />
  </button>
</template>

<script>
import Spinner from "../General/Spinner.vue";

export default {
  components: {
    Spinner
  },
  props: {
    text: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "primary"
    },
    outline: {
      type: Boolean,
      default: false
    },
    iconPosition: {
      type: String,
      default: "left"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className() {
      if (this.isIconButton) {
        return "btn-plain btn-link btn-icon";
      }
      let className = "btn-";
      if (this.outline) {
        className += "outline-";
      }
      return className + this.type;
    },
    isIconButton() {
      return this.icon && !this.text && !this.$slots.default;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 24px !important;
  text-transform: none;
  overflow: visible;
  -webkit-appearance: button;
  &-sm {
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    padding: 0.25rem 0.75rem;
  }
  &-primary {
    color: $white;
    background-color: $primary-color;
    border-color: $primary-color;
    box-shadow: 0 3px 6px 0px #9ca4aa;
  }
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  &.disabled {
    opacity: 0.6;
  }
}

.saving-spinner {
  padding: 5px 30px;
}
.btn-icon {
  border: none;
  padding: 0;
}
</style>
