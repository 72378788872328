<template>
  <div>
    <span class="overline block mt-2 font-bold text-left">Primary Phone</span>
    <div
      class="d-flex align-start full-width primary-row"
      :class="{
        'flex-column': $vuetify.breakpoint.xs
      }"
    >
      <div
        v-if="primaryPhone"
        class="d-flex align-start flex-2 mr-2"
        :class="{
          'phone-input-field': !$vuetify.breakpoint.xs
        }"
      >
        <v-text-field
          v-mask="'(###) ###-####'"
          outlined
          :label="getLabel(primaryPhone)"
          hint="ex: (555) 555-5555"
          :rules="allPhoneRules"
          class="flex-grow-1 full-width"
          :value="primaryPhone.phone_number"
          @input="updatePhoneNumber($event, primaryPhone)"
        />
        <v-select
          outlined
          label="Type"
          :items="types"
          class="phone-types"
          :value="primaryPhone.type"
          @input="updatePhoneType($event, primaryPhone)"
        ></v-select>
      </div>
      <v-text-field
        v-if="primaryPhone"
        data-test-id="primary-phone-input"
        outlined
        label="Label"
        hint="ex: Primary Phone"
        class="phone-label-field"
        :value="primaryPhone.label"
        :class="{
          'full-width': $vuetify.breakpoint.xs
        }"
        @input="updatePhoneLabel($event, primaryPhone)"
      />
    </div>
    <span class="overline block mt-2 font-bold text-left"
      >Additional Phone(s)</span
    >
    <div class="phones-cont">
      <div
        v-for="phone in additionalPhones"
        :key="phone.id"
        class="d-flex align-start"
      >
        <div
          class="d-flex align-start full-width"
          :class="{
            'flex-column': $vuetify.breakpoint.xs
          }"
        >
          <div
            class="d-flex align-start full-width mr-2 flex-2"
            :class="{ 'phone-input-field': !$vuetify.breakpoint.xs }"
          >
            <v-text-field
              v-mask="'(###) ###-####'"
              outlined
              :label="getLabel(phone)"
              hint="ex: (555) 555-5555"
              :rules="allPhoneRules"
              class="flex-grow-1 full-width"
              :value="phone.phone_number"
              @input="updatePhoneNumber($event, phone)"
            />
            <v-select
              outlined
              label="Type"
              :items="types"
              class="phone-types"
              :value="phone.type"
              @input="updatePhoneType($event, phone)"
            ></v-select>
          </div>
          <v-text-field
            outlined
            label="Label"
            hint="ex: Primary"
            class="phone-label-field"
            :value="phone.label"
            :class="{
              'full-width': $vuetify.breakpoint.xs
            }"
            @input="updatePhoneLabel($event, phone)"
          />
        </div>
        <ActionsMenu
          :actions="actions"
          class="action-menu-icon"
          @delete="handleDelete(phone)"
          @set-primary="setPrimary(phone)"
        />
      </div>
    </div>
    <div class="d-flex">
      <v-btn color="primary" rounded outlined @click="addPhone">
        <v-icon left>mdi-plus</v-icon>Add Phone
      </v-btn>
    </div>
  </div>
</template>

<script>
import { generateUID } from "@/lib/helpers";

import ValidationRules from "@/mixins/validationRules";
export default {
  name: "PhoneFields",
  mixins: [ValidationRules],
  props: {
    additionalRules: {
      type: Function,
      default: () => true
    },
    showSetPrimary: {
      type: Boolean,
      default: true
    },
    phones: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      types: [
        {
          text: "Mobile",
          value: "mobile"
        },
        {
          text: "Home",
          value: "home"
        },
        {
          text: "Work",
          value: "work"
        }
      ]
    };
  },
  computed: {
    allPhoneRules() {
      return [...this.requiredRules, this.additionalRules];
    },
    formData: {
      get() {
        return [...this.phones].sort((a) => (a.primary ? -1 : 0));
      },
      set(val) {
        this.$emit("update", val);
      }
    },
    primaryPhone() {
      return this.formData.find((phone) => phone.primary) || this.formData[0];
    },
    additionalPhones() {
      if (this.formData.find((phone) => phone.primary)) {
        return this.formData.filter((phone) => !phone.primary);
      }
      return this.formData.slice(1);
    },
    actions() {
      const actions = [
        {
          label: "Delete",
          icon: "fa-regular fa-trash-can",
          eventName: "delete",
          className: "action-menu-item"
        }
      ];
      if (this.showSetPrimary) {
        actions.push({
          label: "Primary",
          icon: "fa-solid fa-star",
          eventName: "set-primary",
          className: "action-menu-item"
        });
      }
      return actions;
    }
  },
  methods: {
    addPhone() {
      this.formData = [
        ...this.formData,
        {
          id: generateUID(),
          new: true,
          phone_number: "",
          type: "",
          primary: false
        }
      ];
    },
    getLabel(phone) {
      return phone.primary ? "Primary Phone" : "Additional Phone";
    },
    handleDelete(data) {
      this.formData = this.formData.filter((phone) => phone.id !== data.id);
    },
    setPrimary(phoneData) {
      this.formData = this.formData.map((phone) => ({
        ...phone,
        primary: phone.id === phoneData.id
      }));
    },
    updatePhoneNumber(value, phone) {
      this.formData = this.formData.map((_phone) => {
        if (phone.id === _phone.id) {
          return {
            ..._phone,
            phone_number: value
          };
        }
        return _phone;
      });
    },
    updatePhoneType(value, phone) {
      this.formData = this.formData.map((_phone) => {
        if (phone.id === _phone.id) {
          return {
            ..._phone,
            type: value
          };
        }
        return _phone;
      });
    },
    updatePhoneLabel(value, phone) {
      this.formData = this.formData.map((_phone) => {
        if (phone.id === _phone.id) {
          return {
            ..._phone,
            label: value
          };
        }
        return _phone;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.phone-input-field {
  //width: 49% !important;
  flex: 2;
}
.phones-cont {
  padding-top: 5px;
  max-height: 300px;
  overflow: scroll;
}
.phone-types {
  width: 175px;
}
::v-deep(.action-menu-trigger) {
  margin-top: -5px;
  padding: 20px !important;
  cursor: pointer;
}

.phone-label-field {
  min-width: 100px;
  flex: 1;
}
.primary-row {
  padding-right: 45px;
}
</style>

<style>
.action-menu-item {
  font-size: 1.2em;
}
</style>
