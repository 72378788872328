<template>
  <div style="height: 100%">
    <div v-if="treeData.fetched_at" ref="container" class="task-picker">
      <Header ref="header" :crumbs="crumbs" :categories="subCategories" />
      <TaskTable
        ref="table"
        :membership-program-id="membershipProgramId"
        :tasks="tasks"
        :history="history"
        :show-addons="showAddons"
        :show-prices="showPrices"
        :show-upgrades="showUpgrades"
        :replace-task-id="replaceTaskId"
        :render-limit="renderLimit"
      />
    </div>
    <div v-else class="tasks-loading">Loading Tasks...</div>
  </div>
</template>

<script>
import Header from "serviceshift-ui/components/TaskPicker/Header.vue";
import TaskTable from "serviceshift-ui/components/TaskPicker/TaskTable.vue";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";
import {
  addParentNodes,
  findMatches,
  findNode,
  parentNodes
} from "serviceshift-ui/shared/src/lib/tree";
import { nextTick } from "vue";

const HEADER_PADDING = 21;

export default {
  name: "TaskPicker",
  components: {
    Header,
    TaskTable
  },
  props: {
    showAddons: {
      type: Boolean,
      default: true
    },
    showUpgrades: {
      type: Boolean,
      default: true
    },
    showPrices: {
      type: Boolean,
      default: true
    },
    search: {
      type: String,
      default: ""
    },
    tree: {
      type: Object,
      default: () => ({})
    },
    membershipProgramId: {
      type: Number,
      default: null
    },
    techApp: {
      type: Boolean,
      default: false
    },
    replaceTaskId: {
      type: Number,
      default: null
    },
    renderLimit: {
      type: Number,
      default: 800
    }
  },
  data() {
    return {
      internalSearch: "",
      category: null,
      crumbs: [],
      history: []
    };
  },
  computed: {
    treeData() {
      let tree = this.tree;
      if (this.$listeners["item-filter"] && tree) {
        const getNodes = (result, item, itemParent) => {
          if (this.$listeners["item-filter"](item, itemParent)) {
            result.push({
              ...item,
              children: item.children
                ? item.children.reduce(
                    (result, childItem) =>
                      getNodes(result, childItem, itemParent || item),
                    []
                  )
                : undefined
            });
            return result;
          }
          return result;
        };
        tree = {
          ...tree,
          children: tree.children.reduce(
            (result, item) => getNodes(result, item),
            []
          )
        };
      }
      return addParentNodes(tree);
    },
    currentCategory() {
      if (!this.category) {
        return {};
      }
      return (
        findNode(this.treeData, this.category.kind, this.category.id) || {}
      );
    },
    subCategories() {
      // don't show subcategories for Memberships Programs or Warranties
      return this.currentCategory.name &&
        (this.currentCategory.name.includes("Membership") ||
          this.currentCategory.name.includes("Warranty"))
        ? []
        : this.currentCategory.children;
    },
    tasks() {
      return findMatches(this.currentCategory, this.internalSearch);
    }
  },
  watch: {
    currentCategory(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.$emit("category-change", newValue);
        this.updateCrumbs(newValue);
        this.setTableHeight();
      }
    },
    search(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalSearch = newValue;
      }
    },
    tree(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setTableHeight();
      }
    }
  },
  mounted() {
    this.setTableHeight();
    EventBus.$on("header-toggle", this.setTableHeight);
    EventBus.$on("category-click", this.setCategory);
    EventBus.$on("upgrade-click", this.handleUpgradeClick);
    EventBus.$on("add-task", this.addTask);
    EventBus.$on("replace-task", this.replaceTask);
    EventBus.$on("add-addon", this.addAddon);
    EventBus.$on("task-opened", this.handleTaskOpened);
    EventBus.$on("get-history", this.historyResponse);
    EventBus.$on("get-tech-app", this.techAppResponse);
    this.setCategory({
      id: null,
      kind: "root",
      name: "All"
    });
  },
  beforeDestroy() {
    EventBus.$off("header-toggle", this.setTableHeight);
    EventBus.$off("category-click", this.setCategory);
    EventBus.$off("upgrade-click", this.handleUpgradeClick);
    EventBus.$off("add-task", this.addTask);
    EventBus.$off("replace-task", this.replaceTask);
    EventBus.$off("add-addon", this.addAddon);
    EventBus.$off("task-opened", this.handleTaskOpened);
    EventBus.$off("get-history", this.historyResponse);
    EventBus.$off("get-tech-app", this.techAppResponse);
  },
  methods: {
    addTask(allItems, task, addons, doneCallback) {
      this.$emit("add-task", allItems, task, addons, doneCallback);
    },
    replaceTask(allItems, task, addons, doneCallback) {
      this.$emit("replace-task", allItems, task, addons, doneCallback);
    },
    addAddon(e) {
      this.$emit("add-addon", e);
    },
    handleTaskOpened(task, ref) {
      // Remove task from existing history
      const uniqHistory = this.history.filter((item) => item.id !== task.id);
      // store in history
      this.history = [task].concat(uniqHistory);
      // scroll to task
      this.scrollToTask(ref);
    },
    handleUpgradeClick(upgrade) {
      this.internalSearch = "";
      this.$emit("search-cleared");
      if (this.currentCategory.id !== upgrade.category_id) {
        const category = findNode(
          this.treeData,
          "category",
          upgrade.category_id
        );
        if (category) {
          this.setCategory(category);
          nextTick(() => {
            // Re-emit event after updating filters
            EventBus.$emit("upgrade-click", upgrade);
          });
        }
      }
    },
    historyResponse() {
      EventBus.$emit("get-history-response", this.history);
    },
    setCategory(category) {
      this.category = category;
    },
    scrollToTask(ref) {
      nextTick(() => {
        if (
          this.$refs.header &&
          this.$refs.table &&
          this.$refs.table.$refs.table
        ) {
          const hdims = this.$refs.header.$el.getBoundingClientRect();
          this.$refs.table.$refs.table.scrollTop =
            ref.offsetTop - hdims.height - HEADER_PADDING;
        }
      });
    },
    setTableHeight() {
      nextTick(() => {
        if (this.$refs.container && this.$refs.header && this.$refs.table) {
          const headerDims = this.$refs.header.$el.getBoundingClientRect();
          const pageDims = this.$refs.container.getBoundingClientRect();
          this.$refs.table.$el.style.height = `${
            pageDims.height - headerDims.height - HEADER_PADDING
          }px`;
        }
      });
    },
    techAppResponse() {
      EventBus.$emit("get-tech-app-response", this.techApp);
    },
    updateCrumbs(newCategory) {
      const parents = parentNodes(newCategory);
      this.crumbs = [...parents, newCategory]
        .filter((node) => node.kind !== "task")
        .map((crumb) => ({
          ...crumb,
          label: crumb.name
        }));
    }
  }
};
</script>

<style lang="scss" scoped>
.tasks-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.task-picker {
  height: 100%;
  position: relative;
}
</style>
