<template>
  <div
    :class="['equipment-request-icon', equipmentStatus]"
    @click="$emit('click')"
  >
    <img :src="statusIcon" class="request-icon" />
  </div>
</template>

<script>
import EquipmentDocumentedIcon from "serviceshift-ui/shared/src/assets/equipment-documented.svg";
import EquipmentOptionalIcon from "serviceshift-ui/shared/src/assets/equipment-optional.svg";
import EquipmentRequestedIcon from "serviceshift-ui/shared/src/assets/equipment-requested.svg";

const STATUSES = {
  FULFILLED: "fulfilled",
  UNFULFILLED: "unfulfilled",
  OPTIONAL: "optional"
};

export default {
  name: "EquipmentRequestIcon",
  props: {
    status: {
      type: String, // one of STATUSES
      default: "optional",
      required: true
    }
  },
  data() {
    return {
      STATUSES
    };
  },
  computed: {
    statusIcon() {
      if (this.equipmentStatus.fulfilled) return EquipmentDocumentedIcon;
      if (this.equipmentStatus.unfulfilled) return EquipmentRequestedIcon;
      return EquipmentOptionalIcon;
    },
    equipmentStatus() {
      return {
        fulfilled: this.status === STATUSES.FULFILLED,
        unfulfilled: this.status === STATUSES.UNFULFILLED,
        optional: ![STATUSES.FULFILLED, STATUSES.UNFULFILLED].includes(
          this.status
        ),
        pointer: this.hasClick
      };
    },
    hasClick() {
      return this.$listeners && this.$listeners.click;
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-request-icon {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}
.fulfilled {
  position: relative;
  top: -2px;
}
</style>
