<template>
  <div class="line-item-select">
    <Select
      v-model="selectedItems"
      multiple
      search
      :options="filteredItems"
      :render-limit="renderLimit"
      :label="noLabel ? undefined : label"
      placeholder="Search line item name or category..."
      auto-width
      body-render
    >
      <template #item-row="{ option }">
        <div :class="{ 'tw-line-through': option.archived || !option.active }">
          {{ option.displayLabel }}
          <span v-if="option.categoryName" class="tw-text-xs">
            <em>({{ option.categoryName }})</em>
          </span>
        </div>
      </template>
    </Select>
  </div>
</template>

<script>
import Select from "serviceshift-ui/components/Inputs/Select.vue";
import {
  addParentNodes,
  findMatches,
  findNode
} from "serviceshift-ui/shared/src/lib/tree";

export default {
  name: "LineItemSelect",
  components: {
    Select
  },
  props: {
    noLabel: {
      type: Boolean,
      default: () => false
    },
    items: {
      type: Object,
      default: () => ({})
    },
    renderLimit: {
      type: Number,
      default: 100
    },
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: "Line Items"
    }
  },
  computed: {
    selectedItems: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    itemsArray() {
      let tree = this.items;
      if (this.$listeners["item-filter"] && tree) {
        const getNodes = (result, item, itemParent) => {
          if (this.$listeners["item-filter"](item, itemParent)) {
            result.push({
              ...item,
              children: item.children
                ? item.children.reduce(
                    (result, childItem) =>
                      getNodes(result, childItem, itemParent || item),
                    []
                  )
                : undefined
            });
            return result;
          }
          return result;
        };
        tree = {
          ...tree,
          children: tree.children.reduce(
            (result, item) => getNodes(result, item),
            []
          )
        };
      }
      return findNode(addParentNodes(tree), "root", null) || {};
    },
    filteredItems() {
      // Convert task tree to a flattened array
      return findMatches(this.itemsArray, "")
        .sort((a, b) => {
          if ((a.archived || !a.active) && !b.archived && b.active) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 1;
        })
        .reduce((rows, item) => {
          // Prepend the item kind so the API knows how to find the item
          // One of t_ (Task), c_ (Task Category), d_ (Department),
          // mp_ (MembershipProgram), wp_ (WarrantyProgram)
          const kindLabel = item.kind
            .replace(/_/, " ")
            .split(" ")
            .map((word) => word[0])
            .join("");
          rows.push({
            label: `${item.name} ${item.parentNode.flattened_name}`,
            displayLabel: item.name,
            value: `${kindLabel}_${item.id}`,
            categoryName: item.parentNode.flattened_name,
            ...item
          });
          return rows;
        }, []);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .non-selectable {
  padding: 0 !important;
  border-bottom: 1px solid $medium-color;
  &.results-count-text {
    border-bottom: none;
    padding: 3px 10px !important;
  }
}
</style>
