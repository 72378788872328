import debounce from "lodash/debounce";
import minBy from "lodash/minBy";
import orderBy from "lodash/orderBy";
import { Quote } from "serviceshift-ui/shared/src/typings/quote";
import { computed, ref, watch } from "vue";
import provideHook from "@/lib/provideHook";
import { useStore } from "@/lib/vuex-composition";

import {
  FinancingSummaryOffers,
  Lenders,
  VendorLoanOffers
} from "../interface";

function buildOfferSummary(
  quote: Quote | null,
  vendorLoanOffers: VendorLoanOffers
): FinancingSummaryOffers {
  const offers = orderBy(
    vendorLoanOffers.map((vendorOffer) => {
      return {
        lender: vendorOffer.lender,
        paymentsAsLowAsCents: minBy(
          vendorOffer.loan_offers,
          (offer) => offer.payment_amount_cents
        )!.payment_amount_cents
      };
    }),
    (offer) => offer.paymentsAsLowAsCents
  );
  if (quote?.payments_as_low_as_cents) {
    offers.push({
      lender: "other",
      paymentsAsLowAsCents: quote.payments_as_low_as_cents
    });
  }
  return offers;
}

export const useFinancingAvailable = provideHook(() => {
  const loanOfferRequestId = ref(0);
  const selectedLoanOfferId = ref<number>();
  const quoteTotalAmountThresholdInCents = 0;
  const store = useStore();
  const loadingPrograms = ref(false);
  const firstTimeLoading = ref(true);
  const vendorLoanOffers = ref<VendorLoanOffers>([]);
  const vendorLoanOffersAtZeroDown = ref<VendorLoanOffers>([]);
  const financingOffersEnabled = ref(false);
  const paymentsAsLowAsEnabled = ref(false);
  const quote = ref<Quote | null>(null);
  const lenders = ref(Lenders);
  const totalDownPaymentCents = ref(0);

  const isJoblessQuote = computed(() => {
    return quote.value?.job_id === null;
  });

  const joblessFinancing = computed(() => {
    return quote.value?.jobless_financing === true;
  });

  const showFinancingAvailable = computed(() => {
    if (!financingOffersEnabled.value) {
      return false;
    }

    return !isJoblessQuote.value || joblessFinancing.value;
  });

  // Used to handle the display of "Payments as low as" with a $0 or no display
  const totalDownPaymentFromCurrentResultsCents = ref(
    totalDownPaymentCents.value
  );
  const totalOffersAtZeroDown = ref(0);

  const showSummary = computed(() => {
    return quote.value?.status !== "draft";
  });

  const offerSummary = computed<FinancingSummaryOffers>(() => {
    if (firstTimeLoading.value) return [];
    return buildOfferSummary(quote.value, vendorLoanOffers.value);
  });

  const offerSummaryAtZeroDown = computed<FinancingSummaryOffers>(() => {
    if (firstTimeLoading.value) return [];
    return buildOfferSummary(quote.value, vendorLoanOffersAtZeroDown.value);
  });

  const selectedLoanOffer = computed(() => {
    const allLoanOffers = vendorLoanOffers.value
      .map((val) => val.loan_offers)
      .flat();
    return allLoanOffers.find(
      (loanOffer) => loanOffer.id === selectedLoanOfferId.value
    )!;
  });

  // Quick check to see if we should fetch loans or show the additional financing sections
  const totalAmountMeetsThreshold = computed(() => {
    return Boolean(
      quote.value?.financials?.total_cents &&
        quote.value.financials.total_cents > quoteTotalAmountThresholdInCents
    );
  });

  const totalOffers = computed(() => {
    return vendorLoanOffers.value.reduce((acc, val) => {
      return acc + val.loan_offers.length;
    }, 0);
  });

  async function init(params: {
    financingOffersEnabled: boolean;
    paymentsAsLowAsEnabled: boolean;
    quote: typeof quote.value;
  }) {
    financingOffersEnabled.value = params.financingOffersEnabled;
    paymentsAsLowAsEnabled.value = params.paymentsAsLowAsEnabled;
    quote.value = params.quote;
    // If we only have paymentsAsLowAsEnabled but no financingOffers, skip the loading
    if (firstTimeLoading.value) {
      if (!financingOffersEnabled.value) {
        firstTimeLoading.value = false;
        return;
      }
    }
  }

  async function fetchLoanOffersForQuote() {
    loadingPrograms.value = true;
    const currentTotalDownPaymentCents = totalDownPaymentCents.value;
    if (financingOffersEnabled.value) {
      try {
        loanOfferRequestId.value++;
        const currentRequestId = loanOfferRequestId.value;
        const loanOffers = await store.dispatch("fetchLoanOffersForQuote", {
          customerId: quote.value!.customer!.customer_id,
          quoteId: quote.value!.id,
          downPaymentAmountCents: currentTotalDownPaymentCents
        });
        // Prevent the wrong results being displayed to the user
        // Not super important anymore since calculations are handled on the front end
        if (currentRequestId !== loanOfferRequestId.value) return;

        // Keep track of the total downpayment used on the results
        totalDownPaymentFromCurrentResultsCents.value =
          currentTotalDownPaymentCents;
        vendorLoanOffers.value = loanOffers;
      } catch (e) {
        vendorLoanOffers.value = [];
      }
    } else {
      vendorLoanOffers.value = [];
    }

    // If we are fetching with a down payment of $0, we need to store the number of results
    if (currentTotalDownPaymentCents === 0) {
      totalOffersAtZeroDown.value = totalOffers.value;
      vendorLoanOffersAtZeroDown.value = vendorLoanOffers.value;
    }
    loadingPrograms.value = false;
    firstTimeLoading.value = false;
  }
  const debouncedFetchLoanOffersForQuote = debounce(
    fetchLoanOffersForQuote,
    800
  );

  async function handleAddPaymentsAsLowAsOffer(paymentsAsLowAsCents: number) {
    return store.dispatch("updateQuote", {
      ...quote.value,
      payments_as_low_as_cents: paymentsAsLowAsCents
    });
  }

  async function handleRemovePaymentsAsLowAsOffer() {
    return store.dispatch("updateQuote", {
      ...quote.value,
      payments_as_low_as_cents: null
    });
  }

  // Only refresh the quote if we have a valid ID and loan programs are enabled. If the
  // total amount of the quote doesn't meet the threshold, the fetch programs will
  // return an empty array and not make an API call.
  watch(
    () =>
      [
        quote.value?.id,
        quote.value?.financials?.total_cents,
        paymentsAsLowAsEnabled.value,
        financingOffersEnabled.value,
        totalAmountMeetsThreshold.value,
        totalDownPaymentCents.value
      ].join(""), // This is a hack to create a hash of the values
    () => {
      if (
        quote.value?.id &&
        financingOffersEnabled.value &&
        totalAmountMeetsThreshold.value &&
        totalDownPaymentCents.value >= 0
      ) {
        if (firstTimeLoading.value) return fetchLoanOffersForQuote();
        debouncedFetchLoanOffersForQuote();
      }
    },
    { immediate: true }
  );

  return {
    selectedLoanOfferId,
    selectedLoanOffer,
    totalDownPaymentCents,
    totalAmountMeetsThreshold,
    handleRemovePaymentsAsLowAsOffer,
    handleAddPaymentsAsLowAsOffer,
    fetchLoanOffersForQuote,
    offerSummary,
    offerSummaryAtZeroDown,
    showSummary,
    vendorLoanOffers,
    vendorLoanOffersAtZeroDown,
    loadingPrograms,
    init,
    lenders,
    quote,
    paymentsAsLowAsEnabled,
    financingOffersEnabled,
    firstTimeLoading,
    totalDownPaymentFromCurrentResultsCents,
    totalOffers,
    showFinancingAvailable,
    totalOffersAtZeroDown
  };
});
