<template>
  <div class="upgrade-addon-card">
    <span class="upgrade-title">Add-ons</span>
    <div v-if="tasks.length" class="upgrade-addon-list">
      <div v-for="task in tasks" :key="task.id" class="task-item">
        <label class="addon-label">
          <input
            class="addon-checkbox"
            type="checkbox"
            :checked="isSelected(task)"
            @change="$emit('update-addons', $event, task)"
          />
          <span>{{ task.name }}</span>
        </label>
      </div>
    </div>
    <div v-else class="text-center task-item full-width no-recommendations">
      No recommendations available
    </div>
  </div>
</template>

<script>
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  props: {
    tasks: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick(e, task) {
      e.stopPropagation();
      if (this.title === "Upgrades") {
        EventBus.$emit("upgrade-click", task);
      } else {
        EventBus.$emit("add-addon", task);
      }
    },
    isSelected(addon) {
      return this.selected.some((a) => a.id === addon.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.addon-label {
  margin-bottom: 0;
  width: 100%;
  display: inline-flex;
  align-items: center;
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}
.upgrade-addon-card {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: $box-shadow;
}
.upgrade-addon-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $primary-light-accent-color;
  border-radius: 0 0 5px 5px;
}
.upgrade-title {
  display: block;
  text-align: center;
  background-color: $medium-color;
  font-weight: bold;
  padding: 2px;
  border-radius: 5px 5px 0 0;
}
.task-item {
  padding: 5px;
  color: $primary-color;
  width: 100%;
  &:nth-of-type(even) {
    border-left: none;
  }
  .addon-checkbox {
    margin-right: 5px;
  }
}
.muted-gray {
  color: $gray;
  font-style: italic;
  text-align: center;
}
.no-recommendations {
  border: $border;
  border-radius: 0 0 5px 5px;
}
</style>
