<template>
  <div class="currencyInput">
    <label v-if="label || helperText" for="money">
      <strong v-if="label">{{ label }}</strong>
      <span v-if="helperText" class="helperText">{{ helperText }}</span>
    </label>
    <div class="inputWrapper">
      <Money id="money" v-model="amount" v-bind="config" class="money-input" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Money } from "v-money2";
import { computed, ref } from "vue";

const props = defineProps({
  helperText: {
    type: String,
    default: ""
  },
  label: {
    type: String,
    default: ""
  },
  name: {
    type: String,
    default: ""
  },
  type: {
    type: String,
    default: ""
  },
  value: {
    type: [Number, String],
    default: ""
  },
  placeholder: {
    type: String,
    default: ""
  }
});

const emit = defineEmits(["input"]);

const config = ref({
  decimal: ".",
  thousands: ",",
  prefix: "$",
  suffix: "",
  precision: 2,
  masked: false
});

const amount = computed({
  get() {
    return props.value;
  },
  set(v) {
    emit("input", v);
  }
});
</script>

<style scoped lang="scss">
input {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  line-height: 20px;
  padding: 8px 0 8px;
  max-width: 100%;
  min-width: 0px;
  width: 100%;
  outline: none;
}
label {
  display: block;
  margin-bottom: 3px;
  .title {
    font-weight: bold;
  }
  > * {
    display: block;
  }
}
.helperText {
  font-size: 0.8rem;
}
.inputWrapper {
  display: flex;
  padding: 0 12px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 3px;
}
</style>
