<template>
  <div v-if="showInvoice" class="invoiceBalance">
    <span class="heading">
      You have an unpaid balance - Invoice #{{ invoice.invoice_id }}
    </span>
    <div class="balanceAndTotal">
      <div class="balance">
        <div class="amount">
          {{ dollars(invoice.financials.balance_cents) }}
        </div>
        <div class="desc">Balance due</div>
      </div>
      <div class="divider"></div>
      <div class="total">
        <div class="amount">{{ dollars(invoice.financials.total_cents) }}</div>
        <div class="desc">Invoice Total</div>
      </div>
    </div>
    <div v-if="$slots.actions" class="actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Invoice } from "serviceshift-ui/shared/src/typings/invoice";
import { computed } from "vue";

import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";

const { paymentMethodsAvailable } = usePaymentMethods();

const props = defineProps({
  invoice: {
    type: Object as () => Invoice,
    default: () => null
  }
});

const showInvoice = computed(() => {
  return (
    paymentMethodsAvailable.value &&
    props.invoice &&
    props.invoice.financials.balance_cents > 0
  );
});
</script>

<style lang="scss" scoped>
.invoiceBalance {
  margin-top: 15px;
  background: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px;
  display: inline-block;
}
.divider {
  height: 38px;
  width: 1px;
  background: #d3d7d9;
}
.heading {
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 7px;
}
.balanceAndTotal {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}
.amount {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
}
.balance .amount {
  color: #ef2828;
}
.desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
}
.actions {
  margin-top: 10px;
  padding-top: 7px;
  border-top: 1px solid #d3d7d9;
  font-size: 14px;
  display: flex;
  justify-content: center;
}
</style>
