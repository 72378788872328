<template>
  <table class="table" :class="[{ lightTheme }]">
    <slot />
  </table>
</template>

<script>
export default {
  name: "Table",
  props: {
    lightTheme: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  box-shadow: 0 0 12px -6px #b9b9b9;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  thead {
    th {
      color: #fff;
      background-color: $tertiary-color;
      &:first-of-type {
        border-radius: 5px 0 0;
      }
      &:last-of-type {
        border-radius: 0 5px 0 0;
      }
    }
  }
  tbody {
    width: 100%;
    border: 1px solid $border-light-color;
    border-top: none;
    box-sizing: border-box;
  }
  tr {
    width: 100%;
    background-color: white;
    &:nth-child(even) {
      background-color: $angel-blue;
    }
    td,
    th {
      padding: 5px;
      border: none;
      &:first-of-type {
        padding-left: 10px;
      }
    }
    th {
      a,
      .link {
        color: #fff !important;
        text-decoration: underline;
      }
    }
    td {
      color: $text-color-ss;
    }
  }
}

.lightTheme {
  thead {
    th {
      color: $text-color-ss !important;
      background-color: white !important;
    }
  }
}
</style>
