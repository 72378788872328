<template>
  <div @keydown.tab="handleTabPress">
    <div
      v-if="!noLabel && (label || name || $slots.label)"
      class="select-field-label"
    >
      <slot v-if="$slots.label" name="label" />
      <span v-else>
        {{ $slots.label || label || name }}
      </span>
    </div>
    <div
      ref="selectBox"
      class="select-box"
      :class="{
        open,
        disabled,
        'full-width-select': !autoWidth,
        'is-invalid': fieldError
      }"
      :style="selectStyle"
      :data-uid="uid"
    >
      <div class="d-flex align-items-center full-width search-input-cont">
        <input
          ref="searchInput"
          v-model="searchVal"
          tabindex="0"
          :class="[
            'full-width ss-input-field',
            inputClass,
            {
              'cursor-pointer': !search
            },
            {
              'hidden-input':
                selectedValue &&
                multiple &&
                selectedValue.length &&
                (!search || !open)
            }
          ]"
          :placeholder="searchPlaceholder"
          :readonly="!search"
          @focus="handleFocus"
        />
        <div
          v-if="multiple && selectedValue && selectedValue.length"
          :class="[
            'full-width ss-input-field multi-select-cont',
            { visible: multiple && (!search || !open) }
          ]"
          :style="selectStyle"
          @click="handleFocus"
        >
          <div class="pill" :data-uid="uid">
            {{ pillLabel }}
            <span
              class="erase-pill-icon tw-pl-1 tw-pr-2"
              @click="clearSelections"
              >x</span
            >
          </div>
        </div>
        <ChevronIcon
          ref="arrowIcon"
          :direction="open ? 'up' : 'down'"
          class="chevron-icon"
          @click="toggleOpen"
        />
      </div>
      <MountingPortal
        mount-to="body"
        name="selectOptions"
        append
        :disabled="!bodyRender"
      >
        <Transition name="slide-down">
          <div v-if="open" class="select-options" :style="selectOptionsStyle">
            <div
              v-if="multiple && selectedOptions.length"
              class="selected-options-cont"
            >
              <div
                v-for="option in selectedOptions"
                :key="option.value"
                class="option d-flex align-items-center"
                :class="{
                  selected: isSelected(option),
                  'non-selectable': option.selectable === false
                }"
                :data-uid="uid"
                @click="($evt) => handleSelect(option, $evt)"
              >
                <Checkbox
                  v-if="multiple && option.selectable !== false"
                  :value="isSelected(option)"
                  class="multi-select-checkbox !tw-mr-2"
                />
                <slot name="item-row" :option="option">
                  <span>{{ option.label }}</span>
                </slot>
              </div>
              <div class="selected-options-divider" />
            </div>

            <div
              v-for="option in filteredOptions"
              :key="option.value"
              class="option d-flex align-items-center"
              :class="{
                selected: isSelected(option),
                'non-selectable': option.selectable === false
              }"
              :data-uid="uid"
              @click="($evt) => handleSelect(option, $evt)"
            >
              <Checkbox
                v-if="multiple && option.selectable !== false"
                :value="isSelected(option)"
                class="multi-select-checkbox !tw-mr-2"
                :data-uid="uid"
              />
              <slot name="item-row" :option="option">
                <span>{{ option.label }}</span>
              </slot>
            </div>
            <div
              v-if="resultsCountText"
              class="option non-selectable select-results-count-text tw-text-xs !tw-pt-2"
            >
              <slot name="no-results">{{ resultsCountText }}</slot>
            </div>
          </div>
        </Transition>
      </MountingPortal>
    </div>
    <span v-if="fieldError" class="select-field-error-text">{{
      fieldError
    }}</span>
  </div>
</template>

<script>
import { MountingPortal } from "portal-vue";
import ChevronIcon from "serviceshift-ui/components/General/ChevronIcon.vue";
import Transition from "serviceshift-ui/components/General/Transition.vue";
import Checkbox from "serviceshift-ui/components/Inputs/Checkbox.vue";
import { generateUID } from "serviceshift-ui/shared/src/lib/helpers.js";
import { nextTick } from "vue";

export default {
  name: "Select",
  components: {
    Transition,
    ChevronIcon,
    Checkbox,
    MountingPortal
  },
  props: {
    allowEmpty: {
      type: Boolean,
      default: true
    },
    value: { type: [Number, String, Array], default: "" },
    closeOnMultiSelect: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Select..."
    },
    options: {
      type: Array,
      default: () => []
    },
    fieldError: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    search: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    autoWidth: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    renderLimit: {
      type: Number,
      default: -1
    },
    // Will leave the option in the main list when selected vs moving to top in multiple select
    cloneItem: {
      type: Boolean,
      default: false
    },
    bodyRender: {
      type: Boolean,
      default: false
    },
    selectOptionsStyleDefault: {
      type: Object,
      default: () => ({})
    },
    inputClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      searchVal: "",
      open: false,
      selectedValue: null,
      selectStyle: {},
      selectOptionsStyle: this.selectOptionsStyleDefault,
      uid: generateUID()
    };
  },
  computed: {
    visibleOptions() {
      // Add placeholder as empty option
      let options = [...this.options];
      if (!this.multiple && this.allowEmpty) {
        options.unshift({ value: null, label: this.placeholder });
      }

      // Filter out selected options
      if (
        this.multiple &&
        this.selectedValue &&
        this.selectedValue.length &&
        !this.cloneItem
      ) {
        options = options.filter((option) => !this.isSelected(option));
      }

      // Filter out non-visible items
      return options.filter((option) => option.visible !== false);
    },
    filteredOptions() {
      const selectedLabel = this.selectedValue
        ? this.getSelectedLabel(this.selectedValue)
        : "";
      // If no search is entered show full list
      if (this.searchVal === selectedLabel) {
        return this.renderLimit > -1
          ? this.visibleOptions.slice(0, this.renderLimit)
          : this.visibleOptions;
      }

      // Filter out options matching search word
      const searchResults = this.visibleOptions.filter((option) => {
        return (
          `${option.label}`
            .toLowerCase()
            .includes((this.searchVal || "").toLowerCase()) &&
          option.searchable !== false
        );
      });
      return this.renderLimit > -1
        ? searchResults.slice(0, this.renderLimit)
        : searchResults;
    },
    selectedOptions() {
      if (!this.selectedValue) {
        return [];
      }
      // Return a list of selected options
      return this.options.filter(this.isSelected);
    },
    pillLabel() {
      if (!this.selectedValue || !this.selectedValue.length) {
        return this.placeholder;
      }
      if (this.selectedValue.length > 1) {
        return `${this.selectedValue.length} Selected`;
      }
      const searchVal = this.selectedValue[0] || "";
      return (
        (
          this.options.find(
            (option) => option.value.toString() === searchVal.toString()
          ) || {}
        ).label || this.placeholder
      );
    },
    searchPlaceholder() {
      if (this.multiple) {
        return this.pillLabel;
      }
      return this.placeholder;
    },
    resultsCountText() {
      if (this.search && this.searchVal)
        return `Showing ${this.filteredOptions.length} results`;
      const renderLimitReached =
        this.renderLimit > -1 && this.visibleOptions.length > this.renderLimit;
      if (renderLimitReached) {
        return `Showing ${this.renderLimit} of ${this.visibleOptions.length}`;
      }
      return "";
    }
  },
  watch: {
    searchVal(newValue, oldValue) {
      // Reset selection when search is cleared
      if (newValue !== oldValue) {
        this.$emit("search", newValue);
        if (!newValue && oldValue && !this.multiple) {
          this.selectedValue = null;
        }
      }
    },
    selectedValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.multiple) {
          this.searchVal = newValue ? this.getSelectedLabel(newValue) : "";
        }
        // Emit input to update v-model in parent
        this.$emit("input", newValue);
        // Emit blur to trigger vee-validate error message reset
        this.$emit("blur");
      }
    },
    value(newValue, oldValue) {
      // Handle changes from parent
      if (newValue !== oldValue) {
        this.selectedValue = newValue;
      }
    },
    open(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          if (this.bodyRender) {
            // Enable parent element scroll listener so the absolute menu follows the parent
            document.addEventListener("scroll", this.positionOptions, true);
          }
          this.positionOptions();
          if (this.search || this.multiple) {
            // Auto focus search input
            nextTick(() => {
              this.$refs.searchInput.focus();
            });
          }
          this.$emit("opened");
          this.$emit("focus");
        } else {
          this.$emit("closed");
          this.$emit("blur");
          if (this.bodyRender) {
            document.removeEventListener("scroll", this.positionOptions, true);
          }
        }
      }
    },
    options(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.calculateWidth();
        if (!this.multiple) {
          this.searchVal = this.selectedValue
            ? this.getSelectedLabel(this.selectedValue)
            : "";
        }
      }
    }
  },
  created() {
    if (this.value) {
      this.selectedValue = this.value;
    }
  },
  mounted() {
    document.addEventListener("click", this.handleClickOut);
    this.calculateWidth();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOut);
  },
  methods: {
    positionOptions() {
      if (this.bodyRender && this.open) {
        const { left, top, height, width } =
          this.$refs.selectBox.getBoundingClientRect();
        const MENU_OFFSET = 1;
        this.selectOptionsStyle = {
          ...this.selectOptionsStyle,
          left: `${left}px`,
          top: `${top + height + window.pageYOffset + MENU_OFFSET}px`,
          width: "auto",
          "min-width": `${width}px`
        };
      }
    },
    calculateWidth() {
      if (this.autoWidth && (this.options.length || this.placeholder)) {
        const getLabelLength = (option) => {
          let label = option.label || "";
          if (option.hintLabel) {
            label += ` (${option.hintLabel})`;
          }
          return label.length;
        };
        // Only calculate visible options
        const options = [...this.visibleOptions];
        // Include pill width when multi-select
        if (this.multiple) {
          options.push({ label: "XXX Selected" });
        }
        // Find longest option label
        const longestOption = options.sort((a, b) =>
          getLabelLength(a) > getLabelLength(b) ? -1 : 0
        )[0];
        // Create Canvas and measure longest label in pixels
        const mycanvas = document.createElement("canvas");
        const context = mycanvas.getContext("2d");
        context.font = "1rem Arial";
        let innerText = longestOption.label;
        if (longestOption.hintLabel) {
          innerText += ` (${longestOption.hintLabel})`;
        }
        // Calculate display width (including element padding)
        const offset = this.multiple ? -0.01 : -0.06;
        let minWidth = context.measureText(innerText).width;
        minWidth = minWidth + minWidth * offset;
        this.selectStyle = { minWidth: `${minWidth}px !important` };
      }
    },
    clearSearch() {
      if (!this.selectedValue) {
        this.searchVal = "";
      }
      this.searchVal = this.getSelectedLabel(this.selectedValue);
    },
    clearSelections() {
      this.open = true;
      this.selectedValue = null;
    },
    getSelectedLabel(val) {
      if (this.multiple) {
        return "";
      }
      const searchVal = Array.isArray(val) ? val[0] : val;
      return (this.options.find((option) => option.value === searchVal) || {})
        .label;
    },
    toggleOpen(e) {
      e.preventDefault();
      if (this.disabled) return false;
      this.open = !this.open;
    },
    handleClickOut(e) {
      // Handle click out event to auto close options menu
      // check if the clicked element is in the parent and
      // ignore clicks on options, pills and arrow icon
      try {
        const isInParent =
          this.$refs.selectBox.contains(e.target) || this.isChildEl(e.target);
        const isArrowIcon = e.target === this.$refs.arrowIcon;
        if (this.open && !isInParent && !isArrowIcon) {
          this.open = false;
          this.clearSearch();
        }
      } catch (err) {
        console.error(err);
      }
    },
    isChildEl(el) {
      // Traverse up from target until data-uid is found
      while (el) {
        if (!el.dataset.uid) {
          el = el.parentElement;
        } else {
          return el.dataset.uid === this.uid;
        }
      }
      return false;
    },
    handleSelect(option, e) {
      e.preventDefault();
      if (this.multiple) {
        this.$refs.searchInput.focus();
      }
      if (option.selectable === false) {
        return;
      }
      // Handle single select
      if (!this.multiple) {
        this.selectedValue = option.value;
        this.open = false;
        return;
      }
      // if multiple, check if value exists to deselect
      // or add it to the selected values array
      if (
        (this.selectedValue && Array.isArray(this.selectedValue)) ||
        !this.selectedValue
      ) {
        if (this.selectedValue && this.isSelected(option)) {
          // Remove option from selected list if already selected
          const selectedOptions = this.selectedValue.filter(
            (value) => value.toString() !== option.value.toString()
          );
          // If removing the last selected value then reset selected value to null instead of empty array
          if (selectedOptions.length) {
            this.selectedValue = selectedOptions;
          } else {
            this.selectedValue = null;
          }
        } else {
          // Default selected value to empty array on first select
          if (!this.selectedValue) {
            this.selectedValue = [];
          }
          this.selectedValue = this.selectedValue.concat([option.value]);
        }
        this.open = !this.closeOnMultiSelect;
      }
    },
    isSelected(option) {
      if (!this.selectedValue) {
        return false;
      }
      if (!this.multiple) {
        return option.value === this.selectedValue;
      }
      return (
        (Array.isArray(this.selectedValue) &&
          this.selectedValue.includes(option.value)) ||
        this.selectedValue.includes(option.value?.toString())
      );
    },
    handleFocus() {
      nextTick(() => {
        if (!this.disabled && !this.open) {
          this.open = true;
        }
      });
    },
    handleTabPress() {
      nextTick(() => {
        if (!this.disabled) {
          this.open = !this.open;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.multi-select-checkbox {
  pointer-events: none;
  margin: 0;
}
.search-input-cont {
  position: relative;
  .chevron-icon {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 1em;
    width: 1em;
    z-index: 2;
  }
}
.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: width 150ms ease;
  box-shadow: $box-shadow;
  &.open {
    border-radius: 5px 5px 0 0;
  }
  &.disabled {
    opacity: 0.6;
    background-color: #e6e6e6;
    cursor: default;
  }
  &.select-error {
    border-color: $danger-color;
    background-color: #f7e5e5;
    border: 2px solid $danger-color;
    input {
      background-color: #f7e5e5;
    }
  }
  .select-content {
    padding: 8px 10px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    height: 35px;
    &.select-error {
      border-color: $danger-color;
      background-color: #f7e5e5;
      border-width: 2px;
    }
  }
}
.select-options {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 4px 12px -4px $medium-color-shade;
  z-index: 3;
  max-height: 225px;
  overflow-y: scroll;
  font-size: clamp(13px, 0.9em, 16px);
  background-color: white;
  padding: 5px 0;
  .option {
    min-height: 30px;
    background-color: white;
    cursor: pointer;
    padding: 3px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      background-color: $angel-blue;
    }
    &.non-selectable {
      cursor: default;
      &:hover {
        background-color: $white;
      }
    }
    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &.selected {
      color: $primary-color;
    }
    &.no-results-option {
      cursor: default;
      &:hover {
        background-color: white;
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.multi-select-cont {
  display: none !important;
  &.visible {
    display: block !important;
  }
  .pill {
    background-color: $primary-color;
    color: #fff;
    padding: 2px 6px;
    padding-right: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    font-size: 0.85em;
    margin-right: 10px;
    white-space: nowrap;
    cursor: pointer;
    .erase-pill-icon {
      font-weight: bold;
      margin-left: 5px;
      margin-top: -2px;
      pointer-events: none;
    }
  }
}
.selected-options-cont {
  .selected-options-divider {
    margin: 5px 10px;
    border-bottom: 1px solid #ced4da;
  }
}

.select-field-error-text {
  color: $danger-color;
  font-size: 0.8em;
}
.select-field-label {
  white-space: nowrap;
}

.full-width-select {
  width: 100%;
}

.select-results-count-text {
  display: flex;
  align-items: center;
}

.ss-input-field {
  &.ss-input-group-append {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 0;

    /* for consistency with .form-control height and spacing */
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 14px;
    box-shadow: 0 3px 6px 0px var(--secondary-color);
    clip-path: inset(-6px -6px -6px -1px);
  }
}
</style>
