<template>
  <div>
    <v-app-bar app dense :clipped-left="!$vuetify.breakpoint.smAndDown">
      <router-link to="/" class="d-flex align-center">
        <img
          :src="$store.state.tenant.customizations.Assets.logo_url"
          alt="logo"
          class="nav-logo"
        />
      </router-link>
      <div class="flex-grow-1"></div>
      <v-toolbar-items>
        <div
          v-if="showLoginAndCallUs"
          class="d-flex row align-items-center mr-0 loginButtons"
        >
          <div class="d-flex align-items-center">
            <v-btn :href="`tel: ${phone}`" color="primary" rounded>
              <i class="fa fa-phone mr-1" />
              <span>Call Us</span>
            </v-btn>
            <div class="divider mr-5 ml-5" />
            <v-btn href="/" color="primary" rounded>
              <i class="fa fa-user mr-1" />
              <span>Log In</span>
            </v-btn>
          </div>
        </div>
        <div
          v-else
          class="d-flex flex-column white--text overline text-right justify-center hq-phone"
        >
          <p class="ma-0">Need Assistance?</p>
          <p class="ma-0">
            {{ $store.state.tenant.customizations.General.phone }}
          </p>
        </div>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  props: {
    showLoginAndCallUs: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      open: false,
      scheduling: false
    };
  },
  computed: {
    phone() {
      if (!this.$store.state.tenant) return "";
      return this.$store.state.tenant.customizations.General.phone;
    }
  },
  watch: {
    $route(newValue, oldValue) {
      if (newValue.name !== oldValue.name) {
        this.setScheduling();
      }
    }
  },
  mounted() {
    this.setScheduling();
  },
  methods: {
    setScheduling() {
      this.scheduling = this.$route.name === "scheduleAppointment";
    },
    toggleOpen() {
      this.open = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-app-bar {
  background-color: var(--v-secondary-base) !important;
}
.v-toolbar__title {
  color: #fff;
  width: 35%;
  padding-left: 0 !important;
}
.v-list {
  padding: 0;
}
.nav-logo {
  max-height: 35px;
  max-width: 100px;
}
.schedule-nav-btn {
  text-transform: none;
}
.hq-phone {
  line-height: 1.5;
}
.divider {
  height: 30px;
  background: #d3d7d9;
  width: 1px;
}
.loginButtons {
  .v-btn {
    padding: 0 10px;
  }
}
</style>
