<template>
  <div class="inputWithError" :class="classes">
    <div class="label d-flex align-items-center">
      <span>{{ label }}</span
      ><span v-if="required">*</span>
      <v-tooltip v-if="tooltip" v-model="showTooltip" top>
        <template #activator="{ on }">
          <v-icon color="black" class="tooltip" v-on="on"
            >mdi-help-circle</v-icon
          >
        </template>
        <div style="max-width: 250px">{{ tooltip }}</div>
      </v-tooltip>
    </div>
    <div class="inputWrapper">
      <slot />
    </div>
    <div v-if="error" class="errorMessage">
      <i class="fa fa-exclamation-circle mr-1"></i><span>{{ error }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

const props = defineProps({
  tooltip: {
    type: String,
    default: () => ""
  },
  // Iframe input is a special attribute to tell us the input is rendered in an iframe
  iframeInput: {
    type: Boolean,
    default: () => false
  },
  required: {
    type: Boolean,
    default: () => false
  },
  label: {
    type: String,
    default: () => ""
  },
  error: {
    type: String,
    default: () => ""
  }
});

const showTooltip = ref(false);

const classes = computed(() => {
  const newClasses: string[] = [];
  if (props.error) newClasses.push("input-error");
  if (props.iframeInput) newClasses.push("iframe");
  return newClasses;
});
</script>

<style lang="scss">
.inputWithError {
  .tooltip {
    font-size: 16px;
    margin-left: 3px;
  }
  label span {
    white-space: nowrap;
    text-align: left;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 2px;
  }
  .v-input__slot {
    margin-bottom: 0 !important;
  }
  .inputWrapper {
    position: relative;
    .select-box {
      box-shadow: none;
    }
    .select-box .ss-input-field {
      border: 1px solid #a8a8a8;
      height: 47px;
    }
  }
  &.input-error {
    .v-input__slot,
    .select-box input {
      border: 1px solid #f00;
    }
    &.iframe {
      .inputWrapper > *:not(.no-error-highlight) {
        border: 1px solid #f00;
      }
    }
    .errorMessage {
      margin-top: 3px;
      color: #f00;
      font-size: 0.9rem;
    }
  }
}
</style>
