import API from "serviceshift-ui/api-client";

export default {
  fetchComments(_, { id, type }) {
    return API.comment.getAll(id, type);
  },
  createComment(_, { id, type, comment }) {
    return API.comment.create(id, type, comment);
  }
};
