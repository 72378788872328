<template>
  <div :class="['tw-inline-block tooltip-wrapper', className]">
    <div ref="tooltipTrigger">
      <slot name="trigger" />
    </div>
    <div ref="content">
      <slot v-if="!disabled" name="tooltip" />
    </div>
  </div>
</template>

<script>
import tippy from "tippy.js";

export default {
  name: "Tooltip",
  props: {
    placement: {
      type: String,
      default: "left"
    },
    options: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      tippyRef: null
    };
  },
  watch: {
    disabled(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          // Turn off tooltip
          this.tippyRef.destroy();
        } else {
          this.initTooltip();
        }
      }
    }
  },
  mounted() {
    if (!this.disabled) {
      this.initTooltip();
    }
  },
  beforeDestroy() {
    if (this.tippyRef) {
      this.tippyRef.destroy();
    }
  },
  methods: {
    initTooltip() {
      this.tippyRef = tippy(this.$refs.tooltipTrigger, {
        appendTo: document.body,
        content: this.$refs.content,
        interactive: true,
        delay: [300, 300],
        placement: this.placement,
        hideOnClick: false,
        arrow: true,
        ...this.options
      });
    },
    hideTooltip() {
      this.tippyRef.hide();
    }
  }
};
</script>

<style lang="scss">
@import "tippy.js/dist/tippy.css";
@import "tippy.js/themes/light.css";
.tippy-tooltip {
  max-width: none !important;
}
</style>
