import mitt from "mitt";
import API from "serviceshift-ui/api-client";
import { AuthRole } from "serviceshift-ui/shared/src/typings/auth";
import { Customer } from "serviceshift-ui/shared/src/typings/customer";
import { PartialRecord } from "serviceshift-ui/shared/src/typings/globals";
import { PaymentMethod } from "serviceshift-ui/shared/src/typings/paymentMethods";
import {
  computed,
  getCurrentInstance,
  onUnmounted,
  Ref,
  ref,
  UnwrapRef,
  watch
} from "vue";

type CardData = {
  card: string;
  name: string;
  zip_code: number;
};

type ACHData = {
  ach_account_type: string;
  ach_routing_number: number;
  ach_account_number: number;
  ach_check_number: number;
  ach_first_name: string;
  ach_last_name: string;
  ach_token: string;
};

type UserDefinedCallbacks = PartialRecord<
  keyof typeof store.globalCallbacks,
  () => any
>;

const emitter = mitt();

// We need to define these globally since the store is shared through multiple components
// By putting the store into the window, we can use this across multiple apps
let store = {
  customerRef: ref<Ref<Customer>>(),
  fetchingPaymentMethods: ref(false),
  paymentMethodsResponse: ref<PaymentMethod[]>([]),
  loadingPaymentMethods: ref(true),
  globalCallbacks: {
    onPaymentSuccess: [] as Array<() => any>
  }
};
Object.keys(store.globalCallbacks).forEach((key) => {
  emitter.on(key, () => {
    store.globalCallbacks[key].forEach((callback) => callback());
  });
});

const globalWindow = window as any;
if (globalWindow.usePaymentMethodsStore) {
  store = globalWindow.usePaymentMethodsStore;
}
globalWindow.usePaymentMethodsStore = store;

// Since we assigned the customerRef.value to proxy another ref, customerRef.value is now
// the actual customer (not a ref). Typescript unfortunately does not understand this, so
// we have to tell it what the real type is.
const customer = computed(() => {
  return store.customerRef.value as UnwrapRef<
    UnwrapRef<typeof store.customerRef>
  >;
});

// Auto fetch payment methods when we have a customer defined
watch(
  customer,
  (newVal, oldVal) => {
    if ((!oldVal && newVal) || (oldVal && newVal && newVal !== oldVal)) {
      if (newVal.role !== AuthRole.customer) return;
      fetchPaymentMethods();
    }
  },
  { immediate: true }
);

function buildAddressDisplay(address: {
  city?: string;
  state?: string;
  zip_code?: number | string;
}) {
  if (address.city && address.state && address.zip_code) {
    return `${address.city}, ${address.state} ${address.zip_code}`;
  }
  return undefined;
}

// The billing address comes from the primary address. When a user
// saves a payment method, we capture the billing address they entered
// and make that the "savedPaymentBillingAddress"
const billingAddress = computed(() => {
  if (!customerPrimaryAddress.value) return undefined;
  const primaryAddress = customerPrimaryAddress.value;
  return {
    name: fullName.value,
    city: primaryAddress.city,
    state: primaryAddress.state,
    street: primaryAddress.street,
    zip_code: primaryAddress.zip_code,
    cityStateZip: buildAddressDisplay(primaryAddress)
  };
});

const savedPaymentBillingAddress = computed(() => {
  const paymentMethod = customer.value?.payment_method as any;
  if (!paymentMethod) return undefined;
  return {
    name: fullName.value,
    city: paymentMethod.city,
    state: paymentMethod.state,
    street: paymentMethod.street,
    zip_code: paymentMethod.zip_code,
    cityStateZip: buildAddressDisplay(paymentMethod)
  };
});

const fullName = computed(() => {
  return `${firstName.value} ${lastName.value}`;
});

const firstName = computed(() => {
  if (customer.value?.full_name) {
    const split = customer.value?.full_name.split(" ");
    return split.length ? split[0] : "";
  } else if (customer.value?.first_name) {
    return customer.value.first_name;
  }
  return "";
});

const lastName = computed(() => {
  if (customer.value?.full_name) {
    const split = customer.value?.full_name.split(" ");
    return split.length > 1 ? split[1] : "";
  } else if (customer.value?.last_name) {
    return customer.value.last_name;
  }
  return "";
});

const paymentMethodOptions = computed(() => {
  return store.paymentMethodsResponse.value.map((method) => {
    if (method.key === "payment_method") {
      return {
        ...method,
        value: method.key,
        disabled: !customer.value?.payment_method
      };
    }
    return {
      ...method,
      value: method.key
    };
  });
});

const customerPrimaryAddress = computed(() => {
  if (!customer.value) return undefined;
  if (!customer.value?.addresses) return undefined;
  return customer.value?.addresses.find((address) => address.primary);
});

const storedPaymentMethods = computed(() => {
  return customer.value?.payment_method ? [customer.value.payment_method] : [];
});

const paymentMethodCreateUrl = computed(() => {
  return (
    customer.value?.links?.payment_method_create ||
    customer.value?.payment_method_create_url
  );
});

const paymentMethodsAvailable = computed(() => {
  return store.paymentMethodsResponse.value.length > 0;
});

const fetchCustomer = async (customerId: number) => {
  try {
    const response = await API.user.get(customerId);
    setCustomerRef(response.data);
  } catch (e) {
    setCustomerRef(undefined);
  }
};

const updatePaymentMethod = async (data: CardData | ACHData) => {
  const link = paymentMethodCreateUrl.value;
  if (link) {
    return API.makeRequest(link, {
      method: "POST",
      data: {
        ...data,
        card_save_payment_method: undefined
      }
    });
  } else {
    throw new Error("No save payment method url found.");
  }
};

const deletePaymentMethod = async () => {
  const link = customer.value?.payment_method?.payment_method_delete_url;
  if (link) {
    await API.makeRequest(link, {
      method: "DELETE"
    });
    await fetchPaymentMethods();
  } else {
    throw new Error("No delete payment method URL found.");
  }
};

const fetchPaymentMethods = async () => {
  store.loadingPaymentMethods.value = true;
  try {
    const res = await API.tenant.getPaymentMethods();
    store.paymentMethodsResponse.value = res.data;
  } catch (e) {
    // Do nothing
  }
  store.loadingPaymentMethods.value = false;
};

const createTransaction = async ({ invoice, data }) => {
  const response = await API.makeRequest<{
    amount_cents: number;
    transaction_time: string; // date
    reference_id: string;
    invoice_id: number;
  }>(invoice.links.payment_create, {
    ignoreAppHandleAPIError: true,
    method: "POST",
    data
  });
  emitter.emit("onPaymentSuccess");
  return response;
};

const setCustomerRef = (customer: Ref<Customer> | Customer | undefined) => {
  store.customerRef.value = customer as any;
};

export default function usePaymentMethods() {
  // The user can pass in their own callbacks by using the `definePaymentCallbacks` method
  const userDefinedCallbacks = {} as UserDefinedCallbacks;
  const instance = getCurrentInstance();

  const definePaymentCallbacks = (config: UserDefinedCallbacks) => {
    if (config.onPaymentSuccess) {
      userDefinedCallbacks.onPaymentSuccess = config.onPaymentSuccess;
      store.globalCallbacks.onPaymentSuccess.push(config.onPaymentSuccess);
    }
  };

  // We can only access the lifecycle methods for components.
  if (instance) {
    onUnmounted(() => {
      removeUserCallbacks();
    });
  }

  function removeCustomCallback(key: keyof typeof store.globalCallbacks) {
    if (userDefinedCallbacks[key]) {
      store.globalCallbacks[key].splice(
        store.globalCallbacks[key].indexOf(userDefinedCallbacks[key] as any),
        1
      );
    }
  }

  function removeUserCallbacks() {
    Object.keys(userDefinedCallbacks).forEach((key) => {
      removeCustomCallback(key as any);
    });
  }

  return {
    billingAddress,
    definePaymentCallbacks,
    fetchCustomer,
    customer,
    firstName,
    lastName,
    fullName,
    loadingPaymentMethods: store.loadingPaymentMethods,
    deletePaymentMethod,
    fetchPaymentMethods,
    storedPaymentMethods,
    paymentMethodOptions,
    paymentMethodsAvailable,
    setCustomerRef,
    updatePaymentMethod,
    createTransaction,
    paymentMethodsResponse: store.paymentMethodsResponse,
    removeUserCallbacks,
    customerPrimaryAddress,
    savedPaymentBillingAddress
  };
}
