import axios from "axios";
import { Id } from "serviceshift-ui/shared/src/typings/globals";

import ENV from "../env";

export default {
  create: (data) => {
    return axios({
      method: "POST",
      url: `${ENV.v1Path}/appointments`,
      data
    });
  },
  get: (id) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/appointments/${id}`
    });
  },
  getAll: (params) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/appointments`,
      params
    });
  },
  getCurrent: (params) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/appointments/current`,
      params
    });
  },
  getRatings: (id: Id) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/appointments/${id}/ratings`
    });
  },
  resendConfirmation: (data) => {
    return axios({
      method: "POST",
      url: `${ENV.v1Path}/appointments/${data.id}/resend_confirmation`
    });
  },
  uploadPhoto: (data) => {
    return axios({
      method: "POST",
      url: `${ENV.v1Path}/appointments/${data.id}/photos`,
      data: data.body
    });
  },
  removePhoto: (data) => {
    return axios({
      method: "DELETE",
      url: `${ENV.v1Path}/appointments/${data.appointmentId}/remove_photo?photo_id=${data.photoId}`
    });
  },
  update: (data) => {
    return axios({
      method: "PUT",
      url: `${ENV.v1Path}/appointments/${data.appointment.id}`,
      data
    });
  }
};
