<template>
  <div>
    <ItemName
      :editing="editing"
      :tech-mode="techMode"
      :item="item"
      :open="internalIsOpen"
      :event-id="eventId"
      :equipment-types-enabled="supportsEquipmentRequests"
      @toggle-open-details="toggleOpenDetails"
    />
    <div
      v-if="internalIsOpen && editing"
      class="details-container details-text"
      :class="{ 'eq-icon-left-margin': supportsEquipmentRequests }"
    >
      <div v-if="showCoveredSystems" class="cover-systems-container">
        <span class="cover-systems-text">Covered Systems</span>
        <NumberInput
          :value="item.covered_systems"
          :min="0"
          @input="handleCoveredSystems"
        />
      </div>
      <label v-if="showWarrantyLabel" class="label-text tw-mr-1"
        >Warranty:</label
      >
      <span class="warranty-info">
        {{ item.warranty || "Not provided" }}
      </span>
      <div>
        <textarea
          id="description"
          :value="item.description"
          class="description-input paragraph-text"
          name="description"
          cols="30"
          rows="3"
          placeholder="Description..."
          @blur="updateDescription($event, item)"
        />
      </div>
    </div>
    <div
      v-if="!editing && (item.warranty || item.description)"
      class="details-container details-text"
      :class="{ 'eq-icon-left-margin': supportsEquipmentRequests }"
    >
      <div v-if="item.warranty">
        <span class="pre-line">
          <span v-if="showWarrantyLabel" class="warranty-label">Warranty:</span>
          <span class="warranty-info">{{ item.warranty }}</span>
        </span>
      </div>
      <span
        v-if="includeDetails && item.description"
        style="margin-top: 8px"
        class="non-editable pre-line"
      >
        {{ item.description }}
      </span>
    </div>
  </div>
</template>

<script>
import NumberInput from "serviceshift-ui/components/Inputs/NumberInput.vue";
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";
import debounce from "serviceshift-ui/shared/src/mixins/debounce";

import ItemName from "./ItemName.vue";

export default {
  components: {
    ItemName,
    NumberInput
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: undefined
    },
    /** @type { QuoteInvoiceTypes.LineItem } */
    item: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: "quote"
    },
    techMode: {
      type: Boolean,
      default: false
    },
    eventId: {
      type: Number,
      default: null
    },
    equipmentTypesEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showWarrantyLabel() {
      return (
        this.item.item_type !== "membership_program" &&
        this.item.item_type !== "warranty_program"
      );
    },
    internalIsOpen: {
      get() {
        return this.open === undefined ? !!this.item.description : this.open;
      },
      set(val) {
        this.$emit("update:open", val);
      }
    },
    showCoveredSystems() {
      return this.editing && this.item.item_type === "membership_program";
    },
    supportsEquipmentRequests() {
      return this.type === "invoice" && this.equipmentTypesEnabled;
    },
    includeDetails() {
      return this.type === "quote" || this.item.invoice_format === "detailed";
    }
  },
  methods: {
    handleCoveredSystems: debounce(function (value) {
      if (value !== this.item.covered_systems) {
        const systems = value ? parseInt(value, 10) : 0;
        EventBus.$emit(
          "edit-covered-systems",
          {
            target: {
              value: systems
            }
          },
          this.item,
          this.eventId
        );
      }
    }, 750),
    toggleOpenDetails() {
      this.internalIsOpen = !this.internalIsOpen;
    },
    updateWarranty(e, item) {
      EventBus.$emit("edit-warranty", e, item, this.eventId);
    },
    updateDescription(e, item) {
      EventBus.$emit("edit-description", e, item, this.eventId);
    }
  }
};
</script>

<style lang="scss" scoped>
.warranty-input,
.description-input {
  width: 98%;
  border: 1px solid $primary-accent-color;
  border-radius: 5px;
  padding: 2px 10px;
  margin: 5px 5px;
  font-family: inherit;
}

.description-input {
  margin-left: 0px;
}

.row {
  display: block;
}

.details-container {
  margin-left: 7px;
  padding-right: 20px;
}

.paragraph-text {
  font: 400 0.85em inherit;
}

.details-text {
  font-size: 0.9em;
}

.pre-line {
  white-space: pre-line;
}
.name-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
input,
textarea {
  font-family: inherit;
}
.warranty-label {
  margin-right: 5px;
}

.warranty-info {
  white-space: pre-line;
  font-style: italic;
}
.eq-icon-left-margin {
  margin-left: 3em;
}
</style>
