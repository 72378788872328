import timeoutPromise from "./timeoutPromise";

// TODO: Typescript 4.5 supports Awaited out of the box and we should eventually bump projects to this
type _Awaited<T> = T extends Promise<infer U> ? U : never;

/**
 * This function will help standardize how we call API's that involve polling.
 * The API response must have a { loading: boolean; data: any } format.
 */
export default async function pollResponse<
  T extends (...args: any) => Promise<{ loading: boolean; data: any }>
>(
  fn: T,
  config?: {
    pollingRateMs?: number;
    maxTries?: number;
  },
  attemptCount?: number
): Promise<_Awaited<ReturnType<T>>["data"]> {
  let currentAttemptCount = attemptCount || 0;
  let pollingRate = config?.pollingRateMs || 2000;
  if (pollingRate < 1000) pollingRate = 1000; // Safeguard
  const callback = fn as any;
  const results = await callback();
  if (!results.loading) return results.data;
  if (config?.maxTries && currentAttemptCount >= config.maxTries)
    throw "max attempts reached and response not loading";
  await timeoutPromise(pollingRate);
  return pollResponse(fn, config, ++currentAttemptCount);
}
