import { format, formatDistanceToNowStrict } from "date-fns";
import enUS from "date-fns/locale/en-US";
import { formatInTimeZone } from "date-fns-tz";

export const emptyDisplay = "--";

export const extractName = (name?: string) => {
  const trimmedName = `${name}`.trim();
  const [firstName, ...lastName] = trimmedName.split(" ");
  return {
    firstName: firstName.trim(),
    lastName: lastName.join(" ").trim()
  };
};

export const capitalize = (value) => {
  if (!value) return "";
  const capped = value.toString();
  return capped.charAt(0).toUpperCase() + capped.slice(1);
};

// See: https://stackoverflow.com/questions/196972/convert-string-to-title-case-with-javascript
export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const tableFieldDisplay = (val) => {
  const valWithNoUnderscore = val.replaceAll("_", " ");
  return toTitleCase(valWithNoUnderscore);
};

export const tableValueDisplay = (val) => {
  if (val === null || val === undefined) return emptyDisplay;
  if (!isNaN(new Date(val).getTime())) return formatDate(new Date(val));
  return val ? val : emptyDisplay;
};

/**
 * Formats dates in a given timezone (optional)
 *
 * @param {Date|String} userDate Date to be formatted
 * @param {object} options Date format options, supports { timezone: string; format: string; }
 * @returns {string}
 */
const DEFAULT_DATE_FORMAT = "MM/dd/yyyy";
export const formatDate = (userDate, options = {} as any) => {
  if (!userDate) return emptyDisplay;
  try {
    const date = new Date(userDate);
    const dateFormat = options.format || DEFAULT_DATE_FORMAT;
    return options.timezone
      ? formatInTimeZone(date, options.timezone, dateFormat, { locale: enUS })
      : format(date, dateFormat, { locale: enUS });
  } catch (e) {
    console.warn(e);
    return "Invalid date";
  }
};

// Converts cents to dollars as a string
export const dollars = (cents, options = {}) => {
  if (!cents && cents !== 0) {
    return "--";
  }
  const fixed = (cents / 100).toFixed(2);
  try {
    return Number(parseFloat(fixed)).toLocaleString("en-us", {
      style: "currency",
      currency: "USD",
      ...options
    });
  } catch (e) {
    return "$0.00";
  }
};

export const fullAddress = (address) => {
  const { street, unit, city, state, zip_code } = address;
  return unit
    ? `${street}, ${unit}, ${city}, ${state} ${zip_code}`
    : `${street} ${city}, ${state} ${zip_code}`;
};
export const fromNow = (dateVal, includeSuffix = false) => {
  if (!dateVal) return "--";
  try {
    return formatDistanceToNowStrict(new Date(dateVal), {
      roundingMethod: "floor",
      addSuffix: includeSuffix
    });
  } catch (e) {
    return "Invalid date";
  }
};
