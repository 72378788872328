import axios from "axios";

import ENV from "../env";

export default {
  createTransaction: (jobId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/transactions`, {
      method: "POST",
      data
    });
  },
  deleteDocument: (jobId, id) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/documents/${id}`, {
      method: "DELETE"
    });
  },
  deletePhoto: (jobId, id) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/photos/${id}`, {
      method: "DELETE"
    });
  },
  getPaymentReceivers: (jobId) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/transactions/receivers`, {
      method: "GET"
    });
  },
  getTransactions: (jobId) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/transactions`, {
      method: "GET"
    });
  },
  get: (jobId) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}`, {
      method: "GET"
    });
  },
  setQuoteOwner: (jobId, quoteId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/quotes/${quoteId}/owner`, {
      method: "PUT",
      data
    });
  },
  uploadDocument: (jobId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/documents`, {
      method: "POST",
      data: data.body
    });
  },
  uploadPhoto: (jobId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/photos`, {
      method: "POST",
      data: data.body
    });
  },
  updatePhotoVisibility: (jobId, photoId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/photos/${photoId}`, {
      method: "PUT",
      data
    });
  },
  updateDocumentVisibility: (jobId, docId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/documents/${docId}`, {
      method: "PUT",
      data
    });
  },
  setJobOwner: (jobId, owner_id) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/owner`, {
      method: "PUT",
      data: {
        owner_id
      }
    });
  },
  setJobCommissions: (jobId, commissions) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/commissions`, {
      method: "PUT",
      data: {
        commissions
      }
    });
  }
};
