<template>
  <div>
    <span
      v-if="missingFields"
      class="caption block text-left error--text mb-2"
      @click="handleToggle"
    >
      <v-icon color="error" class="caption">mdi-alert-circle</v-icon>
      {{ missingFields }}
    </span>
    <span v-else class="toggle caption block text-left" @click="handleToggle">
      <v-icon color="primary" class="caption">mdi-help-circle</v-icon>
      {{
        autocomplete
          ? "Need to enter address manually?"
          : "Find address using Google Autocomplete"
      }}
    </span>
    <v-fade-transition mode="out-in">
      <div v-if="autocomplete">
        <vuetify-google-autocomplete
          id="g-autocomplete"
          outlined
          append-icon="mdi-google-maps"
          label="Addresss"
          placeholder="Addresss"
          :rules="[validAddress(address)]"
          :value="addressToDisplay"
          @placechanged="handlePlaceChange"
        ></vuetify-google-autocomplete>
      </div>
      <div v-else>
        <v-text-field
          v-model="street"
          outlined
          label="Street"
          :rules="requiredRules"
        />
        <v-text-field
          v-model="city"
          outlined
          label="City"
          :rules="requiredRules"
        />
        <v-text-field
          v-model="state"
          outlined
          label="State"
          :rules="requiredRules"
        />
        <v-text-field
          v-model="zip"
          outlined
          label="Zip Code"
          :rules="requiredRules"
          :error-messages="addressValidationErrorMessage"
        />
      </div>
    </v-fade-transition>
    <v-text-field
      v-model="suite"
      outlined
      label="Suite or Apt #"
      hint="(Optional)"
      persistent-hint
    />
  </div>
</template>

<script>
import Vue from "vue";

import ValidationRules from "@/mixins/validationRules";

export default {
  name: "AddressField",
  mixins: [ValidationRules],
  props: {
    value: {
      type: String,
      default: ""
    },
    currentAddress: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      autocomplete: true,
      street: "",
      suite: "",
      city: "",
      state: "",
      zip: "",
      address: this.currentAddress || {},
      missingFields: ""
    };
  },
  computed: {
    valid() {
      if (this.missingFields === "") return true;
      return this.street && this.city && this.state && this.zip;
    },
    addressToDisplay() {
      if (Object.keys(this.address).length) {
        return this.address.id
          ? `${this.address.street} ${this.address.city}, ${this.address.zip_code}`
          : `${this.address.name}, ${this.address.locality}, ${this.address.administrative_area_level_1} ${this.address.postal_code}`;
      }
      return "";
    }
  },
  watch: {
    address(newValue, oldValue) {
      if (newValue !== oldValue && newValue.postal_code) {
        this.setFields();
      }
    },
    zip(newValue, oldValue) {
      if (newValue !== oldValue && newValue.length > 4) {
        this.validateCoverageArea(newValue);
      }
    }
  },
  mounted() {
    if (this.currentAddress) {
      this.$emit("validate-form");
      this.suite = this.address.unit ? this.address.unit : "";
    }
  },
  methods: {
    handleToggle() {
      this.autocomplete = !this.autocomplete;
    },
    handlePlaceChange(place) {
      if (place) {
        this.address = { ...place, isGoogleAddress: true };
      }
    },
    getAddress() {
      let address = { ...this.address, unit: this.suite };
      if (!this.autocomplete) {
        address = {
          street: this.street,
          unit: this.suite,
          locality: this.city,
          administrative_area_level_1: this.state,
          postal_code: this.zip
        };
      }
      return address;
    },
    setFields() {
      this.street =
        this.address.street_number && this.address.route
          ? `${this.address.street_number} ${this.address.route}`
          : "";
      this.city = this.address.locality;
      this.state = this.address.administrative_area_level_1;
      this.zip = this.address.postal_code;
      if (
        !this.address.street_number ||
        !this.address.route ||
        !this.address.locality ||
        !this.address.administrative_area_level_1 ||
        !this.address.postal_code
      ) {
        this.missingFields =
          "Autocomplete suggestion is missing required fields, please enter them manually.";
        // Auto switch on google places error
        this.autocomplete = false;
        Vue.nextTick(() => {
          this.$emit("validate-form");
        });
      } else {
        this.missingFields = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle {
  cursor: pointer;
  color: var(--v-primary-base);
}

// Gets rid of a double placeholder
:deep(#g-autocomplete::placeholder) {
  color: transparent;
}
</style>
