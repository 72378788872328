<template>
  <v-dialog
    v-model="dialog"
    :transition="
      $vuetify.breakpoint.smAndDown
        ? 'dialog-bottom-transition'
        : 'dialog-transition'
    "
    max-width="300"
  >
    <v-card>
      <div class="pa-4 text-center modal-body">
        <v-icon class="close-icon" @click="toggleModal">mdi-close</v-icon>
        <slot name="body"></slot>
        <v-btn rounded color="primary" class="mt-4" @click="$emit('click')">{{
          btnText
        }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    btnText: {
      type: String,
      default: "Continue"
    }
  },
  data() {
    return {
      dialog: true
    };
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.$emit("hide-modal");
      }
    }
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-body {
  font-size: 14px;
  padding: 30px !important;
  position: relative;
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
  }
}
</style>
