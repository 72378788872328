<template>
  <v-card class="arrivalDetails">
    <div class="text-center arrivalDetails pa-3">
      <div class="arrivalTitle mb-1">{{ arrivalTitle }}</div>
      <div class="d-flex flex-column align-items-center">
        <div class="font-bold d-flex secondary--text mb-1">
          <div class="mr-5">
            <i class="fa fa-calendar mr-1" />
            <strong>{{ appointmentDate }}</strong>
          </div>
          <div>
            <i class="fa fa-clock mr-1" />
            <strong>{{ appointmentWindow }}</strong>
          </div>
        </div>
      </div>
      <div class="text-overflow">
        {{ addressDisplay }}
      </div>
    </div>
    <div v-if="$slots.tabs">
      <slot name="tabs" />
    </div>
  </v-card>
</template>

<script lang="ts" setup>
import { Appointment } from "serviceshift-ui/shared/src/typings/appointment";
import { Job } from "serviceshift-ui/shared/src/typings/job";
import { computed, watch } from "vue";

import { useAppointmentHookData } from "@/hooks/useAppointmentHookData";

const props = defineProps({
  appointment: {
    type: Object as () => Appointment,
    default: () => null
  },
  job: {
    type: Object as () => Job,
    default: () => null
  }
});

const {
  addressDisplay,
  hasArrived,
  isActiveAppointment,
  appointmentDate,
  appointmentWindow,
  updateAppointmentHookData
} = useAppointmentHookData();

const arrivalTitle = computed(() => {
  if (hasArrived.value) return "Technician has arrived";
  if (isActiveAppointment.value) return "Your appointment time";
  return "Technician will arrive";
});

watch(props, updateAppointmentHookData, { immediate: true });
</script>

<style lang="scss">
.arrivalDetails {
  font-size: 14px;
}
</style>
