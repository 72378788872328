<template>
  <div v-if="jobAppointment" class="d-flex flex-column align-items-center">
    <div v-if="isTechnicianOnRoute">
      <div class="text-center mb-2">
        Your technician is on the way. Please call our office to make any
        necessary changes to this appointment.
      </div>
      <div class="d-flex flex-column align-items-center">
        <v-btn :href="`tel: ${phone}`" color="primary" rounded>Call Us</v-btn>
      </div>
    </div>
    <div v-else class="d-flex align-items-center">
      <RescheduleAppointmentDialog
        :appointment="jobAppointment"
        :address="job.address"
        :disabled="!canRescheduleCancel"
        class="mr-5"
      />
      <div class="mr-5">or</div>
      <CancelAppointmentDialog
        :appointment="jobAppointment"
        :address="job.address"
        :disabled="!canRescheduleCancel"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { Appointment } from "serviceshift-ui/shared/src/typings/appointment";
import { Job } from "serviceshift-ui/shared/src/typings/job";
import { computed, watch } from "vue";
import { useStore } from "@/lib/vuex-composition";

import CancelAppointmentDialog from "@/components/Appointment/CancelAppointmentDialog.vue";
import RescheduleAppointmentDialog from "@/components/Appointment/RescheduleAppointmentDialog.vue";
import { useAppointmentHookData } from "@/hooks/useAppointmentHookData";

const store = useStore();

const props = defineProps({
  isCustomer: {
    type: Boolean,
    default: () => false
  },
  appointment: {
    type: Object as () => Appointment,
    default: () => null
  },
  job: {
    type: Object as () => Job,
    default: () => null
  }
});

const {
  canRescheduleCancel,
  isTechnicianOnRoute,
  jobAppointment,
  updateAppointmentHookData
} = useAppointmentHookData();

const phone = computed(() => store.state.tenant?.customizations.General.phone);

watch(props, updateAppointmentHookData, { immediate: true });
</script>
