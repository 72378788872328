import axios from "axios";
import { PaymentMethod } from "serviceshift-ui/shared/src/typings/paymentMethods";
import {
  Tenant,
  TenantCustomizations
} from "serviceshift-ui/shared/src/typings/tenant";

import ENV from "../env";

export default {
  getBySubdomain: (subdomain) => {
    return axios<Tenant>({
      method: "GET",
      url: `${ENV.v1Path}/tenants/${subdomain}`
    });
  },
  getCustomizations: (subdomain) => {
    return axios<TenantCustomizations>({
      method: "GET",
      url: `${ENV.v1Path}/tenants/${subdomain}/customizations`
    });
  },
  getPaymentMethods: () => {
    return axios<PaymentMethod[]>(`${ENV.v2Path}/payment_methods`, {
      method: "GET"
    });
  },
  getPaymentMethodsSettings: () => {
    return axios(`${ENV.v2Path}/settings/payment_methods`, {
      method: "GET"
    });
  },
  updatePaymentMethodsSettings: (data) => {
    return axios(`${ENV.v2Path}/settings/payment_methods`, {
      method: "POST",
      data
    });
  }
};
