<template>
  <div class="login-body">
    <div class="login-container">
      <h2 class="display-1 text-center mb-3">Log In</h2>
      <v-card width="350px" class="bordered-card mb-6">
        <v-card-text>
          <v-form @submit.prevent="handleLogin">
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              prepend-inner-icon="mdi-account-outline"
              :loading="loading"
              :error="error.length > 0"
            ></v-text-field>
            <v-text-field
              v-model="password"
              outlined
              label="Password"
              type="password"
              prepend-inner-icon="mdi-lock-outline"
              :loading="loading"
              :error="error.length > 0"
              :error-messages="error"
            ></v-text-field>
            <v-btn block large color="primary" type="submit" :loading="loading"
              >Log In</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
      <div class="mt-4 d-flex">
        <v-btn
          class="mr-1 flex-1"
          outlined
          color="primary"
          @click="$router.push({ name: 'passwordReset' })"
          >Forgot Password</v-btn
        >
        <v-btn
          class="ml-1 flex-1"
          color="primary"
          outlined
          @click="$router.push({ name: 'createAccount' })"
          >Create Account</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { AuthRole } from "serviceshift-ui/shared/src/typings/auth";
import { AuthLocalStorage } from "@/lib/auth";

import AuthMixin from "../mixins/auth";

export default {
  name: "Login",
  mixins: [AuthMixin],
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      error: ""
    };
  },
  mounted() {
    const cachedEmail = AuthLocalStorage.email;
    if (cachedEmail) {
      this.email = cachedEmail;
    }
  },
  methods: {
    async handleLogin() {
      if (this.email) {
        this.loading = true;
        try {
          await this.login(this.email, this.password);
          await this.$router.push({ path: this.$route.query.redirect || "/" });
          window.gtag("event", "login", { method: "email" });
        } catch (e) {
          this.error = "Invalid username or password. Please try again.";
        }
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login-body {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  margin-top: 10%;
}
</style>
