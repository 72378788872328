<template>
  <div>
    <ErrorToast v-if="error" @hide-modal="error = ''"
      ><div slot="body">{{ error }}</div></ErrorToast
    >
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from "axios";
import API from "serviceshift-ui/api-client";

import ErrorToast from "@/components/ErrorToast.vue";

const SCORE_THRESHOLD = 0.5; // Google recommended default

export default {
  components: {
    ErrorToast
  },
  props: {
    action: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      error: ""
    };
  },
  computed: {
    ffEnabled() {
      return this.$store.state.tenant?.feature_flags.recaptcha_enabled;
    }
  },
  methods: {
    async validate() {
      if (!this.ffEnabled) return true;
      try {
        const token = await this.$recaptcha(this.action);
        if (!token) return false;
        const response: AxiosResponse<{
          success: boolean;
          score: number;
          challenge_ts: Date; // timestamp of the challenge load (ISO format yyyy-MM-dd'T'HH:mm:ssZZ)
          hostname: string; // the hostname of the site where the reCAPTCHA was solved
          "error-codes": string[]; // optional
        }> = await API.makeRequest("/v1/users/siteverify", {
          method: "POST",
          data: { token }
        });
        const { success, score } = response.data;
        const valid = success && score > SCORE_THRESHOLD;
        if (!valid) {
          this.error = "Unexpected error occurred, please try again later.";
        }
        return valid;
      } catch (e) {
        return false;
      }
    }
  }
};
</script>
