import API from "serviceshift-ui/api-client";
import { AuthLocalStorage } from "@/lib/auth";

import debounce from "../lib/debounce";

export default {
  data() {
    return {
      formIsValid: false,
      requiredRules: [this.requiredValidationFunc],
      passwordRules: [this.requiredValidationFunc, this.passwordValidation],
      emailRules: [
        this.requiredValidationFunc,
        (v) => /.+@.+/.test(v) || "Email must be valid"
      ],
      emailValidationErrorMessage: "",
      addressRules: this.addressValidations,
      validAddress: this.validateAddress,
      addressValidationErrorMessage: ""
    };
  },
  methods: {
    passwordValidation(v) {
      return v.length > 5 || "Password must be at least 6 characters";
    },
    requiredValidationFunc(v) {
      return !!v || "Required field";
    },
    validateAddress(address) {
      if (!Object.keys(address).length) {
        return "Required field";
      }
      if (address.id && !this.isValidExisting(address)) {
        return "We can't find this address";
      }
      if (address.isGoogleAddress && !this.isValidGoogleAddress(address)) {
        return "Must select an address from Google suggestions or enter the address manually.";
      }
      if (this.addressValidationErrorMessage) {
        return this.addressValidationErrorMessage;
      }
      return true;
    },
    validateEmail: debounce(function (v) {
      return API.user
        .validateEmail({ email: v })
        .then(() => {
          this.emailValidationErrorMessage = "";
        })
        .catch(() => {
          this.emailValidationErrorMessage = "Email already exists";
        });
    }, 250),
    validateCoverageArea(zipCode) {
      return API.user
        .validateCoverageArea({
          email: AuthLocalStorage.email,
          zip_code: zipCode
        })
        .then(() => (this.addressValidationErrorMessage = ""))
        .catch(
          () =>
            (this.addressValidationErrorMessage = `Unfortunately, ${this.$store.state.tenant.name} does not yet service this location.`)
        );
    },
    isValidGoogleAddress(address) {
      const result =
        address.street_number &&
        address.route &&
        address.locality &&
        address.administrative_area_level_1 &&
        address.postal_code;
      return !!result;
    },
    isValidExisting(address) {
      const result =
        address.street &&
        address.city &&
        address.state &&
        address.zip_code &&
        address.latitude &&
        address.longitude &&
        !address.invalid;
      return !!result;
    },
    addressValidations(address) {
      if (address) return [!address.invalid];
      return ["We can't find this address"];
    }
  }
};
