<template>
  <Page :title="title" max-width="768" no-banner>
    <div v-if="!isLoading" class="full-width px-4">
      <div v-if="showCompletedMessage">
        <Card>
          <div class="text-center">
            This appointment is no longer active because it has been concluded
            or canceled.
          </div>
        </Card>
      </div>
      <AppointmentDetails
        v-else
        :is-customer="store.getters.isCustomer"
        :appointment="appointment"
        :job="job"
      />
    </div>
  </Page>
</template>

<script setup lang="ts">
import API from "serviceshift-ui/api-client";
import { Appointment } from "serviceshift-ui/shared/src/typings/appointment";
import { Job } from "serviceshift-ui/shared/src/typings/job";
import { computed, onMounted, ref } from "vue";
import { trackMagicLink } from "@/lib/gaEvents";
import { useStore } from "@/lib/vuex-composition";

import AppointmentDetails from "@/components/Appointment/AppointmentDetails.vue";
import Card from "@/components/Card.vue";
import Page from "@/components/Page.vue";
import { useAppointmentHookData } from "@/hooks/useAppointmentHookData";
import useTokenHook from "@/hooks/useTokenHook";

const store = useStore();
const { invalidToken, tokenResponse } = useTokenHook<{
  id: number;
}>();
const { appointment, invalidAppointment, updateAppointmentHookData } =
  useAppointmentHookData();

const loadingAppointment = ref(true);
const job = ref<Job>();

const isLoading = computed(() => {
  if (invalidToken.value) return false;
  return loadingAppointment.value;
});

const showCompletedMessage = computed(() => {
  const completedStatuses: Appointment["status"][] = [
    "completed",
    "cancelled",
    "lost_opportunity"
  ];
  if (completedStatuses.includes(appointment.value?.status as any)) return true;
  if (appointment.value?.status === "cancelled") return true;
  return invalidToken.value || invalidAppointment.value;
});

const title = computed(() => {
  if (isLoading.value) return "Loading Appointment...";
  if (appointment.value) {
    return `Appointment #${appointment.value.id}`;
  }
  return "";
});

onMounted(() => {
  if (!invalidToken.value) refreshData();
});

async function refreshData() {
  loadingAppointment.value = true;
  const appointmentId = tokenResponse.value!.id;
  trackMagicLink({ appointment_id: appointmentId });
  try {
    const { data: appointmentData } = await API.appointment.get(appointmentId);
    const { data: jobData } = (await API.makeRequest(
      `/v2/jobs/${appointmentData.job_id}`,
      {
        params: { item_grouping: 1 }
      }
    )) as { data: Job };
    job.value = jobData;
    updateAppointmentHookData({
      appointment: appointmentData,
      job: jobData
    });
  } catch (e) {
    updateAppointmentHookData(null);
  }
  loadingAppointment.value = false;
}
</script>
