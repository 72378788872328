<template>
  <div v-if="notes.length" class="notes-container">
    <div class="sub-header-text">NOTES</div>
    <Table>
      <thead>
        <tr>
          <th class="date-header">Date</th>
          <th class="note-header">Note</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="note in notesPinnedOrder" :key="note.id">
          <td class="note-date-text">
            {{ formatNoteDate(note.created_at) }}
          </td>
          <td class="comment-text">
            {{ note.comment }}
          </td>
        </tr>
      </tbody>
    </Table>
  </div>
</template>

<script>
import Table from "serviceshift-ui/components/General/Table.vue";
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";

export default {
  components: {
    Table
  },
  props: {
    /** @type {QuoteInvoiceTypes.Note[]} */
    notes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    notesPinnedOrder() {
      const invoiceNotes = this.notes.reduce(
        (separatedNotes, note) => {
          if (note.pinned) {
            separatedNotes.important.push(note);
          } else {
            separatedNotes.normal.push(note);
          }
          return separatedNotes;
        },
        { important: [], normal: [] }
      );
      return [
        ...invoiceNotes.important.sort(this.mostRecentlyUpdated),
        ...invoiceNotes.normal.sort(this.mostRecentlyUpdated)
      ];
    }
  },
  methods: {
    formatNoteDate(date) {
      const dateComponents = new Date(date)
        .toLocaleString("en-US", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit"
        })
        .split(",");
      return `${dateComponents[0]}\n${dateComponents[1]}`;
    },
    mostRecentlyUpdated(a, b) {
      return new Date(b.updated_at) - new Date(a.updated_at);
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-header-text {
  font-size: 14px;
  font-weight: bolder;
  line-height: 16px;
  margin-bottom: 1rem;
}

.notes-container {
  margin-bottom: 1rem;
}

.date-header {
  text-align: center;
  width: 15%;
}

.note-header {
  text-align: left;
  width: 85%;
}

.note-date-text {
  white-space: pre-line;
  text-align: center;
}

tbody tr td {
  text-align: center;
  font-size: 13px;
  line-height: 16px;
}

.comment-text {
  text-align: left;
  white-space: pre-line;
}
</style>
