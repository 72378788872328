import axios from "axios";
import { Appointment } from "serviceshift-ui/shared/src/typings/appointment";
import { AuthRole } from "serviceshift-ui/shared/src/typings/auth";
import { Customer } from "serviceshift-ui/shared/src/typings/customer";
import { Id } from "serviceshift-ui/shared/src/typings/globals";

import ENV from "../env";

export default {
  create: (data) => {
    return axios({
      method: "POST",
      url: `${ENV.v1Path}/users`,
      data
    });
  },
  findByEmail: (email: string) => {
    return axios<Customer>({
      method: "GET",
      url: `${ENV.v1Path}/users/find_by_email`,
      params: { email }
    });
  },
  forgotPassword: (data: {
    session: {
      email: string;
    };
  }) => {
    return axios({
      method: "POST",
      url: `${ENV.v1Path}/users/forgot_password`,
      data
    });
  },
  get: (id: Id) => {
    return axios<Customer>(`${ENV.v1Path}/users/${id}`, {
      method: "GET"
    });
  },
  getAllAppointments: (id: Id) => {
    return axios<Appointment[]>({
      method: "GET",
      url: `${ENV.v1Path}/users/${id}/appointments`
    });
  },
  login: (data: {
    role?: AuthRole;
    session: {
      email: string;
      password: string;
    };
  }) => {
    const role = data.role || "customer";
    delete data.role;
    return axios<Customer>({
      method: "POST",
      url: `${ENV.v1Path}/users/${role}/sign_in`,
      data
    });
  },
  logout: () => {
    return axios({
      method: "DELETE",
      url: `${ENV.v1Path}/users/sign_out`
    });
  },
  removePaymentMethod: (id: Id) => {
    return axios({
      method: "DELETE",
      url: `${ENV.v1Path}/payments/${id}`
    });
  },
  sendPasswordReset: (id: Id) => {
    return axios(`${ENV.v2Path}/customers/${id}/reset_password`, {
      method: "POST"
    });
  },
  update: (data: {
    user?: {
      first_name?: string;
      last_name?: string;
      phones?: string[];
      emails?: string[];
    };
  }) => {
    return axios({
      method: "PUT",
      url: `${ENV.v1Path}/users`,
      data
    });
  },
  updatePaymentMethod: (data) => {
    return axios({
      method: "POST",
      url: `${ENV.v1Path}/payments`,
      data
    });
  },
  updatePassword: (data) => {
    return axios({
      method: "POST",
      url: `${ENV.v1Path}/users/customer/change_password`,
      data
    });
  },
  validateEmail: (params: { email: string }) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/users/validate_email`,
      params
    });
  },
  validateCoverageArea: (params: {
    email: string;
    zip_code: string | number;
  }) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/users/validate/coverage-area`,
      params
    });
  }
};
