<template>
  <div class="full-width">
    <span class="overline">Membership</span>
    <v-card class="full-width bordered-card">
      <v-card-text v-if="!memberships.length" class="empty-membership">
        Contact us today at
        {{ $store.state.tenant.customizations.General.phone }} for membership
        options!</v-card-text
      >
      <v-card-text v-else>
        <div
          v-for="(membership, i) in memberships"
          :key="membership.membership_id"
        >
          <MembershipInfo :membership="membership" />
          <div
            v-if="i < memberships.length - 1"
            class="divider mt-3 mb-3"
            :class="{ wider: $vuetify.breakpoint.smAndDown }"
          ></div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MembershipInfo from "./MembershipInfo.vue";

export default {
  components: {
    MembershipInfo
  },
  props: {
    memberships: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.empty-membership {
  text-align: center;
  font-size: 12px;
}

.divider {
  height: 1px;
  background-color: #e0e0e0;
}

.wider {
  height: 2px;
}
</style>
