<template>
  <div class="quote-group">
    <div class="group-header" @click="toggleExpanded">
      <span>
        {{ formattedStatus }}
        <i v-if="showCheckMark" class="fas fa-check check-icon" />
      </span>
      <ChevronIcon :direction="iconDirection" light class="chevron-icon" />
    </div>
    <Transition>
      <div v-if="expanded">
        <div
          v-for="quote in sortedQuotes"
          :key="quote.id"
          class="quote-card-cont"
        >
          <QuoteCard
            :quote="quote"
            :tech-mode="techMode"
            @quote-card-click="$emit('quote-card-click', $event)"
          >
            <slot name="content" :quote="quote" />
          </QuoteCard>
          <div class="quote-card-divider" />
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import ChevronIcon from "serviceshift-ui/components/General/ChevronIcon.vue";
import Transition from "serviceshift-ui/components/General/Transition.vue";
import QuoteCard from "serviceshift-ui/components/MultipleQuotes/QuoteCard.vue";
import { capitalize } from "serviceshift-ui/shared/src/mixins";

const groupNameMap = {
  pending: "Open",
  approved: "Accepted"
};

export default {
  name: "QuoteGroup",
  components: {
    QuoteCard,
    Transition,
    ChevronIcon
  },
  props: {
    status: {
      type: String,
      default: ""
    },
    quotes: {
      type: Array,
      default: () => []
    },
    techMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      expanded: this.status !== "archived" && this.status !== "voided"
    };
  },
  computed: {
    formattedStatus() {
      // Maps pending status name to Open
      return groupNameMap[this.status]
        ? groupNameMap[this.status]
        : capitalize(this.status);
    },
    iconDirection() {
      return this.expanded ? "up" : "down";
    },
    showCheckMark() {
      return this.status === "approved";
    },
    sortedQuotes() {
      return [...this.quotes].sort((a, b) => {
        return a.id < b.id ? -1 : 1;
      });
    }
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
    }
  }
};
</script>

<style lang="scss" scoped>
.quote-group {
  border-radius: 4px;
  box-shadow: $box-shadow;
}
.group-header {
  border-radius: 4px 4px 0 0;
  background-color: $tertiary-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-weight: bold;
  padding: 5px 15px;
  cursor: pointer;
  font-size: 1.2em;
}
.quote-card-cont {
  .quote-card-divider {
    border-top: 1px solid $separator-gray;
    margin: 0 15px;
    box-sizing: border-box;
  }
  &:last-of-type {
    .quote-card-divider {
      display: none;
    }
  }
}
.chevron-icon {
  height: 1em;
}

.check-icon {
  font-size: 1em;
  margin-left: 8px;
}
</style>
