<template>
  <div class="avatar" :style="`background-color: ${color}`">
    {{ content }}
    <i v-if="!content" class="fas fa-user text-white user-icon" />
  </div>
</template>

<script>
import { awsAsset } from "serviceshift-ui/shared/src/mixins/aws";

const avatarColorPalette = [
  "#864E09",
  "#006607",
  "#5B089B",
  "#F1911B",
  "#00AE0B",
  "#9C2AF4",
  "#A30000",
  "#A38500",
  "#00778F",
  "#DE0000",
  "#FFDA33",
  "#00CCF5",
  "#00058F",
  "#00538F"
];

export default {
  props: {
    content: { type: String, default: "" },
    id: { type: [Number, String], default: null }
  },
  data() {
    return {
      color: "",
      avatars: {}
    };
  },
  watch: {
    id(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getAvatarColor();
      }
    }
  },
  mounted() {
    this.loadAvatars();
    this.getAvatarColor();
  },
  methods: {
    shuffleArray(arr) {
      return [...arr].sort(() => Math.random() - 0.5);
    },
    loadAvatars() {
      // Check for cached avatars
      // This helps users see the same avatars across reloads
      const avatarsCache = localStorage.getItem("serviceshift-avatars");
      if (avatarsCache) {
        this.avatars = JSON.parse(avatarsCache);
      }
    },
    getAvatarColor() {
      if (this.avatars[this.id]) {
        this.color = this.avatars[this.id].color;
        return;
      }
      // Get new color and add to cache
      this.generateNewAvatar();
    },
    generateNewAvatar() {
      const shuffledColorPalette = this.shuffleArray([...avatarColorPalette]);
      const colorsInUse = Object.keys(this.avatars)
        .filter((key) => this.avatars[key].color)
        .map((key) => this.avatars[key].color);
      // Generate new avatars
      let colors = [...shuffledColorPalette].filter(
        (color) => !colorsInUse.includes(color)
      );
      if (colors.length === 0) {
        colors = [...shuffledColorPalette];
      }

      this.avatars = {
        ...this.avatars,
        [this.id]: {
          content: "",
          color: colors.shift()
        }
      };

      localStorage.setItem(
        "serviceshift-avatars",
        JSON.stringify(this.avatars)
      );

      // Set newly created avatar color
      this.color = this.avatars[this.id].color;
    },
    getSvg(svgName) {
      return awsAsset(svgName, "leaderboard", "svg");
    }
  }
};
</script>

<style lang="scss" scoped>
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.user-icon {
  font-size: 24px;
  background: transparent;
}
</style>
