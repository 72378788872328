<template>
  <BaseCard :style="cardStyle">
    <div
      v-if="!headless"
      class="card-header d-flex justify-content-between align-items-center border-bottom"
      :class="{ 'disabled-header': disabledHeader }"
    >
      <slot
        v-if="!lgTitle"
        name="title"
        class="font-weight-bold card-title-text"
      />
      <h5 v-else-if="title" class="card-title-text !tw-mb-0">
        {{ title }}
      </h5>
      <slot name="header" />
    </div>
    <div class="card-body">
      <slot><slot name="body" /></slot>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "../../components/Card/BaseCard.vue";

export default {
  name: "Card",
  components: {
    BaseCard
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    lgTitle: Boolean,
    cardStyle: {
      type: [String, Object],
      default: ""
    },
    headless: {
      type: Boolean,
      default: false
    },
    disabledHeader: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.card-title-text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $text-color-ss;
}
.card-header {
  border-radius: 25px 25px 0 0;
  padding: 0.75rem 0;
  margin: 0 1.25rem;
}
.card-body {
  color: $text-color-ss;
}
.disabled-header {
  background-color: var(--disabled-gray);
}
</style>
