import axios from "axios";

import ENV from "../env";

export default {
  approveInvoice: (jobId, data) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/work_confirmation`, {
      method: "POST",
      data: {
        signature: data
      }
    });
  },
  download: (jobId, newTab = false) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/invoice_pdf`, {
      method: "GET"
    }).then((res) => {
      // Auto-download file
      const link = document.createElement("a");
      link.href = res.data.invoice_pdf.url;
      link.download = `Job_${jobId}_Invoice.pdf`;
      if (newTab) {
        link.target = "_blank";
      }
      link.click();
      return res;
    });
  },
  get: (jobId) => {
    return axios(`${ENV.v2Path}/jobs/${jobId}/invoice`, {
      method: "GET"
    });
  }
};
