<template>
  <div class="warningMessage">
    <div class="tw-flex tw-items-center">
      <i class="fas fa-exclamation-triangle tw-mr-1" />
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WarningMessage"
};
</script>

<style lang="scss" scoped>
.warningMessage {
  display: flex;
  align-items: center;
  align-content: center;
  background: $warning-color;
  padding: 5px 10px;
  margin-bottom: 0.5rem;
  border-radius: 3px;
  font-weight: bold;
  > div {
    margin: auto;
  }
}
.fa-exclamation-triangle {
  font-size: 1.1em;
  position: relative;
  top: 1px;
}
</style>
