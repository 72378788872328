<template>
  <div>
    <div v-if="components.length" class="section-spacing-10">
      <div
        v-for="(component, index) in components"
        :key="index"
        class="componentTable section-spacing-8"
      >
        <div
          v-for="fieldName in sortedFields"
          :key="fieldName"
          class="componentTable__row"
        >
          <strong class="componentTable__row__field">
            {{ getDisplayForField(fieldName) }}
          </strong>
          <div class="componentTable__row__value">
            {{ getTableValueDisplay(component, fieldName) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="tw-mb-0">No Components Found</p>
    </div>
  </div>
</template>

<script>
import sortBy from "lodash/sortBy";
import ComponentType from "serviceshift-ui/shared/src/classes/componentType";
import { fromNow } from "serviceshift-ui/shared/src/mixins";
import {
  tableFieldDisplay,
  tableValueDisplay
} from "serviceshift-ui/shared/src/mixins";

export const propHelpers = { Component: ComponentType };

export default {
  name: "ComponentTable",
  props: {
    fieldLabelLookup: {
      type: Object,
      default: () => {
        return {
          age: "Age",
          install_location: "Location",
          warranty: "Included Warranty",
          manufactured_on: "Manufacturer Date",
          installed_on: "Installation Date"
        };
      }
    },
    components: {
      type: Array, // Component[]
      default: () => []
    }
  },
  data: function () {
    return {
      sortedFields: [
        "name",
        "manufacturer",
        "install_location",
        "warranty",
        "serial_number",
        "model_number",
        "manufactured_on",
        "installed_on",
        "age"
      ],
      tableValueDisplay
    };
  },
  computed: {
    sortedComponents() {
      return sortBy(this.components, (component) =>
        (component.name || "").toLowerCase()
      );
    }
  },
  methods: {
    getTableValueDisplay(component, fieldName) {
      if (fieldName === "age") {
        return fromNow(component.installed_on || component.manufactured_on);
      }
      return tableValueDisplay(component[fieldName]);
    },
    getDisplayForField(fieldName) {
      if (this.fieldLabelLookup[fieldName])
        return this.fieldLabelLookup[fieldName];
      return tableFieldDisplay(fieldName);
    }
  }
};
</script>

<style lang="scss" scoped>
.componentTable {
  &__row {
    display: flex;
    align-items: center;
    &__value {
      margin-left: auto;
    }
  }
  &:not(:last-of-type) {
    padding-bottom: 20px;
    border-bottom: 1px solid $border-dark-color;
  }
  &:not(:first-of-type) {
    margin-top: 20px;
  }
}
</style>
