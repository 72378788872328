<template>
  <div class="billingAddressForm">
    <div class="label mb-1">Credit Card Billing Address</div>
    <RadioGroup
      v-model="addressType"
      :options="addressOptions"
      class="addressOptions"
    >
      <template #option-current-content>
        <div class="text-small">
          {{ address.street }}
          <br />
          {{ address.city }}, {{ address.state }} {{ address.zip_code }}
        </div>
      </template>
      <template #option-new-content>
        <div class="text-small">Use different address</div>
      </template>
    </RadioGroup>
    <div v-if="addressType === 'new'" class="mt-2">
      <PaymentMethodInput
        required
        :error="errorMessages.street"
        label="Address"
        class="border-bottom flex-1 mr-4 mb-1"
      >
        <Input v-model="formValues.street" solo />
      </PaymentMethodInput>
      <div class="d-flex">
        <PaymentMethodInput
          required
          :error="errorMessages.city"
          label="City"
          class="border-bottom mr-4"
        >
          <Input v-model="formValues.city" solo />
        </PaymentMethodInput>
        <PaymentMethodInput
          required
          :error="errorMessages.state"
          label="State"
          class="border-bottom mr-4"
        >
          <Select v-model="formValues.state" search :options="STATE_OPTIONS" />
        </PaymentMethodInput>
        <PaymentMethodInput
          required
          :error="errorMessages.zip_code"
          label="Zip Code"
          class="border-bottom"
        >
          <Input
            v-model="formValues.zip_code"
            solo
            type="tel"
            placeholder="Zip Code"
          />
        </PaymentMethodInput>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { STATE_OPTIONS } from "serviceshift-ui/shared/src/lib/stateOptions";
import {  ref } from "vue";

import Input from "@/components/Input.vue";
import PaymentMethodInput from "@/modules/paymentMethods/components/PaymentMethodInput.vue";

const props = defineProps({
  address: {
    type: Object as () => {
      city: string;
      state: string;
      street: string;
      zip_code: string;
    },
    default: null
  }
});
const addressType = ref<"current" | "new">("current");
const errorMessages = ref({
  street: "",
  zip_code: "",
  city: "",
  state: ""
});
const formValues = ref({
  street: "",
  zip_code: "",
  city: "",
  state: ""
});

const addressOptions = [{ value: "current" }, { value: "new" }];

function clearErrors() {
  Object.keys(errorMessages.value).forEach((key) => {
    errorMessages.value[key] = "";
  });
}

async function getData() {
  clearErrors();
  const data = formValues.value;
  if (addressType.value === "current") return props.address;
  if (!data.street) {
    const error = "Street Address is required";
    errorMessages.value.street = error;
    throw error;
  }
  if (!data.city) {
    const error = "City is required";
    errorMessages.value.city = error;
    throw error;
  }
  if (!data.state) {
    const error = "State is required";
    errorMessages.value.state = error;
    throw error;
  }
  if (!data.zip_code) {
    const error = "Zip code is required";
    errorMessages.value.zip_code = error;
    throw error;
  }
  return data;
}

defineExpose({
  getData
});
</script>

<style lang="scss">
.billingAddressForm {
  .addressOptions {
    display: flex;
    .group {
      flex: 1;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
</style>
