<template>
  <div class="sera-accordion">
    <AccordionItem
      v-for="(_item, idx) in items"
      :key="_item ? JSON.stringify(_item) : idx"
      :item="_item"
      :selected-item="selectedItem"
      @expand-item="handleExpand"
    >
      <template #header="{ item }"
        ><slot name="header" :item="item"
      /></template>
      <template #content="{ item }"
        ><slot name="content" :item="item"
      /></template>
    </AccordionItem>
  </div>
</template>

<script>
import AccordionItem from "./AccordionItem.vue";

export default {
  name: "Accordion",
  components: { AccordionItem },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    defaultOpen: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      selectedItem: null
    };
  },
  watch: {
    defaultOpen(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setDefaultSelected();
      }
    },
    items(newValue, oldValue) {
      if (newValue !== oldValue) this.setDefaultSelected();
    }
  },
  mounted() {
    this.setDefaultSelected();
  },
  methods: {
    handleExpand(item) {
      this.selectedItem = item;
    },
    setDefaultSelected() {
      if (this.defaultOpen !== null && this.defaultOpen !== undefined) {
        this.selectedItem = this.items[this.defaultOpen];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sera-accordion {
  background-color: #f4f5f5;
  padding: 0 15px;
  border-radius: 5px;
}
</style>
