<template>
  <div>
    <Page title="Your Appointments" max-width="850">
      <UnpaidInvoices @pay-balance="handlePayBalance" />
      <Datatable
        ref="datatable"
        :headers="headers"
        :report-path="'/reports/consumer/appointments.json'"
        :sort-by="'date'"
        :sort-desc="true"
        :instruction-text="'Select any row to view details'"
        @click:row="handleRowClick"
      />
    </Page>
    <InvoicePaymentModal
      v-model="showPaymentModal"
      :invoice-id="selectedInvoiceId"
    />
  </div>
</template>

<script>
import Datatable from "@/components/Datatable.vue";
import InvoicePaymentModal from "@/components/InvoicePaymentModal.vue";
import Page from "@/components/Page.vue";
import UnpaidInvoices from "@/components/UnpaidInvoices.vue";

export default {
  name: "ServiceHistory",
  components: {
    Datatable,
    InvoicePaymentModal,
    Page,
    UnpaidInvoices
  },
  data() {
    return {
      headers: [
        { text: "Date", value: "date" },
        { text: "Location", value: "address_name" },
        { text: "Service Address", value: "address_short" },
        { text: "Appointment", value: "service_category_name" },
        { text: "Total", value: "invoice_total" },
        { text: "Status", value: "status" }
      ],
      selectedInvoiceId: null,
      showPaymentModal: false
    };
  },
  methods: {
    handleRowClick(item) {
      this.$router.push({
        name: "appointmentSummary",
        params: { id: item.id }
      });
    },
    handlePayBalance(invoiceId) {
      this.selectedInvoiceId = invoiceId;
      this.showPaymentModal = true;
    }
  }
};
</script>

<style lang="scss"></style>
