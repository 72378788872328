<template>
  <div class="totals d-flex flex-column" :class="{ 'edit-mode': editing }">
    <div class="d-flex flex-column adjustments-cont total-collection">
      <div
        v-if="
          (editing && lateFeesEnabled) || data.financials.late_fee_cents !== 0
        "
        class="display-row fee-row"
      >
        <span>Technician Late Fee</span>
        <span v-if="!editing || techMode">{{
          ((data.financials.late_fee_cents / 100) * -1) | currency
        }}</span>
        <span v-else>
          <CurrencyInput
            :value="data.financials.late_fee_cents"
            class="late-fee-input"
            @price-change="handlePriceChange"
          />
        </span>
        <div
          v-if="editing && !techMode"
          class="remove-fee-icon"
          @click="handleRemove"
        >
          <slot name="deleteIcon">X</slot>
        </div>
      </div>
      <div
        v-if="
          data.discount_attributes ||
          data.financials.discount_amount_cents !== 0
        "
        class="display-row fee-row"
      >
        <span v-if="data.discount_attributes">
          <a v-if="editing" class="discount-link" @click="discountClick">
            Promo Code {{ data.discount_attributes.code }}
            {{
              data.discount_attributes.kind === "percentage"
                ? "(" + data.discount_attributes.percent + "%)"
                : ""
            }}
          </a>
          <span v-if="!editing">
            Promo Code {{ data.discount_attributes.code }}
            {{
              data.discount_attributes.kind === "percentage"
                ? "(" + data.discount_attributes.percent + "%)"
                : ""
            }}
          </span>
        </span>
        <span v-if="!data.discount_attributes">Discount</span>
        <span>{{
          ((data.financials.discount_amount_cents / 100) * -1) | currency
        }}</span>
        <div
          v-if="editing && data.discount_attributes.id"
          class="remove-fee-icon"
          @click="handleRemoveDiscount(data.discount_attributes.id)"
        >
          <slot name="deleteIcon">X</slot>
        </div>
      </div>
      <div v-if="!data.discount_attributes && editing" class="display-row">
        <span>
          <a href="javascript:;" class="discount-link" @click="discountClick"
            >Add Promo Code</a
          >
        </span>
        <span>--</span>
      </div>
      <div class="display-row">
        <span>
          <a
            v-if="editing && editTaxesEnabled"
            href="javascript:;"
            class="tax-link"
            @click="taxesClick"
          >
            Tax
            <i class="far fa-pen-to-square" />
          </a>
          <span v-else>Tax</span>
        </span>
        <span>{{ (data.financials.sales_tax_cents / 100) | currency }}</span>
      </div>
    </div>
    <div class="total-collection">
      <div class="display-row">
        <span v-if="type === 'quote'">Estimated Total</span>
        <span v-if="type !== 'quote'">Total</span>
        <span>{{ (data.financials.total_cents / 100) | currency }}</span>
      </div>

      <div v-if="type === 'invoice'">
        <div class="display-row">
          <span v-if="techMode">
            <a class="payments-link" @click="paymentsClick">Payments</a>
          </span>
          <span v-else>Payments</span>
          <span v-if="data.financials.total_transactions_cents > 0">
            {{ (data.financials.total_transactions_cents / 100) | currency }}
          </span>
          <span v-if="data.financials.total_transactions_cents <= 0">--</span>
        </div>
        <div class="display-row">
          <span>Balance Remaining</span>
          <span v-if="data.financials.balance_cents > 0">{{
            (data.financials.balance_cents / 100) | currency
          }}</span>
          <span v-if="data.financials.balance_cents <= 0">--</span>
        </div>
      </div>
    </div>

    <div
      v-if="!editing && Math.abs(data.financials.savings_cents) > 0"
      class="savings-cont"
    >
      <img
        v-if="Math.abs(data.financials.savings_cents) > 0"
        :src="HandDrawnCircle"
      />
      <div
        v-if="data.financials.savings_cents > 0"
        class="display-row savings-total"
      >
        <span>
          <a
            v-if="type !== 'invoice'"
            class="savings-link"
            @click="savingsClick"
            >Membership savings!</a
          >
          <span v-else>Membership Savings!</span>
        </span>
        <span>{{ (data.financials.savings_cents / 100) | currency }}</span>
      </div>
      <div
        v-if="data.financials.savings_cents < 0"
        class="display-row savings-total"
      >
        <span>You could have saved:</span>
        <span>
          {{ (Math.abs(data.financials.savings_cents) / 100) | currency }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyInput from "serviceshift-ui/components/Inputs/CurrencyInput.vue";
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import HandDrawnCircle from "serviceshift-ui/shared/src/assets/hand_drawn_circle.svg";
import currency from "serviceshift-ui/shared/src/filters/currency";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";
import debounce from "serviceshift-ui/shared/src/mixins/debounce";

export default {
  components: {
    CurrencyInput
  },
  filters: { currency },
  props: {
    /** @type { QuoteInvoiceTypes.Quote } */
    data: {
      type: Object,
      default: () => ({})
    },
    editing: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ""
    },
    discountId: {
      type: Number,
      default: null
    },
    lateFeesEnabled: {
      type: Boolean,
      default: false
    },
    editTaxesEnabled: {
      type: Boolean,
      default: false
    },
    techMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      HandDrawnCircle,
      moneyConfig: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        suffix: "",
        precision: 2,
        masked: false
      }
    };
  },
  computed: {
    eventId() {
      return this.data.invoice_id || this.data.id;
    }
  },
  methods: {
    handlePriceChange: debounce(function (value) {
      if (value !== this.data.financials.late_fee_cents) {
        EventBus.$emit(
          "edit-late-fee",
          {
            target: {
              value
            }
          },
          this.eventId
        );
      }
    }, 750),
    handleRemove() {
      EventBus.$emit("remove-late-fee", this.eventId);
    },
    discountClick() {
      EventBus.$emit("discount-click", this.eventId);
    },
    taxesClick() {
      EventBus.$emit("taxes-click", this.eventId);
    },
    handleRemoveDiscount(id) {
      EventBus.$emit("remove-discount", id, this.eventId);
    },
    paymentsClick() {
      EventBus.$emit("payments-click", this.eventId);
    },
    savingsClick() {
      EventBus.$emit("savings-click", this.eventId);
    }
  }
};
</script>

<style lang="scss" scoped>
.discount-select {
  justify-content: flex-end;
  align-items: center;
  span {
    &:first-of-type {
      display: flex;
      justify-content: flex-end;
      ion-item {
        background: transparent;
        width: 188px;
        font-size: 1.6rem;
        margin-right: -45px;
        height: 2rem;
        .select-icon {
          display: none;
        }
        .select-placeholder {
          color: $primary-color;
        }
        .item-inner {
          border-bottom: none;
        }
        ion-select {
          max-width: none;
        }
      }
    }
  }
}
.fee-row {
  position: relative;
  .remove-fee-icon {
    position: absolute;
    color: $primary-color;
    font-size: 1.3em !important;
    right: -36px;
    top: -3px;
    cursor: pointer;
  }
}
.total-collection {
  padding: 5px 10px 0px !important;
}
.totals {
  background-color: $light-color;
  .adjustments-cont {
    background-color: $pale-blue;
  }
}
.savings-total {
  padding: 5px 10px !important;
  font-weight: bold;
  font-style: italic;
  background-color: $pale-blue;
  height: 50px;
  align-items: center;
}
.savings-cont {
  position: relative;
  img {
    position: absolute;
    width: 100px;
    top: 5px;
    right: 0;
    -webkit-animation: puff-in-center 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
      1s both;
    animation: puff-in-center 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 1s both;
  }
}
.display-row {
  display: flex;
  margin-bottom: 5px;

  span {
    text-align: right;
    width: 95px;
    &:first-of-type {
      flex: 3;
    }
  }
}
.late-fee-input {
  width: 80px;
  text-align: center;
  border: 1px solid $primary-accent-color;
  border-radius: 5px;
  min-height: 30px;
  font-family: inherit;
  padding: 0 5px;
}

.edit-mode {
  .total-collection {
    padding: 5px 46px 0px !important;
  }
  .savings-total {
    padding: 5px 50px !important;
  }
  .savings-cont {
    img {
      right: 35px;
    }
  }
}
</style>
