<template>
  <tr
    ref="itemRow"
    :class="{
      lineItemRow: itemIndex > 0,
      'selected-group-leader': isSelectedGroupLeader,
      'is-selected': isSelectedForGrouping
    }"
    :data-id="item.id"
    :data-group-id="item.group_id"
  >
    <template v-if="editing">
      <td class="first-cell">
        <div
          class="tw-flex tw-flex-col tw-items-center tw-h-full left-actions-cell"
          :class="{
            'group-child-row': isGroup && itemIndex > 0
          }"
        >
          <div class="tw-flex">
            <div v-if="groupingEnabled" class="tw-mr-4" style="margin-top: 4px">
              <Tooltip
                v-if="isSelectedGroupLeader"
                :options="{
                  theme: 'light',
                  showOnCreate: true,
                  trigger: 'manual',
                  placement: 'top',
                  distance: 10
                }"
              >
                <template #trigger>
                  <Checkbox
                    v-if="(isGroup && itemIndex === 0) || !isGroup"
                    v-model="selected"
                  />
                </template>
                <template #tooltip>
                  <div class="text-center tw-p-2">
                    <span v-if="groupItems.length < 2">
                      {{
                        isGroup
                          ? "Select items to add to this group"
                          : "Add more items to create a group"
                      }}
                    </span>
                    <SeraButton
                      v-else
                      icon="fa fa-object-group"
                      @click="handleAction('group-selected-items', item)"
                    >
                      Group {{ groupItems.length }} items
                    </SeraButton>
                  </div>
                </template>
              </Tooltip>
              <Checkbox
                v-else-if="(isGroup && itemIndex === 0) || !isGroup"
                v-model="selected"
              />
            </div>
            <div
              class="col-drag drag-handle"
              :class="{
                'lineItemRow-drag-handle': isGroup && itemIndex === 0,
                'group-item-drag-handle ': itemIndex > 0
              }"
            >
              <i class="fas fa-grip-lines grip-icon" />
            </div>
          </div>
        </div>
      </td>
    </template>
    <td class="col-qty">
      <span v-if="!editing">{{ item.quantity }}</span>
      <input
        v-if="editing"
        class="quantity-input"
        type="number"
        min="0"
        :value="item.quantity"
        @blur="handleEditQuantity($event.target.value, item)"
      />
    </td>
    <td class="col-lineItemRowDetails">
      <LineItemRowDetails
        :editing="editing"
        :item="item"
        :type="lineItemType"
        :tech-mode="techMode"
        :event-id="eventId"
        :open.sync="internalLineItemRowDetailsOpen[item.id]"
        :equipment-types-enabled="equipmentTypesEnabled"
      />
      <div v-if="items.length > 1 && itemIndex === 0" class="alsoIncludes">
        <strong>Also includes:</strong>
      </div>
    </td>
    <td
      v-if="twoColumn"
      class="col-per-unit comparison-value align-cell-baseline"
    >
      <span v-if="isFirstRowInGroupAndPresentationMode(itemIndex)">
        {{ getCostDisplay(value.comparison_price_cents, item) }}
      </span>
      <span v-else>
        {{ getCostDisplay(item.comparison_price_cents, item) }}
      </span>
    </td>
    <td v-if="twoColumn" class="col-total comparison-value align-cell-baseline">
      <span v-if="isFirstRowInGroupAndPresentationMode(itemIndex)">
        {{ getCostDisplay(value.comparison_total_cents, item) }}
      </span>
      <span v-else>
        {{ getCostDisplay(item.comparison_total_cents, item) }}
      </span>
    </td>
    <td v-if="editing && editTaxesEnabled" class="col-tax">
      <Checkbox :value="taxable" @input="handleTaxableChange($event, item)" />
    </td>
    <td class="col-per-unit">
      <div v-if="!techMode && editing">
        <CurrencyInput
          :value="item.unit_price_cents"
          class="unit-price-input"
          @price-change="handlePriceChange($event, item)"
        />
      </div>
      <div v-else>
        <span v-if="isFirstRowInGroupAndPresentationMode(itemIndex)">
          {{ getCostDisplay(value.unit_price_cents) }}
        </span>
        <span v-else>
          {{ getCostDisplay(item.unit_price_cents, item) }}
        </span>
      </div>
    </td>
    <td class="col-total">
      <span v-if="isFirstRowInGroupAndPresentationMode(itemIndex)">
        {{ getCostDisplay(value.total_cents) }}
      </span>
      <span v-else>
        {{ getCostDisplay(item.total_cents, item) }}
      </span>
    </td>
    <td v-if="editing" class="col-actions">
      <ActionsMenu
        v-if="isGroup && itemIndex === 0"
        :actions="actionsForGroupLeader"
        :options="actionMenuOptions"
        :tech-mode="techMode"
        @ungroup-items="handleAction('ungroup-items', item)"
        @delete-main-item="handleAction('delete-main-item', item)"
      >
        <template #trigger>
          <div class="icon actions-icon">
            <slot name="actionsIcon" />
          </div>
        </template>
      </ActionsMenu>
      <ActionsMenu
        v-else-if="isGroup"
        :actions="actionsForGroupChild"
        :options="actionMenuOptions"
        :tech-mode="techMode"
        @ungroup-item="handleAction('ungroup-item', item)"
        @delete-item="handleAction('delete-item', item)"
      >
        <template #trigger>
          <div class="icon actions-icon">
            <slot name="actionsIcon" />
          </div>
        </template>
      </ActionsMenu>
      <div v-else class="icon" @click="handleRemoveItem(item)">
        <slot name="deleteIcon" />
      </div>
    </td>
  </tr>
</template>

<script>
import ActionsMenu from "serviceshift-ui/components/General/ActionsMenu.vue";
import Tooltip from "serviceshift-ui/components/General/Tooltip.vue";
import SeraButton from "serviceshift-ui/components/Inputs/Button.vue";
import Checkbox from "serviceshift-ui/components/Inputs/Checkbox.vue";
import CurrencyInput from "serviceshift-ui/components/Inputs/CurrencyInput.vue";
import * as QuiteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import LineItemRowDetails from "serviceshift-ui/components/QuoteInvoice/LineItemRowDetails.vue";
import currency from "serviceshift-ui/shared/src/filters/currency";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  components: {
    ActionsMenu,
    CurrencyInput,
    LineItemRowDetails,
    Checkbox,
    Tooltip,
    SeraButton
  },
  filters: {
    currency
  },
  props: {
    /** @type { QuiteInvoiceTypes.LineItem } */
    item: {
      type: Object,
      default: null
    },
    itemIndex: {
      type: Number,
      default: null
    },
    equipmentTypesEnabled: {
      type: Boolean,
      default: false
    },
    groupingEnabled: {
      type: Boolean,
      default: true
    },
    editTaxesEnabled: {
      type: Boolean,
      default: false
    },
    lineItemRowDetailsOpen: {
      type: Object,
      default: () => ({})
    },
    eventId: {
      type: Number,
      required: true
    },
    /** @type { QuiteInvoiceTypes.Group } */
    value: {
      type: Object,
      default: null,
      required: true
    },
    lineItemType: {
      type: String,
      default: "quote"
    },
    editing: {
      type: Boolean,
      default: false
    },
    techMode: {
      type: Boolean,
      default: false
    },
    twoColumn: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    groupItems: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tippyOptions: {
        hideOnClick: true,
        placement: "left",
        trigger: "click",
        theme: `light actions-menu`
      },
      selected: false,
      taxable: this.item.tax_setting?.taxable
    };
  },
  computed: {
    actionMenuOptions() {
      return {
        appendTo: document.body
      };
    },
    internalLineItemRowDetailsOpen: {
      get() {
        return this.lineItemRowDetailsOpen;
      },
      set(val) {
        this.$emit("update:lineItemRowDetailsOpen", {
          ...this.lineItemRowDetailsOpen,
          [this.item.id]: val
        });
      }
    },
    isGroup() {
      return this.items.length > 1;
    },
    actionsForGroupLeader() {
      return [
        {
          label: "Ungroup Items",
          icon: "fa fa-object-ungroup",
          eventName: "ungroup-items"
        },
        {
          label: "Delete Main Item",
          icon: "fa fa-trash-alt",
          eventName: "delete-main-item"
        }
      ];
    },
    actionsForGroupChild() {
      return [
        {
          label: "Remove From Group",
          icon: "fa fa-object-ungroup",
          eventName: "ungroup-item"
        },
        {
          label: "Delete Item",
          icon: "fa fa-trash-alt",
          eventName: "delete-item"
        }
      ];
    },
    actionsForGrouping() {
      return [
        {
          label: `Group Items (${this.groupItems.length})`,
          icon: "fa fa-object-group",
          eventName: "group-items",
          disabled: this.groupItems.length < 2
        }
      ];
    },
    isSelectedGroupLeader() {
      return this.groupItems.length && this.groupItems[0].id === this.item.id;
    },
    isSelectedForGrouping() {
      const groupLeader = this.items[0];
      return (
        this.groupItems.length &&
        this.groupItems.some(
          (item) => item.id === this.item.id || item.id === groupLeader.id
        )
      );
    }
  },
  watch: {
    selected(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("group-item-selected", this.item, newValue);
      }
    },
    item(newValue) {
      this.taxable = newValue.tax_setting?.taxable;
    }
  },
  mounted() {
    EventBus.$on("clear-group-selected", this.clearSelected);
    if (this.groupItems.some((groupItem) => groupItem.id === this.item.id)) {
      this.selected = true;
    }
  },
  beforeDestroy() {
    EventBus.$off("clear-group-selected", this.clearSelected);
  },
  methods: {
    clearSelected(eventId) {
      if (eventId === this.eventId) {
        this.selected = false;
      }
    },
    isFirstRowInGroupAndPresentationMode(itemIndex) {
      return this.isGroup && !this.editing && itemIndex === 0;
    },
    /** @type { string | undefined } */
    getCostDisplay(priceInCents, item) {
      if (item && !this.editing && this.items.indexOf(item) > 0)
        return undefined;
      return currency(priceInCents / 100);
    },
    handleAction(action, item) {
      if (action === "ungroup-item") {
        return this.$emit("ungroup-item", item);
      }
      if (action === "delete-item") {
        return this.handleRemoveItem(item);
      }
      this.$emit("action", { eventName: action, item, id: this.eventId });
    },
    handleEditQuantity(item, val) {
      this.$emit("edit-quantity", item, val);
    },
    handleRemoveItem(item) {
      this.$emit("remove-item", item);
    },
    handlePriceChange($event, item) {
      this.$emit("price-change", $event, item);
    },
    handleTaxableChange($event, item) {
      this.taxable = $event;
      this.$emit("taxable-change", $event, item);
    }
  }
};
</script>

<style scoped lang="scss">
.first-cell {
  width: 60px;
}
.left-actions-cell {
  position: relative;
  top: 6px;
}
.group-child-row {
  margin-left: 20px;
  top: 6px;
}
.is-selected {
  box-shadow: 0 0 0 1px $primary-color inset;
}
.alsoIncludes {
  display: block;
  margin-top: 15px;
  padding-left: 10px;
  border-bottom: 1px solid $border-gray-color;
}
.lineItemRow-drag-handle {
  .grip-icon {
    background-color: $tertiary-color;
    color: white;
  }
}
</style>
