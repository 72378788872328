<template>
  <div class="signature d-flex justify-content-end">
    <div
      v-if="!data && techMode"
      class="signature-placeholder d-flex align-items-center justify-content-center font-size-lg half-width"
      @click="handleClick"
    >
      {{ placeholder }}
    </div>
    <div
      v-else-if="data"
      class="d-flex align-items-center justify-content-end full-height half-width"
    >
      <div class="d-flex flex-column" text-right>
        <span>
          {{ approvalText }}
          {{ data.kind === "overridden" ? " Manually" : "" }}
        </span>
        <span>{{ data.signature_time | date }}</span>
      </div>
      <img
        v-if="data.kind !== 'overridden'"
        :src="data.image_url"
        alt="Signature"
      />
    </div>
  </div>
</template>

<script>
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import date from "serviceshift-ui/shared/src/filters/date";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  filters: { date },
  props: {
    /** @type { QuoteInvoiceTypes.Signature } */
    data: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: "Sign Here"
    },
    type: {
      type: String,
      default: ""
    },
    approvalText: {
      type: String,
      default: "Approved"
    },
    eventId: {
      type: [Number, String],
      default: null
    },
    techMode: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      EventBus.$emit("signature-click", this.eventId);
    }
  }
};
</script>

<style lang="scss" scoped>
.signature {
  padding: 5px 0;
  img {
    height: 90px;
  }
  .signature-placeholder {
    height: 100%;
    padding: 30px;
    color: $primary-color;
    border: 3px dashed $medium-color;
    margin: 0 5px;
    text-decoration: underline;
  }
}
.full-height {
  height: 100%;
}
.half-width {
  width: 50%;
}
</style>
