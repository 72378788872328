<template>
  <div class="number-input-cont">
    <input
      ref="input"
      :value="input"
      type="number"
      class="number-input"
      v-on="{ ...$listeners, input: updateInputValue }"
    />
    <div class="arrows-container">
      <div class="number-arrow top" @click="increment">
        <ChevronIcon direction="up" />
      </div>
      <div class="number-arrow bottom" @click="decrement">
        <ChevronIcon direction="down" />
      </div>
    </div>
  </div>
</template>

<script>
import ChevronIcon from "../General/ChevronIcon.vue";

export default {
  name: "NumberInput",
  components: {
    ChevronIcon
  },
  inheritAttrs: false,
  props: {
    step: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    value: {
      type: Number,
      default: 0
    }
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        const value = val ? parseInt(val, 10) : 0;
        this.$emit("input", value);
      }
    }
  },
  methods: {
    updateInputValue(e) {
      this.input = e.target.value;
    },
    increment() {
      const newValue = this.input + this.step;
      if (this.max && newValue < this.max) {
        return;
      }
      this.input = newValue;
    },
    decrement() {
      const newValue = this.input - this.step;
      if (this.min !== null && newValue < this.min) {
        return;
      }
      this.input = newValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.number-input {
  padding: 2px 0;
  box-sizing: border-box;
  margin: 0;
  width: 40px;
  border-radius: 3px 0 0 3px;
  border-right: none;
  border-left: 1px solid #7e8fb4;
  border-top: 1px solid #7e8fb4;
  border-bottom: 1px solid #7e8fb4;
  text-align: center;
  font-family: inherit;
}
.number-input::-webkit-outer-spin-button,
.number-input::-webkit-inner-spin-button {
  opacity: 0;
  pointer-events: none;
  -webkit-appearance: none;
  margin: 0;
}
.number-input-cont {
  display: inline-flex;
}
.number-arrow {
  font-weight: bold;
  height: 100%;
  color: #fff;
  background: #fff;
  width: 30px;
  height: 15px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  background: #efefef;
  img {
    height: 0.5em;
  }
  &.top {
    border: 1px solid #7e8fb4;
    border-bottom: none;
    border-radius: 0 3px 0 0;
  }
  &.bottom {
    border: 1px solid #7e8fb4;
    border-top: none;
    border-radius: 0 0 3px 0;
  }
}
.arrows-container {
  display: flex;
  flex-direction: column;
}
</style>
