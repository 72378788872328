// Breadth first tree search
export const findNode = (tree, nodeType, nodeId) => {
  const collection = [tree];

  while (collection.length) {
    const node = collection.shift();

    if (node && node.kind === nodeType && node.id === nodeId) {
      return node;
    }

    if (node && node.children) {
      collection.push(...node.children);
    }
  }

  return null;
};

// Finds nodes which a name that match the searchText (case insensitive)
export const findMatches = (
  tree,
  searchText,
  emptySearchResults = false,
  kinds = ["task", "warranty_program", "membership_program"]
) => {
  if (!tree) {
    return [];
  }

  // Determines if no items should be return when there is no search
  // default is false
  if (emptySearchResults && !searchText) {
    return [];
  }

  const matches = [];
  const collection = [tree];
  const searchTextLower = searchText.toLowerCase();

  while (collection.length) {
    const node = collection.shift();

    if (
      kinds.includes(node.kind) &&
      node.name &&
      node.name.toLowerCase().includes(searchTextLower)
    ) {
      matches.push(node);
    }

    if (node.children) {
      collection.push(...node.children);
    }
  }

  return matches;
};

export const parentNodes = (tree) => {
  const parents = [];
  let node = tree;

  while (node.parentNode) {
    parents.push(node.parentNode);
    node = node.parentNode;
  }

  return parents.reverse();
};

/* eslint-disable no-param-reassign */
function addParentNode(parent, tree) {
  tree.parentNode = parent;

  const children = tree.children || [];
  children.forEach((item) => {
    addParentNode(tree, item);
  });
}

export const addParentNodes = (tree) => {
  if (!tree) {
    return { parentNode: null };
  }

  addParentNode(null, tree);

  return tree;
};
