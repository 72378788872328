<template>
  <div class="tw-flex tw-flex-row tw-gap-3">
    <SimpleTable :card-wrap="cardWrap">
      <thead>
        <tr>
          <th colspan="2">
            <span class="info-header font-bold"
              >Email ({{ filteredEmails.length }})</span
            >

            <ExpandButton
              v-if="alternateEmailsExist"
              class="tw-ml-4 tw-float-right"
              :title="expandEmailsButtonText"
              :expanded="showAdditionalEmails"
              @toggle-expanded="toggleShowAdditionalEmails"
            />
          </th>
        </tr>
      </thead>
      <template v-for="(email, i) in filteredEmails">
        <tr
          v-if="i === 0"
          :key="`${email.id}-primary-header`"
          class="contact-list-item-header font-bold"
        >
          <td colspan="2">Primary Email</td>
        </tr>
        <tr
          v-if="i === 0 && !email.primary"
          :key="`${email.id}-missing-primary-placeholder`"
          class="contact-list-item"
        >
          <td colspan="2">--</td>
        </tr>
        <tr
          v-if="
            showAdditionalItemHeader(
              showAdditionalEmails,
              primaryEmailExists,
              i
            )
          "
          :key="`${email.id}-additional-header`"
          class="contact-list-item-header font-bold"
        >
          <td colspan="2">Additional Email(s)</td>
        </tr>
        <tr
          :key="email.id"
          class="contact-list-item"
          :class="{
            'contact-list-item-collapsed':
              !email.primary && !showAdditionalEmails
          }"
        >
          <td class="contact-info">
            <TruncateText>
              {{ email.email_address }}
            </TruncateText>
          </td>
          <td>
            <TruncateText class="contact-label-text">
              {{ email.label }}
            </TruncateText>
          </td>
        </tr>
      </template>
      <tr v-if="!filteredEmails.length">
        <td
          class="text-danger cursor-pointer !tw-whitespace-nowrap"
          @click="$emit('edit-profile')"
        >
          <i class="fas fa-exclamation-circle" />
          Missing Email Address
        </td>
      </tr>
    </SimpleTable>
    <SimpleTable :card-wrap="cardWrap">
      <thead>
        <tr>
          <th colspan="3">
            <span class="info-header font-bold"
              >Phone ({{ phones.length }})</span
            >

            <ExpandButton
              v-if="alternatePhonesExist"
              class="tw-ml-4 tw-float-right"
              :title="expandPhonesButtonText"
              :expanded="showAdditionalPhones"
              @toggle-expanded="toggleShowAdditionalPhone"
            />
          </th>
        </tr>
      </thead>
      <template v-for="(phone, i) in phones">
        <tr
          v-if="i === 0"
          :key="`${phone.id}-primary-header`"
          class="contact-list-item-header font-bold"
        >
          <td colspan="3">Primary Phone</td>
        </tr>
        <tr
          v-if="i === 0 && !phone.primary"
          :key="`${phone.id}-missing-primary-placeholder`"
          class="contact-list-item"
        >
          <td colspan="2">--</td>
        </tr>
        <tr
          v-if="
            showAdditionalItemHeader(
              showAdditionalPhones,
              primaryPhoneExists,
              i
            )
          "
          :key="`${phone.id}-additional-header`"
          class="contact-list-item-header font-bold"
        >
          <td colspan="3">Additional Phone(s)</td>
        </tr>
        <tr
          :key="phone.id"
          class="contact-list-item"
          :class="{
            'contact-list-item-collapsed':
              !phone.primary && !showAdditionalPhones
          }"
        >
          <td class="contact-info">
            <TruncateText>
              {{ phone.phone_number }}
            </TruncateText>
          </td>
          <td class="contact-info-icon">
            <i :class="`far fa-fw fa-${getPhoneIcon(phone)}`" />
          </td>
          <td>
            <TruncateText class="contact-label-text">
              {{ phone.label }}
            </TruncateText>
          </td>
        </tr>
      </template>
      <tr v-if="!phones.length">
        <td
          class="text-danger cursor-pointer !tw-whitespace-nowrap"
          @click="$emit('edit-profile')"
        >
          <i class="fas fa-exclamation-circle" />
          Missing Phone Number
        </td>
      </tr>
    </SimpleTable>
  </div>
</template>

<script lang="ts">
export default {
  name: "ContactList"
};
</script>

<script setup lang="ts">
import ExpandButton from "serviceshift-ui/components/General/ExpandButton.vue";
import SimpleTable from "serviceshift-ui/components/General/SimpleTable.vue";
import TruncateText from "serviceshift-ui/components/General/TruncateText.vue";
import {
  CustomerEmailsEntity,
  CustomerPhonesEntity
} from "serviceshift-ui/shared/src/typings/customer";
import { computed, ref, withDefaults } from "vue";

const props = withDefaults(
  defineProps<{
    phones: CustomerPhonesEntity[];
    emails: CustomerEmailsEntity[];
    cardWrap?: boolean;
  }>(),
  {
    phones: () => [],
    emails: () => [],
    cardWrap: false
  }
);
const showAdditionalEmails = ref(false);
const showAdditionalPhones = ref(false);
const filteredEmails = computed(() =>
  props.emails.filter((email) => email.email_address)
);
const expandEmailsButtonText = computed(() =>
  showAdditionalEmails.value ? "Hide All" : "Show All"
);
const expandPhonesButtonText = computed(() =>
  showAdditionalPhones.value ? "Hide All" : "Show All"
);

const primaryEmailExists = computed(() =>
  filteredEmails.value.some((email) => email.primary)
);
const primaryPhoneExists = computed(() =>
  props.phones.some((phone) => phone.primary)
);

const alternateEmailsExist = computed(() =>
  filteredEmails.value.some((email) => !email.primary)
);
const alternatePhonesExist = computed(() =>
  props.phones.some((phone) => !phone.primary)
);

const toggleShowAdditionalEmails = () => {
  showAdditionalEmails.value = !showAdditionalEmails.value;
};

const toggleShowAdditionalPhone = () => {
  showAdditionalPhones.value = !showAdditionalPhones.value;
};

const showAdditionalItemHeader = (toggleShow, primaryExists, index) => {
  if (!toggleShow) return false;

  switch (index) {
    case 0:
      return !primaryExists;
    case 1:
      return primaryExists;
    default:
      return false;
  }
};

const getPhoneIcon = (phone) => {
  switch (phone.type) {
    case "mobile":
      return "mobile";
    case "home":
      return "house-chimney";
    default:
      return "phone";
  }
};
</script>

<style lang="scss" scoped>
.contact-label-text {
  white-space: nowrap;
  width: 120px;
  color: $tertiary-color;
}

.contact-list-item {
  td {
    white-space: nowrap;
  }

  &.contact-list-item-collapsed {
    visibility: collapse;
  }
}

.contact-info {
  color: $gray;
  min-width: 120px;
  max-width: 200px;
}

.info-header {
  font-size: 1.1em;
}

.contact-info-icon {
  width: 50px;
  text-align: center;
}
</style>
