<template>
  <div>
    <v-dialog
      v-model="modelValue"
      :transition="
        $vuetify.breakpoint.smAndDown
          ? 'dialog-bottom-transition'
          : 'dialog-transition'
      "
      max-width="350"
    >
      <v-card>
        <div class="modal-content text-center py-5 px-5">
          <v-progress-circular class="mb-5" indeterminate />
          <div class="mb-5">
            <strong>We’re processing your payment</strong>
          </div>
          <div class="text-small mb-5">
            Please wait a few seconds to get a confirmation for your
            transaction. Do not refresh your browser.
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  value: {
    type: Boolean,
    default: () => false
  }
});

const emit = defineEmits(["input"]);

const modelValue = computed({
  get() {
    return props.value;
  },
  set(val) {
    emit("input", val);
  }
});
</script>
