export default (cents) => {
  if (!cents && cents !== 0) {
    return "--";
  }
  const fixed = cents.toFixed(2);
  try {
    return Number(parseFloat(fixed)).toLocaleString("en-us", {
      style: "currency",
      currency: "USD"
    });
  } catch (e) {
    return "$0.00";
  }
};
