<template>
  <div class="login-body">
    <div class="login-container">
      <h2 v-if="!submitted" class="display-1 text-center mb-3">
        Forgot Password
      </h2>
      <v-card v-if="!submitted" width="350px" class="bordered-card">
        <v-card-text>
          <v-form @submit.prevent="handleResetRequest">
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              prepend-inner-icon="mdi-at"
              :loading="loading"
              autofocus
            ></v-text-field>
            <div class="buttons">
              <v-btn
                block
                large
                color="primary"
                type="submit"
                :loading="loading"
              >
                Send Reset Link
              </v-btn>
              <router-link class="forgot-password" :to="{ name: 'login' }">
                Log In
              </router-link>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <v-icon class="mr-1">mdi-email-check</v-icon>Reset Email Sent
        </v-card-title>
        <v-card-text>Check your inbox for instructions.</v-card-text>
        <v-card-actions>
          <v-btn text color="primary">
            <router-link class="forgot-password" :to="{ name: 'login' }">
              Log In
            </router-link>
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import API from "serviceshift-ui/api-client";
import { AuthLocalStorage } from "@/lib/auth";

export default {
  name: "PasswordReset",
  data() {
    return {
      email: "",
      loading: false,
      submitted: false
    };
  },
  mounted() {
    const cachedEmail = AuthLocalStorage.email;
    if (cachedEmail) {
      this.email = cachedEmail;
    }
  },
  methods: {
    handleResetRequest() {
      this.loading = true;
      API.user
        .forgotPassword({
          session: { email: this.email }
        })
        .then(() => {
          window.gtag("event", "reset_password_request");
        })
        .finally(() => {
          this.loading = false;
          this.submitted = true;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.login-body {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  margin-top: 10%;
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .forgot-password {
      font-size: 0.9em;
      margin-top: 15px;
    }
  }
}
</style>
