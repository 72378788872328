<template>
  <v-dialog
    v-model="show"
    persistent
    :retain-focus="false"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="
      $vuetify.breakpoint.smAndDown
        ? 'dialog-bottom-transition'
        : 'dialog-transition'
    "
    max-width="550"
  >
    <v-toolbar dark color="secondary">
      <v-btn icon @click="dismissModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Make a Payment</v-toolbar-title>
    </v-toolbar>
    <v-card class="px-4 py-4">
      <div v-if="loading" class="text-center px-2 py-2">Loading...</div>
      <div
        v-else-if="showModalContent"
        class="modal-content invoicePaymentModalContent"
      >
        <div class="text-center mb-6">
          <CustomerInvoiceBalance class="full-width" :invoice="invoice">
            <template #actions>
              <button class="link mr-6" @click="handleViewInvoice">
                View Invoice
              </button>
            </template>
          </CustomerInvoiceBalance>
        </div>
        <PaymentForm
          :vendor="tenant.name"
          :invoice="invoice"
          customer-mode
          @payment-method-deleted="handlePaymentMethodDeleted"
          @payment-success="handlePaymentSuccess"
          @payment-failed="handlePaymentFailed"
          @payment-success-confirmed="handlePaymentSuccessConfirmed"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import API from "serviceshift-ui/api-client";
import { Customer } from "serviceshift-ui/shared/src/typings/customer";
import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router/composables";
import {
  trackFinishedPayment,
  trackPaymentFailure,
  trackStartedPayment
} from "@/lib/gaEvents";
import { useStore } from "@/lib/vuex-composition";

import CustomerInvoiceBalance from "@/modules/paymentMethods/components/CustomerInvoiceBalance.vue";
import PaymentForm from "@/modules/paymentMethods/components/PaymentForm.vue";
import Invoice from "@/views/Invoice.vue";

const store = useStore();
const router = useRouter();
const tenant = store.state.tenant;

const props = defineProps({
  value: {
    type: Boolean,
    default: () => false
  },
  customer: {
    type: Object as () => Customer,
    default: () => null
  },
  invoiceId: {
    type: [Number, String],
    default: () => null
  },
  invoice: {
    type: Object as () => Invoice,
    default: () => null
  }
});

const showModalContent = ref(false);
const loading = ref(false);
const invoice = ref(null);

const emit = defineEmits(["input", "payment-success", "payment-confirm"]);

watch(
  () => props.value,
  (newVal, oldVal) => {
    if (newVal === oldVal) return;
    if (newVal) {
      setInvoice();
      showModalContent.value = true;
    } else {
      setTimeout(() => {
        showModalContent.value = false;
        invoice.value = null;
      }, 500);
    }
  }
);

watch(invoice, (newValue) => {
  if (newValue) {
    trackStartedPayment({
      invoice_id: newValue.invoice_id,
      customer_id: props.customer?.id
    });
  }
});

const show = computed({
  get() {
    return props.value;
  },
  set(val) {
    emit("input", val);
  }
});

function setInvoice() {
  if (props.invoice) {
    invoice.value = props.invoice;
  } else {
    fetchInvoice();
  }
}

async function fetchInvoice() {
  if (!props.invoiceId) {
    return;
  }

  loading.value = true;
  const response = await API.makeRequest(`/v2/invoices/${props.invoiceId}`, {
    method: "GET"
  });
  invoice.value = response.data;
  loading.value = false;
}

function handlePaymentFailed() {
  trackPaymentFailure({
    invoice_id: invoice.value.invoice_id,
    customer_id: props.customer?.id
  });
}

function handleViewInvoice() {
  dismissModal();
  router.push({
    name: "invoice",
    params: { id: invoice.value.invoice_id }
  });
}

function dismissModal() {
  show.value = false;
}

function handlePaymentSuccess() {
  store.dispatch("fetchUser"); // Fetch user to get latest saved payment methods
  trackFinishedPayment({
    invoice_id: invoice.value.invoice_id,
    customer_id: props.customer?.id
  });
  emit("payment-success");
}

function handlePaymentMethodDeleted() {
  store.dispatch("fetchUser");
}

function handlePaymentSuccessConfirmed() {
  dismissModal();
  emit("payment-confirm");
}
</script>

<style lang="scss">
.invoicePaymentModalContent {
  max-width: 650px;
  margin: auto;
  @media only screen and (max-width: 600px) {
    padding: 15px;
  }
}
</style>
