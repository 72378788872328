import { AuthLocalStorage } from "./auth";

const { email } = AuthLocalStorage;

// const release = window.currentTenant.release;
const env = import.meta.env.VITE_ENV;

// Don't track errors in development or test
if (!["development", "test"].includes(env) && window.Sentry) {
  // Init Sentry
  Sentry.init({
    dsn: "https://8d11639decce9a843d01844afac96431@o271816.ingest.sentry.io/4505755491762176",
    environment: env,
    // release: release,
    // This sets the session and error replays to 0 for customer-portal
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0
  });

  // Set user context
  if (email) {
    Sentry.configureScope(function (scope) {
      scope.setUser({ email: email });
    });
  }

  // Capture full url with params
  Sentry.configureScope(function (scope) {
    scope.setExtra("url_with_params", window.location.href);
  });
}
