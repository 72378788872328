<template>
  <div class="table-cell-container">
    <div class="display-content">
      <ComparisonDisplay v-bind="$props" />
    </div>
  </div>
</template>

<script>
import ComparisonDisplay from "./ComparisonDisplay.vue";

export default {
  components: {
    ComparisonDisplay
  },
  props: {
    base: {
      type: Object,
      default: null
    },
    comparison: {
      type: Object,
      default: null
    },
    techMode: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
.table-cell-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    display: block;
  }
}

.display-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
