<template>
  <LinkCollection title="Recently Viewed" :links="historyLinks" />
</template>

<script>
import LinkCollection from "serviceshift-ui/components/TaskPicker/LinkCollection.vue";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  components: {
    LinkCollection
  },
  props: {
    currentTask: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      history: [],
      max: 5
    };
  },
  computed: {
    historyLinks() {
      // Filter out current task visually
      return this.history
        .filter((task) => task.id !== this.currentTask.id)
        .slice(0, this.max)
        .map((task) => ({
          label: task.name,
          eventName: "upgrade-click",
          data: task
        }));
    }
  },
  mounted() {
    EventBus.$on("get-history-response", this.setHistory);
    EventBus.$emit("get-history");
  },
  beforeDestroy() {
    EventBus.$off("get-history-response", this.setHistory);
  },
  methods: {
    setHistory(history) {
      this.history = history;
    }
  }
};
</script>

<style lang="scss" scoped></style>
