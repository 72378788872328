<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="
      $vuetify.breakpoint.smAndDown
        ? 'dialog-bottom-transition'
        : 'dialog-transition'
    "
    max-width="768"
    :style="dialogStyle"
  >
    <v-card>
      <v-toolbar dark color="secondary">
        <v-btn icon @click="$emit('hide-modal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="editId">Edit Address</v-toolbar-title>
        <v-toolbar-title v-else>Add New Address</v-toolbar-title>
      </v-toolbar>
      <div class="py-6 text-center">
        <v-form
          ref="form"
          v-model="formIsValid"
          lazy-validation
          @submit.prevent="handleSubmit"
        >
          <span class="overline ml-4 block text-left">Address</span>
          <v-card class="ma-4 mt-1 pa-3 bordered-card">
            <AddressField
              ref="address"
              :current-address="currentAddress"
              @validate-form="forceValidate"
            />
            <v-text-field
              v-model="name"
              outlined
              label="Name"
              hint="(Optional) Use to quickly identify an address"
              persistent-hint
              class="mb-3"
            />
            <div>
              <v-switch
                v-model="primary"
                data-test-id="primary-billing-checkbox"
                color="primary"
                class="mr-6 mt-0 mb-0 pb-0"
                label="Make this your Billing Address"
                :disabled="primaryDisabled"
              />
            </div>
            <div>
              <div class="d-flex align-items-center text-left">
                <v-switch
                  v-model="receives_communication"
                  data-test-id="receive-communications-checkbox"
                  class="mt-0 mb-0 pt-0"
                  color="primary"
                >
                  <template #label>
                    <div class="flex-1">
                      <div>
                        <strong>Receives Additional Communications</strong>
                      </div>
                      <div>
                        <p class="mb-0">
                          Additional contacts defined in the list below will
                          receive notifications.
                        </p>
                      </div>
                    </div>
                  </template>
                </v-switch>
              </div>
              <div v-if="receives_communication">
                <EmailFields
                  data-test-id="email-fields"
                  :validate-global-unique="false"
                  :emails="emails"
                  :additional-rules="additionalEmailRules"
                  @update="handleUpdateEmails($event)"
                />
                <Divider />
                <PhoneFields
                  data-test-id="phone-fields"
                  :phones="phones"
                  :show-set-primary="false"
                  :additional-rules="additionalPhoneRules"
                  @update="handleUpdatePhones($event)"
                  @validate-form="validateForm"
                />
              </div>
            </div>
          </v-card>
          <div>
            <v-btn
              v-if="editId"
              color="primary"
              rounded
              class="ml-4"
              type="submit"
              :loading="loading"
              >Update Address</v-btn
            >
            <v-btn
              v-else
              color="primary"
              rounded
              type="submit"
              :loading="loading"
              >Add Address</v-btn
            >
          </div>
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import API from "serviceshift-ui/api-client";
import { mapState } from "vuex";
import { getAddressComponents } from "@/lib/address";
import { generateUID } from "@/lib/helpers";

import EmailFields from "@/components/AccountModal/EmailFields.vue";
import PhoneFields from "@/components/AccountModal/PhoneFields.vue";
import AddressField from "@/components/AddressField.vue";
import ValidationRules from "@/mixins/validationRules";

export default {
  components: {
    AddressField,
    EmailFields,
    PhoneFields
  },
  mixins: [ValidationRules],
  props: {
    currentAddress: {
      type: Object,
      default: null
    },
    onClose: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      editId: null,
      loading: false,
      deleting: false,
      address: {},
      name: "",
      primary: false,
      dialog: true,
      receives_communication: false,
      phones: [],
      emails: []
    };
  },
  computed: {
    ...mapState({
      user: "user"
    }),
    // TODO: The actions menu doesn't work if the z-index isn't set properly.
    // We need to eventually figure out why that is.
    dialogStyle() {
      return "z-index: 800;'";
    },
    primaryDisabled() {
      const address = this.user.addresses.find(
        (address) => address.id === this.editId
      );
      return address && address.primary;
    }
  },
  watch: {
    receives_communication: function (val) {
      if (!val) return;
      if (!this.phones.length) {
        this.phones.push({
          id: generateUID(),
          new: true,
          phone_number: "",
          type: ""
        });
      }
      if (!this.emails.length) {
        this.emails.push({
          id: generateUID(),
          new: true,
          email_address: "",
          label: ""
        });
      }
    },
    dialog(newValue) {
      if (newValue === false) {
        this.$emit("hide-modal");
      }
    }
  },
  mounted() {
    if (this.currentAddress) {
      this.initAddressFields();
    }
  },
  methods: {
    additionalEmailRules(value) {
      const isUnique =
        this.emails.filter((email) => email.email_address === value).length < 2;
      return isUnique || "Emails must be unique.";
    },
    additionalPhoneRules(value) {
      const isUnique =
        this.phones.filter((phone) => phone.phone_number === value).length < 2;
      return isUnique || "Phone numbers must be unique.";
    },
    handleUpdateEmails(data) {
      this.emails = data;
    },
    handleUpdatePhones(data) {
      this.phones = data;
    },
    initAddressFields() {
      const currentAddress = this.currentAddress;
      this.name = currentAddress.name;
      this.primary = currentAddress.primary;
      this.editId = currentAddress.id;
      this.receives_communication =
        currentAddress.receives_communication || false;
      this.emails = currentAddress.emails || [];
      this.phones = currentAddress.phones || [];
    },
    handleDelete() {
      this.deleting = true;
      const addresses = this.user.addresses.filter(
        (address) => address.id !== this.editId
      );
      API.user
        .update({
          user: {
            addresses_attributes: addresses
          }
        })
        .then((res) => {
          this.$store.dispatch("setUser", res.data);
          this.$emit("hide-modal");
        })
        .finally(() => (this.deleting = false));
    },
    async validateForm() {
      const validChecks = [
        this.$refs.form.validate(),
        !this.$refs.address.addressValidationErrorMessage,
        this.$refs.address.valid
      ];
      return validChecks.every((v) => v === true);
    },
    async handleSubmit() {
      const isValid = await this.validateForm();
      if (!isValid) return;
      this.loading = true;
      try {
        const data = this.getFormData();
        if (this.editId) {
          await API.makeRequest(
            `/v2/customers/${this.user.id}/addresses/${this.editId}`,
            {
              method: "PUT",
              data: {
                address: data
              }
            }
          );
        } else {
          await API.makeRequest(`/v2/customers/${this.user.id}/addresses`, {
            method: "POST",
            data: {
              address: data
            }
          });
        }
        this.$store.dispatch("fetchUser");
        this.$emit("save-success", !!this.editId);
        this.$emit("hide-modal");
      } catch (e) {
        // Do nothing
      }
      this.loading = false;
    },

    getFormData() {
      const addressFieldInfo = this.$refs.address.getAddress();
      const addressComponents = getAddressComponents({
        ...addressFieldInfo,
        name: this.name
      });
      let addressData = addressComponents;
      if (this.editId === null) {
        addressData = addressComponents;
      } else {
        const existingAddress = (this.user.addresses || []).find(
          (address) => address.id === this.editId
        );
        addressData = {
          ...existingAddress,
          ...addressComponents
        };
      }

      // If we decide to toggle off receives communications, we need to
      // filter out all new phones / emails so we don't submit junk data.
      const filteredPhones = this.phones.filter((phone) => {
        return !this.receives_communication ? !phone.new : true;
      });
      const filteredEmails = this.emails.filter((email) => {
        return !this.receives_communication ? !email.new : true;
      });

      return {
        ...addressData,
        primary: this.primary,
        receives_communication: this.receives_communication,
        phones: filteredPhones.map((phone) => ({
          ...phone,
          id: phone.new ? null : phone.id
        })),
        emails: filteredEmails.map((email) => ({
          ...email,
          id: email.new ? null : email.id
        })),
        create_with_tax_setting_default: true
      };
    },
    forceValidate() {
      this.$refs.form.validate();
    }
  }
};
</script>

<style lang="scss" scoped></style>
