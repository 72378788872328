<template>
  <div>
    <Page title="All Quotes" max-width="850">
      <p class="text-center">
        Review or request service from {{ tenant.name }} for the following
        quotes by selecting the Proceed with Service option.
      </p>
      <Datatable
        :headers="headers"
        :report-path="'/reports/consumer/quotes.json'"
        :sort-by="'id'"
        :sort-desc="true"
      >
        <template #item.actions="{ item }">
          <div class="d-flex justify-end align-center">
            <div class="d-flex">
              <a href="" @click.prevent="toggleProceedModal(item)">
                Proceed with Service
              </a>
              <v-divider vertical class="mx-3"></v-divider>
            </div>
            <router-link
              :to="{ name: 'quotePreview', params: { id: item.id } }"
            >
              View Quote
            </router-link>
          </div>
        </template>
      </Datatable>
    </Page>
    <quote-proceed-modal
      v-if="proceedModal"
      :quote="selectedQuote"
      @hide-modal="toggleProceedModal"
      @save-success="toggleProceedSuccess"
    ></quote-proceed-modal>
    <success-toast v-if="proceedSuccess" @hide-modal="toggleProceedSuccess">
      <div slot="body">
        <p>Your request for service has been successfully submitted.</p>
      </div>
    </success-toast>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Datatable from "@/components/Datatable.vue";
import Page from "@/components/Page.vue";
import QuoteProceedModal from "@/components/QuoteProceedModal.vue";
import SuccessToast from "@/components/SuccessToast.vue";

export default {
  components: {
    Page,
    QuoteProceedModal,
    SuccessToast,
    Datatable
  },
  data() {
    return {
      proceedModal: false,
      selectedQuote: null,
      proceedSuccess: false,
      headers: [
        { text: "Date", value: "date" },
        { text: "Name", value: "name" },
        { text: "Description", value: "summary" },
        { text: "Quote #", value: "id" },
        { text: "Job #", value: "job_id" },
        { text: "Total", value: "total", align: "end" },
        { text: "", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    ...mapState({
      user: "user",
      tenant: "tenant"
    })
  },
  methods: {
    toggleProceedModal(quote) {
      this.selectedQuote = quote;
      this.proceedModal = !this.proceedModal;
    },
    toggleProceedSuccess() {
      this.proceedSuccess = !this.proceedSuccess;
    }
  }
};
</script>

<style lang="scss"></style>
