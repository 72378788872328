<template>
  <i
    :class="[{ light }, `fas fa-chevron-${direction}`, 'text-link-color']"
    v-on="$listeners"
  />
</template>

<script>
export default {
  props: {
    direction: {
      type: String,
      default: "right"
    },
    light: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
i {
  font-size: 1em;
  &.light {
    color: white;
  }
}
</style>
