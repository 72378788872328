<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="
      $vuetify.breakpoint.smAndDown
        ? 'dialog-bottom-transition'
        : 'dialog-transition'
    "
    max-width="550"
  >
    <v-card>
      <v-toolbar dark color="secondary">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit Payment Preferences</v-toolbar-title>
      </v-toolbar>
      <div class="py-6 px-4 actions">
        <PaymentMethods
          ref="customerStoredPaymentMethods"
          stored-methods-mode
          class="mb-3"
        />
        <div class="text-center">
          <Recaptcha ref="recaptcha"></Recaptcha>
          <v-btn
            color="primary"
            rounded
            :loading="loading"
            @click="handleSubmit"
            >Update Preferences</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

import PaymentMethods from "@/modules/paymentMethods/components/PaymentMethods.vue";
import usePaymentMethods from "@/modules/paymentMethods/usePaymentMethods";
import Recaptcha from "./Recaptcha.vue";

export default {
  components: {
    PaymentMethods,
    Recaptcha
  },
  setup() {
    const { updatePaymentMethod } = usePaymentMethods();
    return {
      updatePaymentMethod
    };
  },
  data() {
    return {
      dialog: true,
      loading: false
    };
  },
  computed: {
    ...mapState({
      user: "user"
    })
  },
  watch: {
    dialog(newValue, oldValue) {
      if (newValue !== oldValue && !newValue) {
        this.$emit("hide-modal");
      }
    }
  },
  methods: {
    ...mapActions(["setGlobalErrorMessage"]),
    async handleSubmit() {
      const userVerified = await this.$refs.recaptcha.validate();
      if (!userVerified) return;
      this.loading = true;
      try {
        const result = await this.$refs.customerStoredPaymentMethods.getData();
        await this.updatePaymentMethod(result);
        await this.$store.dispatch("fetchUser");
        this.dialog = false;
      } catch (e) {
        this.setGlobalErrorMessage(
          "Your payment method could not be processed. Please try again or try a different payment method."
        );
      }
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.paymentProcessingError {
  color: #f00;
  font-weight: bold;
}
.actions {
  padding-top: 30px;
  background: #fff;
}
</style>
