<template>
  <div class="login-body">
    <div class="login-container">
      <h2 v-if="!submitted" class="display-1 text-center mb-3">
        Create New Password
      </h2>
      <v-card v-if="!submitted" width="350px" class="bordered-card">
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="handleSubmit"
          >
            <v-text-field
              v-model="password"
              outlined
              label="Password"
              :loading="loading"
              type="password"
              :rules="validation"
              validate-on-blur
            ></v-text-field>
            <v-text-field
              v-model="confirmPassword"
              outlined
              label="Confirm Password"
              :loading="loading"
              type="password"
              :rules="validation"
              validate-on-blur
            ></v-text-field>
            <div class="buttons">
              <v-btn
                block
                large
                color="primary"
                type="submit"
                :loading="loading"
                >Save Password</v-btn
              >
              <router-link class="forgot-password" :to="{ name: 'login' }"
                >Log In</router-link
              >
            </div>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          <v-icon class="mr-1">mdi-shield-check</v-icon>Password Saved
        </v-card-title>
        <v-card-text>Your password has been successfully updated.</v-card-text>
        <v-card-actions>
          <v-btn text color="primary">
            <router-link class="forgot-password" :to="{ name: 'login' }"
              >Log in with new password</router-link
            >
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordReset",
  data() {
    return {
      email: "",
      loading: false,
      submitted: false,
      password: "",
      confirmPassword: "",
      validation: [this.validatePassword],
      valid: true
    };
  },
  methods: {
    handleSubmit() {
      // FIXME: Need endpoint for updating password
      // FIXME: Need to use the reset_token param
      if (this.$refs.form.validate()) {
        this.submitted = true;
        // this.loading = true;
        // API.user
        //   .forgotPassword({
        //     session: { email: this.email }
        //   })
        //   .finally(res => {
        //     this.loading = false;
        //     this.submitted = true;
        //   });
      }
    },
    validatePassword() {
      return this.password && this.confirmPassword
        ? this.password === this.confirmPassword || "Passwords do not match!"
        : true;
    }
  }
};
</script>

<style lang="scss" scoped>
.login-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  .bordered-card {
    border-top: 5px solid var(--v-secondary-base);
  }
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .forgot-password {
      font-size: 0.9em;
      margin-top: 15px;
    }
  }
}
</style>
