<template>
  <div>
    <!-- eslint-disable vue/no-mutating-props -->
    <div v-if="checkPayableTo" class="p-4 text-center bg-white">
      Make check payable to: <b>{{ checkPayableTo }}</b>
    </div>
    <div class="mb-3 px-4">
      <Input
        v-model="form.check_number"
        solo
        name="Check Number"
        class="mb-1 w-full border-bottom"
        label="Check Number"
      />
      <div class="border-bottom mb-3">
        <Select
          v-model="form.check_dl_state"
          :options="stateOptions"
          placeholder="DL State"
          label="Check Drivers License"
        />
      </div>
      <Input
        v-model="form.check_dl_number"
        solo
        name="Drivers License #"
        class="w-full border-bottom"
        label="Drivers License #"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { STATE_OPTIONS } from "serviceshift-ui/shared/src/lib/stateOptions";
import { computed } from "vue";

import Input from "@/components/Input.vue";
import usePaymentMethods from "../usePaymentMethods";

const { paymentMethodOptions } = usePaymentMethods();

defineProps({
  form: { type: Object, default: null }
});

const checkPayableTo = computed(() => {
  const check = paymentMethodOptions.value.find(
    (method) => method.key === "check"
  );
  return check?.check_payable_to;
});

const stateOptions = computed(() => {
  return STATE_OPTIONS;
});
</script>

<style scoped lang="scss">
:deep(.select-box) {
  margin-bottom: 7px;
  box-shadow: none;
}
.single-row {
  display: flex;
  align-items: center;
  .field-label {
    padding-left: 20px;
  }
}
.select-box {
  justify-content: flex-start !important;
}
</style>
