<template>
  <AppBanner
    v-if="appointment && isAppointmentUpcoming && $store.state.user"
    color="#FDF9F3"
  >
    <div class="d-flex">
      {{ $store.state.user.first_name }} - your appointment is in
      <b class="ml-1">{{ countdown }}</b
      >!
      <v-btn
        color="primary"
        text
        outlined
        rounded
        :to="{ name: 'appointment', params: { id: appointment.id } }"
        x-small
        class="ml-2 pa-0 caption text-center"
        >View</v-btn
      >
    </div>
  </AppBanner>
</template>

<script>
import pluralize from "pluralize";
import { getDifference, isTimeBefore } from "@/lib/date";

import AppBanner from "@/components/AppBanner.vue";

export default {
  components: {
    AppBanner
  },
  data() {
    return {
      appointment: null
    };
  },
  computed: {
    countdown() {
      const diff = getDifference(
        new Date(),
        new Date(this.appointment.datetime)
      );

      if (diff.days) {
        return `${diff.days} ${pluralize("days", diff.days)}`;
      }

      return `${diff.hours} ${pluralize("hours", diff.hours)} & ${
        diff.minutes
      } min`;
    },
    isAppointmentUpcoming() {
      return isTimeBefore(new Date(), new Date(this.appointment.datetime));
    }
  },
  watch: {
    "$store.state.recentAppointments": function () {
      this.getUpcomingAppointment();
    }
  },
  mounted() {
    if (this.$store.state.recentAppointmentsLoaded) {
      this.getUpcomingAppointment();
    }
  },
  methods: {
    getUpcomingAppointment() {
      this.appointment = this.$store.state.recentAppointments.find((appt) =>
        ["Pending", "Assigned"].includes(appt.status)
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
