<template>
  <Tooltip ref="tooltipRef" :options="tippyOptions">
    {{/* eslint-disable vue/no-v-html */}}
    <template #trigger>
      <div class="action-menu-trigger" data-cy="actions-menu-trigger">
        <slot name="trigger">
          <i class="fas fa-ellipsis-v text-link-color cursor-pointer" />
        </slot>
      </div>
    </template>
    <template #tooltip>
      <div ref="content">
        <ul class="actions-menu-list">
          <li
            v-for="action in visibleActions"
            :key="action.label"
            :data-cy="action.label"
            :class="[
              `d-flex align-items-center action-item ${action.className || ''}`,
              { disabled: action.disabled },
              { '!tw-whitespace-nowrap': noWrap }
            ]"
            @click="performAction(action)"
          >
            <Tooltip :disabled="!action.tooltip" placement="top">
              <template #trigger>
                <img
                  v-if="action.source"
                  :src="action.source"
                  class="tw-mr-2 image-icon"
                />
                <i v-if="action.icon" :class="[action.icon, 'tw-mr-2']" />
                <span v-if="action.label">{{ action.label }}</span>
                <span v-if="action.html" v-html="action.html" />
              </template>
              <template #tooltip>{{ action.tooltip }}</template>
            </Tooltip>
          </li>
        </ul>
      </div>
    </template>
  </Tooltip>
</template>

<script>
import Tooltip from "serviceshift-ui/components/General/Tooltip.vue";

export default {
  name: "ActionsMenu",
  components: {
    Tooltip
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    actions: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => ({})
    },
    placement: {
      type: String,
      default: "left"
    },
    noWrap: {
      type: Boolean,
      default: false
    },
    techMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tippyOptions: {
        placement: this.placement,
        ...this.options,
        theme: `${this.options.theme || ""} ${
          this.techMode ? "tech-actions-menu" : ""
        } actions-menu light`,
        onShow: this.onShow,
        onHide: this.onHide
      }
    };
  },
  computed: {
    visibleActions() {
      return this.actions.filter(
        (action) => !Object.keys(action).includes("visible") || action.visible
      );
    }
  },
  methods: {
    performAction(action) {
      if (!action.disabled) {
        if (action.eventName) {
          this.$refs.tooltipRef.hideTooltip();
          this.$emit(action.eventName);
        }
      }
    },
    onShow(instance) {
      return this.$emit("on-show", instance);
    },
    onHide(instance) {
      return this.$emit("on-hide", instance);
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  min-width: 100px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font: inherit;
  li {
    white-space: normal;
    color: #fff;
    cursor: pointer;
    padding: 5px 10px;
    &:hover {
      color: $pale-blue !important;
    }
    &:last-of-type {
      border-bottom: none !important;
    }
    &.disabled {
      cursor: not-allowed !important;
      color: $gray;
      opacity: 0.6;
    }
    i {
      width: 1em;
    }
    .icon-image {
      height: 1em;
      width: 1em;
    }
    .image-icon {
      width: 16px;
      height: 16px;
    }
  }
}
.action-menu-trigger {
  padding: 0 5px;
}
</style>
