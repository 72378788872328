<template>
  <div class="d-flex">
    <div class="tech-image-container">
      <i v-if="isFirst" class="crown-icon fas fa-crown" />
      <div
        v-if="technician.avatar.url"
        class="tech-avatar"
        :style="{
          'background-image': `url(${technician.avatar.url})`,
          'background-position': 'center'
        }"
      />
      <Avatar
        v-else
        :id="technician.technician_id"
        class="tech-avatar avatar-icon"
        :content="initials"
      />
    </div>
    <div
      class="tech-info"
      :class="{ 'first-row': isFirst, 'tech-app': techMode }"
    >
      <div class="tech-name">
        <span v-if="techMode">{{ technician.full_name }}</span>
        <a v-else :href="technician.technician_details_url">{{
          technician.full_name
        }}</a>
        <i v-if="technician.archived" class="fas fa-archive" />
        <i v-if="technician.installer" class="fas fa-wrench" />
      </div>
      <div v-if="!techMode">
        {{ technician.department.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { awsAsset } from "serviceshift-ui/shared/src/mixins/aws";

import Avatar from "./Avatar.vue";

export default {
  components: {
    Avatar
  },
  props: {
    technician: {
      type: Object,
      default: () => ({})
    },
    techMode: {
      type: Boolean,
      default: false
    },
    isFirst: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    initials() {
      const names = this.technician.full_name
        .trim()
        .split(" ")
        .filter((element) => element.length);
      return names.length >= 2
        ? (names[0] || "")[0] + (names[1] || "")[0]
        : null;
    }
  },
  methods: {
    getSvg(svgName) {
      return awsAsset(svgName, "leaderboard", "svg");
    }
  }
};
</script>

<style lang="scss" scoped>
.tech-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tech-avatar {
  min-width: 48px;
  width: 48px;
  height: 48px;
  background-size: cover;
  border-radius: 50%;
}

.tech-info {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  justify-content: center;
  margin-left: 1rem;
  i {
    font-size: 1em;
    margin-left: 5px;
    color: $primary-color;
  }
}

.first-row {
  justify-content: flex-end;
  &.tech-app {
    margin-bottom: 1em;
  }
}

.tech-name {
  font-size: 1em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.avatar-icon {
  font-size: 1em;
}

.crown-icon {
  font-size: 1em;
  display: flex;
  color: #f3ba56;
  justify-content: center;
  margin-bottom: 2px;
}
</style>
