/**
 * Easily creates a localStorage object with an array of keys
 * Ex: createLocalStorage<{ token: string}>("example", ["token"]);
 */
export function createLocalStorage<Def extends Record<string, any>>(
  lookupKey: string,
  keys: Array<keyof Def>
) {
  const storage = {
    clear,
    setItems,

    // NOTE: Since this uses custom getters, using the spread operator will not include the getters. Adding
    // this helper method helps us get all the values from the getters
    values() {
      const val: any = {};
      keys.forEach((key) => {
        val[key] = storage[key as any];
      });
      return val as Def;
    }
  };
  keys.forEach((key) => {
    const keyName = getKeyName(key as string);
    Object.defineProperty(storage, key, {
      get() {
        return localStorage.getItem(keyName);
      },
      set(val: Def[typeof key] | null) {
        if (val !== null) localStorage.setItem(keyName, val);
        else localStorage.removeItem(keyName);
      }
    });
    storage[key as any] = localStorage.getItem(keyName);
  });

  function getKeyName(key: string) {
    return `${lookupKey}-${key}`;
  }

  function setItems(data: Partial<Def>) {
    Object.keys(data).forEach((key) => {
      storage[key] = data[key];
    });
  }

  function clear() {
    keys.forEach((key) => {
      localStorage.removeItem(getKeyName(key as string));
    });
  }

  return storage as typeof storage & Def;
}
