<template>
  <div :style="computedStyle" :class="className" />
</template>

<script lang="ts">
export default {
  name: "Divider",
  inheritAttrs: false,
  customOptions: {}
};
</script>

<script setup lang="ts">
import { computed, withDefaults } from "vue";

const props = withDefaults(
  defineProps<{
    size?: number;
    vertical?: boolean;
  }>(),
  { size: 15 }
);

const computedStyle = computed(function () {
  if (props.vertical) {
    return {
      minHeight: `${props.size}px`
    };
  }
  return undefined;
});

const className = computed(() => (props.vertical ? "vertical" : "horizontal"));
</script>

<style lang="scss" scoped>
.horizontal {
  border-top: 1px solid $medium-color;
  margin: 10px 0;
}
.vertical {
  border-left: 1px solid $medium-color;
  margin: 0 10px;
}
</style>
