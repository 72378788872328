<template>
  <div class="bottom-margin quote-fields">
    <div>
      <label class="bottom-margin">
        <div class="field-label">Name</div>
        <input
          v-model="name"
          class="ss-input-field name-input"
          @blur="handleNameChange"
        />
      </label>
      <div v-if="!quote.job_id">
        <slot
          v-if="$scopedSlots.serviceAddressField"
          name="serviceAddressField"
          :address-id="quote.service_address_id"
          :update-address-id="handleCustomAddressIdChange"
        />
        <Select
          v-else
          v-model="quote.service_address_id"
          label="Service Address"
          class="bottom-margin address-input"
          placeholder="Type or Select a Service Address"
          :options="addresses"
          search
        >
          <div slot="no-results" class="no-address-results-message">
            There's no match for this address. Make sure to type the correct
            address or add a new one.
            <div class="add-address-btn" @click="handleAddAddress">
              <img :src="PlusCircleSolid" />
              Add New Address
            </div>
          </div>
        </Select>
      </div>
      <label class="full-width">
        <div class="field-label">Summary</div>
        <textarea
          v-model="summary"
          class="ss-input-field summary-field"
          @blur="handleSummaryChange"
        />
      </label>
    </div>
  </div>
</template>

<script>
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import PlusCircleSolid from "serviceshift-ui/shared/src/assets/plus-circle-solid.svg";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";
import debounce from "serviceshift-ui/shared/src/mixins/debounce";

import Select from "../Inputs/Select.vue";

export default {
  name: "QuoteFields",
  components: { Select },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    /** @type { QuoteInvoiceTypes.Quote } */
    quote: {
      type: Object,
      default: () => ({})
    },
    /** @type {QuoteInvoiceTypes.Address[]} */
    customerAddresses: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      PlusCircleSolid,
      name: this.quote.name,
      summary: this.quote.summary
    };
  },
  computed: {
    addresses() {
      return this.customerAddresses.map((address) => ({
        label: this.addressLabel(address),
        value: address.id
      }));
    }
  },
  watch: {
    "quote.service_address_id": function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleAddress(newValue, "address");
      }
    }
  },
  methods: {
    handleCustomAddressIdChange(addressId) {
      this.quote.service_address_id = addressId;
    },
    /** @param address {QuoteInvoiceTypes.Address} */
    addressLabel(address) {
      if (!address) {
        return "Service Address Unavailable";
      }
      return `${address.street} ${address.city}, ${address.state} ${address.zip_code}`;
    },
    handleAddAddress() {
      EventBus.$emit("add-address-click", this.quote.id);
    },
    handleAddress: debounce(function (val, field) {
      EventBus.$emit(
        `edit-quote-${field}`,
        {
          target: {
            value: val
          }
        },
        this.quote.id
      );
    }, 750),
    handleNameChange() {
      EventBus.$emit(
        `edit-quote-name`,
        {
          target: {
            value: this.name
          }
        },
        this.quote.id
      );
    },
    handleSummaryChange() {
      EventBus.$emit(
        `edit-quote-summary`,
        {
          target: {
            value: this.summary
          }
        },
        this.quote.id
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.bottom-margin {
  margin-bottom: 10px;
  display: block;
}
.quote-name {
  margin-bottom: 0;
}
.quote-address {
  margin: 8px 0 0 0;
}
.quote-summary {
  margin-top: 8px;
  white-space: pre-line;
}
.quote-details {
  margin-bottom: 20px;
}
.summary-field {
  width: 100%;
}
.name-input,
.address-input {
  width: 50%;
  min-width: 200px;
}
.no-address-results-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .add-address-btn {
    border: 2px dashed $primary-color;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary-color;
    margin: 10px 0;
    width: 100%;
    max-width: 200px;
    cursor: pointer;
    border-radius: 3px;
    img {
      height: 12px;
      margin-right: 5px;
    }
  }
}
</style>
