<template>
  <div class="stax-form">
    <div class="px-4" :class="{ hidden: !ready }">
      <PaymentMethodInput
        required
        :error="errorMessages.nameOnCard"
        label="Name on Card"
        class="w-full mb-1"
      >
        <Input v-model="name" solo />
      </PaymentMethodInput>
      <div class="border-bottom mb-1 flex items-center">
        <PaymentMethodInput
          required
          iframe-input
          :error="errorMessages['number']"
          label="Card Number"
          :class="[
            'card-number mr-4',
            { invalid: !validNumber },
            { valid: validNumber }
          ]"
        >
          <div
            class="card-type no-error-highlight"
            :class="['card-type-' + (cardType || 'none')]"
          ></div>
          <div id="card-number" class="stax-field" />
        </PaymentMethodInput>
      </div>
      <div class="d-flex mb-1">
        <div class="flex items-center mr-4 exp-field">
          <PaymentMethodInput
            required
            :error="errorMessages['month'] || errorMessages['year']"
            label="Expiration Date"
            class="card-expiration"
            :class="[{ invalid: !validExp }, { valid: validExp }]"
          >
            <Input
              v-model="expiration"
              solo
              placeholder="MM / YYYY"
              @keypress="exp_typed"
              @input="exp_changed"
            />
          </PaymentMethodInput>
        </div>
        <div
          class="flex items-center border-right cvv-field"
          :class="[{ invalid: !validCvv }, { valid: validCvv }]"
        >
          <PaymentMethodInput
            required
            iframe-input
            :error="errorMessages.cvv"
            label="CVV"
            tooltip="The CVV is a 3-4 digit number on the back of VISA, MasterCard, Discover, or from the front of American Express cards."
          >
            <div id="card-cvv" class="stax-field" />
          </PaymentMethodInput>
        </div>
      </div>
      <BillingAddressForm
        ref="billingAddressForm"
        :address="billingAddress"
        class="mb-1"
      />
      <div
        v-if="showSavePayment && config && config.card_savable && !autoSaveCard"
        class="flex-col mb-3 mt-4"
      >
        <SavePaymentMethodCheckbox
          v-model="saveCard"
          payment-type="Credit Card"
        />
      </div>
    </div>

    <div v-if="!ready" class="text-center p-6 my-4">
      Loading payment form...
    </div>
  </div>
</template>

<script lang="ts" setup>
import { extractName } from "serviceshift-ui/shared/src/mixins";
import { computed, onMounted, ref } from "vue";

import Input from "@/components/Input.vue";
import BillingAddressForm from "@/modules/paymentMethods/components/BillingAddressForm.vue";
import usePaymentMethods from "../usePaymentMethods";
import PaymentMethodInput from "./PaymentMethodInput.vue";
import SavePaymentMethodCheckbox from "./SavePaymentMethodCheckbox.vue";

const { paymentMethodOptions, billingAddress, customer } = usePaymentMethods();

const props = defineProps({
  showSavePayment: {
    type: Boolean,
    default: true
  },
  autoSaveCard: {
    type: Boolean,
    default: false
  }
});

defineExpose({
  getData,
  resetForm
});

const staxJs = ref(null as any);
const name = ref("");
const expiration = ref<any>("");
const cardType = ref("");
const validNumber = ref(false);
const validCvv = ref(false);
const validExp = ref(false);
const resolver = ref(null as any);
const rejector = ref(null as any);
const saveCard = ref(false);
const ready = ref(false);
const billingAddressForm = ref<any>(null);

const errorMessages = ref({
  nameOnCard: "",
  number: "",
  cvv: "",
  month: "",
  year: ""
});

const config = computed(() => {
  return paymentMethodOptions.value.find(
    (method) => method.key === "stax_card"
  );
});

const expirationMonth = computed(() => {
  if (expiration.value) {
    const split = expiration.value.split("/");
    return split.length ? split[0] : "";
  }
  return "";
});

const expirationYear = computed(() => {
  if (expiration.value) {
    const split = expiration.value.split("/");
    return split.length > 1 ? split[1] : "";
  }
  return "";
});

const firstName = computed(() => {
  return extractName(name.value).firstName;
});

const lastName = computed(() => {
  return extractName(name.value).lastName;
});

onMounted(() => {
  initForm();
});

function exp_typed(event) {
  // only allow valid characters (digits or "/") and discard anything longer than 7
  const re = RegExp("[0-9/]+");
  if (!re.test(event.key) || event.target.value.length >= 7) {
    event.preventDefault();
  }
  if (
    re.test(event.key) &&
    event.target.value.length + event.key.length === 7
  ) {
    validExp.value = true;
  } else {
    validExp.value = false;
  }
}

function exp_changed(value) {
  // autocorrect and insert "/" character
  expiration.value = value
    .replace(/[^0-9]/g, "") // remove non-digit character
    .replace(/^([2-9])$/g, "0$1") // convert "2" to "9" to "0X" since there's only 12 months
    .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2") // auto insert the "/" character -> "122" to "12/2" for 3-character string
    .replace(/^0{1,}/g, "0") // replace "0000" with "0"
    .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g, "$1/$2"); // auto insert "/" character for longer string
}

function clearErrors() {
  Object.keys(errorMessages.value).forEach((key) => {
    errorMessages.value[key] = "";
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getData() {
  clearErrors();
  return new Promise(async (resolve, reject) => {
    resolver.value = resolve;
    rejector.value = reject;

    // Quick front end validation
    let errorMessage = "";
    let billingAddressData = {} as any;
    if (!firstName.value) {
      errorMessage = "Name is required.";
      errorMessages.value.nameOnCard = errorMessage;
    } else if (!expiration.value) {
      errorMessage = "Expiration date is required.";
      errorMessages.value.month = errorMessage;
    }
    try {
      billingAddressData = await billingAddressForm.value!.getData();
    } catch (e: any) {
      errorMessage = e;
    }
    if (errorMessage) {
      throw rejector.value(
        "Unable to submit Stax CC Payment with error: ",
        errorMessage
      );
    }

    const details = {
      method: "card",
      first_name: firstName.value,
      last_name: lastName.value,
      month: expirationMonth.value,
      year: expirationYear.value,
      address_1: billingAddressData.street || "",
      address_city: billingAddressData.city || "",
      address_state: billingAddressData.state || "",
      address_zip: billingAddressData.zip_code || ""
    };
    return staxJs.value
      .tokenize(details)
      .then((response) => {
        if (response.has_cvv !== true) {
          const errorMessage = "Please provide a valid CVV";
          errorMessages.value.cvv = errorMessage;
          rejector.value(errorMessage);
          return;
        }

        resolve({
          ...billingAddressData,
          name: name.value,
          card_token: response.id,
          card_save_payment_method: saveCard.value || props.autoSaveCard
        });
      })
      .catch((err) => {
        highlightFieldErrors(err);
        throw rejector.value(
          "Unable to submit Stax CC Payment with error: ",
          err
        );
      });
  });
}

function highlightFieldErrors(err: any) {
  if (!err) return;
  if (err.field) {
    errorMessages.value[err.field] = err.message;
  }

  // If the err is an object, map existing keys
  Object.keys(errorMessages.value).forEach((key) => {
    if (err[key]) errorMessages.value[key] = err[key][0];
  });
}

function initForm() {
  if (!config.value) {
    return;
  }

  if (customer.value) {
    name.value =
      customer.value.full_name ||
      `${customer.value.first_name} ${customer.value.last_name}`;
  }

  // eslint-disable-next-line
    staxJs.value = new StaxJs(config.value.web_id, {
    number: {
      id: "card-number",
      placeholder: "•••• •••• •••• ••••",
      style: "padding: 14px 15px; letter-spacing: 2px; width: 100%",
      type: "text",
      format: "prettyFormat" // the formatting of the CC number (prettyFormat || plainFormat || maskedFormat)
    },
    cvv: {
      id: "card-cvv",
      placeholder: "•••",
      style: "padding: 14px 15px; letter-spacing: 2px",
      type: "text"
    }
  });

  staxJs.value
    .showCardForm()
    .then((ready.value = true))
    .catch((err) => {
      ready.value = false;
      console.log(err);
      // Sentry.captureException(
      //   new Error("Stax form initialization error: " + err)
      // );
    });

  // watch for 'cardType' so we can show it visually (with a background image)
  staxJs.value.on("card_form_complete", (message) => {
    cardType.value = message.cardType;
    validNumber.value = message.validNumber;
    validCvv.value = message.validCvv;
  });

  staxJs.value.on("card_form_incomplete", (message) => {
    cardType.value = message.cardType;
    validNumber.value = message.validNumber;
    validCvv.value = message.validCvv;
  });
}

function resetForm() {
  initForm();
}
</script>

<style lang="scss">
.paymentProcessingError {
  color: #f00;
  font-weight: bold;
}
.stax-form {
  :deep(iframe) {
    width: 100%;
    min-height: 45px;
  }
  .card-number {
    width: 100%;
  }
  .card-type {
    position: absolute;
    right: 0;
    z-index: 1;
    height: 32px;
    width: 64px;
  }
  .card-type-none {
    background-image: none;
  }
  .card-type-visa {
    background: rgba(0, 0, 0, 0)
      url("https://hps.github.io/token/gp-1.0.0/images/logo-visa@2x.png")
      no-repeat right;
    background-position-y: 8px;
    background-size: 60px 64px;
  }
  .card-type-master {
    background: rgba(0, 0, 0, 0)
      url("https://hps.github.io/token/gp-1.0.0/images/logo-mastercard@2x.png")
      no-repeat right;
    background-position-y: 4px;
    background-size: 72px 76px;
  }
  .card-type-discover {
    background: rgba(0, 0, 0, 0)
      url("https://hps.github.io/token/gp-1.0.0/images/logo-discover@2x.png")
      no-repeat right;
    background-position-y: 8px;
    background-size: 60px 64px;
  }
  .card-type-american_express {
    background: rgba(0, 0, 0, 0)
      url("https://hps.github.io/token/gp-1.0.0/images/logo-amex@2x.png")
      no-repeat right;
    background-position-y: 8px;
    background-size: 70px 74px;
  }
  .card-type-jcb:deep(iframe) {
    background: rgba(0, 0, 0, 0)
      url("https://hps.github.io/token/gp-1.0.0/images/logo-jcb@2x.png")
      no-repeat right;
    background-position-y: 8px;
    background-size: 60px 64px;
  }
  .label {
    margin-bottom: 3px;
    font-weight: bold;
  }
  .submit-container {
    display: none;
  }
  .stax-field {
    height: 48px;
    overflow: hidden;
    width: 100%;
  }

  .label {
    font-size: 1.1em;
    margin-right: 15px;
    white-space: nowrap;
    text-align: left;
  }
  .exp-field {
    flex: 1;
  }
  .cvv-field {
    flex: initial;
    width: 150px;
  }
  .cvv-field,
  .exp-field {
    overflow: hidden;
  }

  .valid .stax-field {
    background-color: #d6ffd9;
  }
  .invalid .stax-field {
    border: none;
    background-color: #f7e5e5;
  }
  button: {
    display: none;
  }
}
</style>
