<template>
  <div class="task-table-cont">
    <Transition>
      <div v-if="rows.length" ref="table" class="task-table">
        <TaskRow
          v-for="task in rows"
          :key="`${task.kind}-${task.id}`"
          :task="task"
          :membership-program-id="membershipProgramId"
          :replace-task-id="replaceTaskId"
          :show-prices="showPrices"
          :show-addons="showAddons"
          :show-upgrades="showUpgrades"
          class="task-row"
        />
        <div v-if="rows.length >= renderLimit" class="render-limit-message">
          Showing {{ renderLimit.toLocaleString() }} out of
          {{ tasks.length.toLocaleString() }} tasks.
        </div>
      </div>
      <div v-else class="no-results">No Results</div>
    </Transition>
  </div>
</template>

<script>
import Transition from "serviceshift-ui/components/General/Transition.vue";
import TaskRow from "serviceshift-ui/components/TaskPicker/TaskRow.vue";

export default {
  components: {
    TaskRow,
    Transition
  },
  props: {
    showAddons: {
      type: Boolean,
      default: true
    },
    showUpgrades: {
      type: Boolean,
      default: true
    },
    showPrices: {
      type: Boolean,
      default: true
    },
    membershipProgramId: {
      type: Number,
      default: null
    },
    tasks: {
      type: Array,
      default: () => []
    },
    renderLimit: {
      type: Number,
      default: null
    },
    replaceTaskId: {
      type: Number,
      default: null
    }
  },
  computed: {
    rows() {
      let currentCategory = null;
      // Organize tasks by category
      return this.tasks.slice(0, this.renderLimit).reduce((rows, task) => {
        if (task.parentNode !== currentCategory) {
          currentCategory = task.parentNode;
          rows.push({
            id: task.parentNode.flattened_name,
            name: task.parentNode.flattened_name,
            kind: "category"
          });
        }
        rows.push(task);
        return rows;
      }, []);
    }
  }
};
</script>

<style lang="scss" scoped>
.task-table {
  width: 100%;
  border: $border;
  border-radius: 5px;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  .task-row {
    &:nth-of-type(even) {
      background-color: $angel-blue;
    }
    &:nth-of-type(odd) {
      background-color: #fff;
    }
  }
}

.task-table-cont {
  height: 100%;
}
.no-results {
  border: $border;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9em;
  background-color: #fff;
}
</style>
