<template>
  <div class="tw-flex tw-items-center" :class="{ light }">
    <span
      v-if="!hideRootText"
      class="header-link"
      @click="handleClick({ id: 'root' })"
      >{{ rootText }}</span
    >
    <div
      v-for="(crumb, i) in crumbs"
      :key="crumb.id"
      class="tw-flex tw-items-center"
      :class="crumb.className"
      @click="handleClick(crumb)"
    >
      <i
        v-if="i > 0 || !hideRootText"
        class="fas fa-chevron-right tw-mx-1 header-icon"
      />
      <span
        class="crumb"
        :class="{ 'font-bold': isLastCrumb(i), 'header-link': !isLastCrumb(i) }"
      >
        {{ crumb.label }}
      </span>
      <span v-if="crumb.hint" class="font-italic tw-ml-1 hint-crumb"
        >({{ crumb.hint }})</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    crumbs: {
      type: Array,
      default: () => []
    },
    hideRootText: {
      type: Boolean,
      default: false
    },
    rootText: {
      type: String,
      default: "Home"
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(crumb) {
      this.$emit("click", crumb);
    },
    isLastCrumb(index) {
      return index === this.crumbs.length - 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.fa-chevron-right {
  font-size: 0.6em;
}
.crumb {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  color: $text-color-ss;
}

.hint-crumb {
  text-transform: capitalize;
}

.light {
  .crumb,
  .header-link,
  .header-icon {
    color: $white !important;
  }
}
</style>
