<template>
  <div class="links-collection">
    <span>
      <b>{{ title }}</b>
    </span>
    <div v-if="links.length" class="links-cont">
      <a
        v-for="link in links"
        :key="link.label"
        @click="handleClick($event, link)"
        >{{ link.label }}</a
      >
    </div>
    <div v-else>
      {{ emptyText }}
    </div>
  </div>
</template>

<script>
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  props: {
    links: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ""
    },
    emptyText: {
      type: String,
      default: "No results"
    }
  },
  methods: {
    handleClick(e, link) {
      e.stopImmediatePropagation();
      EventBus.$emit(link.eventName, link.data);
    }
  }
};
</script>

<style lang="scss" scoped>
.links-cont {
  display: flex;
  flex-direction: column;
  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
</style>
