<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :transition="
      $vuetify.breakpoint.smAndDown
        ? 'dialog-bottom-transition'
        : 'dialog-transition'
    "
    max-width="768"
  >
    <template #activator="{ on: dialog }">
      <v-tooltip left>
        <template #activator="{ on: tooltip }">
          <v-btn text icon color="secondary" v-on="{ ...dialog, ...tooltip }">
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>Change Password</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar dark color="secondary">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Change Password</v-toolbar-title>
      </v-toolbar>
      <div class="py-6 px-4 text-center">
        <v-form ref="form" lazy-validation @submit.prevent="handleSubmit">
          <span class="overline block mb-2 text-left">Password</span>
          <v-card class="pa-3 bordered-card mb-6">
            <v-text-field
              v-model="oldPassword"
              outlined
              label="Current Password"
              :append-icon="oldVisible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="oldVisible ? 'text' : 'password'"
              :rules="passwordRules"
              @click:append="toggleVisible('old')"
            />
            <v-text-field
              v-model="newPassword"
              outlined
              label="Password"
              :append-icon="newVisible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="newVisible ? 'text' : 'password'"
              :rules="passwordRules"
              @click:append="toggleVisible('new')"
            />
          </v-card>
          <p v-if="error" class="error--text caption">{{ error }}</p>
          <v-btn
            color="primary"
            type="submit"
            rounded
            :loading="loading"
            :class="{ error }"
            >Update Password</v-btn
          >
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import API from "serviceshift-ui/api-client";

import ValidationRules from "../mixins/validationRules";

export default {
  mixins: [ValidationRules],
  data() {
    return {
      error: "",
      dialog: false,
      oldPassword: "",
      newPassword: "",
      newVisible: false,
      oldVisible: false,
      loading: false
    };
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        API.user
          .updatePassword({
            session: {
              password: this.newPassword,
              password_confirmation: this.newPassword,
              current_password: this.oldPassword
            }
          })
          .then((res) => {
            this.$store.dispatch("setUser", res.data);
            this.dialog = false;
          })
          .catch((e) => {
            this.error = e.response.data.message;
          })
          .finally(() => (this.loading = false));
      }
    },
    toggleVisible(type) {
      this[`${type}Visible`] = !this[`${type}Visible`];
    }
  }
};
</script>

<style lang="scss" scoped></style>
