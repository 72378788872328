<template>
  <div class="link" @click="$emit('toggle-expanded')">
    {{ title }}
    <i class="tw-ml-1" :class="chevronIcon" />
  </div>
</template>

<script lang="ts">
export default {
  name: "ExpandButton"
};
</script>

<script setup lang="ts">
import { computed } from "vue";
const props = defineProps<{ expanded?: boolean; title: string }>();
const chevronIcon = computed(() =>
  props.expanded ? "fas fa-chevron-up" : "fas fa-chevron-down"
);
</script>

<style lang="scss" scoped>
.link {
  display: inline;
  font-size: 1em;
  color: $primary-color;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}
</style>
