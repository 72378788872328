<template>
  <div
    class="d-flex align-items-center justify-content-center membership-switcher"
  >
    <span class="quoting-text">
      Quoting
      <a
        :class="{
          'link-underline': quote.actual_membership_program_id,
          'no-underline': !quote.actual_membership_program_id
        }"
        @click="
          quote.actual_membership_program_id
            ? handleClick(quote.actual_membership_program_id, 'actual')
            : null
        "
      >
        {{ quote.actual_membership_program_name }}
      </a>
    </span>

    <div>
      <transition mode="out-in" name="fade">
        <div v-if="!loading" class="swap-icon" @click="handleSwap">
          <slot name="swapIcon">Swap</slot>
        </div>
        <div v-else class="swap-icon">
          <slot name="spinner">Swapping...</slot>
        </div>
      </transition>
    </div>

    <a
      :class="{
        'link-underline': quote.comparison_membership_program_id,
        'no-underline': !quote.comparison_membership_program_id
      }"
      @click="
        quote.comparison_membership_program_id
          ? handleClick(quote.comparison_membership_program_id, 'comparison')
          : null
      "
    >
      <em>{{ quote.comparison_membership_program_name }}</em>
    </a>
  </div>
</template>

<script>
import * as QuoteInvoiceTypes from "serviceshift-ui/components/QuoteInvoice/interface";
import EventBus from "serviceshift-ui/shared/src/lib/event_bus";

export default {
  props: {
    /** @type { QuoteInvoiceTypes.Quote } */
    quote: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    eventId() {
      return this.quote.id;
    }
  },
  methods: {
    handleClick(id, type) {
      EventBus.$emit("membership-click", id, type, this.eventId);
    },
    handleSwap() {
      this.loading = true;
      EventBus.$emit(
        "swap-click",
        () => {
          this.loading = false;
        },
        this.eventId
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.membership-switcher {
  padding: 5px;
  background-color: $pale-blue;
  color: $primary-color;
  font-size: 1.2em;
}
.swap-icon {
  margin: 0 15px;
  font-size: 1.3em;
  cursor: pointer;
}
.link-underline {
  text-decoration: underline;
  cursor: pointer;
  color: $primary-color;
}

.no-underline {
  text-decoration: none !important;
  cursor: default !important;
  color: $text-color-ss !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 250ms ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.quoting-text {
  color: $text-color-ss;
}
</style>
