import axios from "axios";

import ENV from "../env";

export default {
  get: (deptId, id) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/parts/${id}`, {
      method: "GET"
    });
  },
  update: (deptId, id, data) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/parts/${id}`, {
      method: "PUT",
      data
    });
  },
  delete: (deptId, id) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/parts/${id}`, {
      method: "DELETE"
    });
  },
  create: (deptId, data) => {
    return axios(`${ENV.v2Path}/departments/${deptId}/parts`, {
      method: "POST",
      data
    });
  },
  fetchCategories(deptId) {
    return axios(`${ENV.v2Path}/departments/${deptId}/part_categories`, {
      method: "GET"
    });
  },
  fetchCategoryTree(deptId) {
    return axios(`${ENV.v2Path}/departments/${deptId}/parts/organized`, {
      method: "GET"
    });
  },
  fetchCategory(deptId, id) {
    return axios(`${ENV.v2Path}/departments/${deptId}/part_categories/${id}`, {
      method: "GET"
    });
  },
  createCategory(deptId, data) {
    return axios(`${ENV.v2Path}/departments/${deptId}/part_categories`, {
      method: "POST",
      data: {
        part_category: data
      }
    });
  },
  updateCategory(deptId, data) {
    return axios(
      `${ENV.v2Path}/departments/${deptId}/part_categories/${data.id}`,
      {
        method: "PUT",
        data: {
          part_category: data
        }
      }
    );
  },
  deleteCategory(deptId, id) {
    return axios(`${ENV.v2Path}/departments/${deptId}/part_categories/${id}`, {
      method: "DELETE"
    });
  }
};
