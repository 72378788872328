<template>
  <Tooltip placement="top">
    <template #trigger>
      <span class="text-primary">
        <i class="fa-solid fa-circle-info tw-mr-1" />
        <b>Icons/Colors</b>
      </span>
    </template>
    <template #tooltip
      ><img :src="EquipmentIconsLegend" alt="legend"
    /></template>
  </Tooltip>
</template>

<script>
import Tooltip from "serviceshift-ui/components/General/Tooltip.vue";
import EquipmentIconsLegend from "serviceshift-ui/shared/src/assets/equipment-icons_legend.png";

export default {
  name: "EquipmentIconsLegend",
  components: {
    Tooltip
  },
  data() {
    return {
      EquipmentIconsLegend
    };
  }
};
</script>

<style lang="scss" scoped></style>
