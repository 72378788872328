import API from "serviceshift-ui/api-client";
import { Appointment } from "serviceshift-ui/shared/src/typings/appointment";
import { AppointmentReport } from "serviceshift-ui/shared/src/typings/appointmentReport";
import { AuthRole } from "serviceshift-ui/shared/src/typings/auth";
import { Customer } from "serviceshift-ui/shared/src/typings/customer";
import { Invoice } from "serviceshift-ui/shared/src/typings/invoice";
import { Job } from "serviceshift-ui/shared/src/typings/job";
import {
  Tenant,
  TenantCustomizations
} from "serviceshift-ui/shared/src/typings/tenant";
import Vue from "vue";
import Vuex from "vuex";
import { setRequestHeaders } from "@/lib/api";
import { AuthLocalStorage, setAuth } from "@/lib/auth";

import actions from "./actions";
import { formatDate } from "./lib/date";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    globalErrorMessage: "",
    appointment: null as Appointment | null,
    currentQuote: null,
    currentInvoice: null,
    availableDates: [],
    customizations: {},
    equipment: [],
    fetchedMonths: {},
    invoices: [] as Invoice[],
    job: null as Job | null,
    quotes: [],
    recentAppointments: [] as AppointmentReport[],
    recentAppointmentsLoaded: false,
    serviceCategories: [],
    tenant: null as (Tenant & TenantCustomizations) | null,
    timeSlots: [],
    user: null as Customer | null,
    userAppointments: [],
    userAppointmentsLoaded: false
  },
  mutations: {
    updateState(state, payload) {
      state[payload.state] = {
        ...state[payload.state],
        ...payload.data
      };
    },
    replaceState(state, payload) {
      state[payload.state] = payload.data;
    }
  },
  actions: {
    ...actions,
    setGlobalErrorMessage({ commit }, message) {
      commit("replaceState", {
        state: "globalErrorMessage",
        data: message
      });
    },
    async fetchAppointment({ dispatch, commit }, id) {
      return API.appointment.get(id).then(async (res) => {
        commit("replaceState", {
          state: "appointment",
          data: res.data
        });
        await dispatch("fetchJob", res.data.job_id);
        return res;
      });
    },
    async fetchJob({ commit }, jobId) {
      return API.makeRequest(`/v2/jobs/${jobId}`, {
        method: "GET",
        params: { item_grouping: 1 }
      }).then((res) => {
        commit("replaceState", {
          state: "job",
          data: res.data
        });
      });
    },
    async fetchQuote({ commit }, quoteId) {
      return API.makeRequest(`/v2/quotes/${quoteId}`, {
        method: "GET",
        params: { item_grouping: 1 }
      }).then((res) => {
        commit("replaceState", {
          state: "currentQuote",
          data: res.data
        });
      });
    },
    async fetchInvoice({ commit }, invoiceId) {
      return API.makeRequest(`/v2/invoices/${invoiceId}`, {
        method: "GET",
        params: { item_grouping: 1 }
      }).then((res) => {
        commit("replaceState", {
          state: "currentInvoice",
          data: res.data
        });
      });
    },
    fetchInvoices({ commit }, customerId) {
      return API.makeRequest(`/v2/customers/${customerId}/invoices`, {
        method: "GET",
        params: { item_grouping: 1 }
      }).then((res) => {
        commit("replaceState", {
          state: "invoices",
          data: res.data
        });
      });
    },
    fetchQuotes({ commit }, customerId) {
      return API.makeRequest(`/v2/customers/${customerId}/quotes`, {
        method: "GET",
        params: { item_grouping: 1 }
      }).then((res) => {
        commit("replaceState", {
          state: "quotes",
          data: res.data
        });
      });
    },
    fetchAvailableDates({ commit, state }, params) {
      return new Promise((resolve) => {
        const { date, category, zipCode } = params;
        const monthKey = `${date.getFullYear()}-${date.getMonth() + 1}`;
        // Check for month in existing cache
        const cachedKey = Object.keys(state.fetchedMonths).find(
          (key) => key === monthKey
        );
        // Only fetch months once
        if (!cachedKey) {
          API.availability
            .getAvailableDates({
              service_date: formatDate(date),
              expected_duration: category.duration,
              service_category_id: category.id,
              postal_code: zipCode
            })
            .then((res) => {
              commit({
                type: "replaceState",
                state: "availableDates",
                data: { ...res.data }
              });
              // Add month to cache
              commit("updateState", {
                state: "fetchedMonths",
                data: { [monthKey]: res.data }
              });
              resolve(void 0);
            });
        } else {
          // Rehydrate from cache
          commit({
            type: "replaceState",
            state: "availableDates",
            data: state.fetchedMonths[cachedKey]
          });
          resolve(void 0);
        }
      });
    },
    fetchTimeSlots({ commit }, params) {
      const { date, category, zipCode } = params;
      return API.availability
        .getAvailableTimeSlots({
          service_date: formatDate(date),
          expected_duration: category.duration,
          service_category_id: category.id,
          postal_code: zipCode
        })
        .then((res) => {
          commit({
            type: "replaceState",
            state: "timeSlots",
            data: res.data
          });
        });
    },
    fetchUserAppointments({ commit, state }) {
      return API.user.getAllAppointments(state.user!.id).then((res) => {
        commit("replaceState", {
          state: "userAppointments",
          data: res.data
        });
        commit("replaceState", {
          state: "userAppointmentsLoaded",
          data: true
        });
      });
    },
    fetchRecentAppointments({ commit }) {
      const reportPath = "/reports/consumer/appointments.json";
      const reportParams = {
        page: 1,
        per_page: 10,
        sort_by: "date",
        sort_desc: true
      };
      return API.makeRequest(reportPath, {
        method: "GET",
        params: reportParams
      }).then((res) => {
        commit("replaceState", {
          state: "recentAppointments",
          data: res.data.data
        });
        commit("replaceState", {
          state: "recentAppointmentsLoaded",
          data: true
        });
      });
    },
    quoteProceed(_, { link, data }) {
      return API.makeRequest(link, {
        method: "POST",
        data
      });
    },
    createAppointment(_, params) {
      // Add defaults to payload
      const data = { ...params };

      // Add promo code to notes
      if (data.promoCode.length) {
        data.details.notes = `PROMO CODE: ${data.promoCode}<br>--------------------<br>${data.details.notes}`;
      }
      delete data.promoCode;

      return API.appointment.create({ appointment: data });
    },
    uploadPhotos(_, payload) {
      // Create form data for body
      // Add required fields
      const allRequests: any[] = [];
      payload.photos.forEach((photo) => {
        const formData = new FormData();
        formData.append("original_filename", photo.name);
        formData.append("file", photo.file);

        allRequests.push(
          API.appointment.uploadPhoto({
            id: payload.appointmentId,
            body: formData
          })
        );
      });
      return Promise.all(allRequests);
    },
    fetchUser({ dispatch }) {
      return API.user.findByEmail(AuthLocalStorage.email).then((res) => {
        dispatch("setUser", res.data);
      });
    },
    setUser({ commit }, user: Customer | null) {
      if (user) {
        setAuth(user);
        if (user.authentication_token) {
          setRequestHeaders({ ...user, id: undefined });
        }
      } else {
        AuthLocalStorage.clear();
        setRequestHeaders(null);
      }
      commit("replaceState", {
        state: "user",
        data: user
      });
    }
  },
  getters: {
    isCustomer(state) {
      return state.user?.role === AuthRole.customer;
    },
    primaryAddress(state) {
      return state.user?.addresses?.find((a) => a.primary) || {};
    },
    isDevelopment() {
      return import.meta.env.VITE_ENV === "development";
    }
  }
});
