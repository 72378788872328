import axios from "axios";

import ENV from "../env";

export default {
  getAvailableDates: (params) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/availability/available_dates`,
      params
    });
  },
  getBusyDates: (params) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/availability/busy_dates`,
      params
    });
  },
  getAvailableTimeSlots: (params) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/availability/available_time_slots`,
      params
    });
  },
  getDisabledTimeSlots: (params) => {
    return axios({
      method: "GET",
      url: `${ENV.v1Path}/availability/time_slots`,
      params
    });
  }
};
