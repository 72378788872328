import axios from "axios";
import { Id } from "serviceshift-ui/shared/src/typings/globals";

import ENV from "../env";

export default {
  createCategory: (data) => {
    return axios(`${ENV.v2Path}/accounting_categories`, {
      method: "POST",
      data
    });
  },
  deleteCategory: (id: Id) => {
    return axios(`${ENV.v2Path}/accounting_categories/${id}`, {
      method: "DELETE"
    });
  },
  getCategory: (id: Id) => {
    return axios(`${ENV.v2Path}/accounting_categories/${id}`, {
      method: "GET"
    });
  },
  getAllCategories: () => {
    return axios(`${ENV.v2Path}/accounting_categories`, {
      method: "GET"
    });
  },
  updateCategory: (id: Id, data) => {
    return axios(`${ENV.v2Path}/accounting_categories/${id}`, {
      method: "PUT",
      data
    });
  }
};
