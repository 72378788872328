<template>
  <div>
    <div v-if="serviceHistory.length" class="section-spacing-10">
      <div
        v-for="(historyRecord, index) in serviceHistory"
        :key="index"
        class="serviceHistoryTable section-spacing-8"
      >
        <div class="serviceHistoryTable__row">
          <strong class="serviceHistoryTable__row__field">Invoice Date</strong>
          <div class="serviceHistoryTable__row__value">
            {{ formatDate(historyRecord.invoice_finalized_at) }}
          </div>
        </div>
        <div class="serviceHistoryTable__row">
          <strong class="serviceHistoryTable__row__field">Line Item</strong>
          <div class="serviceHistoryTable__row__value">
            {{ historyRecord.invoice_line_item_unit_name || emptyDisplay }}
          </div>
        </div>
        <div class="serviceHistoryTable__row">
          <strong class="serviceHistoryTable__row__field">Owner</strong>
          <div class="serviceHistoryTable__row__value">
            {{ historyRecord.opportunity_owner_full_name || emptyDisplay }}
          </div>
        </div>
        <div class="serviceHistoryTable__row">
          <strong class="serviceHistoryTable__row__field">Invoice #</strong>
          <div class="serviceHistoryTable__row__value">
            {{ historyRecord.invoice_id || emptyDisplay }}
          </div>
        </div>
        <div class="serviceHistoryTable__row">
          <strong class="serviceHistoryTable__row__field">Job #</strong>
          <div class="serviceHistoryTable__row__value">
            <!-- It's possible the job url and job id may not come back if the invoice is created on the customer without scheduling a job -->
            <span v-if="historyRecord.job_id">
              {{ historyRecord.job_id }}
            </span>
            <span v-else>{{ emptyDisplay }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="!tw-mb-0">No Service History Found</p>
    </div>
  </div>
</template>

<script>
import {
  emptyDisplay,
  formatDate,
  tableFieldDisplay
} from "serviceshift-ui/shared/src/mixins";

export default {
  name: "ServiceHistoryTable",
  props: {
    serviceHistory: {
      type: Array, // ServiceHistory[]
      default: () => []
    }
  },
  data() {
    return {
      emptyDisplay
    };
  },
  methods: {
    formatDate,
    getDisplayForField(fieldName) {
      return tableFieldDisplay(fieldName);
    }
  }
};
</script>

<style lang="scss" scoped>
.serviceHistoryTable {
  &__row {
    display: flex;
    align-items: center;
    &__value {
      margin-left: auto;
    }
  }
  &:not(:last-of-type) {
    padding-bottom: 20px;
    border-bottom: 1px solid $border-dark-color;
  }
  &:not(:first-of-type) {
    margin-top: 20px;
  }
}
</style>
